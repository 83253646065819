/* eslint-disable no-unused-vars */
import { Add } from '@mui/icons-material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Button, ButtonGroup, Divider, Menu, MenuItem } from '@mui/material';
import { useTheme } from '@mui/styles';
import DownloadIcon from 'assets/img/download-risk.svg';
import FileIcon from 'assets/img/file-plus-02.svg';
import DeleteIcon from 'assets/img/trash.svg';
import SubTab from 'components/new_components/tabs/SubTab';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { GetAllTemplate } from 'store/actions/riskAssessmentActions';
import { RiskTemplatesTab } from '../utils/constants';

const TopContent = (props) => {
    const {
        setCurrentTab,
        // riskTypeAnchorEl,
        // riskType,
        // riskTypeChange,
        spoolCloseMenu,
        spoolOpenMenu,
        anchorEl,
        closeMenu,
        currentTab,
        // riskTypeCloseMenu,
        // riskTypeOpenMenu,
        spoolAnchorEl,
        openMenu,
        setRunRiskOpen,
        ids,
        // openReslovedModal,
        openDeleteModal,
        openRiskTemplate,
        pageData,
        openUpdateRiskModal,
        openCsvModal,
    } = props;
    const theme = useTheme();

    const [assessmentAnchorEl, setAssessmentAnchorEl] = useState(null);
    const [runTemplateAnchorEl, setRunTemplateAnchorEl] = useState(null);
    const [maatrixAnchorEl, setMatrixAnchorEl] = useState(null);
    const [maatrixAnchorExcelEl, setMatrixAnchorExcelEl] = useState(null);
    const [currentTab_, setCurrentTab_] = useState(0);
    const [search, setSearch] = useState('');

    // redux
    const dispatch = useDispatch();

    const { risk_assessment_templates } = useSelector((state) => state.riskAssessmentReducers);
    const merchant_info = useSelector((state) => state?.merchantReducers?.merchant_info);
    const tabs = [
        {
            index: 0,
            label: 'Smartcomply template',
        },
        {
            index: 1,
            label: 'Custom template',
        },
    ];

    useEffect(() => {
        const getAllRiskTemplate = async () => {
            const res = await dispatch(GetAllTemplate());
            if (!res?.success) {
                toast.error(res?.message);
            }
        };
        getAllRiskTemplate();
    }, []);

    const filtered_template = risk_assessment_templates?.filter((template) =>
        template?.template_name?.toLowerCase()?.includes(search?.toLowerCase())
    );

    return (
        <div className="flex items-center justify-between relative">
            <div className="flex items-center gap-2">
                <button
                    className={`rounded-3xl font-medium text-sm !border !border-[#E2E8F0] transition-all hover:brightness-90 w-fit h-8 px-2  grid place-items-center ${
                        currentTab === 'all' ? 'bg-[#395BA9] text-[#fff]' : 'text-[#475569] bg-[#fff]'
                    }`}
                    onClick={() => {
                        setCurrentTab('all');
                    }}
                >
                    All
                </button>
                <button
                    className={`rounded-3xl font-medium text-sm !border !border-[#E2E8F0] transition-all hover:brightness-90 w-fit h-8 px-2  grid place-items-center ${
                        currentTab === RiskTemplatesTab.FiveByFive
                            ? 'bg-[#395BA9] text-[#fff]'
                            : 'text-[#475569] bg-[#fff]'
                    }`}
                    onClick={() => {
                        setCurrentTab(RiskTemplatesTab.FiveByFive);
                    }}
                >
                    5x5
                </button>

                <button
                    className={`rounded-3xl font-medium text-sm !border !border-[#E2E8F0] transition-all hover:brightness-90 w-fit h-8 px-2  grid place-items-center ${
                        currentTab === RiskTemplatesTab.ThreeByThree
                            ? 'bg-[#395BA9] text-[#fff]'
                            : 'text-[#475569] bg-[#fff]'
                    }`}
                    onClick={() => {
                        setCurrentTab(RiskTemplatesTab.ThreeByThree);
                    }}
                >
                    3x3
                </button>
            </div>
            {ids?.length > 0 && (
                <div className="absolute top-1/2 left-[12.5rem] -translate-y-1/2 z-10">
                    <ButtonGroup
                        className="shadow"
                        variant="contained"
                        color="inherit"
                        sx={{
                            backgroundColor: '#FFFFFF',
                            '&:hover': {
                                backgroundColor: '#FFFFFF',
                            },
                            '&:.MuiButtonGroup-groupedOutlinedVertical': {
                                border: '1px solid #F1F5F9',
                            },
                        }}
                    >
                        {/* <Button
                            startIcon={<img src={FileIcon} alt="FileIcon" />}
                            endIcon={
                                <img
                                    src="/img/risk-assessment/material-symbols_keyboard-arrow-down-rounded.svg"
                                    alt="Arrow Down Icon"
                                    className="object-contain"
                                />
                            }
                            sx={{
                                backgroundColor: '#FFFFFF',
                                color: '#64748B',
                                border: 'none',
                                textTransform: 'inherit',
                                fontSize: '14px',
                                fontWeight: 500,
                                borderRadius: 0,
                                borderRight: 'ipx solid #E2E8F0',
                                '&:hover': {
                                    backgroundColor: '#FFFFFF',
                                    color: '#64748B',
                                    border: 'none',
                                    borderRight: 'ipx solid #E2E8F0',
                                    borderRadius: 0,
                                },
                            }}
                            onClick={(event) => setAssessmentAnchorEl(event.target)}
                        >
                            Run Assessment
                        </Button> */}
                        <Button
                            startIcon={<img src={FileIcon} alt="FileIcon" />}
                            sx={{
                                backgroundColor: '#FFFFFF',
                                color: '#64748B',
                                border: 'none',
                                textTransform: 'inherit',
                                fontSize: '14px',
                                fontWeight: 500,
                                borderRadius: 0,
                                '&:hover': {
                                    backgroundColor: '#FFFFFF',
                                    color: '#64748B',
                                    border: 'none',
                                    borderRight: 'ipx solid #E2E8F0',
                                    borderRadius: 0,
                                },
                            }}
                            onClick={() => {
                                const details = pageData?.filter((item) => ids.includes(item?.id));
                                if (details.length === 0) {
                                    toast.error('No data found');
                                    return;
                                } else if (details.length > 5) {
                                    toast.error('Maximum of 5 risks can be updated at a time');
                                    return;
                                } else {
                                    openUpdateRiskModal(details);
                                }
                            }}
                        >
                            Update
                        </Button>
                        {/* <Button
                            startIcon={
                                <img
                                    src="/img/risk-assessment/check-square-broken-risk-register.svg"
                                    alt="Resolved Icon"
                                    className="object-contain"
                                />
                            }
                            sx={{
                                backgroundColor: '#FFFFFF',
                                color: '#64748B',
                                border: 'none',
                                textTransform: 'inherit',
                                fontSize: '14px',
                                fontWeight: 500,
                                borderRadius: 0,
                                '&:hover': {
                                    backgroundColor: '#FFFFFF',
                                    color: '#64748B',
                                    border: 'none',
                                    borderRight: 'ipx solid #E2E8F0',
                                    borderRadius: 0,
                                },
                            }}
                            onClick={openReslovedModal}
                        >
                            Mark as resolved
                        </Button> */}
                        <Button
                            startIcon={<img src={DeleteIcon} alt="Resolved Icon" className="object-contain" />}
                            sx={{
                                backgroundColor: '#FFFFFF',
                                color: '#64748B',
                                border: 'none',
                                textTransform: 'inherit',
                                fontSize: '14px',
                                fontWeight: 500,
                                borderRadius: 0,
                                '&:hover': {
                                    backgroundColor: '#FFFFFF',
                                    color: '#64748B',
                                    border: 'none',
                                    borderRight: 'ipx solid #E2E8F0',
                                    borderRadius: 0,
                                },
                            }}
                            onClick={openDeleteModal}
                        >
                            Delete
                        </Button>
                    </ButtonGroup>
                </div>
            )}

            <div className="flex items-center gap-3">
                <div>
                    <ButtonGroup variant="contained" aria-label="Basic button group">
                        <Button
                            startIcon={<Add />}
                            color="primary"
                            variant="contained"
                            disableElevation
                            // onClick={() => openModal('add')}
                            sx={{
                                display: 'flex-inline',
                                alignItems: 'center',
                                justifyContent: 'center',
                                fontWeight: '600',
                                // borderRadius: '4px',
                                py: 1.2,
                                px: 2.4,
                                textTransform: 'unset',
                                '&.Mui-disabled': {
                                    color: theme.palette.white.main,
                                    backgroundColor: theme.palette.gray[600],
                                },
                            }}
                            onClick={() => setRunRiskOpen(true)}
                        >
                            Run Assessment
                        </Button>
                        <Button
                            onClick={openMenu}
                            color="primary"
                            variant="contained"
                            sx={{ borderLeft: '1px solid #5474C4', py: 1.2 }}
                        >
                            <KeyboardArrowDownIcon />
                        </Button>
                    </ButtonGroup>
                    <Menu
                        open={Boolean(anchorEl)}
                        anchorEl={anchorEl}
                        onClose={closeMenu}
                        sx={{ mt: 2 }}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        // PaperProps={{
                        //     style: {
                        //         width: 250,
                        //     },
                        // }}
                    >
                        <MenuItem
                            sx={{ color: '#64748B', fontSize: '14px', fontWeight: 500 }}
                            onClick={(event) => setRunTemplateAnchorEl(event.currentTarget)}
                        >
                            Run Assessment With Template
                        </MenuItem>
                        <MenuItem
                            sx={{ color: '#64748B', fontSize: '14px', fontWeight: 500 }}
                            onClick={() => {
                                openCsvModal();
                                closeMenu();
                            }}
                        >
                            Upload From EXCEL
                        </MenuItem>
                    </Menu>
                </div>
                <div>
                    <div
                        className="bg-[#F1F5F9] border border-[#E2E8F0] flex items-center gap-1 rounded p-1.5 cursor-pointer"
                        onClick={spoolOpenMenu}
                    >
                        <img src={DownloadIcon} alt="Download Icon" />
                        <p className="text-[#395BA9] text-[13px] font-medium">Spool</p>
                    </div>
                    <Menu open={Boolean(spoolAnchorEl)} anchorEl={spoolAnchorEl} onClose={spoolCloseMenu}>
                        <MenuItem
                            sx={{
                                color: '#64748B',
                                fontSize: '15px',
                                fontWeight: 500,
                                py: 0,
                                pl: -5,
                                display: 'flex',
                                alignItems: 'center',
                                gap: 2,
                            }}
                        >
                            <a
                                href={`https://iqsa.herokuapp.com/api/scans/spool_risk_assessment/?merchant_id=${merchant_info?.id}`}
                                target="_blank"
                                rel="noreferrer"
                            >
                                CBN report
                            </a>
                        </MenuItem>
                        <Divider />
                        <MenuItem
                            sx={{ color: '#64748B', fontSize: '15px', fontWeight: 500, py: 0, pl: -5 }}
                            onClick={(event) => setMatrixAnchorEl(event.currentTarget)}
                        >
                            Smartcomply report(PDF)
                        </MenuItem>
                        <Divider />
                        <MenuItem
                            sx={{ color: '#64748B', fontSize: '15px', fontWeight: 500, py: 0, pl: -5 }}
                            onClick={(event) => setMatrixAnchorExcelEl(event.currentTarget)}
                        >
                            Smartcomply report(Excel)
                        </MenuItem>
                    </Menu>
                </div>
                <Menu
                    open={Boolean(maatrixAnchorEl)}
                    anchorEl={maatrixAnchorEl}
                    onClose={() => setMatrixAnchorEl(null)}
                >
                    <MenuItem
                        sx={{
                            color: '#64748B',
                            fontSize: '15px',
                            fontWeight: 500,
                            py: 0,
                            pl: -5,
                            display: 'flex',
                            alignItems: 'center',
                            gap: 2,
                        }}
                    >
                        <a
                            href={`https://iqsa.herokuapp.com/api/scans/riskreport/?merchant_id=${merchant_info?.id}&matrix=3x3`}
                            target="_blank"
                            rel="noreferrer"
                        >
                            3X3 Report(PDF)
                        </a>
                    </MenuItem>
                    <Divider />
                    <MenuItem
                        sx={{
                            color: '#64748B',
                            fontSize: '15px',
                            fontWeight: 500,
                            py: 0,
                            pl: -5,
                            display: 'flex',
                            alignItems: 'center',
                            gap: 2,
                        }}
                    >
                        <a
                            href={`https://iqsa.herokuapp.com/api/scans/riskreport/?merchant_id=${merchant_info?.id}&matrix=5x5`}
                            target="_blank"
                            rel="noreferrer"
                        >
                            5X5 Report(PDF)
                        </a>
                    </MenuItem>
                </Menu>
                <Menu
                    open={Boolean(maatrixAnchorExcelEl)}
                    anchorEl={maatrixAnchorExcelEl}
                    onClose={() => setMatrixAnchorExcelEl(null)}
                >
                    <MenuItem
                        sx={{
                            color: '#64748B',
                            fontSize: '15px',
                            fontWeight: 500,
                            py: 0,
                            pl: -5,
                            display: 'flex',
                            alignItems: 'center',
                            gap: 2,
                        }}
                    >
                        <a
                            href={`https://api.smartcomplyapp.com/api/scans/riskassessment_excel_report/?merchant=${merchant_info?.id}`}
                            target="_blank"
                            rel="noreferrer"
                        >
                            All Report(Excel)
                        </a>
                    </MenuItem>
                    <Divider />
                    <MenuItem
                        sx={{
                            color: '#64748B',
                            fontSize: '15px',
                            fontWeight: 500,
                            py: 0,
                            pl: -5,
                            display: 'flex',
                            alignItems: 'center',
                            gap: 2,
                        }}
                    >
                        <a
                            href={`https://api.smartcomplyapp.com/api/scans/riskassessment_excel_report/?merchant=${merchant_info?.id}&matrix=3x3`}
                            target="_blank"
                            rel="noreferrer"
                        >
                            3X3 Report(Excel)
                        </a>
                    </MenuItem>
                    <Divider />
                    <MenuItem
                        sx={{
                            color: '#64748B',
                            fontSize: '15px',
                            fontWeight: 500,
                            py: 0,
                            pl: -5,
                            display: 'flex',
                            alignItems: 'center',
                            gap: 2,
                        }}
                    >
                        <a
                            href={`https://api.smartcomplyapp.com/api/scans/riskassessment_excel_report/?merchant=${merchant_info?.id}&matrix=5x5`}
                            target="_blank"
                            rel="noreferrer"
                        >
                            5X5 Report(Excel)
                        </a>
                    </MenuItem>
                </Menu>
                {/* <div>
                    <div
                        className="p-2 border border-[#E2E8F0] flex items-center gap-1 rounded"
                        onClick={riskTypeOpenMenu}
                    >
                        <p className={`text-[13px] font-medium rounded-[4px]`}>{riskType?.name}</p>
                        <img
                            src="/img/risk-assessment/material-symbols_keyboard-arrow-down-rounded.svg"
                            alt="Arrow Down Icon"
                            className="object-contain"
                        />
                    </div>
                    <Menu open={Boolean(riskTypeAnchorEl)} anchorEl={riskTypeAnchorEl} onClose={riskTypeCloseMenu}>
                        {riskTypeOptions?.map((option, index) => (
                            <div key={index} className="p-[-2rem]">
                                <MenuItem
                                    sx={{ color: '#64748B', fontSize: '15px', fontWeight: 500, py: 0, pl: -5 }}
                                    onClick={() => {
                                        riskTypeChange(option);
                                        riskTypeCloseMenu();
                                    }}
                                >
                                    {option?.name}
                                </MenuItem>
                                {index < 2 && <Divider />}
                            </div>
                        ))}
                    </Menu>
                </div> */}
            </div>
            <Menu
                anchorEl={assessmentAnchorEl}
                open={Boolean(assessmentAnchorEl)}
                onClose={() => setAssessmentAnchorEl(null)}
                sx={{
                    '& .MuiPaper-root': {
                        borderRadius: '4px',
                        boxShadow: '4px 4px 8px rgba(175, 172, 172, 0.15), -4px -4px 8px rgba(175, 172, 172, 0.15)',
                    },
                    '& .MuiList-root': {
                        padding: 0,
                    },
                    mt: 1.5,
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <MenuItem
                    sx={{
                        color: '#64748B',
                        fontWeight: 500,
                        fontSize: '14px',
                        textTransform: 'capitalize',
                        borderBottom: `1px solid #F1F5F9`,
                        padding: '8px 16px',
                    }}
                    onClick={(event) => setRunTemplateAnchorEl(event.currentTarget)}
                >
                    <div className="flex items-center gap-1.5">
                        <p className="text-[#64748B] font-medium text-sm">Run with Template</p>
                        <img
                            src="/img/risk-assessment/right-arrow.svg"
                            alt="Arrow Right Icon"
                            className="object-contain"
                        />
                    </div>
                </MenuItem>

                <MenuItem
                    sx={{
                        color: '#64748B',
                        fontWeight: 500,
                        fontSize: '14px',
                        textTransform: 'capitalize',
                        borderBottom: `1px solid #F1F5F9`,
                        padding: '8px 16px',
                    }}
                >
                    <div className="flex items-center gap-1">
                        <p className="text-[#64748B] font-medium text-sm">Run Manually</p>
                    </div>
                </MenuItem>
            </Menu>

            <Menu
                open={Boolean(runTemplateAnchorEl)}
                anchorEl={runTemplateAnchorEl}
                onClose={() => setRunTemplateAnchorEl(null)}
                sx={{ ml: '11rem' }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <div className="w-fit">
                    <SubTab
                        tabs={tabs}
                        currentTab={currentTab_}
                        setCurrentTab={setCurrentTab_}
                        sx={{ mx: '1rem', mt: 0, gap: 3 }}
                    />
                    <div className="relative mx-3 mt-3 mb-1">
                        <input
                            type="text"
                            placeholder="Search"
                            className="w-full !py-2.5 !pr-2.5 !pl-8 !border !border-[#E2E8F0] h-7 text-sm font-normal placeholder:text-[#94A3B8] text-[#64748B] !rounded focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-[#2a447f] focus-visible:ring-opacity-75"
                            value={search}
                            onChange={(event) => {
                                event.stopPropagation();
                                setSearch(event.target.value);
                            }}
                        />

                        <img
                            src="/img/risk-assessment/ri_search-line-small.svg"
                            alt="Search Icon"
                            className="absolute object-contain top-1/2 -translate-y-1/2 left-[10px]"
                        />
                    </div>
                    {currentTab_ === 0 && (
                        <>
                            {filtered_template?.filter((template) => template.tag === 'admin')?.length ? (
                                <div className="!p-2 flex flex-col min-fit">
                                    {filtered_template
                                        .filter((template) => template.tag === 'admin')
                                        ?.map((template) => (
                                            <MenuItem
                                                key={template?.id}
                                                sx={{
                                                    color: '#64748B',
                                                    fontWeight: 500,
                                                    fontSize: '13px',
                                                    padding: '6px 8px',
                                                }}
                                                onClick={() => {
                                                    closeMenu();
                                                    openRiskTemplate(template);
                                                    setRunTemplateAnchorEl(null);
                                                }}
                                            >
                                                {template?.template_name}
                                            </MenuItem>
                                        ))}
                                </div>
                            ) : (
                                <p className="flex items-center justify-center my-4 text-sm text-[#64748B] font-medium">
                                    No template found
                                </p>
                            )}
                        </>
                    )}
                    {currentTab_ === 1 && (
                        <>
                            {filtered_template?.filter((template) => template.tag === 'custom')?.length ? (
                                <div className="!p-2 flex flex-col w-fit">
                                    {filtered_template
                                        .filter((template) => template.tag === 'custom')
                                        ?.map((template) => (
                                            <MenuItem
                                                key={template?.id}
                                                sx={{
                                                    color: '#64748B',
                                                    fontWeight: 500,
                                                    fontSize: '13px',
                                                    padding: '6px 8px',
                                                }}
                                                onClick={() => {
                                                    closeMenu();
                                                    openRiskTemplate(template);
                                                    setRunTemplateAnchorEl(null);
                                                }}
                                            >
                                                {template?.template_name}
                                            </MenuItem>
                                        ))}
                                </div>
                            ) : (
                                <p className="flex items-center justify-center my-4 text-sm text-[#64748B] font-medium">
                                    No template found
                                </p>
                            )}
                        </>
                    )}
                </div>
            </Menu>
        </div>
    );
};

export default TopContent;
