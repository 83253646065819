import React from 'react';
import RiskDistribution from './RiskDistribution';
import PeopleComputers from './PeopleComputers';
import Vendors from '../ThirdSection//Vendors';

const SecondSection = (props) => {
    const { switch_on, handleOpen } = props;
    return (
        <div className="grid grid-cols-2 gap-10 mt-[1.5rem]">
            <div className="2xl:block xl:hidden md:hidden">
                <RiskDistribution switch_on={switch_on} handleOpen={handleOpen} />
            </div>
            <PeopleComputers switch_on={switch_on} handleOpen={handleOpen} />
            <div className="2xl:hidden xl:block">
                <Vendors switch_on={switch_on} handleOpen={handleOpen} />
            </div>
        </div>
    );
};

export default SecondSection;
