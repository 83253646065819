import {
    // Language,
    LightbulbOutlined,
} from '@mui/icons-material';
import { Button, MenuItem, MenuList, Paper, ListItemIcon, ListItemText } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Link, useHistory } from 'react-router-dom';
import SettingsIcon from 'assets/img/settingsIcon_.svg';
import LogoutIcon from 'assets/img/solar_logout-linear.svg';

// core components
import SwitchAccessShortcutIcon from '@mui/icons-material/SwitchAccessShortcut';
import AppMenuItem from 'components/new_components/AppMenuItem';

//
import { connect, useSelector } from 'react-redux';
import { LogoutUser } from 'store/actions/authActions';

const UserMenu = (props) => {
    const theme = useTheme();
    const history = useHistory();
    const {
        handleClose,
        LogoutUser,
        // openLanguageMenu,
        closeLanguageMenu,
    } = props;
    const user_info = useSelector((state) => state?.generalReducers?.user_info);

    const logout = async () => {
        await LogoutUser();
        handleClose();
        localStorage.clear();
        history.push('/auth/login');
    };

    const SwitchtoMainDashboard = () => {
        history.push('/implementer/client');
    };
    return (
        <>
            <Paper
                sx={{
                    maxWidth: '100%',
                    borderRadius: '5px',
                    boxShadow: '0px 12px 24px rgba(0, 0, 0, 0.16)',
                    overflow: 'hidden',
                    // width: 250,
                }}
            >
                <MenuList sx={{ py: 1.5 }}>
                    <div className="mx-2 flex cursor-pointer items-center gap-2 rounded bg-[#F1F5F9] p-1">
                        <div className="relative grid h-12 w-12 place-items-center overflow-hidden rounded-full bg-[#64748B] font-black text-[#FAFAFA] text-capitalize">
                            {user_info?.first_name || user_info?.last_name
                                ? user_info.first_name.charAt(0) + user_info.last_name.charAt(0)
                                : user_info?.email?.charAt(0) ?? '' + user_info?.email?.charAt(1)}
                        </div>
                        <div className="flex flex-col gap-0.5">
                            <p className="text-label-lg capitalize text-[#2563EB]">
                                {user_info?.first_name} {user_info.last_name}
                            </p>

                            <p className="text-[11px] text-[#94A3B8]">{user_info?.email?.substring(0, 28)}...</p>
                        </div>
                    </div>
                    {/* <AppMenuItem icon={<Language fontSize="small" />} text="Language" onClick={openLanguageMenu} /> */}
                    {user_info?.user_type !== 'implementer' && (
                        <MenuItem
                            component={Link}
                            to="/merchant/settings/"
                            onClick={() => {
                                handleClose();
                                closeLanguageMenu();
                            }}
                            sx={{
                                py: 1,
                                px: 0,
                                mt: 1,
                                '&:hover': {
                                    backgroundColor: '#F1F5F9',
                                },
                            }}
                        >
                            <ListItemIcon>{<img src={SettingsIcon} alt="SettingsIcon" />}</ListItemIcon>
                            <ListItemText
                                sx={{
                                    '& span': {
                                        fontSize: '13.5px',
                                        fontWeight: 600,
                                    },
                                }}
                            >
                                Settings
                            </ListItemText>
                        </MenuItem>
                    )}
                    {user_info?.user_type === 'implementer' && (
                        <MenuItem
                            onClick={SwitchtoMainDashboard}
                            sx={{
                                mt: 1,
                                '&:hover': {
                                    backgroundColor: '#F1F5F9',
                                },
                            }}
                        >
                            <Button
                                variant="text"
                                endIcon={<SwitchAccessShortcutIcon />}
                                sx={{
                                    textTransform: 'inherit',
                                    color: '#395BA9',
                                    fontSize: '14px',
                                    fontWeight: 600,
                                }}
                            >
                                Switch to main dashboard
                            </Button>
                        </MenuItem>
                    )}
                    <AppMenuItem
                        icon={<LightbulbOutlined fontSize="small" />}
                        text="Page Tips"
                        onClick={handleClose}
                        sx={{ display: { xs: 'flex', md: 'none' } }}
                    />
                    {/* {user_info?.user_type !== 'implementer' && (
                        <AppMenuItem
                            icon={<TextSnippet fontSize="small" />}
                            text="Release notes"
                            to="/merchant/release-notes"
                            component={Link}
                            onClick={() => {
                                handleClose();
                                closeLanguageMenu();
                            }}
                        />
                    )} */}
                    <MenuItem
                        onClick={logout}
                        sx={{
                            py: 1,
                            color: theme.palette.error[700],
                            '&:hover': {
                                backgroundColor: '#F1F5F9',
                            },
                        }}
                    >
                        <ListItemIcon>{<img src={LogoutIcon} alt="SettingsIcon" />}</ListItemIcon>
                        <ListItemText sx={{ '& span': { fontSize: '13.5px', fontWeight: 600 } }}>Logout</ListItemText>
                    </MenuItem>
                </MenuList>
            </Paper>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        ...state,
    };
};
export default connect(mapStateToProps, { LogoutUser })(UserMenu);
