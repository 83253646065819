import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { OutlinedInput, InputAdornment, IconButton, Typography } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import AppFormControl from './forms/AppFormControl';
import FormTooltip from './FormTooltip';

const AppInput = ({
    label,
    type,
    name,
    onChange,
    value,
    error,
    disabled,
    sx = {},
    multiline,
    labelStyle,
    adornment,
    startAdornment,
    placeholder,
    rows,
    fullWidth,
    toolTip,
    tips,
    controlStyle = {},
    onClick,
    disable,
    ...restProps
}) => {
    const theme = useTheme();
    const [passwordType, setPasswordType] = useState('password');

    const toggleShowPassword = () => setPasswordType((current) => (current === 'password' ? 'text' : 'password'));
    return (
        <AppFormControl
            name={name}
            label={
                <div className="flex items-center gap-[5px]">
                    <Typography sx={{ color: theme.palette.neutral[500], fontSize: '13px', fontWeight: 500 }}>
                        {label}
                    </Typography>
                    {toolTip && <FormTooltip placement="top" text={tips} />}
                </div>
            }
            error={error}
            disabled={disabled}
            labelStyle={labelStyle ? labelStyle : { color: '#64748b', fontSize: '13px', fontWeight: 500 }}
            sx={controlStyle}
        >
            <OutlinedInput
                id={name}
                name={name}
                value={value}
                onChange={onChange}
                type={type === 'password' ? passwordType : type}
                disabled={disabled || disable}
                multiline={multiline}
                placeholder={placeholder || ''}
                rows={rows}
                fullWidth={fullWidth}
                onClick={onClick}
                endAdornment={
                    type === 'password' && value ? (
                        <InputAdornment position="end">
                            <IconButton aria-label="toggle password visibility" onClick={toggleShowPassword} edge="end">
                                {passwordType === 'text' ? (
                                    <VisibilityOff sx={{ color: theme.palette.gray[900] }} />
                                ) : (
                                    <Visibility sx={{ color: theme.palette.gray[900] }} />
                                )}
                            </IconButton>
                        </InputAdornment>
                    ) : adornment ? (
                        adornment
                    ) : (
                        ''
                    )
                }
                startAdornment={startAdornment}
                {...restProps}
                sx={{
                    height: multiline ? 'unset' : 36,
                    border: '1px solid',
                    borderColor: error ? theme.palette.error[700] : '#e2e8f0',
                    color: theme.palette.gray[900],
                    textTransform: 'capitalize !important',
                    overflow: 'hidden',
                    fontSize: '13px',
                    fontWeight: 500,
                    '&.Mui-disabled': { borderColor: theme.palette.gray[100] },
                    '& fieldset': { border: '0 !important' },
                    '& placeholder': {
                        color: '#94A3B8',
                        fontWeight: 400,
                        fontSize: '14px',
                    },
                    ...sx,
                }}
            />
        </AppFormControl>
    );
};

export default AppInput;
