import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { Button, Col, Input, Modal, Row } from 'antd';

// redux
import { connect } from 'react-redux';
import { CreateAuditorUser } from 'store/actions/authActions';

const AuditorInviteModal = (props) => {
    const { open, mode, closeModal, CreateAuditorUser } = props;

    // state
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);

    // functions
    const closeAll = () => {
        closeModal();
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const payload = {
            email,
        };
        let res;
        // imvite auditor
        setLoading(true);
        res = await CreateAuditorUser({ ...payload });
        if (res?.success) {
            setLoading(false);
            closeModal();
            return toast.success(res?.message);
        } else {
            setLoading(false);
            return toast.error('Email already Exist');
        }
    };
    const handleTextChange = (e) => {
        setEmail(e?.target?.value);
    };

    return (
        <Modal title={`${mode} an auditor`} open={open} onCancel={closeAll} footer={null} destroyOnClose>
            <form onSubmit={handleSubmit}>
                <Row className="">
                    <Col span={24} className="my-1">
                        <label htmlFor="email">Email</label>
                        <Input
                            type="email"
                            size="large"
                            name="email"
                            id="email"
                            onChange={handleTextChange}
                            value={email}
                            required
                        />
                    </Col>
                    <Col className="mt-4" span={24}>
                        <Button type="primary" htmlType="submit" loading={loading}>
                            Submit
                        </Button>
                    </Col>
                </Row>
            </form>
        </Modal>
    );
};

const mapStateToProps = () => ({});

export default connect(mapStateToProps, { CreateAuditorUser })(AuditorInviteModal);
