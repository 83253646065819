import React, { useState } from 'react';
import { Box, Button, Typography } from '@mui/material';

//redux
import { useDispatch } from 'react-redux';

//translations
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import AppCenteredModal from 'components/new_components/AppCenteredModal';
import CloseIcon from '@mui/icons-material/Close';
import { accept } from 'validate';
// import { toast } from 'react-toastify';
import FolderIcon from 'assets/img/folder.svg';
import FileTypes from 'assets/img/Filetypes.svg';
import Docx from 'assets/img/docx.svg';
import Png from 'assets/img/png.svg';
import Xsl from 'assets/img/xsl.svg';
import Img from 'assets/img/img.svg';
import LightIcon from 'assets/img/Light.svg';
import { toast } from 'react-toastify';
import { AppForm, AppFormInput, AppSubmitButton } from 'components/new_components/forms';
import { ExceptionValidation } from '../validation';
import { EvidenceExeption } from 'store/actions/complianceActions';

const ExemptEvidenceModal = (props) => {
    const { open, handleClose, evidence, standard } = props;
    // state
    const [loading, setLoading] = useState(false);
    const [reason_document, setreason_document] = useState('');
    const dispatch = useDispatch();
    const { pdf, docx, image } = accept;

    // UPLOAD IMAGE ONCHANGE
    const uploadImage = async (event) => {
        const input = event.target;
        const file = input.files?.item(0);
        const fileSize = file.size / 1000 / 1000;
        if (fileSize > 5) {
            setreason_document('');
        } else {
            // const base64 = await convertedBase64(file);
            setreason_document(file);
        }
    };

    // CONVERT IMAGE TO BASE 64
    const convertedBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const filReader = new FileReader();
            filReader.readAsDataURL(file);
            filReader.onload = () => {
                resolve(filReader.result);
            };
            filReader.onerror = (error) => {
                reject(error);
            };
        });
    };

    const dragEnter = () => {
        const target = document.getElementById('drop-zone');
        target.style.borderColor = '#C4C4C4';
        target.style.backgroundColor = '#A58980';
    };

    const dragLeave = () => {
        const target = document.getElementById('drop-zone');
        target.style.borderColor = '#A58980';
        target.style.backgroundColor = 'transparent';
    };

    const dragOver = (e) => {
        e.preventDefault();
    };

    const dragDrop = (e) => {
        e.preventDefault();
        const file = e.dataTransfer.files[0];
        if (file && file.type.includes('image')) {
            const reader = new FileReader();
            reader.onload = () => {
                // setImg(reader.result);
            };
            reader.readAsDataURL(file);
            convertedBase64(file).then(() => {});
        } else {
            // enqueueSnackbar('Invalid Data Dropped', { variant: 'error' });
        }
        dragLeave();
    };
    const onClose = () => {
        handleClose();
    };

    const handleSubmit = async (data) => {
        const doc = new FormData();
        doc.append('reason', data?.reason);
        doc.append('evidence', evidence?.id);
        doc.append('document', reason_document);
        doc.append('standard', standard);

        setLoading(true);
        const res = await dispatch(EvidenceExeption(doc, evidence?.card4, standard));
        setLoading(false);
        //handling response
        if (res?.success) {
            toast.success('Evidence exempted succesfully');
            setreason_document('');
            onClose();
        } else {
            toast.error(res?.message);
        }
    };
    return (
        <AppForm
            initialValues={{
                reason: '',
            }}
            validate={ExceptionValidation}
            onSubmit={handleSubmit}
        >
            <AppCenteredModal
                open={open}
                handleClose={onClose}
                title={
                    <Typography sx={{ color: '#202D66', fontWeight: 600, fontSize: '18px' }}>
                        Reason for exempting this evidence
                    </Typography>
                }
                icon={<ArrowBackIosIcon sx={{ width: 20, height: 20, color: '#000000' }} />}
                noShadow
                noClose
                actions={
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'right',
                            alignItems: 'center',
                            backgroundColor: '#F8FAFC',
                            py: 2,
                            pr: 3,
                            mx: '-2rem',
                        }}
                    >
                        <Button
                            variant="outlined"
                            sx={{ mr: 2, border: '1px solid #E2E8F0', textTransform: 'inherit' }}
                            onClick={onClose}
                        >
                            Cancel
                        </Button>
                        <AppSubmitButton
                            loading={loading}
                            loadingPosition="center"
                            variant="contained"
                            color="primary"
                            text={'Exempt'}
                            sx={{ marginLeft: 3 }}
                        />
                    </Box>
                }
                sx={{ borderRadius: 1, overflowX: 'scroll' }}
                headerAction={
                    <Box
                        sx={{
                            border: '1px solid #E2E8F0',
                            borderRadius: 30,
                            width: 20,
                            height: 20,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            cursor: 'pointer',
                        }}
                        onClick={onClose}
                    >
                        <CloseIcon sx={{ width: 12, height: 12, color: '#334155' }} />
                    </Box>
                }
                width="550px"
                height={standard === 'card4' ? '300px' : '600px'}
                maxHeight={'600px'}
            >
                <Box sx={{ my: 2 }}>
                    <AppFormInput type="text" name="reason" multiline={true} rows={3} placeholder="Why exempting?" />
                    {standard !== 'card4' && (
                        <Typography sx={{ color: '#64748B', fontSize: '13px', fontWeight: 500, mt: 2 }}>
                            Any document/evidence to backup your claim (Optional)
                        </Typography>
                    )}
                    {standard !== 'card4' && (
                        <Box
                            sx={{
                                // border: `3px dashed ${errors[name] ? '#D91B1B' : theme.palette.primary[900]}`,
                                border: '1px dashed #CBD5E1',
                                borderRadius: 2,
                                padding: 1,
                                minHeight: 150,
                                position: 'relative',
                                mt: 1,
                            }}
                        >
                            <Box
                                id="drop-zone"
                                onDragEnter={dragEnter}
                                onDragLeave={dragLeave}
                                onDrop={dragDrop}
                                onDragOver={dragOver}
                                sx={{ height: '100%', p: 2 }}
                            >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        flexDirection: 'column',
                                    }}
                                >
                                    <img src={FolderIcon} alt={FolderIcon} />
                                    <Typography sx={{ color: '#64748B', fontSize: '12px', fontWeight: 500, mt: 2 }}>
                                        Drop your files or click to upload
                                    </Typography>
                                    <Typography sx={{ color: '#94A3B8', fontSize: '12px', fontWeight: 400 }}>
                                        Supported file types: PDF, IMG, PNG, DOCX
                                    </Typography>
                                </Box>
                                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 1 }}>
                                    <label htmlFor="exempt-input">
                                        <Box
                                            sx={{
                                                border: '1px solid #E2E8F0',
                                                cursor: 'pointer',
                                                padding: 0.5,
                                            }}
                                        >
                                            <Typography sx={{ color: '#475569', fontWeight: 400, fontSize: 12 }}>
                                                Browse
                                            </Typography>
                                        </Box>
                                    </label>
                                    <input
                                        type="file"
                                        id="exempt-input"
                                        accept={`${pdf},${docx},${image}`}
                                        onChange={(e) => uploadImage(e)}
                                        hidden
                                    />
                                </Box>
                                <input
                                    type="file"
                                    // onChange={(e) => uploadImage(e)}
                                    accept={`${pdf},${docx},${image}`}
                                    hidden
                                    id="exempt-input"
                                />
                            </Box>
                        </Box>
                    )}

                    {reason_document !== '' && (
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                mt: 3,
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: 2,
                                }}
                            >
                                <img
                                    src={
                                        reason_document.name?.split('.').pop() === 'xlsx'
                                            ? Xsl
                                            : reason_document.name?.split('.').pop() === 'csv'
                                            ? Xsl
                                            : reason_document.name?.split('.').pop() === 'docx'
                                            ? Docx
                                            : reason_document.name?.split('.').pop() === 'png'
                                            ? Png
                                            : reason_document.name?.split('.').pop() === 'img'
                                            ? Img
                                            : FileTypes
                                    }
                                    alt="PdfIcon"
                                />
                                <Typography
                                    sx={{
                                        color: '#475569',
                                        fontWeight: 400,
                                        fontSize: '14px',
                                        wordWrap: 'break-word',
                                    }}
                                >
                                    {reason_document.name}
                                </Typography>
                            </Box>

                            <CloseIcon onClick={() => setreason_document('')} sx={{ cursor: 'pointer' }} />
                        </Box>
                    )}
                    {standard !== 'card4' && (
                        <Box
                            sx={{
                                backgroundColor: '#F8FAFC',
                                border: '1px solid #F0F0FA',
                                display: 'flex',
                                alignItems: 'flex-start',
                                my: 1.5,
                                py: 1.5,
                                px: 2,
                                borderRadius: 1,
                            }}
                        >
                            <img src={LightIcon} alt={'LightIcon'} />
                            <Typography sx={{ color: '#64748B', fontWeight: 400, fontSize: '13px', ml: 1 }}>
                                Exempted document/evidence will not show in your compliance process. To revert it, go to
                                exception tab
                            </Typography>
                        </Box>
                    )}
                </Box>
            </AppCenteredModal>
        </AppForm>
    );
};
export default ExemptEvidenceModal;
