import React from 'react';
//core
import { Dialog, DialogContent, DialogTitle, Grow, Stack } from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Grow ref={ref} {...props} />;
});

const AppReportModal = ({ open, handleClose, title, subtitle, children, width, height }) => {
    //theme
    const theme = useTheme();

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            transitionDuration={1000}
            keepMounted
            onClose={handleClose}
            sx={{
                '& .MuiDialog-container': {
                    justifyContent: 'center',
                    alignItems: 'center',
                },
            }}
            PaperProps={{
                sx: {
                    boxShadow: '0px 12px 24px rgba(0, 0, 0, 0.16)',
                    minWidth: 370,
                    borderRadius: '4px',
                    position: 'relative',
                    top: '-20%',
                    left: { md: '16%', lg: '7%' },
                    width,
                    maxHeight: 550,
                    overflowX: 'hidden',
                    marginTop: 20,
                    height: height,
                },
            }}
            scroll="paper"
        >
            {title && (
                <DialogTitle
                    sx={{
                        // display: "flex",
                        fontSize: 18,
                        fontWeight: 700,
                        color: theme.palette.gray[900],
                    }}
                >
                    {title}
                    <Stack
                        flexDirection="row"
                        sx={{
                            py: 1,
                            pr: '24px',
                            fontSize: 12,
                            fontWeight: 400,
                            color: theme.palette.gray[400],
                        }}
                    >
                        <InfoOutlined sx={{ fontSize: 11.8, mr: 1, mt: 0.4 }} /> {subtitle}
                    </Stack>
                </DialogTitle>
            )}

            <DialogContent sx={{ padding: '0 0.5rem', pr: '24px' }}>{children}</DialogContent>
        </Dialog>
    );
};

export default AppReportModal;
