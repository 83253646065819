import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
//core components
import { toast } from 'react-toastify';
import AppInput from 'components/new_components/AppInput';

//utils
import { formatDateObjectHandler } from 'utils';
//redux
import { connect } from 'react-redux';
import { RequestPentest, UpdatePentestRequest } from 'store/actions/merchantActions';
// import { scheduleOptions } from './utils/constants';
import PentestDrawer from './components/PentestDrawer';
import dateIcon from '../../../assets/img/dateIcon.svg';

//translations
import { useTranslation } from 'react-i18next';

const PentestViewDetails = ({ open, handleClose, mode, payload, RequestPentest, UpdatePentestRequest }) => {
    const [loadingState, setloadingState] = useState(false);
    const [assets, setAssets] = useState([]);
    const [formValues, setFormValues] = useState({});
    const [view, setView] = useState('Wired Assurance');
    const [authorization, setAuthorization] = useState(false);
    const [unpermittedInputValue, setUnpermittedInputValue] = useState('');
    const [unpermittedTags, setUnpermittedTags] = useState([]);

    const [thirdPartiesInputValue, setThirdPartiesInputValue] = useState('');
    const [thirdPartiesTags, setThirdPartiesTags] = useState([]);

    //translation
    const { t } = useTranslation('pentest');

    //sorted Schedule options
    // const sortedScheduleOptions = useMemo(() => {
    //     let filteredOptions;
    //     if (mode === 'view') {
    //         filteredOptions = scheduleOptions?.filter((option) => option.name !== 'Run Now');
    //     } else {
    //         filteredOptions = scheduleOptions;
    //     }
    //     return filteredOptions;
    // }, [scheduleOptions, mode]);

    const closeModal = () => {
        handleClose();
        setAssets([
            {
                id: 1,
                asset: '',
                ip_address: '',
                stack: '',
                enabled: true,
            },
        ]);
        setAuthorization(false);
        setFormValues({});
        setUnpermittedTags([]);
        setThirdPartiesTags([]);
        setView('Wired Assurance');
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setFormValues({ ...formValues, [name]: value });
    };

    //handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (authorization) {
            setloadingState(true);
            const values =
                view.toLowerCase() === 'cobalt'
                    ? {
                          ...formValues,
                          authorization: authorization,
                          cobalt: true,
                      }
                    : {
                          ...formValues,
                          authorization: authorization,
                          unpermitted_hosts: unpermittedTags?.join(','),
                          thirdparties: thirdPartiesTags?.join(','),
                          assets: assets,
                          cobalt: false,
                      };
            const res =
                mode === 'view'
                    ? await UpdatePentestRequest(payload?.id, { schedule: formValues?.schedule })
                    : mode === 'rerun'
                    ? await RequestPentest({ ...values, rerun: true, rerun_id: payload?.id })
                    : await RequestPentest(values);
            setloadingState(false);
            if (res?.success) {
                toast.success(mode === 'view' ? 'Updated Successfully' : 'Successful Request');
                closeModal();
            } else {
                if (res?.message !== 'Upsell' && res?.message !== 'Paywall') {
                    toast.error(res?.message);
                }
            }
        } else {
            toast.info('You have to authorize this request!');
        }
    };

    //useEffect
    useEffect(() => {
        setAssets([
            {
                asset: '',
                ip_address: '',
                stack: '',
                enabled: true,
            },
        ]);
    }, []);

    useEffect(() => {
        if (mode === 'view' || mode === 'rerun') {
            setFormValues({
                name: payload?.name,
                schedule: payload?.runschedule,
                security_concerns: payload?.security_concerns,
                target_env: payload?.target_env,
                test_time: payload?.test_time,
                date_created: payload?.date_created,
            });
            setAssets(payload?.assets);
            setAuthorization(payload?.authorization);
            setUnpermittedTags(payload?.unpermitted_hosts?.split(','));
            setThirdPartiesTags(payload?.thirdparties?.split(','));
        }
    }, [mode, payload]);

    const { date_created } = payload;

    const currentMonthForPentest = (currentDate) => {
        const date = new Date(currentDate);
        const month = date.getMonth() + 1;

        if (month >= 1 && month <= 6) {
            return 'JANUARY-JUNE';
        } else if (month > 6 && month <= 12) {
            return 'JULY - DECEMBER';
        } else {
            return null;
        }
    };

    useEffect(() => {
        console.log('');
    }, [loadingState, setUnpermittedInputValue, setThirdPartiesInputValue]);

    return (
        <PentestDrawer
            open={open}
            handleClose={closeModal}
            style={{ width: '525px' }}
            title={
                <div className="flex items-center gap-[8px]">
                    <span className="text-[#202D66] font-semibold text-[18px]">
                        {mode === 'request'
                            ? t('pentestRequestModal.title1')
                            : mode === 'rerun'
                            ? `${t('pentestRequestModal.title2')}`
                            : t('pentestRequestModal.title3')}
                    </span>
                </div>
            }
        >
            <Box component="form" onSubmit={handleSubmit}>
                <Box
                    sx={{
                        mx: 2,
                        pr: 1.8,
                        height: '100%',
                        maxHeight: view.toLowerCase() !== 'cobalt' ? '100vh' : '100vh',
                        overflowY: 'scroll',
                        overflowX: 'hidden',
                        mt: 3,
                        mb: '200px',
                    }}
                >
                    {view.toLowerCase() !== 'cobalt' ||
                        (mode === 'rerun' && (
                            <AppInput
                                type="text"
                                label={t('pentestRequestModal.securityConcerns')}
                                name="security_concerns"
                                multiline
                                value={formValues?.security_concerns}
                                onChange={handleInputChange}
                                required
                                toolTip={true}
                                disabled={mode === 'view'}
                                tips={t('pentestRequestModal.tips')}
                            />
                        ))}
                    {mode !== 'rerun' && (
                        <>
                            <div>
                                <div className="flex items-center gap-[10.5px] mb-[24px]">
                                    <img src={dateIcon} alt="date icon" />
                                    <span className="text-[13px] text-[#64748b]">Date::</span>
                                    <span className="text-[14px] text-[#64748b] font-medium">
                                        {formatDateObjectHandler(date_created, 'MMMM Do, YYYY')} (
                                        {currentMonthForPentest(date_created)})
                                    </span>
                                </div>
                                <div>
                                    <div className="flex items-center border-b-[1px] border-solid border-[#f1f5f9] h-[36px] w-full">
                                        <span className="w-[35%] text-[13px] text-[#64748b]">Scan name</span>
                                        <div className="w-[1px] h-full bg-[#f1f5f9]"></div>
                                        <span className="text-[#64748b] font-medium pl-[8px] text-[14px]">
                                            {formValues?.name}
                                        </span>
                                    </div>
                                    <div className="flex items-center border-b-[1px] border-solid border-[#f1f5f9] h-[36px] w-full">
                                        <span className="w-[44.5%] text-[13px] text-[#64748b]">Schedule</span>
                                        <div className="w-[1px] h-full bg-[#f1f5f9] ml-[31px]"></div>
                                        <div className="pl-[8px] w-full relative flex items-center ">
                                            <span className="text-[#395ba9] font-medium text-[13px]">
                                                {formValues?.schedule &&
                                                    formValues.schedule.charAt(0).toUpperCase() +
                                                        formValues.schedule.slice(1)}
                                            </span>
                                        </div>
                                    </div>
                                </div>

                                <div className="border-b-[1px] border-solid border-[#f1f5f9] w-[525px] h-[31px] flex items-center justify-start">
                                    <span className="text-[#395ba9] text-[15px] font-semibold">Assets in scope</span>
                                </div>
                                {view.toLowerCase() !== 'cobalt' && (
                                    <div>
                                        {assets?.map((asset, index) => (
                                            <>
                                                <div key={index}>
                                                    <div className="flex items-center border-b-[1px] border-solid border-[#f1f5f9] h-[36px] w-full">
                                                        <div className="w-[50%] text-[13px] text-[#64748b] flex items-center gap-[10px]">
                                                            <span className="text-[#395ba9] text-[14px] w-[17px] flex items-center justify-center bg-[#e2e8f0] rounded-[8px]">
                                                                {index + 1}
                                                            </span>{' '}
                                                            <span>Asset type</span>
                                                        </div>
                                                        <div className="w-[1px] h-full bg-[#f1f5f9]"></div>
                                                        <span className="text-[#64748b] font-medium pl-[8px] text-[14px]">
                                                            {asset?.asset}
                                                        </span>
                                                    </div>
                                                    <div className="flex items-center border-b-[1px] border-solid border-[#f1f5f9] h-[36px] w-full">
                                                        <span className="w-[50%] text-[13px] text-[#64748b]">
                                                            IP/URLS
                                                        </span>
                                                        <div className="w-[1px] h-full bg-[#f1f5f9]"></div>
                                                        <span className="text-[#64748b] font-medium pl-[8px] text-[14px]">
                                                            {asset?.ip_address}
                                                        </span>
                                                    </div>
                                                    <div className="flex items-center border-b-[1px] border-solid border-[#f1f5f9] h-[36px] w-full">
                                                        <span className="w-[50%] text-[13px] text-[#64748b]">
                                                            Technology Stack
                                                        </span>
                                                        <div className="w-[1px] h-full bg-[#f1f5f9]"></div>
                                                        <span className="text-[#64748b] font-medium pl-[8px] text-[14px]">
                                                            {asset?.stack}
                                                        </span>
                                                    </div>
                                                </div>
                                            </>
                                        ))}
                                    </div>
                                )}
                                <div>
                                    <div className="border-b-[1px] border-solid border-[#f1f5f9] w-[525px] h-[31px] flex items-center justify-start">
                                        <span className="text-[#395ba9] text-[15px] font-semibold">
                                            Excluded host/network
                                        </span>
                                    </div>

                                    <div className="flex items-center border-b-[1px] border-solid border-[#f1f5f9] h-[36px] w-full">
                                        <span className="w-[50%] text-[13px] text-[#64748b]">Host/network 1</span>
                                        <div className="w-[1px] h-full bg-[#f1f5f9]"></div>
                                        <span className="text-[#64748b] font-medium pl-[8px] text-[14px]">
                                            {unpermittedInputValue}
                                        </span>
                                    </div>
                                    <div className="flex items-center border-b-[1px] border-solid border-[#f1f5f9] h-[36px] w-full">
                                        <span className="w-[50%] text-[13px] text-[#64748b]">Host/network 2</span>
                                        <div className="w-[1px] h-full bg-[#f1f5f9]"></div>
                                        <span className="text-[#64748b] font-medium pl-[8px] text-[14px]">
                                            {unpermittedInputValue}
                                        </span>
                                    </div>

                                    <div className="border-b-[1px] border-solid border-[#f1f5f9] w-[525px] h-[31px] flex items-center justify-start">
                                        <span className="text-[#395ba9] text-[15px] font-semibold">
                                            Third party system/network in scope
                                        </span>
                                    </div>
                                    <div className="flex items-center border-b-[1px] border-solid border-[#f1f5f9] h-[36px] w-full">
                                        <span className="w-[50%] text-[13px] text-[#64748b]">system/network 1</span>
                                        <div className="w-[1px] h-full bg-[#f1f5f9]"></div>
                                        <span className="text-[#64748b] font-medium pl-[8px] text-[14px]">
                                            {thirdPartiesInputValue}
                                        </span>
                                    </div>
                                    <div className="flex items-center border-b-[1px] border-solid border-[#cbd5e1] h-[36px] w-full">
                                        <span className="w-[50%] text-[13px] text-[#64748b]">system/network 2</span>
                                        <div className="w-[1px] h-full bg-[#f1f5f9]"></div>
                                        <span className="text-[#64748b] font-medium pl-[8px] text-[14px]">
                                            {thirdPartiesInputValue}
                                        </span>
                                    </div>

                                    <div className="flex items-center  h-[36px] w-full">
                                        <span className="w-[50%] text-[13px] text-[#64748b]">Target environment</span>
                                        <div className="w-[1px] h-full bg-[#f1f5f9]"></div>
                                        <span className="text-[#64748b] font-medium pl-[8px] text-[14px]">
                                            {formValues?.target_env}
                                        </span>
                                    </div>
                                    <div className="flex items-center  h-[36px] w-full">
                                        <span className="w-[50%] text-[13px] text-[#64748b]">Test time</span>
                                        <div className="w-[1px] h-full bg-[#f1f5f9]"></div>
                                        <span className="text-[#64748b] font-medium pl-[8px] text-[14px]">
                                            {formValues?.test_time}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                </Box>
            </Box>
        </PentestDrawer>
    );
};

const mapStateToProps = (state) => {
    return {
        ...state,
    };
};
export default connect(mapStateToProps, { RequestPentest, UpdatePentestRequest })(PentestViewDetails);
