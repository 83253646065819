import { Divider } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import PageHeader from 'components/new_components/PageHeader';
import useSearch from 'hooks/useSearch';
import { InfoOutlined, MoreHoriz } from '@mui/icons-material';
import AppTable from 'components/new_components/app-table2';
import TableHeading from './components/TableHeading';
import TableAction from './components/TableAction';
import Header from './Header';
import MarkResolvedModal from './components/MarkResolvedModal';
import MarkApprovedModal from './components/MarkApprovedModal';
import MitigationApprovedModal from './components/MitigationApprovedModal';
import SuggestionModal from './components/SuggestionModal';
import { useDispatch, useSelector } from 'react-redux';
import { GetSelfServiceData } from 'store/actions/generalActions';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { RISK_SELF_PORTAL_TOKEN } from 'store/constants';
import ViewDetailsDrawer from './components/ViewDetailsDrawer';
import TopContent from './components/TopContent';
import { plans, residual5x5Risks, risk_sources } from './utils/constants';
import Warning from 'assets/img/warning2.svg';
import MitigationDisapprovalModal from './components/MitigationDisapprovalModal';
import { GetAllCategories } from 'store/actions/riskAssessmentActions';
import {
    OpenAutoRiskMitigation,
    OpenAutoRiskExcalation,
    OpenAutoRiskApproval,
} from 'store/actions/riskAssessmentActions';

const SelfServiceView = () => {
    const [loading, setLoading] = useState(false);
    const [pageData, setPageData] = useState([]);
    const [openSwitch, setOpenSwitch] = useState(null);
    const [markResolvedModal, setMarkReslovedModal] = useState(false);
    const [markApprovedModal, setMarkApprovedModal] = useState(false);
    const [viewModal, setViewdModal] = useState(false);
    const [approveMitigationModal, setApproveMitigationModal] = useState(false);
    const [disapproveMitigationModal, setDisapproveMitigationModal] = useState(false);
    const [suggestionModal, setSuggestionModal] = useState(false);
    const [details, setDetails] = useState(null);
    const [currentTab, setCurrentTab] = useState('all');
    const [approvedComment, setApprovedComment] = useState('');
    const [suggestionComment, setSuggestionComment] = useState('');
    const [approval_type, setApprovalType] = useState('');
    const [page, setPage] = useState(1);
    const [categoryAnchorEl, setCategoryAnchorEl] = useState(null);
    const [planAnchorEl, setPlanAnchorEl] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const [spoolAnchorEl, setSpoolAnchorEl] = useState(null);
    const [riskTypeAnchorEl, setRiskTypeAnchorEl] = useState(null);
    const [residualRiskAnchorEl, setResidualRiskAnchorEl] = useState(null);
    const [residualRisk, setResidualRisk] = useState([...residual5x5Risks]);
    const [allData, setAllData] = useState([]);
    const [sourcesAnchorEl, setSourcesAnchorEl] = useState(null);
    const [sources, setSources] = useState([...risk_sources]);

    const [riskType, setRiskType] = useState({
        name: 'All',
        value: 'all',
    });
    const [plan, setPlan] = useState([...plans]);
    const [category, setCategory] = useState([]);
    const NO_PER_PAGE = 8;

    const openMenu = (e) => setAnchorEl(e.target);
    const closeMenu = () => setAnchorEl(null);

    const open = Boolean(openSwitch);
    const id = open ? 'simple-popover' : undefined;
    const history = useHistory();
    const dispatch = useDispatch();
    const { risk_current_permission, risk_self_portal_token, risk_self_service } = useSelector(
        (state) => state?.generalReducers
    );
    const {
        risk_assessment_categories,
        open_auto_risk_mitigation,
        open_auto_risk_excalation,
        open_auto_risk_approval,
    } = useSelector((state) => state.riskAssessmentReducers);

    const { data, handleSearch } = useSearch(risk_self_service, [
        'name',
        'category',
        'asset_owner_user',
        'residual_risk_level_slug',
    ]);
    const query_params = useMemo(() => {
        const params = {
            ...(risk_current_permission && {
                type:
                    risk_current_permission === 'risk_migation'
                        ? 'solution_owner'
                        : risk_current_permission === 'escalator'
                        ? 'risk_report'
                        : 'approver',
            }),
            ...(currentTab && currentTab !== 'all' && currentTab !== 'filter' && { matrix: currentTab }),
        };
        return params;
    }, [risk_current_permission, currentTab]);

    const getSelfServiceData = async () => {
        setLoading(true);
        const res = await dispatch(GetSelfServiceData(query_params));
        setLoading(false);
        if (!res?.success) {
            if (res?.message === 'Access denied: The request is older than 4 hours') {
                dispatch({ type: RISK_SELF_PORTAL_TOKEN, payload: undefined });
                // history.push('/risk_self_portal_login/');
            } else {
                toast.error(res?.message || 'Something went wrong');
            }
        }
    };

    const getAllRiskAssessmentCategories = async () => {
        const res = await dispatch(GetAllCategories());
        if (!res?.success) {
            toast.error(res?.message);
        }
    };

    useEffect(() => {
        if (risk_current_permission) {
            getSelfServiceData();
        }
    }, [risk_current_permission, query_params]);

    useEffect(() => {
        getAllRiskAssessmentCategories();
    }, []);

    useEffect(() => {
        if (!risk_self_portal_token) {
            history.push('/risk_self_portal_login/');
        }
    }, []);
    // functions

    const openResolvedmodal = (row) => {
        setDetails(row);
        setMarkReslovedModal(true);
    };

    const closeResolvedModal = () => {
        setDetails(null);
        setMarkReslovedModal(false);
    };

    const openViewModal = (row) => {
        setDetails(row);
        setViewdModal(true);
    };

    const closeViewModal = () => {
        setDetails(null);
        setViewdModal(false);
    };
    const openApprovedModal = (row) => {
        setDetails(row);
        setMarkApprovedModal(true);
    };

    const closeApprovedModal = () => {
        setMarkApprovedModal(false);
    };

    const openApproveMitigationModal = () => {
        setApproveMitigationModal(true);
    };

    const closeApproveMitigation = () => {
        setDetails(null);
        setApprovedComment('');
        setApproveMitigationModal(false);
        closeApprovedModal();
    };

    const openDisApproveMitigationModal = () => {
        setDisapproveMitigationModal(true);
    };

    const closeDisApproveMitigation = () => {
        setDetails(null);
        setApprovedComment('');
        setDisapproveMitigationModal(false);
        closeApprovedModal();
    };

    const openSuggestionModal = (row) => {
        setDetails(row);
        setSuggestionModal(true);
    };

    const closeSuggestionModal = () => {
        setSuggestionModal(false);
    };

    const riskTypeOpenMenu = (e) => setRiskTypeAnchorEl(e.target);
    const riskTypeCloseMenu = () => setRiskTypeAnchorEl(null);

    const spoolOpenMenu = (e) => setSpoolAnchorEl(e.target);
    const spoolCloseMenu = () => setSpoolAnchorEl(null);

    const riskTypeChange = (type) => {
        setRiskType(type);
    };

    const showSwitch = (event) => {
        setOpenSwitch(event.currentTarget);
    };

    const hideSwitch = () => {
        setOpenSwitch(null);
    };

    const handlePageChange = (page) => {
        setPage(page);
    };

    useEffect(() => {
        if (risk_assessment_categories?.length) {
            setCategory(risk_assessment_categories);
        }
    }, [risk_assessment_categories]);

    const columns_1 = [
        {
            title: 'Risk Subject',
            key: 'name',
            render: (row) => (
                <div className="flex items-center gap-1 w-fit">
                    {row?.sub_status === 'timeline expired' && (
                        <div className="w-6 h-6 grid place-items-center">
                            <img src={Warning} alt="Flag Icon" className="object-contain" />
                        </div>
                    )}

                    <p>{row?.name}</p>
                </div>
            ),
        },
        {
            title: 'Risk Source',
            key: 'source',
            render: (row) => <p className="text-[#395BA9] font-medium text-sm text-capitalize">{row?.source}</p>,
        },
        {
            title: 'Risk Owner',
            key: 'risk_owner',
            render: (row) => <>{row?.solution_owner_detail || row?.solution_owner || 'N/A'}</>,
        },
        {
            title: 'Category',
            key: 'category',
            render: (row) => (
                <p className="bg-[#F1F5F9] py-1 px-2 rounded text-[#475569] text-[13px] font-medium w-fit">
                    {row?.category_detail}
                </p>
            ),
        },
        {
            title: 'Inherent Risk',
            key: 'inherent_risk_level_slug',
            render: (row) => (
                <div className="w-fit flex items-center gap-1 h-7 !rounded !py-1 !px-2 !border !border-[#E2E8F0]">
                    {row?.inherent_risk_level_slug ? (
                        <>
                            <div
                                className={`w-2 h-2 rounded-full ${
                                    row?.inherent_risk_level_slug?.toLowerCase() === 'low'
                                        ? 'bg-[#55BE8B]'
                                        : row?.inherent_risk_level_slug?.toLowerCase() === 'medium'
                                        ? 'bg-[#D2A500]'
                                        : 'bg-[#FF5449]'
                                }`}
                            />

                            <p className="text-[#64748B] font-medium text-[13px]">{row?.inherent_risk_level_slug}</p>
                        </>
                    ) : (
                        '--'
                    )}
                </div>
            ),
        },
        {
            title: 'Treatment Plan',
            key: 'mitigation_plan',
            render: (row) => (
                <div className="flex flex-col gap-1">
                    <p className="text-[#395BA9] font-medium text-sm capitalize">{row?.mitigation_plan}</p>
                    {row?.sub_status === 'timeline expired' ? (
                        <p className="text-[#FF5449] font-normal text-xs capitalize">{row?.sub_status}</p>
                    ) : row?.sub_status ? (
                        <p className="text-[#64748B] font-normal text-xs">{row?.sub_status}</p>
                    ) : null}
                </div>
            ),
        },
        {
            title: <MoreHoriz />,
            key: 'status',
            render: (row) => (
                <>
                    <TableAction
                        row={row}
                        openResolvedmodal={openResolvedmodal}
                        openApprovedModal={openApprovedModal}
                        openSuggestionModal={openSuggestionModal}
                        openViewModal={openViewModal}
                    />
                </>
            ),
        },
    ];
    const columns_2 = [
        {
            title: 'Risk Subject',
            key: 'name',
            render: (row) => (
                <div className="flex items-center gap-1 w-fit">
                    {row?.sub_status === 'timeline expired' && (
                        <div className="w-6 h-6 grid place-items-center">
                            <img src={Warning} alt="Flag Icon" className="object-contain" />
                        </div>
                    )}

                    <p>{row?.name}</p>
                </div>
            ),
        },
        {
            title: 'Risk Source',
            key: 'source',
            render: (row) => <p className="text-[#395BA9] font-medium text-sm text-capitalize">{row?.source}</p>,
        },
        {
            title: 'Risk Owner',
            key: 'risk_owner',
            render: (row) => <>{row?.solution_owner_detail || row?.solution_owner || 'N/A'}</>,
        },
        {
            title: 'Category',
            key: 'category',
            render: (row) => (
                <p className="bg-[#F1F5F9] py-1 px-2 rounded text-[#475569] text-[13px] font-medium w-fit">
                    {row?.category_detail}
                </p>
            ),
        },
        {
            title: 'Inherent Risk',
            key: 'inherent_risk_level_slug',
            render: (row) => (
                <div className="w-fit flex items-center gap-1 h-7 !rounded !py-1 !px-2 !border !border-[#E2E8F0]">
                    {row?.inherent_risk_level_slug ? (
                        <>
                            <div
                                className={`w-2 h-2 rounded-full ${
                                    row?.inherent_risk_level_slug?.toLowerCase() === 'low'
                                        ? 'bg-[#55BE8B]'
                                        : row?.inherent_risk_level_slug?.toLowerCase() === 'medium'
                                        ? 'bg-[#D2A500]'
                                        : 'bg-[#FF5449]'
                                }`}
                            />

                            <p className="text-[#64748B] font-medium text-[13px]">{row?.inherent_risk_level_slug}</p>
                        </>
                    ) : (
                        '--'
                    )}
                </div>
            ),
        },
        {
            title: 'Mitigation Plan',
            key: 'mitigation_plan',
            render: (row) => (
                <div className="flex flex-col gap-1">
                    <p className="text-[#395BA9] font-medium text-sm capitalize">{row?.mitigation_plan}</p>
                    {row?.sub_status === 'timeline expired' ? (
                        <p className="text-[#FF5449] font-normal text-xs capitalize">{row?.sub_status}</p>
                    ) : row?.sub_status ? (
                        <p className="text-[#64748B] font-normal text-xs">{row?.sub_status}</p>
                    ) : null}
                </div>
            ),
        },
        {
            title: <MoreHoriz />,
            key: 'status',
            render: (row) => (
                <>
                    <TableAction
                        row={row}
                        openResolvedmodal={openResolvedmodal}
                        openApprovedModal={openApprovedModal}
                        openSuggestionModal={openSuggestionModal}
                        openViewModal={openViewModal}
                        setApprovalType={setApprovalType}
                    />
                </>
            ),
        },
    ];
    const columns = risk_current_permission === 'escalator' ? columns_1 : columns_2;
    useEffect(() => {
        const start = (page - 1) * NO_PER_PAGE;
        const stop = start + NO_PER_PAGE;
        let filteredData = [...(data || [])];

        if (filteredData?.length > 0 && currentTab === 'filter') {
            filteredData = filteredData?.filter((item) => item?.sub_status === 'timeline expired');
        }

        if (filteredData?.length > 0) {
            const category_ids = category?.map((category) => category?.id);
            filteredData = filteredData?.filter((item) => category_ids?.includes(item?.category));
        }

        if (filteredData?.length > 0) {
            filteredData = filteredData.filter((item) => plan?.includes(item?.mitigation_plan));
        }

        if (filteredData?.length > 0) {
            filteredData = filteredData.filter((item) => residualRisk?.includes(item?.inherent_risk_level_slug));
            console.log({ filteredData });
        }

        if (filteredData?.length > 0) {
            filteredData = filteredData?.filter((item) => sources?.includes(item?.source));
        }
        // if (riskType?.name !== 'All') {
        //     filteredData = filteredData.filter((item) => item[riskType?.value]);
        // }
        const new_data = filteredData?.length ? filteredData?.sort((a, b) => b?.id - a?.id)?.slice(start, stop) : [];
        setAllData(filteredData);
        setPageData(new_data);
    }, [page, data, category, plan, residualRisk, currentTab, sources]);

    const all_unresolved_risk = allData?.filter(
        (risk) =>
            risk?.risk_assessment_status === 'not_resolved' ||
            risk?.risk_assessment_status === 'approved' ||
            risk?.risk_assessment_status === 'no response'
    );
    const all_resolved_risk = allData?.filter((risk) => risk?.risk_assessment_status === 'resolved');
    const all_unapproved_risk = allData?.filter((risk) => risk?.risk_assessment_status === 'awaiting_approval');
    const all_time_expired_risk = allData?.filter((risk) => risk?.sub_status === 'timeline expired');
    return (
        <div>
            <Header
                id={id}
                open={open}
                openSwitch={openSwitch}
                hideSwitch={hideSwitch}
                showSwitch={showSwitch}
                setCurrentTab={setCurrentTab}
            />
            <Divider />
            <div className="p-6">
                <div
                    className="flex justify-between items-center bg-[#FFFBEB] border-[1px] border-[#F2C021] p-3 rounded"
                    hidden={
                        !open_auto_risk_mitigation ||
                        all_time_expired_risk?.length === 0 ||
                        risk_current_permission !== 'risk_migation'
                    }
                >
                    <div className="flex items-center gap-5">
                        <div className="flex items-center gap-1">
                            <InfoOutlined sx={{ width: 15, height: 15, marginRight: '5px' }} />
                            <p className="text-[#755B00] text-[15px] font-normal">
                                Immediate action required: <strong>{all_time_expired_risk?.length}</strong> risk
                                mitigation deadlines have passed. Unresolved risks pose a security threat.
                            </p>
                        </div>
                        <button
                            className="border-[1px] border-[#755B00] rounded px-2 py-1 text-[#755B00] text-[15px] font-medium"
                            onClick={() => {
                                setCurrentTab('filter');
                                dispatch(OpenAutoRiskMitigation(false));
                            }}
                        >
                            View all
                        </button>
                    </div>
                    <div
                        className="bg-white rounded-full w-[25px] h-[25px] flex justify-center items-center cursor-pointer"
                        onClick={() => dispatch(OpenAutoRiskMitigation(false))}
                    >
                        <CloseIcon fontSize="6" />
                    </div>
                </div>
                <div
                    className="flex justify-between items-center bg-[#FFFBEB] border-[1px] border-[#F2C021] p-3 rounded"
                    hidden={
                        !open_auto_risk_excalation ||
                        all_time_expired_risk?.length === 0 ||
                        risk_current_permission !== 'escalator'
                    }
                >
                    <div className="flex items-center gap-5">
                        <div className="flex items-center gap-1">
                            <InfoOutlined sx={{ width: 15, height: 15, marginRight: '5px' }} />
                            <p className="text-[#755B00] text-[15px] font-normal">
                                Immediate action required: <strong>{all_time_expired_risk?.length}</strong> risk
                                mitigation deadlines have passed. Unresolved risks pose a security threat.
                            </p>
                        </div>
                        <button
                            className="border-[1px] border-[#755B00] rounded px-2 py-1 text-[#755B00] text-[15px] font-medium"
                            onClick={() => {
                                setCurrentTab('filter');
                                dispatch(OpenAutoRiskExcalation(false));
                            }}
                        >
                            View all
                        </button>
                    </div>
                    <div
                        className="bg-white rounded-full w-[25px] h-[25px] flex justify-center items-center cursor-pointer"
                        onClick={() => dispatch(OpenAutoRiskExcalation(false))}
                    >
                        <CloseIcon fontSize="6" />
                    </div>
                </div>
                <div
                    className="flex justify-between items-center bg-[#FFFBEB] border-[1px] border-[#F2C021] p-3 rounded"
                    hidden={
                        !open_auto_risk_approval ||
                        all_time_expired_risk?.length === 0 ||
                        risk_current_permission !== 'approval'
                    }
                >
                    <div className="flex items-center gap-5">
                        <div className="flex items-center gap-1">
                            <InfoOutlined sx={{ width: 15, height: 15, marginRight: '5px' }} />
                            <p className="text-[#755B00] text-[15px] font-normal">
                                Immediate action required: <strong>{all_time_expired_risk?.length}</strong> risk
                                mitigation deadlines have passed. Unresolved risks pose a security threat.
                            </p>
                        </div>
                        <button
                            className="border-[1px] border-[#755B00] rounded px-2 py-1 text-[#755B00] text-[15px] font-medium"
                            onClick={() => {
                                setCurrentTab('filter');
                                dispatch(OpenAutoRiskApproval(false));
                            }}
                        >
                            View all
                        </button>
                    </div>
                    <div
                        className="bg-white rounded-full w-[25px] h-[25px] flex justify-center items-center cursor-pointer"
                        onClick={() => dispatch(OpenAutoRiskApproval(false))}
                    >
                        <CloseIcon fontSize="6" />
                    </div>
                </div>
                <div className="flex items-center  gap-5 mt-[1.5rem]">
                    <div className="flex justify-between items-center px-3 py-2 bg-white rounded border-[1px] border-[#F1F5F9] w-full">
                        <h4 className="text-xl text-[#334155] font-semibold">Total Risk</h4>
                        <div className="bg-[#F1F5F9] rounded-full p-2">
                            <h1 className="text-[#395BA9] text-2xl font-semibold">{allData?.length}</h1>
                        </div>
                    </div>
                    {risk_current_permission === 'risk_migation' && (
                        <div className="flex justify-between items-center px-3 py-2 bg-white rounded border-[1px] border-[#F1F5F9] w-full">
                            <h4 className="text-xl text-[#334155] font-semibold">Resolved Risk</h4>
                            <div className="bg-[#F1F5F9] rounded-full p-2">
                                <h1 className="text-[#395BA9] text-2xl font-semibold">{all_resolved_risk?.length}</h1>
                            </div>
                        </div>
                    )}
                    {risk_current_permission === 'risk_migation' && (
                        <div className="flex justify-between items-center px-3 py-2 bg-white rounded border-[1px] border-[#F1F5F9] w-full">
                            <h4 className="text-xl text-[#334155] font-semibold">Unresolved Risk</h4>
                            <div className="bg-[#F1F5F9] rounded-full p-2">
                                <h1 className="text-[#DE3730] text-2xl font-semibold">{all_unresolved_risk?.length}</h1>
                            </div>
                        </div>
                    )}
                    {risk_current_permission === 'escalator' && (
                        <div className="flex justify-between items-center px-3 py-2 bg-white rounded border-[1px] border-[#F1F5F9] w-full">
                            <h4 className="text-xl text-[#334155] font-semibold">Escalated Risk</h4>
                            <div className="bg-[#F1F5F9] rounded-full p-2">
                                <h1 className="text-[#395BA9] text-2xl font-semibold">{risk_self_service?.length}</h1>
                            </div>
                        </div>
                    )}
                    {/* {risk_current_permission === 'approval' && (
                        <div className="flex justify-between items-center px-3 py-2 bg-white rounded border-[1px] border-[#F1F5F9] w-full">
                            <h4 className="text-xl text-[#334155] font-semibold">Approved Risk</h4>
                            <div className="bg-[#F1F5F9] rounded-full p-2">
                                <h1 className="text-[#395BA9] text-2xl font-semibold">21</h1>
                            </div>
                        </div>
                    )} */}
                    {risk_current_permission === 'approval' && (
                        <div className="flex justify-between items-center px-3 py-2 bg-white rounded border-[1px] border-[#F1F5F9] w-full">
                            <h4 className="text-xl text-[#334155] font-semibold">Unapproved risk treatment</h4>
                            <div className="bg-[#F1F5F9] rounded-full p-2">
                                <h1 className="text-[#DE3730] text-2xl font-semibold">{all_unapproved_risk?.length}</h1>
                            </div>
                        </div>
                    )}
                </div>
                <PageHeader browserTitle="Self Service | Smartcomply" />
                <div className="bg-white rounded my-[2rem] flex flex-col gap-4 !py-6 !px-4">
                    <TopContent
                        riskTypeAnchorEl={riskTypeAnchorEl}
                        riskType={riskType}
                        riskTypeChange={riskTypeChange}
                        spoolCloseMenu={spoolCloseMenu}
                        spoolOpenMenu={spoolOpenMenu}
                        setCurrentTab={setCurrentTab}
                        anchorEl={anchorEl}
                        closeMenu={closeMenu}
                        currentTab={currentTab}
                        riskTypeCloseMenu={riskTypeCloseMenu}
                        riskTypeOpenMenu={riskTypeOpenMenu}
                        spoolAnchorEl={spoolAnchorEl}
                        openMenu={openMenu}
                        setResidualRisk={setResidualRisk}
                    />
                    <AppTable
                        columns={columns}
                        data={pageData}
                        sorter={(a, b) => b?.id - a?.id}
                        dataLength={allData?.length}
                        noPerPage={NO_PER_PAGE}
                        page={page}
                        onPageChange={handlePageChange}
                        loading={loading}
                        title={
                            <TableHeading
                                onSearch={handleSearch}
                                categoryAnchorEl={categoryAnchorEl}
                                setCategoryAnchorEl={setCategoryAnchorEl}
                                category={category}
                                setCategory={setCategory}
                                setPlanAnchorEl={setPlanAnchorEl}
                                planAnchorEl={planAnchorEl}
                                plan={plan}
                                setPlan={setPlan}
                                currentTab={currentTab}
                                setResidualRiskAnchorEl={setResidualRiskAnchorEl}
                                residualRiskAnchorEl={residualRiskAnchorEl}
                                setResidualRisk={setResidualRisk}
                                residualRisk={residualRisk}
                                sources={sources}
                                setSources={setSources}
                                sourcesAnchorEl={sourcesAnchorEl}
                                setSourcesAnchorEl={setSourcesAnchorEl}
                            />
                        }
                    />
                    <MarkResolvedModal
                        open={markResolvedModal}
                        handleClose={closeResolvedModal}
                        details={details}
                        params={query_params}
                    />
                    <MarkApprovedModal
                        open={markApprovedModal}
                        handleClose={closeApprovedModal}
                        details={details}
                        approvedComment={approvedComment}
                        type={approval_type}
                        setApprovedComment={setApprovedComment}
                        openApproveMitigationModal={openApproveMitigationModal}
                        openDisApproveMitigationModal={openDisApproveMitigationModal}
                    />
                    <MitigationApprovedModal
                        open={approveMitigationModal}
                        handleClose={closeApproveMitigation}
                        id={details?.id}
                        approvedComment={approvedComment}
                        params={query_params}
                    />

                    <MitigationDisapprovalModal
                        open={disapproveMitigationModal}
                        handleClose={closeDisApproveMitigation}
                        id={details?.id}
                        approvedComment={approvedComment}
                        params={query_params}
                    />
                    <SuggestionModal
                        open={suggestionModal}
                        handleClose={closeSuggestionModal}
                        suggestionComment={suggestionComment}
                        setSuggestionComment={setSuggestionComment}
                        details={details}
                        params={query_params}
                    />
                    <ViewDetailsDrawer
                        open={viewModal}
                        handleClose={closeViewModal}
                        details={details}
                        openSuggestionModal={openSuggestionModal}
                        openResolvedmodal={openResolvedmodal}
                    />
                </div>
            </div>
        </div>
    );
};

export default SelfServiceView;
