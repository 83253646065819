import React, { useState } from 'react';
import { Stack } from '@mui/material';
import bulb from '../../../assets/img/lightBulb.svg';
import close from '../../../assets/img/closeReportModal.svg';
//core component
import AppCenteredModal from 'components/new_components/AppCenteredModal';
import { AppFormInput, AppFormSelect, AppSubmitButton, AppForm } from 'components/new_components/forms';
import AppFormDateInput from 'components/new_components/forms/AppFormDateInput';
import { AppFormDragFileUpload } from 'components/new_components/forms/AppFormDragFileUpload';
import { toast } from 'react-toastify';
import AppCheckboxInput from 'components/new_components/AppCheckboxInput';
import { uploadModalValidation } from './utils/validation';
import { accept } from 'validate';
import { severityOptions } from './utils/constants';
//redux
import { connect } from 'react-redux';
import { CreatePentest } from 'store/actions/merchantActions';

//translations
import { useTranslation } from 'react-i18next';

const UploadReportModal = ({ open, handleClose, payload, CreatePentest }) => {
    const [authorization, setAuthorization] = useState(false);
    const [loading, setLoading] = useState(false);

    //translation
    const { t } = useTranslation('pentest');

    //function
    const today = new Date().toISOString().split('T')[0];

    const handleAuthorizationCheck = () => {
        setAuthorization(!authorization);
    };

    const handleSubmit = async (values, { resetForm }) => {
        if (authorization) {
            const formData = new FormData();
            formData.append('name', values?.name);
            formData.append('severity', values?.severity);
            formData.append('authorization', authorization);
            formData.append('uploaded_by', values?.uploaded_by);
            formData.append('conducted_date', values?.conducted_date);
            formData.append('ip_address', values?.ip_address);
            formData.append('file_upload', values.file_upload);
            setLoading(true);
            const res = await CreatePentest(formData);
            setLoading(false);
            resetForm();
            setAuthorization(false);
            if (res?.success) {
                toast.success('Report Uploaded Successfully');
                handleClose();
            } else {
                if (res?.message !== 'Upsell' && res?.message !== 'Paywall') {
                    toast.error(res?.message);
                }
            }
        } else {
            toast.info('You have to authorize this request!');
        }
    };
    return (
        <AppForm initialValues={payload} onSubmit={handleSubmit} validate={uploadModalValidation}>
            <AppCenteredModal
                open={open}
                handleClose={handleClose}
                title={
                    <div className="flex items-center justify-between w-[100%]">
                        <span className="text-[18px] font-semibold text-[#202D66]">{t('uploadReportModal.title')}</span>
                        <img src={close} alt="close" onClick={handleClose} className="cursor-pointer" />
                    </div>
                }
                width={511}
                style={{ height: '832px' }}
                subtitle={
                    <Stack
                        flexDirection="row"
                        justifyContent="flex-start"
                        sx={{
                            py: '8px',
                            px: '16px',
                            fontSize: 13,
                            fontWeight: 400,
                            color: '#64748B',
                            background: '#F8FAFC',
                            borderRadius: '4px',
                            mt: '24px',
                            display: 'flex',
                            gap: '10px',
                            alignItems: 'flex-start',
                            border: '1px solid #F0F0FA',
                        }}
                    >
                        <img src={bulb} alt="bulb" />
                        {t('uploadReportModal.subtitle')}
                    </Stack>
                }
                actions={
                    <>
                        {/* <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                            }}
                        >
                            <AppSubmitButton
                                text={t('uploadReportModal.title')}
                                startIcon={<Upload />}
                                variant="contained"
                                color="primary"
                                loading={loading}
                                loadingPosition="center"
                                sx={{ width: '50%' }}
                            />
                        </Box> */}
                        <div className="flex items-center  justify-end w-[511px] gap-[8px] bg-[#F8FAFC] pt-[40px] h-[68px] pr-[20px] -ml-[25px] -mr-[50px] -mb-[10px]">
                            <button
                                type="cancel"
                                onClick={handleClose}
                                className="bg-white relative z-10 mb-[40px] flex items-center cursor-pointer justify-center text-[14px] w-[84px] rounded-[2px] h-[36px] font-medium text-[#334155] border-[1px] border-[#E2E8F0] border-solid"
                            >
                                Cancel
                            </button>
                            <div className="mb-[40px]">
                                <AppSubmitButton
                                    text={t('Submit')}
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    loading={loading}
                                    loadingPosition="center"
                                    sx={{ width: '84px', height: '36px', boxShadow: 'none' }}
                                />
                            </div>
                        </div>
                    </>
                }
            >
                <AppFormInput
                    type="text"
                    label={t('uploadReportModal.scanName')}
                    name="name"
                    placeholder={t('uploadReportModal.testName')}
                />
                <AppFormInput
                    type="text"
                    label={t('uploadReportModal.ipAddress')}
                    name="ip_address"
                    placeholder={t('uploadReportModal.ipAddress')}
                />
                <AppFormSelect
                    name="severity"
                    label={t('uploadReportModal.severityLevel')}
                    options={severityOptions}
                    defaultValue={t('uploadReportModal.severityLevelValue')}
                    toolTip={true}
                    tips={t('uploadReportModal.severityLevelTip')}
                />
                <AppFormInput
                    type="text"
                    label={t('uploadReportModal.doneBy')}
                    name="uploaded_by"
                    placeholder={t('uploadReportModal.platformName')}
                />
                <AppFormDateInput
                    height={'36px'}
                    name="conducted_date"
                    label={t('uploadReportModal.pentestDate')}
                    max={today}
                />
                <AppFormDragFileUpload name="file_upload" accept={accept} />
                <AppCheckboxInput
                    name="authorization"
                    label={t('uploadReportModal.authorization')}
                    onChange={handleAuthorizationCheck}
                    value={authorization}
                />
            </AppCenteredModal>
        </AppForm>
    );
};

const mapStateToProps = (state) => {
    return {
        ...state,
    };
};
export default connect(mapStateToProps, { CreatePentest })(UploadReportModal);
