import React from 'react';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import AppAvatar from 'components/new_components/AppAvatar';
// redux
import { connect } from 'react-redux';
import UserAvatar from 'assets/img/userAvatar.svg';
import CustomTooltip from 'components/new_components/CustomTooltip';

const UserDropdownTrigger = (props) => {
    const { showDropdown, company_info } = props;
    const theme = useTheme();
    // const userType = useMemo(() => getType('type'), []);
    return (
        <Box
            component="button"
            onClick={showDropdown}
            sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                width: 'fit-content',
                border: 'none',
                backgroundColor: 'inherit',
                color: theme.palette.primary[900],
            }}
        >
            <AppAvatar src={company_info?.company_img || UserAvatar} size={25} />
            <CustomTooltip title="More Options" placement="bottom">
                <ExpandMore color="primary" />
            </CustomTooltip>
        </Box>
    );
};
const mapStateToProps = (state) => {
    return {
        company_info: state?.generalReducers?.user_info?.organization,
        employee_details: state?.generalReducers?.user_info?.employee?.user_detail,
    };
};
export default connect(mapStateToProps, {})(UserDropdownTrigger);
