import React from 'react';
import { useTheme } from '@mui/material/styles';
import { CardHeader, Stack, Typography } from '@mui/material';
import AppInput from '../AppInput';
import searchIcon from '../../../assets/img/search.svg';

//translations
import { useTranslation } from 'react-i18next';

const TableTopbar = (props) => {
    const { title, actions, rowSpacing, search, onSearch, tabs, toggleTabs, tabState, ref1, ref2 } = props;
    const theme = useTheme();

    //translation
    const { t } = useTranslation('common');

    return (
        <CardHeader
            title={
                <Stack
                    direction="row"
                    alignItems="flex-start"
                    sx={{ mt: '-0.3rem' }}
                    gap="8px"
                    flexDirection={'column'}
                >
                    {tabs && (
                        <div className="flex gap-[8px]">
                            <button
                                className="flex items-center justify-center text-[14px] font-medium"
                                style={{
                                    textTransform: 'capitalize',
                                    borderRadius: '16px',
                                    height: '32px',
                                    paddingInline: '12px',
                                    paddingBottom: '8px',
                                    paddingTop: '8px',
                                    border: '1px solid #E2E8F0',
                                    width: '104px',
                                    color:
                                        tabState === 'requested' ? theme.palette.white.main : theme.palette.primary[20],
                                    background: tabState === 'requested' ? '#395BA9' : theme.palette.white.main,
                                    '&:hover': {
                                        background: tabState === 'requested' ? '#395BA9' : theme.palette.white.main,
                                        border: `1px solid ${theme.palette.primary[20]}`,
                                    },
                                }}
                                onClick={() => toggleTabs('requested')}
                                ref={ref1}
                            >
                                {t('requested')}
                            </button>
                            <button
                                className="flex items-center justify-center text-[14px] font-medium"
                                style={{
                                    textTransform: 'capitalize',
                                    borderRadius: '16px',
                                    height: '32px',
                                    paddingInline: '12px',
                                    paddingBottom: '8px',
                                    paddingTop: '8px',
                                    width: '97px',
                                    color:
                                        tabState === 'uploaded' ? theme.palette.white.main : theme.palette.primary[20],
                                    background: tabState === 'uploaded' ? '#395BA9' : theme.palette.white.main,
                                    border: '1px solid #E2E8F0',
                                    '&:hover': {
                                        background: tabState === 'uploaded' ? '#395BA9' : theme.palette.white.main,
                                        border: `1px solid ${theme.palette.primary[20]}`,
                                    },
                                }}
                                onClick={() => toggleTabs('uploaded')}
                                ref={ref2}
                            >
                                {t('uploaded')}
                            </button>
                        </div>
                    )}

                    {search ? (
                        <div className="relative">
                            <AppInput
                                placeholder={t('search')}
                                sx={{
                                    width: 224,
                                    height: 36,
                                    borderColor: '#E2E8F0',
                                    fontSize: '14px',
                                    paddingLeft: '15px',
                                    '& input::placeholder': {
                                        color: '#94A3B8',
                                        fontWeight: 400,
                                    },
                                }}
                                controlStyle={{ mt: -'0.8px', width: 'fit-content' }}
                                onChange={onSearch}
                            />
                            <img src={searchIcon} className="absolute left-[10px] top-[13px]" />
                        </div>
                    ) : (
                        <Typography variant="h3" component="h3" sx={{ marginBottom: '0!important', color: 'primary' }}>
                            {title}
                        </Typography>
                    )}
                </Stack>
            }
            action={
                <Stack direction="row" spacing={1}>
                    {/* {search && (
                        <AppInput
                            placeholder="Search..."
                            sx={{
                                maxWidth: 200,
                                borderColor: theme.palette.gray[30],
                                '& input::placeholder': {
                                    color: theme.palette.gray[40],
                                    fontWeight: 600,
                                },
                            }}
                            controlStyle={{ mt: 0, width: 'fit-content' }}
                            onChange={onSearch}
                        />
                    )} */}
                    {actions}
                </Stack>
            }
            sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', pr: 4, pb: rowSpacing ? 0 : 2.5 }}
        />
    );
};

export default TableTopbar;
