import React from 'react';
import { useSelector } from 'react-redux';
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

const EmployeeSummary = () => {
    const { people_info_summary } = useSelector((state) => state?.CISOReducers);
    return (
        <div className="border border-[#F1F5F9] rounded py-2 my-3 w-[600px]">
            <div className="flex justify-between items-center px-3">
                <h4 className="text-[#2B3674] text-base font-bold">Employees</h4>
                <div className="flex items-center gap-1">
                    <FiberManualRecordIcon
                        sx={{
                            color:
                                people_info_summary?.total_percentage_passed < 50
                                    ? '#FF5449'
                                    : people_info_summary?.total_percentage_passed >= 50 &&
                                      people_info_summary?.total_percentage_passed <= 70
                                    ? '#F2C021'
                                    : '#55BE8B',
                            width: 12,
                            height: 12,
                        }}
                    />
                    <p className="text-gray-600 text-xs font-medium">
                        {people_info_summary?.total_percentage_passed?.toFixed(2)}%
                    </p>
                </div>
            </div>
            <div className="border-t border-[#F1F5F9] px-3 p-2 flex justify-between items-center">
                <div className="flex items-center gap-1">
                    {people_info_summary?.has_background_check === people_info_summary?.total_employees ? (
                        <CheckCircleIcon sx={{ color: '#37A372', width: 12, height: 13 }} />
                    ) : (
                        <ErrorIcon sx={{ color: '#FF5449', width: 12, height: 13 }} />
                    )}
                    <p className="text-gray-600 text-xs font-normal">Employee(s) with background check</p>
                </div>
                <p className="border border-[#E2E8F0] p-1 text-gray-500 text-xs font-medium">
                    {people_info_summary?.has_background_check} /{people_info_summary?.total_employees} Employees
                    checked
                </p>
            </div>
            <div className="border-t border-[#F1F5F9] px-3 p-2 flex justify-between items-center">
                <div className="flex items-center gap-1">
                    {people_info_summary?.onboarding_policy_accepted === people_info_summary?.total_employees ? (
                        <CheckCircleIcon sx={{ color: '#37A372', width: 12, height: 13 }} />
                    ) : (
                        <ErrorIcon sx={{ color: '#FF5449', width: 12, height: 13 }} />
                    )}
                    <p className="text-gray-600 text-xs font-normal">Onboarding Acknowledgement Status</p>
                </div>
                <p className="border border-[#E2E8F0] p-1 text-gray-500 text-xs font-medium">
                    {people_info_summary?.onboarding_policy_accepted} /{people_info_summary?.total_employees} Employees
                    acknowledged
                </p>
            </div>
            <div className="border-t border-[#F1F5F9] px-3 p-2 flex justify-between items-center">
                <div className="flex items-center gap-1">
                    {people_info_summary?.training_complete === people_info_summary?.total_employees ? (
                        <CheckCircleIcon sx={{ color: '#37A372', width: 12, height: 13 }} />
                    ) : (
                        <ErrorIcon sx={{ color: '#FF5449', width: 12, height: 13 }} />
                    )}
                    <p className="text-gray-600 text-xs font-normal">Employee Training Completion Status</p>
                </div>
                <p className="border border-[#E2E8F0] p-1 text-gray-500 text-xs font-medium">
                    {people_info_summary?.training_complete} /{people_info_summary?.total_employees} Employees trained
                </p>
            </div>
        </div>
    );
};

export default EmployeeSummary;
