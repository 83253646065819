import {
    MERCHANT_INFO,
    ALL_MERCHANT_DOCUMENTS,
    ALL_ACTIVITIES,
    MERCHANT_DOCUMENT_VERSIONS,
    ALL_MERCHANT_FORMS,
    MERCHANT_SERVERS,
    SERVER_SCAN_RESULTS,
    OVERVIEW_SUMMARY,
    COMPLIANCE_SUMMARY,
    PCIDSS4POINT0_OVERVIEW_SUMMARY,
    PCIDSS4POINT0_COMPLIANCE_SUMMARY,
    MERCHANT_DOCUMENT_MESSAGES,
    ALL_MERCHANT_USERS,
    MERCHANT_ASVSCANS_REQUESTS,
    MERCHANT_PENTESTS_REQUESTS,
    ALL_COMPUTERS,
    ALL_APPLICATION_SETUPS,
    ALL_SCANS_EXCEPTIONS,
    ALL_MERCHANT_PEOPLE,
    ALL_EXEMPTED_SCANS,
    GITHUB_ACCOUNTS,
    MERCHANT_NDPR_DOCUMENT_VERSIONS,
    ALL_VULNERABILITY_SCANS,
    NDPR_GENERAL_SUMMARY,
    NDPR_SUMMARY_BY_CATEORY,
    ALL_MERCHANT_INFRASTRUCTURES,
    MERCHANT_INFRASTRUCTURE_INFO,
    MERCHANT_ASSET_GROUP,
    ONE_MERCHANT_PERSON,
    ISO_SUMMARY_BY_ANNEX,
    ISO_GENERAL_SUMMARY,
    ALL_MERCHANT_ONBOARDING_ITEMS,
    ALL_MERCHANT_RISK_ASSESSMENTS,
    FILTERED_MERCHANT_RISK_ASSESSMENTS,
    ALL_MERCHANT_INTELLIGENCE_REPORT,
    FILTERED_MERCHANT_INTELLIGENCE_REPORT,
    SPOOL_RISK_ASSESSMENT,
    SPOOL_INTELLIGENCE_REPORT,
    ALL_SPOOL_REPORTS,
    ALL_ONBOARDING_ITEMS_FOR_MERCHANTS,
    SOC2_GENERAL_SUMMARY,
    SOC2_SUMMARY_BY_CONTROL,
    ALL_MERCHANT_EXEMPTED_DOCUMENTS_BY_STANDARD,
    ALL_MERCHANT_PCIDSS_EXEMPTED_FORMS_BY_SUBREQUIREMENT,
    ALL_MERCHANT_EXEMPTED_FORMS_BY_STANDARD,
    ALL_CODE_REVIEW,
    USER_DEVICE_TOKEN,
    ASV_REPORTS_BY_ID,
    USER_SURVEY_RESPONSE,
    PENTESTS_REPORT_BY_ID,
    RISK_ASSESSMENT_SUMMARY,
    ALL_NEEDED_DOCS,
    ALL_MERCHANT_DOCS_RESPONSES,
    ALL_MERCHANT_EXTRA_DOCS,
} from '../constants';

export default (state = {}, action) => {
    switch (action.type) {
        case MERCHANT_INFO:
            return {
                ...state,
                merchant_info: action.payload,
            };
        case ALL_ACTIVITIES:
            return {
                ...state,
                all_activities: action.payload,
            };
        case ALL_MERCHANT_DOCUMENTS:
            return {
                ...state,
                all_merchant_documents: action.payload,
            };
        case MERCHANT_DOCUMENT_VERSIONS:
            return {
                ...state,
                merchant_document_versions: action.payload,
            };
        case ALL_MERCHANT_FORMS:
            return {
                ...state,
                all_merchant_forms: action.payload,
            };
        case MERCHANT_SERVERS:
            return {
                ...state,
                all_merchant_servers: action.payload,
            };
        case SERVER_SCAN_RESULTS:
            return {
                ...state,
                server_scan_results: action.payload,
            };
        case OVERVIEW_SUMMARY:
            return {
                ...state,
                merchant_overview_summary: action.payload,
            };
        case COMPLIANCE_SUMMARY:
            return {
                ...state,
                merchant_compliance_summary: action.payload,
            };
        case PCIDSS4POINT0_OVERVIEW_SUMMARY:
            return {
                ...state,
                merchant_pcidss4Point0_overview_summary: action.payload,
            };
        case PCIDSS4POINT0_COMPLIANCE_SUMMARY:
            return {
                ...state,
                merchant_pcidss4Point0_compliance_summary: action.payload,
            };
        case NDPR_GENERAL_SUMMARY:
            return {
                ...state,
                ndpr_general_summary: action.payload,
            };
        case NDPR_SUMMARY_BY_CATEORY:
            return {
                ...state,
                ndpr_summary_by_category: action.payload,
            };
        case MERCHANT_DOCUMENT_MESSAGES:
            return {
                ...state,
                merchant_document_messages: action.payload,
            };
        case ALL_MERCHANT_USERS:
            return {
                ...state,
                all_merchant_users: action.payload,
            };
        case MERCHANT_PENTESTS_REQUESTS:
            return {
                ...state,
                merchant_pentests_requests: action.payload,
            };
        case MERCHANT_ASVSCANS_REQUESTS:
            return {
                ...state,
                merchant_asvscans_requests: action.payload,
            };
        case ALL_SCANS_EXCEPTIONS:
            return {
                ...state,
                all_scans_exceptions: action.payload,
            };
        case ALL_EXEMPTED_SCANS:
            return {
                ...state,
                all_exempted_scans: action.payload,
            };

        case ALL_MERCHANT_PEOPLE:
            return {
                ...state,
                all_merchant_people: action.payload,
            };
        case ALL_COMPUTERS:
            return {
                ...state,
                all_computers: action.payload,
            };
        case ALL_APPLICATION_SETUPS:
            return {
                ...state,
                all_application_setups: action.payload,
            };
        case GITHUB_ACCOUNTS:
            return {
                ...state,
                all_twoFactorAuth_accounts: action.payload,
            };

        case MERCHANT_NDPR_DOCUMENT_VERSIONS:
            return {
                ...state,
                merchant_ndpr_document_versions: action.payload,
            };
        case ALL_VULNERABILITY_SCANS:
            return {
                ...state,
                all_vulnerability_scans: action.payload,
            };
        case ALL_MERCHANT_INFRASTRUCTURES:
            return {
                ...state,
                all_merchant_infrastructures: action.payload,
            };
        case MERCHANT_INFRASTRUCTURE_INFO:
            return {
                ...state,
                merchant_infrastructure_info: action.payload,
            };
        case ONE_MERCHANT_PERSON:
            return {
                ...state,
                one_merchant_person: action.payload,
            };
        case ISO_SUMMARY_BY_ANNEX:
            return {
                ...state,
                iso_summary_by_annex: action.payload,
            };
        case ISO_GENERAL_SUMMARY:
            return {
                ...state,
                iso_general_summary: action.payload,
            };
        case ALL_MERCHANT_ONBOARDING_ITEMS:
            return {
                ...state,
                all_merchant_onboarding_items: action.payload,
            };
        case ALL_ONBOARDING_ITEMS_FOR_MERCHANTS:
            return {
                ...state,
                all_onboarding_items_for_merchant: action.payload,
            };
        case ALL_MERCHANT_RISK_ASSESSMENTS:
            return {
                ...state,
                all_merchant_risk_assessments: action.payload,
            };
        case FILTERED_MERCHANT_RISK_ASSESSMENTS:
            return {
                ...state,
                filtered_status_merchant_risk_assessment: action.payload,
            };
        case ALL_MERCHANT_INTELLIGENCE_REPORT:
            return {
                ...state,
                all_merchant_intelligence_report: action.payload,
            };
        case FILTERED_MERCHANT_INTELLIGENCE_REPORT:
            return {
                ...state,
                filtered_status_intelligence_report: action.payload,
            };
        case SPOOL_RISK_ASSESSMENT:
            return {
                spool_risk_assessment: action.payload,
            };
        case SPOOL_INTELLIGENCE_REPORT:
            return {
                spool_intelligence_report: action.payload,
            };
        case ALL_SPOOL_REPORTS:
            return {
                all_spool_reports: action.payload,
            };
        case SOC2_GENERAL_SUMMARY:
            return {
                ...state,
                soc2_general_summary: action.payload,
            };
        case SOC2_SUMMARY_BY_CONTROL:
            return {
                ...state,
                soc2_summary_by_control: action.payload,
            };
        case ALL_MERCHANT_EXEMPTED_DOCUMENTS_BY_STANDARD:
            // const payload = {
            //     ...state?.all_merchant_exempted_documents_by_standard,
            //     [action?.payload?.standard]: action?.payload?.data,
            // };
            return {
                ...state,
                all_merchant_exempted_documents_by_standard: state?.all_merchant_exempted_documents_by_standard,
                [action?.payload?.standard]: action?.payload?.data,
            };
        case ALL_MERCHANT_PCIDSS_EXEMPTED_FORMS_BY_SUBREQUIREMENT:
            return {
                ...state,
                all_pcidss_exempted_forms_by_subrequirement: action.payload,
            };
        case ALL_MERCHANT_EXEMPTED_FORMS_BY_STANDARD:
            return {
                ...state,
                all_merchant_exempted_forms_by_standard: state?.all_merchant_exempted_forms_by_standard,
                [action?.payload?.standard]: action?.payload?.data,
            };
        case ALL_CODE_REVIEW:
            return {
                ...state,
                all_code_review: action?.payload,
            };
        case USER_DEVICE_TOKEN:
            return {
                ...state,
                user_device_token: action?.payload,
            };
        case ASV_REPORTS_BY_ID:
            return {
                ...state,
                asv_reports_by_id: action?.payload,
            };
        case USER_SURVEY_RESPONSE:
            return {
                ...state,
                survey_responses: action?.payload,
            };
        case PENTESTS_REPORT_BY_ID:
            return {
                ...state,
                pentest_report_by_id: action?.payload,
            };
        case RISK_ASSESSMENT_SUMMARY:
            return {
                ...state,
                risk_assessment_summary: action.payload,
            };
        case ALL_NEEDED_DOCS:
            return {
                ...state,
                all_needed_docs: action?.payload,
            };
        case ALL_MERCHANT_DOCS_RESPONSES:
            return {
                ...state,
                all_merchant_docs_responses: action.payload,
            };
        case ALL_MERCHANT_EXTRA_DOCS:
            return {
                ...state,
                all_merchant_extra_docs: action.payload,
            };
        case MERCHANT_ASSET_GROUP:
            return {
                ...state,
                all_merchant_asset_groups: action.payload,
            };
        default:
            return state;
    }
};
