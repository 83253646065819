import React from 'react';
import { Container, Card, TableContainer, Table, Stack } from '@mui/material';

// core components
import TableTopbar from './TableTopbar';
import TableHeader from './TableHeader';
import AppTableBody from './AppTableBody';
import AppTablePagination from './AppTablePagination';

const AppTable = (props) => {
    const {
        columns,
        data,
        title,
        actions,
        sorter,
        page,
        onPageChange,
        emptyStateText,
        noPerPage,
        dataLength,
        loading,
        showTitleBar = true,
        sx = {},
        rowSpacing,
        onRowClick,
        border,
        clickableRow,
        search,
        onSearch,
        toggleTabs,
        tabs,
        tabState,
        showPagination = true,
        noBackground,
        rowBorder,
        ref1,
        ref2,
        checked_row,
    } = props;
    const spacingBorderStyles = {
        borderCollapse: 'separate',
        borderSpacing: '0 24px',
    };
    const borderStyles = {
        border: '0.5px solid #77777A',
        borderRadius: 8,
    };

    return (
        <Container
            style={{ marginRight: '100px' }}
            component={Card}
            maxWidth={false}
            elevation={0}
            sx={{
                p: '0 !important',
                minHeight: 'auto',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                flex: 1,
                ...sx?.container,
                // '& .MuiCardHeader-root': {
                //     padding: '0 !important',
                // },
                borderRadius: '4px',
            }}
        >
            <Stack sx={{ p: '16px' }}>
                {showTitleBar && (
                    <TableTopbar
                        title={title}
                        actions={actions}
                        search={search}
                        onSearch={onSearch}
                        rowSpacing={rowSpacing}
                        toggleTabs={toggleTabs}
                        tabs={tabs}
                        tabState={tabState}
                        ref1={ref1}
                        ref2={ref2}
                    />
                )}
                <TableContainer
                    sx={{
                        flex: 1,
                        px: rowSpacing ? 2.5 : 0,
                        mt: '1rem',
                        borderRadius: '6px',
                    }}
                    component={Stack}
                >
                    <Table
                        sx={{
                            ...(rowSpacing ? spacingBorderStyles : {}),
                            ...(border ? borderStyles : {}),
                            border: '1px solid #E2E8F0',
                        }}
                    >
                        <TableHeader columns={columns} noBackground={noBackground} />
                        <AppTableBody
                            columns={columns}
                            data={data}
                            sorter={sorter}
                            emptyStateText={emptyStateText}
                            loading={loading}
                            rowSpacing={rowSpacing}
                            clickableRow={clickableRow}
                            onRowClick={onRowClick}
                            rowBorder={rowBorder}
                            checked_row={checked_row}
                        />
                    </Table>
                </TableContainer>
            </Stack>
            {!!data?.length && showPagination && (
                <AppTablePagination
                    page={page}
                    onPageChange={onPageChange}
                    noPerPage={noPerPage}
                    dataLength={dataLength}
                />
            )}
        </Container>
    );
};

export default AppTable;
