import React, { useState, useMemo } from 'react';
import { toast } from 'react-toastify';
import { Typography, Stack } from '@mui/material';

// Core components
import AppCenteredModal from 'components/new_components/AppCenteredModal';
import { AppFormSelect, AppSubmitButton, AppForm } from 'components/new_components/forms';
import { scheduleOptions } from './utils/constants';
import closeModal from '../../../assets/img/closeReportModal.svg';
import bulb from '../../../assets/img/lightBulb.svg';

// Redux
import { connect } from 'react-redux';
import { RequestPentest, UpdatePentestRequest } from 'store/actions/merchantActions';

const ChangeSchedulingModal = ({ open, handleClose, payload, UpdatePentestRequest, id }) => {
    const [loadingState, setLoadingState] = useState(false);

    const sortedScheduleOptions = useMemo(() => {
        return scheduleOptions?.filter((option) => option.name !== 'Run Now');
    }, [scheduleOptions]);

    // Handle form submit
    const handleSubmit = async (values) => {
        const selectedSchedule = values?.schedule;

        setLoadingState(true);
        const res = await UpdatePentestRequest(id, { schedule: selectedSchedule });
        setLoadingState(false);

        if (res?.success) {
            toast.success('Schedule updated successfully');
            handleClose();
        } else {
            toast.error(res?.message || 'An error occurred');
        }
    };

    return (
        <AppForm
            initialValues={{
                schedule: payload?.schedule,
            }}
            onSubmit={handleSubmit}
        >
            <AppCenteredModal
                open={open}
                handleClose={handleClose}
                title={
                    <div className="flex items-center justify-between w-full h-[52px]">
                        <Typography sx={{ fontSize: '24px', fontWeight: 600, color: '#202D66' }}>
                            Change schedule
                        </Typography>
                        <div
                            onClick={handleClose}
                            className="w-[24px] h-[24px] flex items-center justify-center cursor-pointer border-[1px] border-solid border-[#E2E8F0] rounded-full"
                        >
                            <img src={closeModal} alt="close" />
                        </div>
                    </div>
                }
                width={407}
                actions={
                    <div className="flex flex-col">
                        <Stack
                            flexDirection="row"
                            justifyContent="flex-start"
                            sx={{
                                py: '8px',
                                px: '16px',
                                fontSize: 13,
                                fontWeight: 400,
                                color: '#64748B',
                                background: '#F8FAFC',
                                borderRadius: '4px',
                                display: 'flex',
                                gap: '10px',
                                alignItems: 'flex-start',
                                border: '1px solid #F0F0FA',
                                marginBottom: '24px',
                            }}
                        >
                            <img src={bulb} alt="bulb" />
                            <span>Penetration tests are conducted at intervals specified in the schedule</span>
                        </Stack>
                        <div className="flex items-center justify-end w-[407px] gap-[8px] bg-[#F8FAFC]  h-[84px] -ml-[25px] -mb-[5px]">
                            <div className="flex items-center justify-end gap-[8px] w-full pr-[22px]">
                                <button
                                    type="button"
                                    onClick={handleClose}
                                    className="bg-white flex items-center cursor-pointer justify-center text-[14px] w-[84px] rounded-[2px] h-[36px] font-medium text-[#334155] border-[1px] border-[#E2E8F0] border-solid"
                                >
                                    Cancel
                                </button>

                                <AppSubmitButton
                                    type="submit"
                                    text={'Change'}
                                    variant="contained"
                                    color="primary"
                                    loading={loadingState}
                                    loadingPosition="center"
                                    style={{ width: '84px', height: '36px', boxShadow: 'none' }}
                                />
                            </div>
                        </div>
                    </div>
                }
            >
                <AppFormSelect
                    name="schedule"
                    label={
                        <>
                            <span className="mr-1">Schedule</span>
                        </>
                    }
                    options={sortedScheduleOptions}
                    defaultValue="Select Schedule"
                    style={{ width: '100%', height: '36px', boxShadow: 'none' }}
                />
            </AppCenteredModal>
        </AppForm>
    );
};

const mapStateToProps = (state) => {
    return {
        ...state,
    };
};

export default connect(mapStateToProps, { RequestPentest, UpdatePentestRequest })(ChangeSchedulingModal);
