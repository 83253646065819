import {
    COMPLIANCE_SUMMARY,
    PCIDSS4POINT0_COMPLIANCE_SUMMARY,
    GET_DASHBOARD_RESULT,
    ISO_GENERAL_SUMMARY,
    ISO_SUMMARY_BY_ANNEX,
    ISO_TWO_GENERAL_SUMMARY,
    ISO_TWO_SUMMARY_BY_ANNEX,
    NDPR_GENERAL_SUMMARY,
    CONTINUITY_GENERAL_SUMMARY,
    NDPR_SUMMARY_BY_CATEORY,
    OVERVIEW_SUMMARY,
    PCIDSS4POINT0_OVERVIEW_SUMMARY,
    PCISLC_GENERAL_SUMMARY,
    ISO9001_GENERAL_SUMMARY,
    ISO9901_SUMMARY_BY_CATEGORY,
    PCISLC_SUMMARY_BY_CATEGORY,
    SMART_COMPLIANCE_AI_RECOMMENDATIONS,
    SMART_COMPLIANCE_REPORT,
    SOC2_GENERAL_SUMMARY,
    SOC2_SUMMARY_BY_CONTROL,
    CII_GENERAL_SUMMARY,
    KDPA_GENERAL_SUMMARY,
    KDPA_SUMMARY_BY_CATEGORY,
    GDPA_SUMMARY_BY_CATEGORY,
    GDPA_GENERAL_SUMMARY,
    GET_AUDITOR_DASHBOARD_RESULT,
    GET_SMARTCOMPLY_AUDITOR_DASHBOARD_RESULT,
    AUDITOR_DASHBOARD_SUMMARY,
    TECH_SEC_GENERAL_SUMMARY,
    TECH_SEC_SUMMARY_BY_TECHSEC,
    PEOPLE_INFO_SUMMARY,
    PEOPLE_SUMMARY,
    PEOPLE_TRAINING_SUMMARY,
} from '../constants';
import axiosInstance from '../../utils/https';
import { getType } from 'utils';

export const GetDashboardCompliance = () => async (dispatch, getState) => {
    const company_id = getState().authReducers?.company_id || getType('org_id');
    const user_type = getState().authReducers?.user_type;
    try {
        const res =
            user_type === 'support'
                ? await axiosInstance().get(`/compliance/dashboard/?merchant=${company_id}`)
                : await axiosInstance().get(`/compliance/dashboard/`);

        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: GET_DASHBOARD_RESULT, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};

export const GetAuditorDashboardResult = (merchant) => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/compliance/auditor_dashboard/?merchant=${merchant}`);

        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: GET_AUDITOR_DASHBOARD_RESULT, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};
export const GetSmartcomplyAuditorDashboardResult = (merchant, auditor) => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/compliance/auditor_dashboard/?merchant=${merchant}&auditor=${auditor}`);

        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: GET_SMARTCOMPLY_AUDITOR_DASHBOARD_RESULT, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};
export const GetAuditorDashboardSummary = (standard) => async (dispatch, getState) => {
    const company_id = getState().authReducers?.company_id || getType('org_id');
    const current_standard =
        standard === 'ndpr'
            ? 'ndpr_general'
            : standard === 'soc2'
            ? 'soc2_general'
            : standard === 'soc2type2'
            ? 'soc2_general'
            : standard === 'blockchain'
            ? 'blockchain_general'
            : standard === 'pcidss'
            ? 'general'
            : standard === 'iso27001'
            ? 'iso_general'
            : standard === 'iso27001-2022'
            ? 'iso_two_general'
            : standard === 'iso27017+iso27032'
            ? 'tech_sec_general'
            : standard === 'iso22301'
            ? 'general_continuity_management'
            : standard === 'pcidss4.0'
            ? 'card4_general'
            : standard === 'kdpa'
            ? 'kdpa_general'
            : standard === 'gdpa'
            ? 'gdpa_general'
            : standard === 'cii'
            ? 'general_cii'
            : null;
    try {
        if (standard !== null) {
            const res = await axiosInstance().get(`/compliance/${current_standard}_summary?merchant_id=${company_id}`);
            if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
            dispatch({ type: AUDITOR_DASHBOARD_SUMMARY, payload: res?.data });
            return {
                success: true,
                message: res?.message,
            };
        } else null;
    } catch (err) {
        return { success: false, message: err?.message };
    }
};
export const GetSmartComplianceReport = () => async (dispatch, getState) => {
    const company_id = getState().authReducers?.company_id || getType('org_id');
    try {
        const res = await axiosInstance().get(`/compliance/smart_dashboard/?merchant=${company_id}`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: SMART_COMPLIANCE_REPORT, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};
export const GetSmartComplianceAIRecommendations = () => async (dispatch, getState) => {
    const company_id = getState().authReducers?.company_id || getType('org_id');
    try {
        const res = await axiosInstance().get(`/compliance/health/?merchant=${company_id}`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: SMART_COMPLIANCE_AI_RECOMMENDATIONS, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};

export const GetMerchantNDPRGeneralSummary = () => async (dispatch, getState) => {
    const company_id = getState().authReducers?.company_id || getType('org_id');
    try {
        const res = await axiosInstance().get(`/compliance/ndpr_general_summary/?merchant_id=${company_id}`);

        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: NDPR_GENERAL_SUMMARY, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};

export const GetMerchantNDPRSummaryByCategory = () => async (dispatch, getState) => {
    const company_id = getState().authReducers?.company_id || getType('org_id');
    try {
        const res = await axiosInstance().get(`/compliance/ndpr_get_summary/?merchant_id=${company_id}`);

        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: NDPR_SUMMARY_BY_CATEORY, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};

//K D P A
export const GetMerchantKDPAGeneralSummary = () => async (dispatch, getState) => {
    const company_id = getState().authReducers?.company_id || getType('org_id');
    try {
        const res = await axiosInstance().get(`/compliance/kdpa_general_summary/?merchant_id=${company_id}`);

        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: KDPA_GENERAL_SUMMARY, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};

export const GetMerchantKDPASummaryByCategory = () => async (dispatch, getState) => {
    const company_id = getState().authReducers?.company_id || getType('org_id');
    try {
        const res = await axiosInstance().get(`/compliance/kdpa_get_summary/?merchant_id=${company_id}`);

        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: KDPA_SUMMARY_BY_CATEGORY, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};

//G D P A
export const GetMerchantGDPAGeneralSummary = () => async (dispatch, getState) => {
    const company_id = getState().authReducers?.company_id || getType('org_id');
    try {
        const res = await axiosInstance().get(`/compliance/gdpa_general_summary/?merchant_id=${company_id}`);

        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: GDPA_GENERAL_SUMMARY, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};

export const GetMerchantGDPASummaryByCategory = () => async (dispatch, getState) => {
    const company_id = getState().authReducers?.company_id || getType('org_id');
    try {
        const res = await axiosInstance().get(`/compliance/gdpa_get_summary/?merchant_id=${company_id}`);

        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: GDPA_SUMMARY_BY_CATEGORY, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};

//SOC 2

export const GetMerchantSoc2GeneralSummary = () => async (dispatch, getState) => {
    const company_id = getState().authReducers?.company_id || getType('org_id');
    try {
        const res = await axiosInstance().get(`/compliance/soc2_general_summary/?merchant_id=${company_id}`);

        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: SOC2_GENERAL_SUMMARY, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};

export const GetMerchantSoc2SummaryByControl = () => async (dispatch, getState) => {
    const company_id = getState().authReducers?.company_id || getType('org_id');
    try {
        const res = await axiosInstance().get(`/compliance/soc2_get_summary/?merchant_id=${company_id}`);

        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: SOC2_SUMMARY_BY_CONTROL, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};

//PCIDSS
export const GetMerchantOverviewSummary = () => async (dispatch, getState) => {
    const company_id = getState().authReducers?.company_id || getType('org_id');
    try {
        const res = await axiosInstance().get(`/compliance/get_summary/?merchant_id=${company_id}`);

        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: COMPLIANCE_SUMMARY, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};

export const GetMerchantComplianceSummary = () => async (dispatch, getState) => {
    const company_id = getState().authReducers?.company_id || getType('org_id');
    try {
        const res = await axiosInstance().get(`/compliance/general_summary/?merchant_id=${company_id}`);

        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: OVERVIEW_SUMMARY, payload: res?.data });

        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};

//PCIDSS4POINT0
export const GetMerchantPcidss4point0OverviewSummary = () => async (dispatch, getState) => {
    const company_id = getState().authReducers?.company_id || getType('org_id');
    try {
        const res = await axiosInstance().get(`/compliance/card4_summary/?merchant_id=${company_id}`);

        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: PCIDSS4POINT0_COMPLIANCE_SUMMARY, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};

export const GetMerchantPcidss4point0ComplianceSummary = () => async (dispatch, getState) => {
    const company_id = getState().authReducers?.company_id || getType('org_id');
    try {
        const res = await axiosInstance().get(`/compliance/card4_general_summary/?merchant_id=${company_id}`);

        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: PCIDSS4POINT0_OVERVIEW_SUMMARY, payload: res?.data });

        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};

// ISO27001

export const GetMerchantIsoGeneralSummary = () => async (dispatch, getState) => {
    const company_id = getState().authReducers?.company_id || getType('org_id');
    try {
        const res = await axiosInstance().get(`/compliance/iso_general_summary/?merchant_id=${company_id}`);

        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: ISO_GENERAL_SUMMARY, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};

export const GetMerchantIsoSummaryByAnnex = () => async (dispatch, getState) => {
    const company_id = getState().authReducers?.company_id || getType('org_id');
    try {
        const res = await axiosInstance().get(`/compliance/iso_get_summary/?merchant_id=${company_id}`);

        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: ISO_SUMMARY_BY_ANNEX, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};

// I S O 2 7 0 0 1 + I S O 2 7 0 3 5
export const GetMerchantTechSecGeneralSummary = () => async (dispatch, getState) => {
    const company_id = getState().authReducers?.company_id;
    try {
        const res = await axiosInstance().get(`/compliance/tech_sec_general_summary/?merchant_id=${company_id}`);

        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: TECH_SEC_GENERAL_SUMMARY, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};

export const GetMerchantTechSecSummaryByTechSec = () => async (dispatch, getState) => {
    const company_id = getState().authReducers?.company_id;
    try {
        const res = await axiosInstance().get(`/compliance/tech_sec_get_summary/?merchant_id=${company_id}`);

        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: TECH_SEC_SUMMARY_BY_TECHSEC, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};

// ISO27001-20222

export const GetMerchantIsoTwoGeneralSummary = () => async (dispatch, getState) => {
    const company_id = getState().authReducers?.company_id;
    try {
        const res = await axiosInstance().get(`/compliance/iso_two_general_summary/?merchant_id=${company_id}`);

        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: ISO_TWO_GENERAL_SUMMARY, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};

export const GetMerchantIsoTwoSummaryByAnnex = () => async (dispatch, getState) => {
    const company_id = getState().authReducers?.company_id;
    try {
        const res = await axiosInstance().get(`/compliance/iso_two_get_summary/?merchant_id=${company_id}`);

        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: ISO_TWO_SUMMARY_BY_ANNEX, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};
// CII

export const GetMerchantCiiGeneralSummary = () => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/compliance/general_cii_summary/`);

        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: CII_GENERAL_SUMMARY, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};

export const GetMerchantContinuityGeneralSummary = () => async (dispatch, getState) => {
    const company_id = getState().authReducers?.company_id || getType('org_id');
    try {
        const res = await axiosInstance().get(
            `/compliance/general_continuity_management_summary/?merchant_id=${company_id}`
        );

        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: CONTINUITY_GENERAL_SUMMARY, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};

export const GetAIAnswersToPrompt = (credentials) => async (_, getState) => {
    const company_id = getState().authReducers?.company_id || getType('org_id');
    try {
        const res = await axiosInstance().post(`/compliance/health_chat/?merchant_id=${company_id}`, {
            ...credentials,
            merchant: company_id,
        });
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        return {
            success: true,
            message: res?.message,
            data: res?.data,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};

// PCI SSLC
export const GetMerchantPCISLCGeneralSummary = () => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/compliance/sslc_general_summary/`);

        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: PCISLC_GENERAL_SUMMARY, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};

// ISO9001
export const GetMerchantISO9901CGeneralSummary = () => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/compliance/iso9001_general_summary/`);

        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: ISO9001_GENERAL_SUMMARY, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};

export const GetMerchantPCISLCSummaryByControl = () => async (dispatch, getState) => {
    const company_id = getState().authReducers?.company_id || getType('org_id');
    try {
        const res = await axiosInstance().get(`/compliance/ndpr_get_summary/?merchant_id=${company_id}`);

        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: PCISLC_SUMMARY_BY_CATEGORY, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};

export const GetMerchantISO9001SummaryByManagement = () => async (dispatch, getState) => {
    const company_id = getState().authReducers?.company_id || getType('org_id');
    try {
        const res = await axiosInstance().get(`/compliance/iso9001_get_summary/?merchant_id=${company_id}`);

        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: ISO9901_SUMMARY_BY_CATEGORY, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};

export const GetPeopleInfoSummary = () => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/compliance/people_info`);

        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: PEOPLE_INFO_SUMMARY, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};

export const GetPeopleSummary = () => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/compliance/people`);

        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: PEOPLE_SUMMARY, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};

export const GetPeopleTrainingInfoSummary = () => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`compliance/employee_training_info`);

        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: PEOPLE_TRAINING_SUMMARY, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};
