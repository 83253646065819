import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Drawer,
    Slide,
    Stack,
} from '@mui/material';
import OpenAccordion from 'assets/img/smartIntel/open_accordion.svg';
import CloseAccordion from 'assets/img/smartIntel/close_accordion.svg';
import Calender from 'assets/img/smartIntel/calendar (1).png';
import Tag from 'assets/img/smartIntel/tag-03.png';
import ArrowRight from 'assets/img/arrow-up-right.svg';
import CloseIcon from 'assets/img/smartIntel/Icon Button.svg';
import WarningIcon from 'assets/img/smartIntel/warning.svg';
import ExternalIcon from 'assets/img/smartIntel/external.svg';
import Light from 'assets/img/Light.svg';

import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AppTablePagination from 'components/new_components/app-table/AppTablePagination';
import { UpdateCompanyDetails } from 'store/actions/merchantActions';
import { toast } from 'react-toastify';
import { Loader2 } from 'lucide-react';
import moment from 'moment/moment';
import CustomTooltip from 'components/new_components/CustomTooltip';
import { truncateText } from 'utils';
import ThreatViewModal from '../ThreatViewModal';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="right" ref={ref} {...props} />;
});

const ViewThreatDrawer = (props) => {
    const { open, handleClose, currentData, type, currentTag } = props;
    const merchant_info = useSelector((state) => state?.merchantReducers?.merchant_info);
    const [currentTab, setCurrentTab] = useState('');
    const [currentIntel, setCurrentIntel] = useState('');
    const [link, setLink] = useState('');
    const [engLink, setEngLink] = useState('');
    const [openWarning, setOpenWarning] = useState(false);
    const [openFeed, setOpenFeed] = useState(false);
    const { all_smart_intel } = useSelector((state) => state?.generalReducers);
    const [page, setPage] = useState(1);
    const [viewThreat, setViewThreat] = useState(false);
    const [currentThreat, setCurrentThreat] = useState(null);

    const noPerPage = 8;
    useEffect(() => {
        if (currentData) {
            setCurrentTab(currentTag);
            setCurrentIntel(currentData?.common_name || currentData?.title || currentData?.name);
            const data = all_smart_intel[type][currentTag];
            const currentIndex = data?.findIndex((el) => {
                const name = el?.common_name || el?.title || el?.name;
                const currentName = currentData?.common_name || currentData?.title || currentData?.name;
                return name?.toLowerCase() === currentName?.toLowerCase();
            });
            const pageIndex = Math.floor(currentIndex / noPerPage + 1);
            if (pageIndex && !isNaN(pageIndex)) setPage(pageIndex);
        }
    }, [currentData]);

    const filtered_data = useMemo(() => {
        return open && all_smart_intel
            ? all_smart_intel?.[type]?.[currentTab]?.sort((a, b) =>
                  moment(a.published_date).diff(moment(b.published_date))
              )
            : [];
    }, [currentTab, open]);
    const start = (page - 1) * noPerPage;
    const stop = start + noPerPage;
    const data = filtered_data?.slice(start, stop);
    const tabs =
        open && Object?.keys(all_smart_intel?.[type])
            ? Object.keys(all_smart_intel[type])
                  ?.map((item) => {
                      return {
                          key: item,
                          value: all_smart_intel[type][item],
                      };
                  })
                  ?.filter((item) => item?.value?.length > 0)
                  ?.map((item) => item?.key)
            : [];
    return (
        <Drawer
            open={open}
            anchor="right"
            TransitionComponent={Transition}
            transitionDuration={1000}
            keepMounted
            onClose={() => {
                handleClose();
            }}
            PaperProps={{
                sx: {
                    boxShadow: '0px 12px 24px rgba(0, 0, 0, 0.16)',
                    flex: 1,
                    width: '100%',
                    overflow: 'scroll',
                    zIndex: 9999,
                    backgroundColor: '#F8FAFC',
                },
            }}
            scroll="paper"
        >
            <div
                className="cursor-pointer flex items-center gap-3 border border-[#F1F5F9] px-[2rem] py-[1rem] bg-white"
                onClick={handleClose}
            >
                <KeyboardBackspaceIcon />
                <p className="text-[#002C72] text-lg font-bold capitalize">
                    {merchant_info?.name}’s Cyber Threat Intelligence feed{' '}
                    <span className="text-sx font-medium">::</span> {type}
                </p>
            </div>
            <div className="flex items-center gap-6 bg-white px-[2rem]">
                {tabs?.map((option, index) => (
                    <button
                        className={`relative text-[15px] bg-transparent cursor-pointer !p-0 !py-3 before:absolute before:bottom-0 before:left-1/2 before:h-[4px] before:-translate-x-1/2 before:bg-[#202D66] before:transition-all before:duration-500 hover:bg-transparent hover:text-[#202D66] ${
                            currentTab === option
                                ? 'font-medium text-[#202D66] text-sm before:w-full before:rounded-t-lg'
                                : 'text-[#64748B] font-normal text-sm before:w-0'
                        }`}
                        key={index}
                        onClick={() => {
                            setPage(1);
                            setCurrentIntel('');
                            setCurrentTab(option);
                        }}
                    >
                        <span>{option}</span>
                    </button>
                ))}
            </div>
            <Stack
                flexDirection="row"
                sx={{
                    border: `1px solid #F0F0FA`,
                    backgroundColor: '#F8FAFC',
                    borderRadius: '4px',
                    p: '12px',
                    mx: '2rem',
                }}
            >
                <img src={Light} alt="file question" />
                <p className="tetx-[#64748B] text-[13px] font-normal mt-2 ml-2">
                    Please note that the feeds are tailored to your compliance process.{' '}
                    <span className="tetx-[#395BA9] underline cursor-pointer" onClick={() => setOpenFeed(true)}>
                        Click here
                    </span>{' '}
                    to update settings
                </p>
            </Stack>
            <div className="mt-[1rem] mx-[2rem]">
                {data?.map((option, index) => (
                    <Accordion
                        expanded={
                            currentIntel ===
                            (option?.common_name || option?.title || option?.name || option?.market_place)
                        }
                        key={index}
                        onClick={() => {
                            if (option?.name) {
                                setCurrentThreat(option);
                                setViewThreat(true);
                            }
                        }}
                        className="shadow-[0.2px]"
                        sx={{
                            border:
                                currentIntel ===
                                (option?.common_name || option?.title || option?.name || option?.market_place)
                                    ? '1px solid #FF0000'
                                    : '1px solid #FFFFFF',
                            cursor: option?.name ? 'pointer' : '',
                            borderRadius: 2,
                            my: 3,
                            py: 1,
                            '&.MuiAccordion-root:before': {
                                backgroundColor: 'white',
                                border: '1px solid #FFFFFF',
                            },
                        }}
                        onChange={(event) => {
                            event?.stopPropagation();
                            if (
                                currentIntel ===
                                (option?.common_name || option?.title || option?.name || option?.market_place)
                            ) {
                                setCurrentIntel('');
                            } else {
                                setCurrentIntel(
                                    option?.common_name || option?.title || option?.name || option?.market_place
                                );
                            }
                        }}
                    >
                        <AccordionSummary
                            expandIcon={
                                <img
                                    src={
                                        currentIntel ===
                                        (option?.common_name || option?.title || option?.name || option?.market_place)
                                            ? CloseAccordion
                                            : OpenAccordion
                                    }
                                    alt="Accotdion"
                                    className="cursor-pointer"
                                />
                            }
                            sx={{ border: 'none !important' }}
                        >
                            <h4 className="text-[#475569] text-base font-bold">{option?.title || option?.name}</h4>
                        </AccordionSummary>
                        <AccordionDetails>
                            {option.published_date && (
                                <div className="flex items-center gap-5 mb-[1rem]">
                                    <div className="flex items-center gap-1">
                                        <img src={Calender} alt="Calendar" />
                                        <p className="text-[#64748B] text-sm font-normal">
                                            {moment(option?.published_date).format('DD MMM, YYYY')}
                                        </p>
                                    </div>{' '}
                                    <div className="flex items-center gap-1">
                                        <img src={Tag} alt="Calendar" />
                                        <p className="text-[#64748B] text-sm font-normal">
                                            {option?.common_name || option?.market_place}
                                        </p>
                                    </div>
                                </div>
                            )}
                            <p className="text-[#64748B] text-sm font-normal">
                                {option?.snippet || option?.description}
                            </p>
                            {option?.overview && (
                                <p className="text-[#64748B] text-sm font-normal my-1">
                                    <span className="font-semibold">Overview:</span>{' '}
                                    {truncateText(option?.overview, '200', option?.overview?.length > 200 ? '...' : '')}
                                </p>
                            )}
                            {option?.attack_vectors && (
                                <p className="text-[#64748B] text-sm font-normal my-1">
                                    <span className="font-semibold">Attack Vectors:</span>{' '}
                                    {truncateText(
                                        option?.attack_vectors,
                                        '200',
                                        option?.attack_vectors?.length > 200 ? '...' : ''
                                    )}
                                </p>
                            )}

                            {option?.malware && (
                                <p className="text-[#64748B] text-sm font-normal my-1">
                                    <span className="font-semibold">Malware:</span>{' '}
                                    {option?.malware?.split(',')?.map((data) => (
                                        <span>
                                            {data}
                                            {length < index + 1 ? ',' : ''}
                                        </span>
                                    ))}
                                </p>
                            )}

                            {option?.author && (
                                <p className="text-[#64748B] text-sm font-normal my-1">
                                    <span className="font-semibold">Author:</span> {option?.author}
                                </p>
                            )}
                            {option?.target_sectors && (
                                <p className="text-[#64748B] text-sm font-normal my-1">
                                    <span className="font-semibold">Target Sectors:</span>{' '}
                                    {truncateText(
                                        option?.target_sectors,
                                        '200',
                                        option?.target_sectors?.length > 200 ? '...' : ''
                                    )}
                                </p>
                            )}
                            {option?.organization && (
                                <p className="text-[#64748B] text-sm font-normal my-1">
                                    <span className="font-semibold">Organization:</span> {option?.organization}
                                </p>
                            )}

                            {option?.country && (
                                <p className="text-[#64748B] text-sm font-normal my-1">
                                    <span className="font-semibold">Country:</span> {option?.country}
                                </p>
                            )}
                            {option.url && (
                                <div
                                    className="rounded-[0.5rem] p-1.5 flex items-center gap-2 mt-[1.5rem] w-fit cursor-pointer"
                                    style={{ border: '1px solid #395BA9' }}
                                    onClick={() => {
                                        if (option.url.includes('exploit.in')) {
                                            const translatetoEngLink = `https://translate.google.com/translate?hl=en&sl=auto&tl=en&u=${encodeURIComponent(
                                                option.url
                                            )}`;
                                            setEngLink(translatetoEngLink);
                                        }
                                        setLink(option?.url);
                                        setOpenWarning(true);
                                    }}
                                >
                                    <p className="text-[#395BA9] text-sm font-meidum">View on web</p>
                                    <img src={ArrowRight} alt="ArrowRight" />
                                </div>
                            )}
                        </AccordionDetails>
                    </Accordion>
                ))}
            </div>
            <WarningDialog open={openWarning} onClose={() => setOpenWarning(false)} link={link} engLink={engLink} />
            <FeedSettings open={openFeed} onClose={() => setOpenFeed(false)} />
            {filtered_data?.length > 8 && (
                <AppTablePagination
                    page={page}
                    onPageChange={setPage}
                    dataLength={filtered_data?.length}
                    noPerPage={noPerPage}
                />
            )}
            <ThreatViewModal open={viewThreat} handleClose={() => setViewThreat(false)} currentThreat={currentThreat} />
        </Drawer>
    );
};

const WarningDialog = ({ open, onClose, link, engLink }) => {
    const handleLink = () => {
        window.open(engLink || link, '_blank');
    };
    return (
        <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth={true}>
            <DialogTitle className="flex justify-between items-center">
                <p className="text-[#202D66] text-xl font-semibold">Warning</p>
                <img src={CloseIcon} alt="CloseIcon" className="cursor-pointer" onClick={onClose} />
            </DialogTitle>
            <DialogContent className="flex justify-center items-center flex-col my-[1.5rem]">
                <img src={WarningIcon} alt="WarningIcon" />
                <DialogContentText>
                    <p className="text-[#334155] text-sm font-nromal text-center mt-[1rem]">
                        You are going to an external link which may not be trusted
                    </p>

                    <p className="text-[#334155] text-sm font-nromal text-center mt-[1rem]">{link}</p>
                </DialogContentText>
            </DialogContent>
            <DialogActions className="bg-[#F8FAFC] flex justify-right items-center gap-2 py-4 px-3">
                <Button
                    variant="outlined"
                    sx={{
                        border: '1px solid #E2E8F0',
                        textTransform: 'inherit',
                        color: '#334155',
                        backgroundColor: '#FFFFFF',
                    }}
                    onClick={onClose}
                >
                    Cancel
                </Button>
                <Button
                    endIcon={<img src={ExternalIcon} alt="ExternalIcon" />}
                    variant="contained"
                    sx={{ textTransform: 'inherit', fontWeight: 600, px: 2, py: 1.3, borderRadius: 0.5 }}
                    onClick={handleLink}
                >
                    Go to external link
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const FeedSettings = ({ open, onClose }) => {
    const [loading, setLoading] = useState(false);
    const [compliance_keywords, setComplianceKeywords] = useState([]);
    const [Infrastructure_keywords, setInfrastructureKeywords] = useState([]);
    const [third_party_keywords, setThirdPartyKeywords] = useState([]);
    const dispatch = useDispatch();

    const merchant_info = useSelector((state) => state?.merchantReducers?.merchant_info);

    const handleUpdateKeywords = async () => {
        setLoading(true);
        const body = {
            intel_keywords: {
                Compliance: compliance_keywords,
                Infrastructure: Infrastructure_keywords,
                'Third-Parties': third_party_keywords,
            },
        };
        const res = await dispatch(UpdateCompanyDetails(body));
        setLoading(false);
        if (res?.success) {
            toast.success('Feed keywords updated Successfully');
            onClose();
        } else {
            toast.error(res?.message);
        }
    };
    useEffect(() => {
        if (merchant_info?.intel_keywords) {
            setComplianceKeywords(merchant_info?.intel_keywords?.['Compliance']);
            setInfrastructureKeywords(merchant_info?.intel_keywords?.['Infrastructure']);
            setThirdPartyKeywords(merchant_info?.intel_keywords?.['Third-Parties']);
        }
    }, [merchant_info]);
    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth={true}>
            <DialogTitle className="flex justify-between items-center">
                <p className="text-[#202D66] text-xl font-semibold">Feed Settings</p>
                <img src={CloseIcon} alt="CloseIcon" className="cursor-pointer" onClick={onClose} />
            </DialogTitle>
            <DialogContent className="my-[0.5rem]">
                <DialogContentText>
                    <Stack
                        flexDirection="row"
                        sx={{
                            border: `1px solid #F0F0FA`,
                            backgroundColor: '#F8FAFC',
                            borderRadius: '4px',
                            p: '5px',
                        }}
                    >
                        <img src={Light} alt="file question" />
                        <p className="tetx-[#64748B] text-[13px] font-normal mt-2 ml-2">
                            Choose which feeds you would like to be receive on your cyber threat intelligence. to update
                            settings
                        </p>
                    </Stack>
                    <div className="my-[1rem]">
                        <div className="flex items-center gap-2">
                            <p className="text-[#475569] text-base font-medium">Feeds keywords</p>
                            <CustomTooltip title="Feeds keywords" placement="top">
                                <img
                                    src="/img/risk-assessment/help-circle.svg"
                                    alt="Help Icon"
                                    className="object-contain"
                                />
                            </CustomTooltip>
                        </div>
                        <div className="my-3">
                            <p className="text-[#475569] text-base font-medium">Compliance</p>
                            <Keywords keywords={compliance_keywords} setKeywords={setComplianceKeywords} />
                            <p className="text-[#64748B] text-xs font-normal mt-2">
                                Use commas to separate each keyword. Keywords are not case sensitive.
                            </p>
                        </div>

                        <div className="my-3">
                            <p className="text-[#475569] text-base font-medium">Infrastructure</p>
                            <Keywords keywords={Infrastructure_keywords} setKeywords={setInfrastructureKeywords} />
                            <p className="text-[#64748B] text-xs font-normal mt-2">
                                Use commas to separate each keyword. Keywords are not case sensitive.
                            </p>
                        </div>
                        <div className="my-3">
                            <p className="text-[#475569] text-base font-medium">Third-Parties Threat</p>
                            <Keywords keywords={third_party_keywords} setKeywords={setThirdPartyKeywords} />
                            <p className="text-[#64748B] text-xs font-normal mt-2">
                                Use commas to separate each keyword. Keywords are not case sensitive.
                            </p>
                        </div>
                    </div>
                </DialogContentText>
            </DialogContent>
            <DialogActions className="bg-[#F8FAFC] flex justify-right items-center gap-2 py-4 px-3">
                <Button
                    variant="outlined"
                    sx={{
                        border: '1px solid #E2E8F0',
                        textTransform: 'inherit',
                        color: '#334155',
                        backgroundColor: '#FFFFFF',
                    }}
                    onClick={onClose}
                >
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    sx={{
                        textTransform: 'inherit',
                        fontWeight: 600,
                        px: 2,
                        py: 1,
                        borderRadius: 0.5,
                        display: 'flex',
                        alignItems: 'center',
                        gap: 2,
                    }}
                    onClick={handleUpdateKeywords}
                >
                    {loading && <Loader2 className="mr-1 h-4 w-4 animate-spin" />} Update Settings
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const Keywords = ({ keywords, setKeywords }) => {
    return (
        <div className="relative group mt-[0.5rem]">
            <div className="w-full flex flex-wrap gap-2 !border !border-[#E2E8F0] !rounded-md !px-2 !py-[10px] focus-within:ring-2 focus-within:ring-[#395BA9] focus-within:ring-opacity-75">
                {keywords?.map((keyword, index) => (
                    <button
                        key={index}
                        className="flex items-center gap-2 !border !border-[#F1F5F9] bg-[#F1F5F9] !rounded !py-0 !px-2 hover:brightness-90 transition-all h-6"
                        onClick={(event) => {
                            event.stopPropagation();
                            setKeywords(keywords?.filter((item) => item !== keyword));
                        }}
                    >
                        <p className="font-normal capitalize text-[13px] text-[#64748B]">{keyword}</p>

                        <img
                            src="/img/risk-assessment/x-close-small.svg"
                            alt="Delete Icon"
                            className="object-contain"
                        />
                    </button>
                ))}

                <input
                    placeholder="Add feed keywords"
                    onFocus={(event) => {
                        if (event.target.innerText === 'Add feed keywords') {
                            event.target.innerText = '';
                        }

                        event.target.style.color = 'hsl(215,20%,40%)';
                    }}
                    onChange={(event) => {
                        if (event?.target?.value?.includes(',') && event.target.value.length > 2) {
                            setKeywords([...keywords, ...event.target.value.split(',')].filter(Boolean));
                            event.target.value = '';
                        }
                    }}
                    className="border-none text-[#94A3B8] outline-none w-full"
                />
            </div>
        </div>
    );
};
export default ViewThreatDrawer;
