import {
    ALL_LIFECYCLE,
    ALL_CONTROL_ObJECTIVE_METRICS,
    ALL_ADMIN_DOCUMENTS_BY_CONTROL_OBJECTIVE,
    ALL_ADMIN_EVIDENCE_BY_CONTROL_OBJECTIVE,
    ALL_EXEMPTED_EVIDENCE,
} from '../constants';

export default (state = {}, action) => {
    switch (action.type) {
        case ALL_LIFECYCLE:
            return {
                ...state,
                all_Lifecycle: action.payload,
            };
        case ALL_CONTROL_ObJECTIVE_METRICS:
            return {
                ...state,
                all_Lifecycle_summary: action.payload,
            };
        case ALL_ADMIN_DOCUMENTS_BY_CONTROL_OBJECTIVE:
            return {
                ...state,
                documents_by_control: action.payload,
            };
        case ALL_ADMIN_EVIDENCE_BY_CONTROL_OBJECTIVE:
            return {
                ...state,
                evidence_by_control: action.payload,
            };

        case ALL_EXEMPTED_EVIDENCE:
            return {
                ...state,
                exempted_evidence: action.payload,
            };

        default:
            return state;
    }
};
