import React, { useMemo, useRef, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { Box, Stack, Typography } from '@mui/material';

// core components
import AppAvatar from 'components/new_components/AppAvatar';
import { AppForm, AppSubmitButton, AppFormChips, AppFormInput } from 'components/new_components/forms';
// images
import pfp from 'assets/img/pfp.png';

// redux
import { connect } from 'react-redux';
import { UpdateCompanyDetails, ChangeCompanyLogo } from 'store/actions/merchantActions';

//validation
import { companyValidation } from './utils';
import { toast } from 'react-toastify';
import { removeSpaces } from 'utils';

//translation
import { useTranslation } from 'react-i18next';
import { getType } from 'utils';

const CompanyInfo = (props) => {
    // props
    const { merchant_info, UpdateCompanyDetails, ChangeCompanyLogo } = props;
    // hooks
    const theme = useTheme();
    const logoRef = useRef();

    const { t } = useTranslation('company');

    const role = getType('role');
    // state
    const [loading, setLoading] = useState({ submit: false, upload: false });
    const [desc, setDesc] = useState('');

    // memos
    const standards = useMemo(() => {
        const free_plan_standards = ['PCIDSS'];

        const selected_standards =
            merchant_info?.merchant_plan?.tag === 'demo' || merchant_info?.merchant_plan?.tag === '3_days'
                ? free_plan_standards
                : removeSpaces(merchant_info?.paid_standards)?.split(',');
        return selected_standards
            ?.filter((val) => val !== '')
            ?.map((standard) => {
                return {
                    name: standard?.toUpperCase(),
                    value: standard?.toLowerCase(),
                };
            });
    }, [merchant_info]);

    // functions
    const handleSubmit = async (values) => {
        setLoading({ ...loading, submit: true });
        const res = await UpdateCompanyDetails({
            ...values,
            standards: values?.standards?.join(','),
        });
        setLoading({ ...loading, submit: false });
        if (res?.success) {
            toast.success('Profile updated successfully.');
        } else {
            toast.error(res?.message);
        }
    };
    const changeLogo = async (e) => {
        const formData = new FormData();
        const imageName = e.target.files[0];
        formData.append('company_img', imageName);

        setLoading({ ...loading, upload: true });
        const res = await ChangeCompanyLogo(formData);
        setLoading({ ...loading, upload: false });

        logoRef.current.value = null;
        if (res.success) {
            toast.success('Your logo has been updated successfully');
        } else {
            toast.error(res?.message);
        }
    };
    const handleDescChange = (value) => {
        setDesc(value);
    };

    return (
        <Box sx={{ p: 3 }}>
            <Typography
                variant="h2"
                component="h2"
                sx={{
                    color: theme.palette.primary[900],
                    fontSize: 18,
                    fontWeight: 700,
                    mb: 2.5,
                }}
            >
                {t('header')}
            </Typography>
            <Box sx={{ maxWidth: '500px' }}>
                <AppAvatar
                    label={t('companyInfo.avatarLabel')}
                    src={merchant_info?.company_img || pfp}
                    id="logo"
                    editable={role !== 'user' && role !== 'auditor'}
                    imageFileRef={logoRef}
                    onChange={changeLogo}
                    loading={loading.upload}
                />
                <AppForm
                    initialValues={{
                        standards: merchant_info?.standards?.split(',') || [],
                        name: merchant_info?.name,
                        phone_number: !merchant_info?.phone_number ? '' : merchant_info?.phone_number,
                        address: !merchant_info?.address ? '' : merchant_info?.address,
                        description: !merchant_info?.description ? '' : merchant_info?.description,
                        dba: !merchant_info?.dba ? '' : merchant_info?.dba,
                        website: !merchant_info?.website ? '' : merchant_info?.website,
                        mailing_address: !merchant_info?.mailing_address ? '' : merchant_info?.mailing_address,
                    }}
                    onSubmit={handleSubmit}
                    validate={companyValidation}
                >
                    <AppFormChips
                        name="standards"
                        multiple
                        label={t('companyInfo.formChipLabel')}
                        chipGroup={standards}
                        isClickable={role !== 'user' || role !== 'auditor'}
                    />
                    <AppFormInput
                        type="text"
                        label={t('companyInfo.inputLabel1')}
                        placeholder={t('companyInfo.inputLabel1')}
                        name="name"
                        disabled={role === 'user' || role === 'auditor'}
                    />
                    <AppFormInput
                        type="text"
                        label={t('companyInfo.input2.label')}
                        placeholder={t('companyInfo.input2.placeholder')}
                        name="dba"
                        disabled={role === 'user' || role === 'auditor'}
                    />
                    <AppFormInput
                        type="text"
                        label={t('companyInfo.inputLabel3')}
                        placeholder={t('companyInfo.inputLabel3')}
                        name="website"
                        disabled={role === 'user' || role === 'auditor'}
                    />
                    <AppFormInput
                        type="text"
                        label={t('companyInfo.inputLabel4')}
                        placeholder={t('companyInfo.inputLabel4')}
                        name="phone_number"
                        disabled={role === 'user' || role === 'auditor'}
                    />
                    <AppFormInput
                        type="text"
                        label={t('companyInfo.inputLabel5')}
                        placeholder={t('companyInfo.inputLabel5')}
                        name="mailing_address"
                        multiline
                        disabled={role === 'user' || role === 'auditor'}
                    />
                    <AppFormInput
                        type="text"
                        label={t('companyInfo.inputLabel6')}
                        placeholder={t('companyInfo.inputLabel6')}
                        name="address"
                        multiline
                        disabled={role === 'user' || role === 'auditor'}
                    />
                    <div>
                        <AppFormInput
                            type="text"
                            label={t('companyInfo.input7.label')}
                            placeholder={t('companyInfo.input7.placeholder')}
                            name="description"
                            multiline
                            disabled={role === 'user' || role === 'auditor'}
                            onChange={handleDescChange}
                            maxLength={300}
                        />
                        <Stack flexDirection="row" justifyContent="end" mt={0.3}>
                            <Typography sx={{ fontSize: 12, color: '#64748B' }}>{desc?.length} of 300</Typography>
                        </Stack>
                    </div>

                    {(role !== 'user' || role !== 'auditor') && (
                        <AppSubmitButton
                            text={t('companyInfo.cta')}
                            variant="contained"
                            color="primary"
                            loadingPosition="center"
                            loading={loading.submit}
                            disabled={role === 'auditor'}
                            sx={{ mt: 3 }}
                        />
                    )}
                </AppForm>
            </Box>
        </Box>
    );
};
const mapStateToProps = (state) => {
    return {
        merchant_info: state?.merchantReducers?.merchant_info,
    };
};
export default connect(mapStateToProps, {
    UpdateCompanyDetails,
    ChangeCompanyLogo,
})(CompanyInfo);
