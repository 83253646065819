import { Divider } from '@mui/material';
import PolygonMedium from 'assets/img/medium_polygon.png';
import PolygonLow from 'assets/img/ciso_positive_angle.png';
import PolygonHigh from 'assets/img/LowPolygon.svg';
import React from 'react';
import { useSelector } from 'react-redux';

const Standards = ({ standards }) => {
    const {
        ndpr_ciso_summary,
        all_pcislc_table_ciso_summary,
        pcidss_ciso_summary,
        soc2_ciso_summary,
        pcidss4_ciso_summary,
        kdpa_ciso_summary,
        iso_9001_ciso_summary,
        iso_ciso_summary,
        iso_two_ciso_summary,
        tech_sec_ciso_summary,
        iso22301_summary,
        gdpa_ciso_summary,
        framework_percentage_ciso_summary,
        // blockchain_ciso_summary,
    } = useSelector((state) => state?.CISOReducers);
    return (
        <div className="mt-[5rem]">
            <div className="flex items-center gap-4 mt-[5rem]">
                <h4 className="text-[#2B3674] text-lg font-bold text-center">Frameworks</h4>
                <div className="flex items-center gap-1 border border-[#E2E8F0] p-1 rounded">
                    <img
                        src={
                            framework_percentage_ciso_summary?.total_compliance_percentage < 50
                                ? PolygonHigh
                                : framework_percentage_ciso_summary?.total_compliance_percentage >= 50 &&
                                  framework_percentage_ciso_summary?.total_compliance_percentage <= 70
                                ? PolygonMedium
                                : PolygonLow
                        }
                        alt="PolygonMedium"
                    />
                    <p className="text-gray-500 text-sm font-semibold">
                        {framework_percentage_ciso_summary?.total_compliance_percentage?.toFixed(2)}%
                    </p>
                </div>
                <div className="w-[74%]">
                    <Divider orientation="horizontal" sx={{ backgroundColor: '#E2E8F0' }} />
                </div>
            </div>
            {standards?.includes('pcidss') && (
                <div className="mt-[3rem]">
                    <div className="border-2 border-black bg-[#6E8EDF] flex justify-center items-center p-3">
                        <p className="text-white text-sm font-semibold">PCI DSS</p>
                    </div>
                    <table border="1" cellPadding="5" style={{ borderCollapse: 'collapse', width: '100%' }}>
                        <thead>
                            <tr>
                                <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border-2 border-black">
                                    Category
                                </th>
                                <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border-2 border-black">
                                    Sub-cat
                                </th>
                                <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border-2 border-black">
                                    Questions
                                </th>
                                <th
                                    className="text-[#64748B] text-[13px] font-semibold p-2 text-center border-2 border-black"
                                    colSpan="2"
                                >
                                    Policies
                                </th>
                            </tr>
                            <tr>
                                <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border-2 border-black"></th>
                                <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border-2 border-black"></th>
                                <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border-2 border-black">
                                    Status
                                </th>
                                <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border-2 border-black">
                                    Name
                                </th>
                                <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border-2 border-black">
                                    Status
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {pcidss_ciso_summary &&
                                (Object?.keys(pcidss_ciso_summary)?.length
                                    ? Object?.keys(pcidss_ciso_summary)?.sort((a, b) => a - b)
                                    : []
                                )?.map((option, optionIndex) => {
                                    return (
                                        <React.Fragment key={optionIndex}>
                                            {pcidss_ciso_summary[option]?.sub_req_info
                                                ?.sort((a, b) => a?.sub_requirement_number - b?.sub_requirement_number)
                                                ?.map((sub, subIndex) => (
                                                    <React.Fragment key={subIndex}>
                                                        <tr>
                                                            {subIndex === 0 && (
                                                                <td
                                                                    className="text-[#64748B] text-xs  font-medium p-2 text-center border-2 border-black"
                                                                    rowSpan={
                                                                        pcidss_ciso_summary[option]?.sub_req_info
                                                                            ?.length * 2
                                                                    }
                                                                >
                                                                    Requirement {parseInt(option)}:{' '}
                                                                    {pcidss_ciso_summary[option]?.title}
                                                                </td>
                                                            )}
                                                            <td
                                                                className="text-[#64748B] text-xs  font-normal p-2 text-center border-2 border-black"
                                                                rowSpan="2"
                                                            >
                                                                {sub?.sub_requirement_number}
                                                            </td>
                                                            {subIndex === 0 && (
                                                                <td
                                                                    className="text-[#64748B] text-xs  font-normal p-2 text-center border-2 border-black"
                                                                    rowSpan={
                                                                        pcidss_ciso_summary[option]?.sub_req_info
                                                                            ?.length * 2
                                                                    }
                                                                >
                                                                    {pcidss_ciso_summary[option]?.user_form}/
                                                                    {pcidss_ciso_summary[option]?.total_form}
                                                                </td>
                                                            )}
                                                        </tr>

                                                        <tr>
                                                            <td className="text-[#64748B] text-xs  font-normal text-center border-2 border-black">
                                                                {sub?.doc_info?.map((info, index) => (
                                                                    <p
                                                                        key={index}
                                                                        className={`text-[#64748B] text-xs  font-normal text-center ${
                                                                            index < sub?.doc_info?.length - 1
                                                                                ? 'border-b'
                                                                                : ''
                                                                        } border-black py-2`}
                                                                    >
                                                                        {info?.doc_name || 'N/A'}
                                                                    </p>
                                                                ))}
                                                            </td>
                                                            <td
                                                                className={`text-[#64748B] text-xs  font-normal text-center border-2 border-black`}
                                                            >
                                                                {sub?.doc_info?.map((info, index) => (
                                                                    <p
                                                                        key={index}
                                                                        className={`text-[#64748B] text-xs  font-normal text-center ${
                                                                            index < sub?.doc_info?.length - 1
                                                                                ? 'border-b'
                                                                                : ''
                                                                        } border-black py-2`}
                                                                        style={{
                                                                            color: info?.uploaded
                                                                                ? '#55BE8B'
                                                                                : '#FF5449',
                                                                        }}
                                                                    >
                                                                        {info?.uploaded ? 'Uploaded' : 'Not Uploaded'}
                                                                    </p>
                                                                ))}
                                                            </td>
                                                        </tr>
                                                    </React.Fragment>
                                                ))}
                                        </React.Fragment>
                                    );
                                })}
                        </tbody>
                    </table>
                </div>
            )}
            {standards?.includes('ndpr') && (
                <div className="mt-[3rem]">
                    <div className="border-2 border-black bg-[#6E8EDF] flex justify-center items-center p-3">
                        <p className="text-white text-sm font-semibold">NDPR</p>
                    </div>
                    <table border="1" cellPadding="5" style={{ borderCollapse: 'collapse', width: '100%' }}>
                        <thead>
                            <tr>
                                <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border-2 border-black">
                                    Category
                                </th>
                                <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border-2 border-black">
                                    Questions
                                </th>
                                <th
                                    className="text-[#64748B] text-[13px] font-semibold p-2 text-center border-2 border-black"
                                    colSpan="2"
                                >
                                    Policies
                                </th>
                            </tr>
                            <tr>
                                <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border-2 border-black"></th>
                                <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border-2 border-black">
                                    Status
                                </th>
                                <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border-2 border-black">
                                    Name
                                </th>
                                <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border-2 border-black">
                                    Status
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {(ndpr_ciso_summary && Object?.keys(ndpr_ciso_summary)?.length
                                ? Object?.keys(ndpr_ciso_summary)?.sort((a, b) => a - b)
                                : []
                            )?.map((option, optionIndex) => {
                                return (
                                    <React.Fragment key={optionIndex}>
                                        {ndpr_ciso_summary[option]?.doc_info?.length ? (
                                            ndpr_ciso_summary[option]?.doc_info?.map((sub, subIndex) => (
                                                <React.Fragment key={subIndex}>
                                                    <tr>
                                                        {subIndex === 0 && (
                                                            <td
                                                                className="text-[#64748B] text-xs  font-medium p-2 text-center border-2 border-black"
                                                                rowSpan={
                                                                    ndpr_ciso_summary[option]?.doc_info?.length * 2
                                                                }
                                                            >
                                                                {option}
                                                            </td>
                                                        )}
                                                        {subIndex === 0 && (
                                                            <td
                                                                className="text-[#64748B] text-xs  font-medium p-2 text-center border-2 border-black"
                                                                rowSpan={
                                                                    ndpr_ciso_summary[option]?.doc_info?.length * 2
                                                                }
                                                            >
                                                                {ndpr_ciso_summary[option]?.user_form}/
                                                                {ndpr_ciso_summary[option]?.total_form}
                                                            </td>
                                                        )}
                                                    </tr>
                                                    <tr>
                                                        <td className="text-[#64748B] text-xs  font-medium p-2 text-center border-2 border-black">
                                                            {sub?.doc_name || 'N/A'}
                                                        </td>
                                                        <td
                                                            className="text-xs  font-medium p-2 text-center border-2 border-black"
                                                            style={{ color: sub.uploaded ? '#55BE8B' : '#FF5449' }}
                                                        >
                                                            {sub?.uploaded ? 'Uploaded' : 'Not Uploaded'}
                                                        </td>
                                                    </tr>
                                                </React.Fragment>
                                            ))
                                        ) : (
                                            <>
                                                <tr>
                                                    <td className="text-[#64748B] text-xs  font-medium p-2 text-center border-2 border-black">
                                                        {option}
                                                    </td>
                                                    <td className="text-[#64748B] text-xs  font-medium p-2 text-center border-2 border-black">
                                                        {ndpr_ciso_summary[option]?.user_form}/
                                                        {ndpr_ciso_summary[option]?.total_form}
                                                    </td>
                                                    <td className="text-[#64748B] text-xs  font-medium p-2 text-center border-2 border-black">
                                                        N/A
                                                    </td>
                                                    <td className="text-xs  font-medium p-2 text-center border-2 border-black text-[#64748B]">
                                                        N/A
                                                    </td>
                                                </tr>
                                            </>
                                        )}
                                    </React.Fragment>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            )}

            {standards?.includes('soc2') && (
                <div className="mt-[3rem]">
                    <div className="border-2 border-black bg-[#6E8EDF] flex justify-center items-center p-3">
                        <p className="text-white text-sm font-semibold">SOC 2</p>
                    </div>
                    <table border="1" cellPadding="5" style={{ borderCollapse: 'collapse', width: '100%' }}>
                        <thead>
                            <tr>
                                <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border-2 border-black">
                                    Category
                                </th>
                                <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border-2 border-black">
                                    Sub-cat
                                </th>
                                <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border-2 border-black">
                                    Questions
                                </th>
                                <th
                                    className="text-[#64748B] text-[13px] font-semibold p-2 text-center border-2 border-black"
                                    colSpan="2"
                                >
                                    Policies
                                </th>
                                <th
                                    className="text-[#64748B] text-[13px] font-semibold p-2 text-center border-2 border-black"
                                    colSpan="2"
                                >
                                    Evidences
                                </th>
                            </tr>
                            <tr>
                                <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border-2 border-black"></th>
                                <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border-2 border-black"></th>
                                <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border-2 border-black">
                                    Status
                                </th>
                                <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border-2 border-black">
                                    Name
                                </th>
                                <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border-2 border-black">
                                    Status
                                </th>
                                <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border-2 border-black">
                                    Name
                                </th>
                                <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border-2 border-black">
                                    Status
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {(soc2_ciso_summary && Object?.keys(soc2_ciso_summary)?.length
                                ? Object?.keys(soc2_ciso_summary)
                                : []
                            )?.map((option, optionIndex) => {
                                return (
                                    <React.Fragment key={optionIndex}>
                                        {soc2_ciso_summary[option]?.sub_control_info?.map((sub, subIndex) => (
                                            <React.Fragment key={subIndex}>
                                                <tr>
                                                    {subIndex === 0 && (
                                                        <td
                                                            className="text-[#64748B] text-xs  font-meidum p-2 text-center border-2 border-black"
                                                            rowSpan={
                                                                soc2_ciso_summary[option]?.sub_control_info?.length * 2
                                                            }
                                                        >
                                                            {option}
                                                        </td>
                                                    )}
                                                    <td
                                                        className="text-[#64748B] text-xs  font-medium p-2 text-center border-2 border-black"
                                                        rowSpan="2"
                                                    >
                                                        {sub?.sub_control_title}
                                                    </td>
                                                    {subIndex === 0 && (
                                                        <td
                                                            className="text-[#64748B] text-xs  font-medium p-2 text-center border-2 border-black"
                                                            rowSpan={
                                                                soc2_ciso_summary[option]?.sub_control_info?.length * 2
                                                            }
                                                        >
                                                            {soc2_ciso_summary[option]?.user_form}/
                                                            {soc2_ciso_summary[option]?.total_form}
                                                        </td>
                                                    )}
                                                </tr>

                                                <tr>
                                                    <td className="text-[#64748B] text-xs  font-normal text-center border-2 border-black">
                                                        {sub?.doc_info?.map((info, index) => (
                                                            <p
                                                                key={index}
                                                                className={`text-[#64748B] text-xs  font-normal text-center ${
                                                                    index < sub?.doc_info?.length - 1 ? 'border-b' : ''
                                                                } border-black py-2`}
                                                            >
                                                                {info?.doc_name || 'N/A'}
                                                            </p>
                                                        ))}
                                                    </td>
                                                    <td
                                                        className={`text-[#64748B] text-xs  font-normal text-center border-2 border-black`}
                                                    >
                                                        {sub?.doc_info?.map((info, index) => (
                                                            <p
                                                                key={index}
                                                                className={`text-[#64748B] text-xs  font-normal text-center ${
                                                                    index < sub?.doc_info?.length - 1 ? 'border-b' : ''
                                                                } border-black py-2`}
                                                                style={{
                                                                    color: info?.uploaded ? '#55BE8B' : '#FF5449',
                                                                }}
                                                            >
                                                                {info?.uploaded ? 'Uploaded' : 'Not Uploaded'}
                                                            </p>
                                                        ))}
                                                    </td>
                                                    <td className="text-[#64748B] text-xs  font-normal text-center border-2 border-black">
                                                        {sub?.evidence_info?.map((info, index) => (
                                                            <p
                                                                key={index}
                                                                className={`text-[#64748B] text-xs  font-normal text-center ${
                                                                    index < sub?.evidence_info?.length - 1
                                                                        ? 'border-b'
                                                                        : ''
                                                                } border-black py-2`}
                                                            >
                                                                {info?.evidence_title || 'N/A'}
                                                            </p>
                                                        ))}
                                                    </td>
                                                    <td className="text-[#64748B] text-xs  font-normal text-center border-2 border-black">
                                                        {sub?.evidence_info?.map((info, index) => (
                                                            <p
                                                                key={index}
                                                                className={`text-[#64748B] text-xs  font-normal text-center ${
                                                                    index < sub?.evidence_info?.length - 1
                                                                        ? 'border-b'
                                                                        : ''
                                                                } border-black py-2`}
                                                                style={{
                                                                    color: info?.evidence_data ? '#55BE8B' : '#FF5449',
                                                                }}
                                                            >
                                                                {info?.evidence_data ? 'Uploaded' : 'Not Uploaded'}
                                                            </p>
                                                        ))}
                                                    </td>
                                                </tr>
                                            </React.Fragment>
                                        ))}
                                    </React.Fragment>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            )}

            {standards?.includes('iso27001') && (
                <div className="mt-[3rem]">
                    <div className="border-2 border-black bg-[#6E8EDF] flex justify-center items-center p-3">
                        <p className="text-white text-sm font-semibold">ISO 27001</p>
                    </div>
                    <table border="1" cellPadding="5" style={{ borderCollapse: 'collapse', width: '100%' }}>
                        <thead>
                            <tr>
                                <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border-2 border-black">
                                    Category
                                </th>
                                <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border-2 border-black">
                                    Questions
                                </th>
                                <th
                                    className="text-[#64748B] text-[13px] font-semibold p-2 text-center border-2 border-black"
                                    colSpan="2"
                                >
                                    Policies
                                </th>
                            </tr>
                            <tr>
                                <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border-2 border-black"></th>
                                <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border-2 border-black"></th>
                                <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border-2 border-black">
                                    Status
                                </th>
                                <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border-2 border-black">
                                    Name
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {(iso_ciso_summary?.new_ciso_info?.annex &&
                            Object?.keys(iso_ciso_summary?.new_ciso_info?.annex)?.length
                                ? Object?.keys(iso_ciso_summary?.new_ciso_info?.annex)
                                : []
                            )?.map((option, optionIndex) => {
                                return (
                                    <React.Fragment key={optionIndex}>
                                        {iso_ciso_summary?.new_ciso_info?.annex[option]?.doc_info?.length ? (
                                            iso_ciso_summary?.new_ciso_info?.annex[option]?.doc_info?.map(
                                                (sub, subIndex) => (
                                                    <React.Fragment key={subIndex}>
                                                        <tr>
                                                            {subIndex === 0 && (
                                                                <td
                                                                    className="text-[#64748B] text-xs  font-medium p-2 text-center border-2 border-black"
                                                                    rowSpan={
                                                                        iso_ciso_summary?.new_ciso_info?.annex[option]
                                                                            ?.doc_info?.length * 2
                                                                    }
                                                                >
                                                                    {option}
                                                                </td>
                                                            )}
                                                            {subIndex === 0 && (
                                                                <td
                                                                    className="text-[#64748B] text-xs  font-medium p-2 text-center border-2 border-black"
                                                                    rowSpan={
                                                                        iso_ciso_summary?.new_ciso_info?.annex[option]
                                                                            ?.doc_info?.length * 2
                                                                    }
                                                                >
                                                                    {
                                                                        iso_ciso_summary?.new_ciso_info?.annex[option]
                                                                            ?.user_form
                                                                    }
                                                                    /
                                                                    {
                                                                        iso_ciso_summary?.new_ciso_info?.annex[option]
                                                                            ?.total_form
                                                                    }
                                                                </td>
                                                            )}
                                                        </tr>
                                                        <tr>
                                                            <td className="text-[#64748B] text-xs  font-medium p-2 text-center border-2 border-black">
                                                                {sub?.doc_name || 'N/A'}
                                                            </td>
                                                            <td
                                                                className="text-xs  font-medium p-2 text-center border-2 border-black"
                                                                style={{ color: sub.uploaded ? '#55BE8B' : '#FF5449' }}
                                                            >
                                                                {sub?.uploaded ? 'Uploaded' : 'Not Uploaded'}
                                                            </td>
                                                        </tr>
                                                    </React.Fragment>
                                                )
                                            )
                                        ) : (
                                            <>
                                                <tr>
                                                    <td className="text-[#64748B] text-xs  font-medium p-2 text-center border-2 border-black">
                                                        {option}
                                                    </td>
                                                    <td className="text-[#64748B] text-xs  font-medium p-2 text-center border-2 border-black">
                                                        {iso_ciso_summary?.new_ciso_info?.annex[option]?.user_form}/
                                                        {iso_ciso_summary?.new_ciso_info?.annex[option]?.total_form}
                                                    </td>
                                                    <td className="text-[#64748B] text-xs  font-medium p-2 text-center border-2 border-black">
                                                        N/A
                                                    </td>
                                                    <td className="text-xs  font-medium p-2 text-center border-2 border-black text-[#64748B]">
                                                        N/A
                                                    </td>
                                                </tr>
                                            </>
                                        )}
                                    </React.Fragment>
                                );
                            })}
                        </tbody>
                        <tbody>
                            {(iso_ciso_summary?.new_ciso_info?.clause &&
                            Object?.keys(iso_ciso_summary?.new_ciso_info?.clause)?.length
                                ? Object?.keys(iso_ciso_summary?.new_ciso_info?.clause)
                                : []
                            )?.map((option, optionIndex) => {
                                return (
                                    <React.Fragment key={optionIndex}>
                                        {iso_ciso_summary?.new_ciso_info?.clause[option]?.doc_info?.length ? (
                                            iso_ciso_summary?.new_ciso_info?.clause[option]?.doc_info?.map(
                                                (sub, subIndex) => (
                                                    <React.Fragment key={subIndex}>
                                                        <tr>
                                                            {subIndex === 0 && (
                                                                <td
                                                                    className="text-[#64748B] text-xs  font-medium p-2 text-center border-2 border-black"
                                                                    rowSpan={
                                                                        iso_ciso_summary?.new_ciso_info?.clause[option]
                                                                            ?.doc_info?.length * 2
                                                                    }
                                                                >
                                                                    {option}
                                                                </td>
                                                            )}
                                                            {subIndex === 0 && (
                                                                <td
                                                                    className="text-[#64748B] text-xs  font-medium p-2 text-center border-2 border-black"
                                                                    rowSpan={
                                                                        iso_ciso_summary?.new_ciso_info?.clause[option]
                                                                            ?.doc_info?.length * 2
                                                                    }
                                                                >
                                                                    {
                                                                        iso_ciso_summary?.new_ciso_info?.clause[option]
                                                                            ?.user_form
                                                                    }
                                                                    /
                                                                    {
                                                                        iso_ciso_summary?.new_ciso_info?.clause[option]
                                                                            ?.total_form
                                                                    }
                                                                </td>
                                                            )}
                                                        </tr>
                                                        <tr>
                                                            <td className="text-[#64748B] text-xs  font-medium p-2 text-center border-2 border-black">
                                                                {sub?.doc_name || 'N/A'}
                                                            </td>
                                                            <td
                                                                className="text-xs  font-medium p-2 text-center border-2 border-black"
                                                                style={{ color: sub.uploaded ? '#55BE8B' : '#FF5449' }}
                                                            >
                                                                {sub?.uploaded ? 'Uploaded' : 'Not Uploaded'}
                                                            </td>
                                                        </tr>
                                                    </React.Fragment>
                                                )
                                            )
                                        ) : (
                                            <>
                                                <tr>
                                                    <td className="text-[#64748B] text-xs  font-medium p-2 text-center border-2 border-black">
                                                        {option}
                                                    </td>
                                                    <td className="text-[#64748B] text-xs  font-medium p-2 text-center border-2 border-black">
                                                        {iso_ciso_summary?.new_ciso_info?.clause[option]?.user_form}/
                                                        {iso_ciso_summary?.new_ciso_info?.clause[option]?.total_form}
                                                    </td>
                                                    <td className="text-[#64748B] text-xs  font-medium p-2 text-center border-2 border-black">
                                                        N/A
                                                    </td>
                                                    <td className="text-xs  font-medium p-2 text-center border-2 border-black text-[#64748B]">
                                                        N/A
                                                    </td>
                                                </tr>
                                            </>
                                        )}
                                    </React.Fragment>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            )}
            {standards?.includes('iso22301') && (
                <div className="mt-[3rem]">
                    <div className="border-2 border-black bg-[#6E8EDF] flex justify-center items-center p-3">
                        <p className="text-white text-sm font-semibold">ISO 22301</p>
                    </div>
                    <table border="1" cellPadding="5" style={{ borderCollapse: 'collapse', width: '100%' }}>
                        <thead>
                            <tr>
                                <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border-2 border-black">
                                    Category
                                </th>
                                <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border-2 border-black">
                                    Questions
                                </th>
                                <th
                                    className="text-[#64748B] text-[13px] font-semibold p-2 text-center border-2 border-black"
                                    colSpan="2"
                                >
                                    Policies
                                </th>
                            </tr>
                            <tr>
                                <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border-2 border-black"></th>
                                <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border-2 border-black"></th>
                                <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border-2 border-black">
                                    Status
                                </th>
                                <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border-2 border-black">
                                    Name
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {(iso22301_summary?.new_ciso_info?.clause &&
                            Object?.keys(iso22301_summary?.new_ciso_info?.clause)?.length
                                ? Object?.keys(iso22301_summary?.new_ciso_info?.clause)
                                : []
                            )?.map((option, optionIndex) => {
                                return (
                                    <React.Fragment key={optionIndex}>
                                        {iso22301_summary?.new_ciso_info?.clause[option]?.doc_info?.map(
                                            (sub, subIndex) => (
                                                <React.Fragment key={subIndex}>
                                                    <tr>
                                                        {subIndex === 0 && (
                                                            <td
                                                                className="text-[#64748B] text-xs  font-medium p-2 text-center border-2 border-black"
                                                                rowSpan={
                                                                    iso22301_summary?.new_ciso_info?.clause[option]
                                                                        ?.doc_info?.length * 2
                                                                }
                                                            >
                                                                {option}
                                                            </td>
                                                        )}
                                                        {subIndex === 0 && (
                                                            <td
                                                                className="text-[#64748B] text-xs  font-medium p-2 text-center border-2 border-black"
                                                                rowSpan={
                                                                    iso22301_summary?.new_ciso_info?.clause[option]
                                                                        ?.doc_info?.length * 2
                                                                }
                                                            >
                                                                {
                                                                    iso22301_summary?.new_ciso_info?.clause[option]
                                                                        ?.user_form
                                                                }
                                                                /
                                                                {
                                                                    iso22301_summary?.new_ciso_info?.clause[option]
                                                                        ?.total_form
                                                                }
                                                            </td>
                                                        )}
                                                    </tr>
                                                    <tr>
                                                        <td className="text-[#64748B] text-xs  font-medium p-2 text-center border-2 border-black">
                                                            {sub?.doc_name || 'N/A'}
                                                        </td>
                                                        <td
                                                            className="text-xs  font-medium p-2 text-center border-2 border-black"
                                                            style={{ color: sub.uploaded ? '#55BE8B' : '#FF5449' }}
                                                        >
                                                            {sub?.uploaded ? 'Uploaded' : 'Not Uploaded'}
                                                        </td>
                                                    </tr>
                                                </React.Fragment>
                                            )
                                        )}
                                    </React.Fragment>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            )}
            {standards?.includes('pcidss4.0') && (
                <div className="mt-[3rem]">
                    <div className="border-2 border-black bg-[#6E8EDF] flex justify-center items-center p-3">
                        <p className="text-white text-sm font-semibold">PCI DSS 4.0</p>
                    </div>
                    <table border="1" cellPadding="5" style={{ borderCollapse: 'collapse', width: '100%' }}>
                        <thead>
                            <tr>
                                <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border-2 border-black">
                                    Category
                                </th>
                                <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border-2 border-black">
                                    Sub-cat
                                </th>
                                <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border-2 border-black">
                                    Questions
                                </th>
                                <th
                                    className="text-[#64748B] text-[13px] font-semibold p-2 text-center border-2 border-black"
                                    colSpan="2"
                                >
                                    Policies
                                </th>
                            </tr>
                            <tr>
                                <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border-2 border-black"></th>
                                <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border-2 border-black"></th>
                                <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border-2 border-black">
                                    Status
                                </th>
                                <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border-2 border-black">
                                    Name
                                </th>
                                <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border-2 border-black">
                                    Status
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {(pcidss4_ciso_summary && Object?.keys(pcidss4_ciso_summary)?.length
                                ? Object?.keys(pcidss4_ciso_summary)?.sort((a, b) => a - b)
                                : []
                            )?.map((option, optionIndex) => {
                                return (
                                    <React.Fragment key={optionIndex}>
                                        {pcidss4_ciso_summary[option]?.sub_req_info
                                            ?.sort((a, b) => a?.sub_requirement_number - b?.sub_requirement_number)
                                            ?.map((sub, subIndex) => (
                                                <React.Fragment key={subIndex}>
                                                    <tr>
                                                        {subIndex === 0 && (
                                                            <td
                                                                className="text-[#64748B] text-xs  font-meidum p-2 text-center border-2 border-black"
                                                                rowSpan={
                                                                    pcidss4_ciso_summary?.[option]?.sub_req_info
                                                                        ?.length * 2
                                                                }
                                                            >
                                                                Requirement {parseInt(option)}:{' '}
                                                                {pcidss_ciso_summary?.[option]?.title}
                                                            </td>
                                                        )}
                                                        <td
                                                            className="text-[#64748B] text-xs  font-medium p-2 text-center border-2 border-black"
                                                            rowSpan="2"
                                                        >
                                                            {sub?.sub_requirement_number}
                                                        </td>
                                                        {subIndex === 0 && (
                                                            <td
                                                                className="text-[#64748B] text-xs  font-medium p-2 text-center border-2 border-black"
                                                                rowSpan={
                                                                    pcidss4_ciso_summary[option]?.sub_req_info?.length *
                                                                    2
                                                                }
                                                            >
                                                                {pcidss4_ciso_summary[option]?.user_form}/
                                                                {pcidss4_ciso_summary[option]?.total_form}
                                                            </td>
                                                        )}
                                                    </tr>

                                                    <tr>
                                                        <td className="text-[#64748B] text-xs  font-normal text-center border-2 border-black">
                                                            {sub?.doc_info?.map((info, index) => (
                                                                <p
                                                                    key={index}
                                                                    className="text-[#64748B] text-xs  font-normal text-center border-b border-black py-2"
                                                                >
                                                                    {info?.doc_name || 'N/A'}
                                                                </p>
                                                            ))}
                                                        </td>
                                                        <td className="text-[#64748B] text-xs  font-normaltext-center border-2 border-black">
                                                            {sub?.doc_info?.map((info, index) => (
                                                                <p
                                                                    key={index}
                                                                    className="text-[#64748B] text-xs  font-normal text-center border-b border-black py-2"
                                                                    style={{
                                                                        color: info?.uploaded ? '#55BE8B' : '#FF5449',
                                                                    }}
                                                                >
                                                                    {info?.uploaded ? 'Uploaded' : 'Not Uploaded'}
                                                                </p>
                                                            ))}
                                                        </td>
                                                    </tr>
                                                </React.Fragment>
                                            ))}
                                    </React.Fragment>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            )}

            {standards?.includes('kdpa') && (
                <div className="mt-[3rem]">
                    <div className="border-2 border-black bg-[#6E8EDF] flex justify-center items-center p-3">
                        <p className="text-white text-sm font-semibold">KDPA</p>
                    </div>
                    <table border="1" cellPadding="5" style={{ borderCollapse: 'collapse', width: '100%' }}>
                        <thead>
                            <tr>
                                <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border-2 border-black">
                                    Category
                                </th>
                                <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border-2 border-black">
                                    Questions
                                </th>
                                <th
                                    className="text-[#64748B] text-[13px] font-semibold p-2 text-center border-2 border-black"
                                    colSpan="2"
                                >
                                    Policies
                                </th>
                            </tr>
                            <tr>
                                <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border-2 border-black"></th>
                                <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border-2 border-black">
                                    Status
                                </th>
                                <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border-2 border-black">
                                    Name
                                </th>
                                <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border-2 border-black">
                                    Status
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {(kdpa_ciso_summary && Object?.keys(kdpa_ciso_summary)?.length
                                ? Object?.keys(kdpa_ciso_summary)
                                : []
                            )?.map((option, optionIndex) => {
                                return (
                                    <React.Fragment key={optionIndex}>
                                        {kdpa_ciso_summary[option]?.doc_info?.map((sub, subIndex) => (
                                            <React.Fragment key={subIndex}>
                                                <tr>
                                                    {subIndex === 0 && (
                                                        <td
                                                            className="text-[#64748B] text-xs  font-medium p-2 text-center border-2 border-black"
                                                            rowSpan={kdpa_ciso_summary[option]?.doc_info?.length * 2}
                                                        >
                                                            {option}
                                                        </td>
                                                    )}
                                                    {subIndex === 0 && (
                                                        <td
                                                            className="text-[#64748B] text-xs  font-medium p-2 text-center border-2 border-black"
                                                            rowSpan={kdpa_ciso_summary[option]?.doc_info?.length * 2}
                                                        >
                                                            {kdpa_ciso_summary[option]?.user_form}/
                                                            {kdpa_ciso_summary[option]?.total_form}
                                                        </td>
                                                    )}
                                                </tr>
                                                <tr>
                                                    <td className="text-[#64748B] text-xs  font-medium p-2 text-center border-2 border-black">
                                                        {sub?.doc_name || 'N/A'}
                                                    </td>
                                                    <td
                                                        className="text-xs  font-medium p-2 text-center border-2 border-black"
                                                        style={{ color: sub.uploaded ? '#55BE8B' : '#FF5449' }}
                                                    >
                                                        {sub?.uploaded ? 'Uploaded' : 'Not Uploaded'}
                                                    </td>
                                                </tr>
                                            </React.Fragment>
                                        ))}
                                    </React.Fragment>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            )}
            {standards?.includes('gdpa') && (
                <div className="mt-[3rem]">
                    <div className="border-2 border-black bg-[#6E8EDF] flex justify-center items-center p-3">
                        <p className="text-white text-sm font-semibold">GDPA</p>
                    </div>
                    <table border="1" cellPadding="5" style={{ borderCollapse: 'collapse', width: '100%' }}>
                        <thead>
                            <tr>
                                <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border-2 border-black">
                                    Category
                                </th>
                                <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border-2 border-black">
                                    Questions
                                </th>
                                <th
                                    className="text-[#64748B] text-[13px] font-semibold p-2 text-center border-2 border-black"
                                    colSpan="2"
                                >
                                    Policies
                                </th>
                            </tr>
                            <tr>
                                <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border-2 border-black"></th>
                                <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border-2 border-black">
                                    Status
                                </th>
                                <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border-2 border-black">
                                    Name
                                </th>
                                <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border-2 border-black">
                                    Status
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {(gdpa_ciso_summary && Object?.keys(gdpa_ciso_summary)?.length
                                ? Object?.keys(gdpa_ciso_summary)
                                : []
                            )?.map((option, optionIndex) => {
                                return (
                                    <React.Fragment key={optionIndex}>
                                        {gdpa_ciso_summary[option]?.doc_info?.map((sub, subIndex) => (
                                            <React.Fragment key={subIndex}>
                                                <tr>
                                                    {subIndex === 0 && (
                                                        <td
                                                            className="text-[#64748B] text-xs  font-medium p-2 text-center border-2 border-black"
                                                            rowSpan={gdpa_ciso_summary[option]?.doc_info?.length * 2}
                                                        >
                                                            {option}
                                                        </td>
                                                    )}
                                                    {subIndex === 0 && (
                                                        <td
                                                            className="text-[#64748B] text-xs  font-medium p-2 text-center border-2 border-black"
                                                            rowSpan={gdpa_ciso_summary[option]?.doc_info?.length * 2}
                                                        >
                                                            {gdpa_ciso_summary[option]?.user_form}/
                                                            {gdpa_ciso_summary[option]?.total_form}
                                                        </td>
                                                    )}
                                                </tr>
                                                <tr>
                                                    <td className="text-[#64748B] text-xs  font-medium p-2 text-center border-2 border-black">
                                                        {sub?.doc_name || 'N/A'}
                                                    </td>
                                                    <td
                                                        className="text-xs  font-medium p-2 text-center border-2 border-black"
                                                        style={{ color: sub.uploaded ? '#55BE8B' : '#FF5449' }}
                                                    >
                                                        {sub?.uploaded ? 'Uploaded' : 'Not Uploaded'}
                                                    </td>
                                                </tr>
                                            </React.Fragment>
                                        ))}
                                    </React.Fragment>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            )}
            {standards?.includes('iso27001-2022') && (
                <div className="mt-[3rem]">
                    <div className="border-2 border-black bg-[#6E8EDF] flex justify-center items-center p-3">
                        <p className="text-white text-sm font-semibold">ISO 27001-2022</p>
                    </div>
                    <table border="1" cellPadding="5" style={{ borderCollapse: 'collapse', width: '100%' }}>
                        <thead>
                            <tr>
                                <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border-2 border-black">
                                    Category
                                </th>
                                <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border-2 border-black">
                                    Questions
                                </th>
                                <th
                                    className="text-[#64748B] text-[13px] font-semibold p-2 text-center border-2 border-black"
                                    colSpan="2"
                                >
                                    Policies
                                </th>
                            </tr>
                            <tr>
                                <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border-2 border-black"></th>
                                <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border-2 border-black"></th>
                                <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border-2 border-black">
                                    Status
                                </th>
                                <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border-2 border-black">
                                    Name
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {(iso_two_ciso_summary?.new_ciso_info?.annex &&
                            Object?.keys(iso_two_ciso_summary?.new_ciso_info?.annex)?.length
                                ? Object?.keys(iso_two_ciso_summary?.new_ciso_info?.annex)
                                : []
                            )?.map((option, optionIndex) => {
                                return (
                                    <React.Fragment key={optionIndex}>
                                        {iso_two_ciso_summary?.new_ciso_info?.annex[option]?.doc_info?.map(
                                            (sub, subIndex) => (
                                                <React.Fragment key={subIndex}>
                                                    <tr>
                                                        {subIndex === 0 && (
                                                            <td
                                                                className="text-[#64748B] text-xs  font-medium p-2 text-center border-2 border-black"
                                                                rowSpan={
                                                                    iso_two_ciso_summary?.new_ciso_info?.annex[option]
                                                                        ?.doc_info?.length * 2
                                                                }
                                                            >
                                                                {option}
                                                            </td>
                                                        )}
                                                        {subIndex === 0 && (
                                                            <td
                                                                className="text-[#64748B] text-xs  font-medium p-2 text-center border-2 border-black"
                                                                rowSpan={
                                                                    iso_two_ciso_summary?.new_ciso_info?.annex[option]
                                                                        ?.doc_info?.length * 2
                                                                }
                                                            >
                                                                {
                                                                    iso_two_ciso_summary?.new_ciso_info?.annex[option]
                                                                        ?.user_form
                                                                }
                                                                /
                                                                {
                                                                    iso_two_ciso_summary?.new_ciso_info?.annex[option]
                                                                        ?.total_form
                                                                }
                                                            </td>
                                                        )}
                                                    </tr>
                                                    <tr>
                                                        <td className="text-[#64748B] text-xs  font-medium p-2 text-center border-2 border-black">
                                                            {sub?.doc_name || 'N/A'}
                                                        </td>
                                                        <td
                                                            className="text-xs  font-medium p-2 text-center border-2 border-black"
                                                            style={{ color: sub.uploaded ? '#55BE8B' : '#FF5449' }}
                                                        >
                                                            {sub?.uploaded ? 'Uploaded' : 'Not Uploaded'}
                                                        </td>
                                                    </tr>
                                                </React.Fragment>
                                            )
                                        )}
                                    </React.Fragment>
                                );
                            })}
                        </tbody>
                        <tbody>
                            {(iso_two_ciso_summary?.new_ciso_info?.clause &&
                            Object?.keys(iso_two_ciso_summary?.new_ciso_info?.clause)?.length
                                ? Object?.keys(iso_two_ciso_summary?.new_ciso_info?.clause)
                                : []
                            )?.map((option, optionIndex) => {
                                return (
                                    <React.Fragment key={optionIndex}>
                                        {iso_two_ciso_summary?.new_ciso_info?.clause[option]?.doc_info?.map(
                                            (sub, subIndex) => (
                                                <React.Fragment key={subIndex}>
                                                    <tr>
                                                        {subIndex === 0 && (
                                                            <td
                                                                className="text-[#64748B] text-xs  font-medium p-2 text-center border-2 border-black"
                                                                rowSpan={
                                                                    iso_two_ciso_summary?.new_ciso_info?.clause[option]
                                                                        ?.doc_info?.length * 2
                                                                }
                                                            >
                                                                {option}
                                                            </td>
                                                        )}
                                                        {subIndex === 0 && (
                                                            <td
                                                                className="text-[#64748B] text-xs  font-medium p-2 text-center border-2 border-black"
                                                                rowSpan={
                                                                    iso_two_ciso_summary?.new_ciso_info?.clause[option]
                                                                        ?.doc_info?.length * 2
                                                                }
                                                            >
                                                                {
                                                                    iso_two_ciso_summary?.new_ciso_info?.clause[option]
                                                                        ?.user_form
                                                                }
                                                                /
                                                                {
                                                                    iso_two_ciso_summary?.new_ciso_info?.clause[option]
                                                                        ?.total_form
                                                                }
                                                            </td>
                                                        )}
                                                    </tr>
                                                    <tr>
                                                        <td className="text-[#64748B] text-xs  font-medium p-2 text-center border-2 border-black">
                                                            {sub?.doc_name || 'N/A'}
                                                        </td>
                                                        <td
                                                            className="text-xs  font-medium p-2 text-center border-2 border-black"
                                                            style={{ color: sub.uploaded ? '#55BE8B' : '#FF5449' }}
                                                        >
                                                            {sub?.uploaded ? 'Uploaded' : 'Not Uploaded'}
                                                        </td>
                                                    </tr>
                                                </React.Fragment>
                                            )
                                        )}
                                    </React.Fragment>
                                );
                            })}
                        </tbody>
                        <tbody>
                            {(iso_two_ciso_summary?.new_ciso_info?.annex_two &&
                            Object?.keys(iso_two_ciso_summary?.new_ciso_info?.annex_two)?.length
                                ? Object?.keys(iso_two_ciso_summary?.new_ciso_info?.annex_two)
                                : []
                            )?.map((option, optionIndex) => {
                                return (
                                    <React.Fragment key={optionIndex}>
                                        {iso_two_ciso_summary?.new_ciso_info?.annex_two[option]?.doc_info?.map(
                                            (sub, subIndex) => (
                                                <React.Fragment key={subIndex}>
                                                    <tr>
                                                        {subIndex === 0 && (
                                                            <td
                                                                className="text-[#64748B] text-xs  font-medium p-2 text-center border-2 border-black"
                                                                rowSpan={
                                                                    iso_two_ciso_summary?.new_ciso_info?.annex_two[
                                                                        option
                                                                    ]?.doc_info?.length * 2
                                                                }
                                                            >
                                                                {option}
                                                            </td>
                                                        )}
                                                        {subIndex === 0 && (
                                                            <td
                                                                className="text-[#64748B] text-xs  font-medium p-2 text-center border-2 border-black"
                                                                rowSpan={
                                                                    iso_two_ciso_summary?.new_ciso_info?.annex_two[
                                                                        option
                                                                    ]?.doc_info?.length * 2
                                                                }
                                                            >
                                                                {
                                                                    iso_two_ciso_summary?.new_ciso_info?.annex_two[
                                                                        option
                                                                    ]?.user_form
                                                                }
                                                                /
                                                                {
                                                                    iso_two_ciso_summary?.new_ciso_info?.annex_two[
                                                                        option
                                                                    ]?.total_form
                                                                }
                                                            </td>
                                                        )}
                                                    </tr>
                                                    <tr>
                                                        <td className="text-[#64748B] text-xs  font-medium p-2 text-center border-2 border-black">
                                                            {sub?.doc_name || 'N/A'}
                                                        </td>
                                                        <td
                                                            className="text-xs  font-medium p-2 text-center border-2 border-black"
                                                            style={{ color: sub.uploaded ? '#55BE8B' : '#FF5449' }}
                                                        >
                                                            {sub?.uploaded ? 'Uploaded' : 'Not Uploaded'}
                                                        </td>
                                                    </tr>
                                                </React.Fragment>
                                            )
                                        )}
                                    </React.Fragment>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            )}

            {standards?.includes('pcislc') && (
                <div className="mt-[3rem]">
                    <div className="border-2 border-black bg-[#6E8EDF] flex justify-center items-center p-3">
                        <p className="text-white text-sm font-semibold">PCI SLC</p>
                    </div>
                    <table border="1" cellPadding="5" style={{ borderCollapse: 'collapse', width: '100%' }}>
                        <thead>
                            <tr>
                                <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border-2 border-black">
                                    Category
                                </th>
                                <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border-2 border-black">
                                    Sub-cat
                                </th>
                                <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border-2 border-black">
                                    Questions
                                </th>
                                <th
                                    className="text-[#64748B] text-[13px] font-semibold p-2 text-center border-2 border-black"
                                    colSpan="2"
                                >
                                    Policies
                                </th>
                                <th
                                    className="text-[#64748B] text-[13px] font-semibold p-2 text-center border-2 border-black"
                                    colSpan="2"
                                >
                                    Evidences
                                </th>
                            </tr>
                            <tr>
                                <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border-2 border-black"></th>
                                <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border-2 border-black"></th>
                                <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border-2 border-black">
                                    Status
                                </th>
                                <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border-2 border-black">
                                    Name
                                </th>
                                <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border-2 border-black">
                                    Status
                                </th>
                                <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border-2 border-black">
                                    Name
                                </th>
                                <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border-2 border-black">
                                    Status
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {(all_pcislc_table_ciso_summary && Object?.keys(all_pcislc_table_ciso_summary)?.length
                                ? Object?.keys(all_pcislc_table_ciso_summary)
                                : []
                            )?.map((option, optionIndex) => {
                                return (
                                    <React.Fragment key={optionIndex}>
                                        {all_pcislc_table_ciso_summary[option]?.sub_cycle_info?.length ? (
                                            all_pcislc_table_ciso_summary[option]?.sub_cycle_info?.map(
                                                (sub, subIndex) => (
                                                    <React.Fragment key={subIndex}>
                                                        <tr>
                                                            {subIndex === 0 && (
                                                                <td
                                                                    className="text-[#64748B] text-xs  font-meidum p-2 text-center border-2 border-black"
                                                                    rowSpan={
                                                                        all_pcislc_table_ciso_summary[option]
                                                                            ?.sub_cycle_info?.length * 2
                                                                    }
                                                                >
                                                                    {option}
                                                                </td>
                                                            )}
                                                            <td
                                                                className="text-[#64748B] text-xs  font-medium p-2 text-center border-2 border-black"
                                                                rowSpan="2"
                                                            >
                                                                {sub?.sub_cycle_number}
                                                            </td>
                                                            {subIndex === 0 && (
                                                                <td
                                                                    className="text-[#64748B] text-xs  font-medium p-2 text-center border-2 border-black"
                                                                    rowSpan={
                                                                        all_pcislc_table_ciso_summary[option]
                                                                            ?.sub_cycle_info?.length * 2
                                                                    }
                                                                >
                                                                    {all_pcislc_table_ciso_summary[option]?.user_form}/
                                                                    {all_pcislc_table_ciso_summary[option]?.total_form}
                                                                </td>
                                                            )}
                                                        </tr>
                                                        <tr>
                                                            <td className="text-[#64748B] text-xs  font-normal text-center border-2 border-black">
                                                                {sub?.doc_info?.map((info, index) => (
                                                                    <p
                                                                        key={index}
                                                                        className={`text-[#64748B] text-xs  font-normal text-center ${
                                                                            index < sub?.doc_info?.length - 1
                                                                                ? 'border-b'
                                                                                : ''
                                                                        } border-black py-2`}
                                                                    >
                                                                        {info?.doc_name || 'N/A'}
                                                                    </p>
                                                                ))}
                                                            </td>
                                                            <td
                                                                className={`text-[#64748B] text-xs  font-normal text-center border-2 border-black`}
                                                            >
                                                                {sub?.doc_info?.map((info, index) => (
                                                                    <p
                                                                        key={index}
                                                                        className={`text-[#64748B] text-xs  font-normal text-center ${
                                                                            index < sub?.doc_info?.length - 1
                                                                                ? 'border-b'
                                                                                : ''
                                                                        } border-black py-2`}
                                                                        style={{
                                                                            color: info?.uploaded
                                                                                ? '#55BE8B'
                                                                                : '#FF5449',
                                                                        }}
                                                                    >
                                                                        {info?.uploaded ? 'Uploaded' : 'Not Uploaded'}
                                                                    </p>
                                                                ))}
                                                            </td>
                                                            <td className="text-[#64748B] text-xs  font-normal text-center border-2 border-black">
                                                                {sub?.evidence_info?.map((info, index) => (
                                                                    <p
                                                                        key={index}
                                                                        className={`text-[#64748B] text-xs  font-normal text-center ${
                                                                            index < sub?.evidence_info?.length - 1
                                                                                ? 'border-b'
                                                                                : ''
                                                                        } border-black py-2`}
                                                                    >
                                                                        {info?.evidence_title || 'N/A'}
                                                                    </p>
                                                                ))}
                                                            </td>
                                                            <td className="text-[#64748B] text-xs  font-normal text-center border-2 border-black">
                                                                {sub?.evidence_info?.map((info, index) => (
                                                                    <p
                                                                        key={index}
                                                                        className={`text-[#64748B] text-xs  font-normal text-center ${
                                                                            index < sub?.evidence_info?.length - 1
                                                                                ? 'border-b'
                                                                                : ''
                                                                        } border-black py-2`}
                                                                        style={{
                                                                            color: info?.uploaded
                                                                                ? '#55BE8B'
                                                                                : '#FF5449',
                                                                        }}
                                                                    >
                                                                        {info?.uploaded ? 'Uploaded' : 'Not Uploaded'}
                                                                    </p>
                                                                ))}
                                                            </td>
                                                        </tr>
                                                    </React.Fragment>
                                                )
                                            )
                                        ) : (
                                            <>
                                                <tr>
                                                    <td className="text-[#64748B] text-xs  font-meidum p-2 text-center border-2 border-black">
                                                        {option}
                                                    </td>
                                                    <td className="text-[#64748B] text-xs  font-medium p-2 text-center border-2 border-black">
                                                        N/A
                                                    </td>
                                                    <td className="text-[#64748B] text-xs  font-medium p-2 text-center border-2 border-black">
                                                        {all_pcislc_table_ciso_summary[option]?.user_form}/
                                                        {all_pcislc_table_ciso_summary[option]?.total_form}
                                                    </td>
                                                    <td className="text-[#64748B] text-xs  font-normal text-center border-2 border-black">
                                                        N/A
                                                    </td>
                                                    <td
                                                        className={`text-[#64748B] text-xs  font-normal text-center border-2 border-black`}
                                                    >
                                                        N/A
                                                    </td>
                                                    <td className="text-[#64748B] text-xs  font-normal text-center border-2 border-black">
                                                        N/A
                                                    </td>
                                                    <td className="text-[#64748B] text-xs  font-normal text-center border-2 border-black">
                                                        N/A
                                                    </td>
                                                </tr>
                                            </>
                                        )}
                                    </React.Fragment>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            )}

            {standards?.includes('iso9001') && (
                <div className="mt-[3rem]">
                    <div className="border-2 border-black bg-[#6E8EDF] flex justify-center items-center p-3">
                        <p className="text-white text-sm font-semibold">ISO 9001</p>
                    </div>
                    <table border="1" cellPadding="5" style={{ borderCollapse: 'collapse', width: '100%' }}>
                        <thead>
                            <tr>
                                <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border-2 border-black">
                                    Category
                                </th>
                                <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border-2 border-black">
                                    Sub-cat
                                </th>
                                <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border-2 border-black">
                                    Questions
                                </th>
                                <th
                                    className="text-[#64748B] text-[13px] font-semibold p-2 text-center border-2 border-black"
                                    colSpan="2"
                                >
                                    Policies
                                </th>
                                <th
                                    className="text-[#64748B] text-[13px] font-semibold p-2 text-center border-2 border-black"
                                    colSpan="2"
                                >
                                    Evidences
                                </th>
                            </tr>
                            <tr>
                                <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border-2 border-black"></th>
                                <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border-2 border-black"></th>
                                <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border-2 border-black">
                                    Status
                                </th>
                                <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border-2 border-black">
                                    Name
                                </th>
                                <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border-2 border-black">
                                    Status
                                </th>
                                <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border-2 border-black">
                                    Name
                                </th>
                                <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border-2 border-black">
                                    Status
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {(iso_9001_ciso_summary && Object?.keys(iso_9001_ciso_summary)?.length
                                ? Object?.keys(iso_9001_ciso_summary)
                                : []
                            )?.map((option, optionIndex) => {
                                return (
                                    <React.Fragment key={optionIndex}>
                                        {iso_9001_ciso_summary[option]?.sub_quality_info?.map((sub, subIndex) => (
                                            <React.Fragment key={subIndex}>
                                                <tr>
                                                    {subIndex === 0 && (
                                                        <td
                                                            className="text-[#64748B] text-xs  font-meidum p-2 text-center border-2 border-black"
                                                            rowSpan={
                                                                iso_9001_ciso_summary[option]?.sub_quality_info
                                                                    ?.length * 2
                                                            }
                                                        >
                                                            {option}
                                                        </td>
                                                    )}
                                                    <td
                                                        className="text-[#64748B] text-xs  font-medium p-2 text-center border-2 border-black"
                                                        rowSpan="2"
                                                    >
                                                        {sub?.sub_cycle_number}
                                                    </td>
                                                    {subIndex === 0 && (
                                                        <td
                                                            className="text-[#64748B] text-xs  font-medium p-2 text-center border-2 border-black"
                                                            rowSpan={
                                                                iso_9001_ciso_summary[option]?.sub_quality_info
                                                                    ?.length * 2
                                                            }
                                                        >
                                                            {iso_9001_ciso_summary[option]?.user_form}/
                                                            {iso_9001_ciso_summary[option]?.total_form}
                                                        </td>
                                                    )}
                                                </tr>

                                                <tr>
                                                    <td className="text-[#64748B] text-xs  font-normal text-center border-2 border-black">
                                                        {sub?.doc_info?.map((info, index) => (
                                                            <p
                                                                key={index}
                                                                className={`text-[#64748B] text-xs  font-normal text-center ${
                                                                    index < sub?.doc_info?.length - 1 ? 'border-b' : ''
                                                                } border-black py-2`}
                                                            >
                                                                {info?.doc_name || 'N/A'}
                                                            </p>
                                                        ))}
                                                    </td>
                                                    <td
                                                        className={`text-[#64748B] text-xs  font-normal text-center border-2 border-black`}
                                                    >
                                                        {sub?.doc_info?.map((info, index) => (
                                                            <p
                                                                key={index}
                                                                className={`text-[#64748B] text-xs  font-normal text-center ${
                                                                    index < sub?.doc_info?.length - 1 ? 'border-b' : ''
                                                                } border-black py-2`}
                                                                style={{
                                                                    color: info?.uploaded ? '#55BE8B' : '#FF5449',
                                                                }}
                                                            >
                                                                {info?.uploaded ? 'Uploaded' : 'Not Uploaded'}
                                                            </p>
                                                        ))}
                                                    </td>
                                                    <td className="text-[#64748B] text-xs  font-normal text-center border-2 border-black">
                                                        {sub?.evidence_info?.map((info, index) => (
                                                            <p
                                                                key={index}
                                                                className={`text-[#64748B] text-xs  font-normal text-center ${
                                                                    index < sub?.evidence_info?.length - 1
                                                                        ? 'border-b'
                                                                        : ''
                                                                } border-black py-2`}
                                                            >
                                                                {info?.evidence_title || 'N/A'}
                                                            </p>
                                                        ))}
                                                    </td>
                                                    <td className="text-[#64748B] text-xs  font-normal text-center border-2 border-black">
                                                        {sub?.evidence_info?.map((info, index) => (
                                                            <p
                                                                key={index}
                                                                className={`text-[#64748B] text-xs  font-normal text-center ${
                                                                    index < sub?.evidence_info?.length - 1
                                                                        ? 'border-b'
                                                                        : ''
                                                                } border-black py-2`}
                                                                style={{
                                                                    color: info?.uploaded ? '#55BE8B' : '#FF5449',
                                                                }}
                                                            >
                                                                {info?.uploaded ? 'Uploaded' : 'Not Uploaded'}
                                                            </p>
                                                        ))}
                                                    </td>
                                                </tr>
                                            </React.Fragment>
                                        ))}
                                    </React.Fragment>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            )}
            {standards?.includes('iso27017+iso27032') && (
                <div className="mt-[3rem]">
                    <div className="border-2 border-black bg-[#6E8EDF] flex justify-center items-center p-3">
                        <p className="text-white text-sm font-semibold">ISO 27017- ISO 27032</p>
                    </div>
                    <table border="1" cellPadding="5" style={{ borderCollapse: 'collapse', width: '100%' }}>
                        <thead>
                            <tr>
                                <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border-2 border-black">
                                    Category
                                </th>
                                <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border-2 border-black">
                                    Questions
                                </th>
                                <th
                                    className="text-[#64748B] text-[13px] font-semibold p-2 text-center border-2 border-black"
                                    colSpan="2"
                                >
                                    Policies
                                </th>
                            </tr>
                            <tr>
                                <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border-2 border-black"></th>
                                <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border-2 border-black"></th>
                                <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border-2 border-black">
                                    Status
                                </th>
                                <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border-2 border-black">
                                    Name
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {(tech_sec_ciso_summary?.new_ciso_info?.annex &&
                            Object?.keys(tech_sec_ciso_summary?.new_ciso_info?.annex)?.length
                                ? Object?.keys(tech_sec_ciso_summary.new_ciso_info?.annex)
                                : []
                            )?.map((option, optionIndex) => {
                                return (
                                    <React.Fragment key={optionIndex}>
                                        {tech_sec_ciso_summary?.new_ciso_info?.annex[option]?.doc_info?.map(
                                            (sub, subIndex) => (
                                                <React.Fragment key={subIndex}>
                                                    <tr>
                                                        {subIndex === 0 && (
                                                            <td
                                                                className="text-[#64748B] text-xs  font-medium p-2 text-center border-2 border-black"
                                                                rowSpan={
                                                                    tech_sec_ciso_summary?.new_ciso_info?.annex[option]
                                                                        ?.doc_info?.length * 2
                                                                }
                                                            >
                                                                {option}
                                                            </td>
                                                        )}
                                                        {subIndex === 0 && (
                                                            <td
                                                                className="text-[#64748B] text-xs  font-medium p-2 text-center border-2 border-black"
                                                                rowSpan={
                                                                    tech_sec_ciso_summary?.new_ciso_info?.annex[option]
                                                                        ?.doc_info?.length * 2
                                                                }
                                                            >
                                                                {
                                                                    tech_sec_ciso_summary?.new_ciso_info?.annex[option]
                                                                        ?.user_form
                                                                }
                                                                /
                                                                {
                                                                    tech_sec_ciso_summary?.new_ciso_info?.annex[option]
                                                                        ?.total_form
                                                                }
                                                            </td>
                                                        )}
                                                    </tr>
                                                    <tr>
                                                        <td className="text-[#64748B] text-xs  font-medium p-2 text-center border-2 border-black">
                                                            {sub?.doc_name || 'N/A'}
                                                        </td>
                                                        <td
                                                            className="text-xs  font-medium p-2 text-center border-2 border-black"
                                                            style={{ color: sub.uploaded ? '#55BE8B' : '#FF5449' }}
                                                        >
                                                            {sub?.uploaded ? 'Uploaded' : 'Not Uploaded'}
                                                        </td>
                                                    </tr>
                                                </React.Fragment>
                                            )
                                        )}
                                    </React.Fragment>
                                );
                            })}
                        </tbody>
                        <tbody>
                            {(tech_sec_ciso_summary?.new_ciso_info?.clause &&
                            Object?.keys(tech_sec_ciso_summary?.new_ciso_info?.clause)?.length
                                ? Object?.keys(tech_sec_ciso_summary?.new_ciso_info?.clause)
                                : []
                            )?.map((option, optionIndex) => {
                                return (
                                    <React.Fragment key={optionIndex}>
                                        {tech_sec_ciso_summary?.new_ciso_info?.clause[option]?.doc_info?.map(
                                            (sub, subIndex) => (
                                                <React.Fragment key={subIndex}>
                                                    <tr>
                                                        {subIndex === 0 && (
                                                            <td
                                                                className="text-[#64748B] text-xs  font-medium p-2 text-center border-2 border-black"
                                                                rowSpan={
                                                                    tech_sec_ciso_summary?.new_ciso_info?.clause[option]
                                                                        ?.doc_info?.length * 2
                                                                }
                                                            >
                                                                {option}
                                                            </td>
                                                        )}
                                                        {subIndex === 0 && (
                                                            <td
                                                                className="text-[#64748B] text-xs  font-medium p-2 text-center border-2 border-black"
                                                                rowSpan={
                                                                    tech_sec_ciso_summary?.new_ciso_info?.clause[option]
                                                                        ?.doc_info?.length * 2
                                                                }
                                                            >
                                                                {
                                                                    tech_sec_ciso_summary?.new_ciso_info?.clause[option]
                                                                        ?.user_form
                                                                }
                                                                /
                                                                {
                                                                    tech_sec_ciso_summary?.new_ciso_info?.clause[option]
                                                                        ?.total_form
                                                                }
                                                            </td>
                                                        )}
                                                    </tr>
                                                    <tr>
                                                        <td className="text-[#64748B] text-xs  font-medium p-2 text-center border-2 border-black">
                                                            {sub?.doc_name || 'N/A'}
                                                        </td>
                                                        <td
                                                            className="text-xs  font-medium p-2 text-center border-2 border-black"
                                                            style={{ color: sub.uploaded ? '#55BE8B' : '#FF5449' }}
                                                        >
                                                            {sub?.uploaded ? 'Uploaded' : 'Not Uploaded'}
                                                        </td>
                                                    </tr>
                                                </React.Fragment>
                                            )
                                        )}
                                    </React.Fragment>
                                );
                            })}
                        </tbody>
                        <tbody>
                            {(tech_sec_ciso_summary?.new_ciso_info?.cybersecurity &&
                            Object?.keys(tech_sec_ciso_summary?.new_ciso_info?.cybersecurity)?.length
                                ? Object?.keys(tech_sec_ciso_summary?.new_ciso_info?.cybersecurity)
                                : []
                            )?.map((option, optionIndex) => {
                                return (
                                    <React.Fragment key={optionIndex}>
                                        {tech_sec_ciso_summary?.new_ciso_info?.cybersecurity[option]?.doc_info?.map(
                                            (sub, subIndex) => (
                                                <React.Fragment key={subIndex}>
                                                    <tr>
                                                        {subIndex === 0 && (
                                                            <td
                                                                className="text-[#64748B] text-xs  font-medium p-2 text-center border-2 border-black"
                                                                rowSpan={
                                                                    tech_sec_ciso_summary?.new_ciso_info?.cybersecurity[
                                                                        option
                                                                    ]?.doc_info?.length * 2
                                                                }
                                                            >
                                                                {option}
                                                            </td>
                                                        )}
                                                        {subIndex === 0 && (
                                                            <td
                                                                className="text-[#64748B] text-xs  font-medium p-2 text-center border-2 border-black"
                                                                rowSpan={
                                                                    tech_sec_ciso_summary?.new_ciso_info?.cybersecurity[
                                                                        option
                                                                    ]?.doc_info?.length * 2
                                                                }
                                                            >
                                                                {
                                                                    tech_sec_ciso_summary?.new_ciso_info?.cybersecurity[
                                                                        option
                                                                    ]?.user_form
                                                                }
                                                                /
                                                                {
                                                                    tech_sec_ciso_summary?.new_ciso_info?.cybersecurity[
                                                                        option
                                                                    ]?.total_form
                                                                }
                                                            </td>
                                                        )}
                                                    </tr>
                                                    <tr>
                                                        <td className="text-[#64748B] text-xs  font-medium p-2 text-center border-2 border-black">
                                                            {sub?.doc_name || 'N/A'}
                                                        </td>
                                                        <td
                                                            className="text-xs  font-medium p-2 text-center border-2 border-black"
                                                            style={{ color: sub.uploaded ? '#55BE8B' : '#FF5449' }}
                                                        >
                                                            {sub?.uploaded ? 'Uploaded' : 'Not Uploaded'}
                                                        </td>
                                                    </tr>
                                                </React.Fragment>
                                            )
                                        )}
                                    </React.Fragment>
                                );
                            })}
                        </tbody>
                        <tbody>
                            {(tech_sec_ciso_summary?.new_ciso_info?.project_management &&
                            Object?.keys(tech_sec_ciso_summary?.new_ciso_info?.project_management)?.length
                                ? Object?.keys(tech_sec_ciso_summary?.new_ciso_info?.project_management)
                                : []
                            )?.map((option, optionIndex) => {
                                return (
                                    <React.Fragment key={optionIndex}>
                                        {tech_sec_ciso_summary?.new_ciso_info?.project_management[
                                            option
                                        ]?.doc_info?.map((sub, subIndex) => (
                                            <React.Fragment key={subIndex}>
                                                <tr>
                                                    {subIndex === 0 && (
                                                        <td
                                                            className="text-[#64748B] text-xs  font-medium p-2 text-center border-2 border-black"
                                                            rowSpan={
                                                                tech_sec_ciso_summary?.new_ciso_info
                                                                    ?.project_management[option]?.doc_info?.length * 2
                                                            }
                                                        >
                                                            {option}
                                                        </td>
                                                    )}
                                                    {subIndex === 0 && (
                                                        <td
                                                            className="text-[#64748B] text-xs  font-medium p-2 text-center border-2 border-black"
                                                            rowSpan={
                                                                tech_sec_ciso_summary?.new_ciso_info
                                                                    ?.project_management[option]?.doc_info?.length * 2
                                                            }
                                                        >
                                                            {
                                                                tech_sec_ciso_summary?.new_ciso_info
                                                                    ?.project_management[option]?.user_form
                                                            }
                                                            /
                                                            {
                                                                tech_sec_ciso_summary?.new_ciso_info
                                                                    ?.project_management[option]?.total_form
                                                            }
                                                        </td>
                                                    )}
                                                </tr>
                                                <tr>
                                                    <td className="text-[#64748B] text-xs  font-medium p-2 text-center border-2 border-black">
                                                        {sub?.doc_name || 'N/A'}
                                                    </td>
                                                    <td
                                                        className="text-xs  font-medium p-2 text-center border-2 border-black"
                                                        style={{ color: sub.uploaded ? '#55BE8B' : '#FF5449' }}
                                                    >
                                                        {sub?.uploaded ? 'Uploaded' : 'Not Uploaded'}
                                                    </td>
                                                </tr>
                                            </React.Fragment>
                                        ))}
                                    </React.Fragment>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    );
};

export default Standards;
