/* eslint-disable no-unused-vars */
import ClearIcon from '@mui/icons-material/Clear';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Button, ButtonGroup, Drawer, Menu, MenuItem, Slide } from '@mui/material';
import { useTheme } from '@mui/styles';
import AddBtn from 'assets/img/add_btn.svg';
import BackIcon from 'assets/img/arrow-narrow-right.png';
import { Loader2 } from 'lucide-react';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import {
    GetRiskMitagations,
    RunMultipleNewRiskAssessment,
    RunNewRiskAssessment,
} from 'store/actions/riskAssessmentActions';
import {
    currencies,
    mitigation_plans,
    NewRiskTemplateTabs,
    riskFirstPagePayload,
    riskIdentificationPayload,
} from '../utils/constants';
import RiskIdentification from './RiskIdentifictaion/RiskIdentification';
import RiskMitigation from './RiskMitigation/RiskMitigation';
import { by3riskLevel } from 'utils';
import { by5riskLevel } from 'utils';
import { RunAssmentAction } from 'store/actions/riskAssessmentActions';
import { AddAssetRisk } from 'store/actions/riskAssessmentActions';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="right" ref={ref} {...props} />;
});

const RunRiskDrawer = (props) => {
    const theme = useTheme();
    const { open, handleClose, params, setCurrentTab_ } = props;
    const [currentTab, setCurrentTab] = useState(NewRiskTemplateTabs.riskIdentification);
    const [currentRiskIndex, setCurrentRiskIndex] = useState(0);
    const [riskIdentificationTabStates, setRiskIdentificationTabStates] = useState(riskIdentificationPayload);
    const [riskFirstPageStates, setRiskFirstPageStates] = useState(riskFirstPagePayload);
    const [error, setError] = useState(false);
    const [cia_impact_, setCiaImpact_] = useState(false);
    const [loading, setLoading] = useState(false);
    const [multipleLoading, setMultipleLoading] = useState(false);
    const [assets, setAssets] = useState([]);
    const [matrix, setMatrix] = useState('5x5');
    const [mitigation_plan, setMitigationPlan] = useState('');
    const [new_document, setNewDocument] = useState('');
    const [existing_document, setExistingDocument] = useState('');
    const [cia_impact, setCiaImpact] = useState(null);
    const [management_approver, setManagementApprover] = useState(false);
    const [useAssetOwner, setUseAssetOwner] = useState(false);
    const [currency, setCurrency] = useState('NGN (₦)');
    const [recommendations, setRecommendations] = useState([]);
    const [mitigation_controls, setMitigationControls] = useState([]);
    const [multipleRisks, setMiultipleRisks] = useState([]);
    const [vulnerabilities, setVulnerablities] = useState([]);
    const [continuous_monitoring, setContinousMonitoring] = useState(false);
    const [saveAnchorEl, setSaveAnchorEl] = useState(false);
    const [loadingAI, setLoadingAI] = useState(false);
    const [residual_impact, setResidualImpact] = useState(0);
    const [residual_likelihood, setResidualLikelihood] = useState(0);
    const [mitigation_array, setMitigation_array] = useState([]);
    const [treatmentPlan, setTreatmentPlan] = useState('');
    // const [activeRisk, setActiveRisk] = useState();
    const [continuous_monitoring_frequency, setContinousMonitoringFrequency] = useState({
        frequency: '',
        day_of_week: '',
        number_of_units: '',
        day_of_month: '',
        month: '',
        time: '',
        unit: '',
    });
    const [doc, setDoc] = useState('');

    const dispatch = useDispatch();
    const {
        risk_assessment_threats,
        all_merchant_assets,
        risk_assessment_categories,
        risk_assessment_vulnerabilities,
        risk_asset,
    } = useSelector((state) => state.riskAssessmentReducers);
    const { all_merchant_documents } = useSelector((state) => state.complianceReducers);
    const get_risk_settings = useSelector((state) => state?.riskAssessmentReducers?.get_risk_settings);

    useEffect(() => {
        if (risk_asset) {
            setAssets([...assets, risk_asset]);
        }
    }, [risk_asset]);

    const merchant = useSelector((state) => state.authReducers?.company_id);
    // docs
    const filterDoc = all_merchant_documents?.filter(
        (docs) =>
            docs?.document_type?.iso_title ||
            docs?.document_type?.ndpr_title ||
            docs?.document_type?.iso9001_title ||
            docs?.document_type?.pcislc_title ||
            docs?.document_type?.iso27001_title ||
            docs?.document_type?.pcidss_title ||
            docs?.document_type?.card4_title ||
            docs?.document_type?.soc2_title ||
            docs?.document_type?.document_title
    );

    const docLinkOptions = useMemo(
        () =>
            // Array.isArray(linkDocument) ?
            filterDoc
                ?.sort((a, b) => a?.id - b?.id)
                ?.map((doc) => {
                    return {
                        name:
                            doc?.document_type?.iso_title ||
                            doc?.document_type?.ndpr_title ||
                            doc?.document_type?.document_title ||
                            doc?.document_type?.pcislc_title ||
                            doc?.document_type?.iso9001_title ||
                            doc?.document_type?.iso27001_title ||
                            doc?.document_type?.pcidss_title ||
                            doc?.document_type?.card4_title ||
                            doc?.document_type?.soc2_title,
                        value: doc?.id,
                        document: doc?.document,
                    };
                }),
        // : [],
        [filterDoc]
    );
    function RenderActiveTab() {
        switch (currentTab) {
            case NewRiskTemplateTabs.riskIdentification:
                return (
                    <RiskIdentification
                        setError={setError}
                        error={error}
                        cia_impact_={cia_impact_}
                        setCiaImpact_={setCiaImpact_}
                        setCiaImpact={setCiaImpact}
                        matrix={matrix}
                        setMatrix={setMatrix}
                        assets={assets}
                        setAssets={setAssets}
                        vulnerabilities={vulnerabilities}
                        setVulnerablities={setVulnerablities}
                        riskFirstPageStates={riskFirstPageStates}
                        setRiskFirstPageStates={setRiskFirstPageStates}
                    />
                );
            case NewRiskTemplateTabs.riskMitigation:
                return (
                    <RiskMitigation
                        riskIdentificationTabStates={riskIdentificationTabStates}
                        setRiskIdentificationTabStates={setRiskIdentificationTabStates}
                        error={error}
                        matrix={matrix}
                        setMatrix={setMatrix}
                        setAssets={setAssets}
                        mitigation_plan={mitigation_plan}
                        setMitigationPlan={setMitigationPlan}
                        new_document={new_document}
                        setNewDocument={setNewDocument}
                        existing_document={existing_document}
                        setExistingDocument={setExistingDocument}
                        management_approver={management_approver}
                        setManagementApprover={setManagementApprover}
                        useAssetOwner={useAssetOwner}
                        setUseAssetOwner={setUseAssetOwner}
                        assets={assets}
                        setError={setError}
                        currency={currency}
                        setCurrency={setCurrency}
                        continuous_monitoring={continuous_monitoring}
                        setContinousMonitoring={setContinousMonitoring}
                        continuous_monitoring_frequency={continuous_monitoring_frequency}
                        setContinousMonitoringFrequency={setContinousMonitoringFrequency}
                        recommendations={recommendations}
                        mitigation_controls={mitigation_controls}
                        setMitigationControls={setMitigationControls}
                        riskFirstPageStates={riskFirstPageStates}
                        setRiskFirstPageStates={setRiskFirstPageStates}
                        residual_impact={residual_impact}
                        setResidualImpact={setResidualImpact}
                        residual_likelihood={residual_likelihood}
                        setResidualLikelihood={setResidualLikelihood}
                        loadingAI={loadingAI}
                        doc={doc}
                        setDoc={setDoc}
                        mitigation_array={mitigation_array}
                        treatmentPlan={treatmentPlan}
                    />
                );
            default:
                return (
                    <RiskIdentification
                        setError={setError}
                        error={error}
                        cia_impact_={cia_impact_}
                        setCiaImpact_={setCiaImpact_}
                        setCiaImpact={setCiaImpact}
                        matrix={matrix}
                        setMatrix={setMatrix}
                        assets={assets}
                        setAssets={setAssets}
                        vulnerabilities={vulnerabilities}
                        setVulnerablities={setVulnerablities}
                        riskFirstPageStates={riskFirstPageStates}
                        setRiskFirstPageStates={setRiskFirstPageStates}
                    />
                );
        }
    }

    // Validation
    const { name, category, threat, inherent_likelihood, inherent_impact } = riskFirstPageStates;
    const {
        timeline_end_date,
        timeline_start_date,
        solution_owner,
        mitigation_cost_amount,
        justification,
        contingency_plan,
        contingency_plan_text,
        contingency_start_date,
        contingency_end_date,
        risk_elimination_method,
        risk_source,
        transfer_method,
        vendor,
    } = riskIdentificationTabStates;

    const validate = !name || !category || !threat || !inherent_likelihood || !inherent_impact;

    const validate_mitigate =
        !timeline_end_date ||
        !timeline_start_date ||
        residual_likelihood === 0 ||
        !residual_impact === 0 ||
        !mitigation_cost_amount ||
        !solution_owner ||
        !mitigation_controls;

    // Validate accept
    const validate_accept = !contingency_plan || !contingency_plan_text;
    !contingency_start_date || !contingency_end_date || !residual_impact || !solution_owner || !residual_likelihood;
    // const validate_avoid = !avoid?.risk_source;
    const validate_avoid =
        !risk_source || !risk_elimination_method || !timeline_start_date || !solution_owner || !timeline_end_date;

    // validate transfer
    const validate_transfer = !transfer_method || !vendor || !timeline_start_date || !timeline_end_date;

    const handleClearState = () => {
        setCurrentTab('Risk identification');
        setMatrix('5x5');
        setRiskFirstPageStates(riskFirstPagePayload);
        setRiskIdentificationTabStates(riskIdentificationPayload);
        setError('');
        setNewDocument('');
        setAssets([]);
        setMitigationPlan('');
        setManagementApprover(false);
        setUseAssetOwner(false);
        setCiaImpact(null);
        setCiaImpact_(false);
        setExistingDocument('');
        setMitigationControls([]);
        setVulnerablities([]);
        setDoc('');
        setCurrency('NGN (₦)');
        setRecommendations([]);
        setContinousMonitoring(false);
        setResidualImpact('');
        setResidualLikelihood('');
        setContinousMonitoringFrequency({
            frequency: '',
            day_of_week: '',
            number_of_units: '',
            day_of_month: '',
            month: '',
            time: '',
            unit: '',
        });
    };

    const onClose = (resetMultiple) => {
        handleClearState();
        if (resetMultiple) {
            setCurrentRiskIndex(0);
            setMiultipleRisks([]);
        }
        handleClose();
    };
    function extractTextInsideBraces(input) {
        const match = input?.split(' ');
        return match ? match[0] : null;
    }

    const filtered_assets = assets?.filter(function (item, index, inputArray) {
        return inputArray.indexOf(item) == index;
    });
    const filtered_merchant_assets_with_category = all_merchant_assets
        ?.filter((asset) => !filtered_assets?.includes(asset))
        ?.filter((asset) => asset?.risk_category === riskFirstPageStates?.category);
    const handleRunAssessment = async () => {
        if (validate) {
            setError(true);
            toast.error('incomplete fields');
        } else if (
            (filtered_merchant_assets_with_category?.length > 0 && assets?.length === 0) ||
            vulnerabilities?.length === 0
        ) {
            setError(true);
            toast.error('incomplete fields');
        } else if (mitigation_plan === '') {
            setLoading(true);
            const body = {
                ...riskFirstPageStates,
                assets: assets?.map((asset) => asset?.id),
                matrix,
                cia_impact,
                merchant,
                vulnerabilities: vulnerabilities?.map((vul) => vul?.id),
            };
            const res = await dispatch(RunNewRiskAssessment(body, params));
            setLoading(false);
            if (res?.success) {
                toast.success('Risk Assessment ran successfully');
                onClose();
            } else {
                toast.error(res?.message);
            }
        } else if (mitigation_plan === 'mitigate') {
            if (validate_mitigate && currentTab === 'Risk treatment') {
                setError(true);
                toast.error('incomplete fields');
            } else {
                setLoading(true);
                const body = {
                    ...riskFirstPageStates,
                    ...riskIdentificationTabStates,
                    mitigation_controls: mitigation_controls?.toString(),
                    assets: assets?.map((asset) => asset?.id),
                    new_document,
                    existing_document,
                    mitigation_plan,
                    matrix,
                    merchant,
                    management_approver,
                    mitigation_cost_currency: extractTextInsideBraces(currency),
                    cia_impact,
                    vulnerabilities: vulnerabilities?.map((vul) => vul?.id),
                    residual_likelihood,
                    residual_impact,
                };
                const res = await dispatch(RunNewRiskAssessment(body, params));
                setLoading(false);
                if (res?.success) {
                    toast.success('Risk Assessment ran successfully');
                    setMiultipleRisks([]);
                    setCurrentTab_('Risk Register');
                    onClose();
                } else {
                    toast.error(res?.message);
                }
            }
        } else if (mitigation_plan === 'accept') {
            if (!justification && currentTab === 'Risk treatment') {
                setError(true);
                toast.error('incomplete fields');
            } else if (contingency_plan && validate_accept && currentTab === 'Risk treatment') {
                setError(true);
                toast.error('incomplete fields');
            } else {
                setLoading(true);
                const body = {
                    ...riskFirstPageStates,
                    ...riskIdentificationTabStates,
                    assets: assets?.map((asset) => asset?.id),
                    new_document,
                    existing_document,
                    mitigation_plan,
                    management_approver,
                    matrix,
                    merchant,
                    continuous_monitoring_frequency,
                    continuous_monitoring,
                    cia_impact,
                    vulnerabilities: vulnerabilities?.map((vul) => vul?.id),
                    residual_likelihood,
                    residual_impact,
                };
                const res = await dispatch(RunNewRiskAssessment(body, params));
                setLoading(false);
                if (res?.success) {
                    toast.success('Risk Assessment ran successfully');
                    setCurrentTab_('Risk Register');
                    setMiultipleRisks([]);
                    onClose();
                } else {
                    toast.error(res?.message);
                }
            }
        } else if (mitigation_plan === 'avoid') {
            if (validate_avoid && currentTab === 'Risk treatment') {
                setError(true);
                toast.error('incomplete fields');
            } else {
                setLoading(true);
                const body = {
                    ...riskFirstPageStates,
                    ...riskIdentificationTabStates,
                    assets: assets?.map((asset) => asset?.id),
                    new_document,
                    existing_document,
                    mitigation_plan,
                    management_approver,
                    matrix,
                    merchant,
                    cia_impact,
                    vulnerabilities: vulnerabilities?.map((vul) => vul?.id),
                };
                const res = await dispatch(RunNewRiskAssessment(body, params));
                setLoading(false);
                if (res?.success) {
                    toast.success('Risk Assessment ran successfully');
                    setCurrentTab_('Risk Register');
                    setMiultipleRisks([]);
                    onClose();
                } else {
                    toast.error(res?.message);
                }
            }
        } else {
            if (validate_transfer && currentTab === 'Risk treatment') {
                setError(true);
                toast.error('incomplete fields');
            } else {
                setLoading(true);
                const body = {
                    ...riskFirstPageStates,
                    ...riskIdentificationTabStates,
                    assets: assets?.map((asset) => asset?.id),
                    matrix,
                    new_document,
                    existing_document,
                    mitigation_plan,
                    management_approver,
                    merchant,
                    continuous_monitoring,
                    continuous_monitoring_frequency,
                    cia_impact,
                    vulnerabilities: vulnerabilities?.map((vul) => vul?.id),
                };
                const res = await dispatch(RunNewRiskAssessment(body, params));
                setLoading(false);
                if (res?.success) {
                    toast.success('Risk Assessment ran successfully');
                    setCurrentTab_('Risk Register');
                    setMiultipleRisks([]);
                    onClose();
                } else {
                    toast.error(res?.message);
                }
            }
        }
        dispatch(RunAssmentAction(false));
        dispatch(AddAssetRisk(null));
    };

    const getRiskmitigations = async () => {
        setLoadingAI(true);
        const new_assets = assets?.map((asset) => asset?.id);
        const get_assets = all_merchant_assets?.filter((asset) => new_assets?.includes(asset?.id));
        const get_threat = risk_assessment_threats?.find(
            (threat) => threat?.id === riskIdentificationTabStates?.threat
        );
        const get_category = risk_assessment_categories?.find(
            (category) => category?.id === riskIdentificationTabStates?.category
        );
        const body_with_asset = {
            data: {
                category: get_category?.name,
                asset: get_assets?.map((item) => item?.name).toString(),
                vulnerability: vulnerabilities?.map((item) => item?.name).toString(),
                threat: get_threat?.name,
                mitigation_plan,
            },
        };
        const body_without_asset = {
            data: {
                category: get_category?.name,
                vulnerability: vulnerabilities?.map((item) => item?.name).toString(),
                threat: get_threat?.name,
                mitigation_plan,
            },
        };
        const body = assets?.length > 0 ? body_with_asset : body_without_asset;
        const res = await dispatch(GetRiskMitagations(body));
        setLoadingAI(false);
        if (res?.success) {
            setRecommendations(JSON?.parse(res?.data)?.recommendations);
        } else {
            toast.error(res?.message);
        }
    };
    useEffect(() => {
        if (
            open &&
            mitigation_plan &&
            // assets?.length &&
            vulnerabilities?.length &&
            riskFirstPageStates?.category &&
            riskFirstPageStates?.threat
        ) {
            getRiskmitigations();
        }
    }, [open, mitigation_plan, riskFirstPageStates?.threat, riskFirstPageStates?.category, assets, vulnerabilities]);

    function updateCurrentRiskInMultipleRisk(index, save) {
        if (validate && save) {
            setError(true);
            throw new Error('incomplete fields');
        } else {
            const body = {
                ...riskFirstPageStates,
                assets: assets?.map((asset) => asset?.id),
                matrix,
                cia_impact,
                merchant,
                vulnerabilities: vulnerabilities?.map((vul) => vul?.id),
            };
            if (!mitigation_plan) {
                const new_multiple_risks = multipleRisks?.map((risk, idx) => {
                    if (idx === index) {
                        return body;
                    }
                    return risk;
                });
                setMiultipleRisks(new_multiple_risks);
            } else if (mitigation_plan === 'mitigate') {
                if (validate_mitigate && currentTab === 'Risk treatment') {
                    setError(true);
                    throw new Error('incomplete fields');
                } else {
                    const details = {
                        ...body,
                        ...riskIdentificationTabStates,
                        mitigation_controls: mitigation_controls?.toString(),
                        new_document,
                        existing_document,
                        mitigation_plan,
                        merchant,
                        management_approver,
                        mitigation_cost_currency: extractTextInsideBraces(currency),
                        residual_likelihood,
                        residual_impact,
                    };
                    const new_multiple_risks = multipleRisks?.map((risk, idx) => {
                        if (idx === index) {
                            return details;
                        }
                        return risk;
                    });
                    setMiultipleRisks(new_multiple_risks);
                }
            } else if (mitigation_plan === 'accept') {
                if (validate_accept && currentTab === 'Risk treatment') {
                    setError(true);
                    throw new Error('incomplete fields');
                } else {
                    const details = {
                        ...body,
                        ...riskIdentificationTabStates,
                        new_document,
                        existing_document,
                        mitigation_plan,
                        merchant,
                        management_approver,
                        continuous_monitoring_frequency,
                        continuous_monitoring,
                        residual_likelihood,
                        residual_impact,
                    };
                    const new_multiple_risks = multipleRisks?.map((risk, idx) => {
                        if (idx === index) {
                            return details;
                        }
                        return risk;
                    });
                    setMiultipleRisks(new_multiple_risks);
                }
            } else if (mitigation_plan === 'avoid') {
                if (validate_avoid && currentTab === 'Risk treatment') {
                    setError(true);
                    throw new Error('incomplete fields');
                } else {
                    const details = {
                        ...body,
                        ...riskIdentificationTabStates,
                        new_document,
                        existing_document,
                        mitigation_plan,
                        merchant,
                        management_approver,
                    };
                    const new_multiple_risks = multipleRisks?.map((risk, idx) => {
                        if (idx === index) {
                            return details;
                        }
                        return risk;
                    });
                    setMiultipleRisks(new_multiple_risks);
                }
            } else if (mitigation_plan === 'transfer') {
                if (validate_transfer && currentTab === 'Risk treatment') {
                    setError(true);
                    throw new Error('incomplete fields');
                } else {
                    const details = {
                        ...body,
                        ...riskIdentificationTabStates,
                        new_document,
                        existing_document,
                        mitigation_plan,
                        merchant,
                        management_approver,
                        continuous_monitoring_frequency,
                        continuous_monitoring,
                    };
                    const new_multiple_risks = multipleRisks?.map((risk, idx) => {
                        if (idx === index) {
                            return details;
                        }
                        return risk;
                    });
                    setMiultipleRisks(new_multiple_risks);
                }
            }
        }
    }
    async function createNewMultipleRisk() {
        try {
            const newRisk = {
                ...riskFirstPagePayload,
            };

            if (multipleRisks?.length === 0) {
                if (validate) {
                    setError(true);
                } else {
                    const body = {
                        ...riskFirstPageStates,
                        assets: assets?.map((asset) => asset?.id),
                        matrix,
                        cia_impact,
                        merchant,
                        vulnerabilities: vulnerabilities?.map((vul) => vul?.id),
                    };
                    if (!mitigation_plan) {
                        setMiultipleRisks([...multipleRisks, body, newRisk]);
                        handleClearState();
                        setMatrix('5x5');
                        console.log({ mitigation_plan, heje: [...multipleRisks, body, newRisk] });
                        setCurrentRiskIndex(multipleRisks?.length + 1);
                    } else if (mitigation_plan === 'mitigate') {
                        if (validate_mitigate) {
                            setError(true);
                        } else {
                            const details = {
                                ...body,
                                ...riskIdentificationTabStates,
                                mitigation_controls: mitigation_controls?.toString(),
                                new_document,
                                existing_document,
                                mitigation_plan,
                                merchant,
                                management_approver,
                                mitigation_cost_currency: extractTextInsideBraces(currency),
                                residual_likelihood,
                                residual_impact,
                            };
                            setMiultipleRisks([...multipleRisks, details, newRisk]);
                            console.log({ heje: [...multipleRisks, details, newRisk] });
                            handleClearState();
                            setMatrix('5x5');
                            setCurrentRiskIndex(multipleRisks?.length + 1);
                        }
                    } else if (mitigation_plan === 'accept') {
                        if (validate_accept) {
                            setError(true);
                        } else {
                            const details = {
                                ...body,
                                ...riskIdentificationTabStates,
                                new_document,
                                existing_document,
                                mitigation_plan,
                                merchant,
                                management_approver,
                                continuous_monitoring_frequency,
                                continuous_monitoring,
                                residual_likelihood,
                                residual_impact,
                            };
                            setMiultipleRisks([...multipleRisks, details, newRisk]);
                            handleClearState();
                            setMatrix('5x5');
                            setCurrentRiskIndex(multipleRisks?.length + 1);
                        }
                    } else if (mitigation_plan === 'avoid') {
                        if (validate_avoid) {
                            setError(true);
                        } else {
                            const details = {
                                ...body,
                                ...riskIdentificationTabStates,
                                new_document,
                                existing_document,
                                mitigation_plan,
                                merchant,
                                management_approver,
                            };
                            setMiultipleRisks([...multipleRisks, details, newRisk]);
                            handleClearState();
                            setMatrix('5x5');
                            setCurrentRiskIndex(multipleRisks?.length + 1);
                        }
                    } else if (mitigation_plan === 'transfer') {
                        if (validate_transfer) {
                            setError(true);
                        } else {
                            const details = {
                                ...body,
                                ...riskIdentificationTabStates,
                                new_document,
                                existing_document,
                                mitigation_plan,
                                merchant,
                                management_approver,
                                continuous_monitoring_frequency,
                                continuous_monitoring,
                            };
                            setMiultipleRisks([...multipleRisks, details, newRisk]);
                            handleClearState();
                            setMatrix('5x5');
                            setCurrentRiskIndex(multipleRisks?.length + 1);
                        }
                    }
                }
            } else {
                if (validate) {
                    setError(true);
                    throw new Error('incomplete fields');
                } else {
                    updateCurrentRiskInMultipleRisk(currentRiskIndex);
                    setCurrentRiskIndex(multipleRisks?.length);
                    setMiultipleRisks((prev) => [...prev, newRisk]);
                    handleClearState();
                }
            }
        } catch (error) {
            toast.error(error?.message);
        }
    }

    async function switchBetweenMultipleRisk(prevIndex, newIndex) {
        const find_prev_risk = multipleRisks?.find((_, index) => index === prevIndex);
        try {
            if (prevIndex > 0 && find_prev_risk) {
                updateCurrentRiskInMultipleRisk(prevIndex);
            }
            handleClearState();
            const new_risk = multipleRisks?.find((_, idx) => idx === newIndex);
            const filtered_assets = all_merchant_assets?.filter((asset) => new_risk?.assets?.includes(asset?.id));
            const filtered_vulnerabilities = risk_assessment_vulnerabilities?.filter((asset) =>
                new_risk?.vulnerabilities?.includes(asset?.id)
            );
            const current_doc = docLinkOptions?.find((doc) => doc?.value === new_risk?.existing_document);
            if (new_risk) {
                setMatrix(new_risk?.matrix);
                setRiskFirstPageStates({
                    name: new_risk?.name,
                    category: new_risk?.category,
                    threat: new_risk?.threat,
                    frameworks: new_risk?.frameworks,
                    existing_controls: new_risk?.existing_controls,
                    inherent_impact: new_risk?.inherent_impact,
                    inherent_likelihood: new_risk?.inherent_likelihood,
                    additional_notes: new_risk?.additional_notes,
                });
                setAssets(filtered_assets);
                setVulnerablities(filtered_vulnerabilities);
                setMitigationControls(new_risk?.mitigation_controls?.split(',') || []);
                setMitigationPlan(new_risk?.mitigation_plan);
                setCurrency(
                    new_risk?.mitigation_cost_currency
                        ? currencies?.find((currency) =>
                              extractTextInsideBraces(currency)?.includes(new_risk?.mitigation_cost_currency)
                          )
                        : 'NGN (₦)' || 'NGN (₦)'
                );
                setRiskIdentificationTabStates({
                    report_risk_to: new_risk?.report_risk_to,
                    report_to: new_risk?.report_to,
                    solution_owner: new_risk?.solution_owner,
                    timeline_start_date: new_risk?.timeline_start_date,
                    timeline_end_date: new_risk?.timeline_end_date,
                    mitigation_cost_amount: new_risk?.mitigation_cost_amount,
                    residual_likelihood: new_risk?.residual_likelihood,
                    residual_impact: new_risk?.residual_impact,
                    comments: new_risk?.comments,
                    justification: new_risk?.justification,
                    contingency_plan: new_risk?.contingency_plan,
                    contingency_plan_text: new_risk?.new_risk?.contingency_plan_text,
                    contingency_start_date: new_risk?.contingency_start_date,
                    contingency_end_date: new_risk?.contingency_end_date,
                    risk_source: new_risk?.risk_source,
                    transfer_method: new_risk?.transfer_method,
                    vendor: new_risk?.vendor,
                });
                setCiaImpact_(new_risk?.cia_impact ? true : false);
                setNewDocument(new_risk?.new_document);
                setExistingDocument(new_risk?.existing_document);
                setCiaImpact(new_risk?.cia_impact);
                setManagementApprover(new_risk?.management_approver);
                setUseAssetOwner(new_risk?.use_asset_owner);
                setContinousMonitoring(new_risk?.continuous_monitoring);
                setResidualImpact(new_risk?.residual_impact);
                setResidualLikelihood(new_risk?.residual_likelihood);
                setContinousMonitoringFrequency(new_risk?.continuous_monitoring_frequency);
                setCurrentRiskIndex(newIndex);
                setDoc(current_doc?.document);
            }
        } catch (error) {
            toast.error(error?.message);
        }
    }

    const handleMultipleRiskAssessment = async (type) => {
        const filtered_multiple_risks = multipleRisks?.filter((risk) => risk?.inherent_likelihood !== '');
        setMultipleLoading(true);
        const res = await dispatch(RunMultipleNewRiskAssessment(filtered_multiple_risks, params));
        setMultipleLoading(false);
        if (res?.success) {
            toast.success('Risk Assessments ran successfully');
            setCurrentTab_('Risk Register');
            if (type === 'close') {
                onClose(true);
            }
            setMiultipleRisks([]);
        } else {
            toast.error(res?.message);
        }
        dispatch(RunAssmentAction(false));
        dispatch(AddAssetRisk(null));
    };

    const handleRemoveRisk = (id) => {
        const filtered_risks = multipleRisks?.filter((_, idx) => idx !== id);
        setMiultipleRisks(filtered_risks);
        setCurrentRiskIndex(currentRiskIndex > id ? currentRiskIndex - 1 : currentRiskIndex);
    };

    // const inherent_risk = useMemo(() => {
    //     return matrix === '3x3'
    //         ? by3riskLevel(riskFirstPageStates?.inherent_likelihood * riskFirstPageStates?.inherent_impact)
    //         : by5riskLevel(riskFirstPageStates?.inherent_likelihood * riskFirstPageStates?.inherent_impact);
    // }, [riskFirstPageStates?.inherent_likelihood, riskFirstPageStates?.inherent_impact]);
    useEffect(() => {
        if (open && riskFirstPageStates?.inherent_likelihood && riskFirstPageStates?.inherent_impact) {
            const inherent_risk =
                matrix === '3x3'
                    ? by3riskLevel(riskFirstPageStates?.inherent_likelihood * riskFirstPageStates?.inherent_impact)
                    : by5riskLevel(riskFirstPageStates?.inherent_likelihood * riskFirstPageStates?.inherent_impact);
            if (matrix === '3x3') {
                if (inherent_risk?.type === 'Low') {
                    const treatment_plan_check = mitigation_plans?.filter((plan) =>
                        get_risk_settings[0]?.risk_plan_3x3?.low?.includes(plan)
                    );
                    setTreatmentPlan(treatment_plan_check?.[0]);
                    setMitigation_array(treatment_plan_check);
                } else if (inherent_risk?.type === 'Medium') {
                    const treatment_plan_check = mitigation_plans?.filter((plan) =>
                        get_risk_settings[0]?.risk_plan_3x3?.medium?.includes(plan)
                    );
                    setTreatmentPlan(treatment_plan_check?.[0]);
                    setMitigation_array(treatment_plan_check);
                } else {
                    const treatment_plan_check = mitigation_plans?.filter((plan) =>
                        get_risk_settings[0]?.risk_plan_3x3?.high?.includes(plan)
                    );
                    setTreatmentPlan(treatment_plan_check?.[0]);
                    setMitigation_array(treatment_plan_check);
                }
            } else {
                if (inherent_risk?.type === 'Low') {
                    const treatment_plan_check = mitigation_plans?.filter((plan) =>
                        get_risk_settings[0]?.risk_plan_5x5?.low?.includes(plan)
                    );
                    setTreatmentPlan(treatment_plan_check?.[0]);
                    setMitigation_array(treatment_plan_check);
                } else if (inherent_risk?.type === 'Medium') {
                    const treatment_plan_check = mitigation_plans?.filter((plan) =>
                        get_risk_settings[0]?.risk_plan_5x5?.medium?.includes(plan)
                    );
                    setTreatmentPlan(treatment_plan_check?.[0]);
                    setMitigation_array(treatment_plan_check);
                } else {
                    const treatment_plan_check = mitigation_plans?.filter((plan) =>
                        get_risk_settings[0]?.risk_plan_5x5?.high?.includes(plan)
                    );
                    setTreatmentPlan(treatment_plan_check?.[0]);
                    setMitigation_array(treatment_plan_check);
                }
            }
        }
    }, [open, riskFirstPageStates?.inherent_likelihood, riskFirstPageStates?.inherent_impact]);
    return (
        <Drawer
            open={open}
            anchor="right"
            TransitionComponent={Transition}
            transitionDuration={1000}
            keepMounted
            onClose={() => onClose(true)}
            PaperProps={{
                sx: {
                    boxShadow: '0px 12px 24px rgba(0, 0, 0, 0.16)',
                    flex: 1,
                    width: '100%',
                    overflow: 'scroll',
                    zIndex: 9999,
                },
            }}
            scroll="paper"
        >
            <div>
                <div className="flex justify-between items-center border border-[#F1F5F9] px-[2rem] py-[0.5rem]">
                    <div className="cursor-pointer flex items-center gap-3" onClick={onClose}>
                        <img src={BackIcon} alt="Back icon" className="cursor-pointer" />
                        <p className="text-[#002C72] text-lg font-bold">Run Assessment</p>
                    </div>
                </div>
                <main className="relative !px-[72px] !py-8 bg-white flex gap-28">
                    <section className="self-start flex flex-col shadow-[0px,8px,20px,0px,#3D6BA314] !border !border-[#F1F5F9] relative">
                        <button
                            className={`flex items-center gap-2.5 hover:brightness-90 transition-all relative !border-b !border-[#F1F5F9] !py-4 !px-6 before:bg-[#395BA9] before:h-full before:rounded-tr before:rounded-br before:absolute before:-left-1 overflow-hidden ${
                                currentTab === NewRiskTemplateTabs.riskIdentification
                                    ? 'bg-[#F1F5F9] before:w-2'
                                    : 'bg-[#fff] before:w-0'
                            }`}
                            onClick={() => setCurrentTab(NewRiskTemplateTabs.riskIdentification)}
                        >
                            <p className="text-base font-semibold rounded-sm !py-0.5 !px-1.5 text-white bg-[#94A3B8]">
                                1
                            </p>

                            <p
                                className={`font-semibold text-base ${
                                    currentTab === NewRiskTemplateTabs.riskMitigation
                                        ? 'text-[#395BA9]'
                                        : 'text-[#3D6BA3]'
                                }`}
                            >
                                Risk identification
                            </p>
                        </button>

                        <button
                            className={`flex items-center gap-2.5 hover:brightness-90 transition-all relative !border-b !border-[#F1F5F9] !py-4 !px-6 before:bg-[#395BA9] before:h-full before:rounded-tr before:rounded-br before:absolute before:-left-1 overflow-hidden ${
                                currentTab === NewRiskTemplateTabs.riskMitigation
                                    ? 'bg-[#F1F5F9] before:w-2'
                                    : 'bg-[#fff] before:w-0'
                            }`}
                            disabled={
                                validate ||
                                vulnerabilities?.length === 0 ||
                                (filtered_merchant_assets_with_category?.length > 0 && assets?.length === 0)
                            }
                            onClick={() => {
                                setCurrentTab(NewRiskTemplateTabs.riskMitigation);
                            }}
                        >
                            <p className="text-base font-semibold rounded-sm !py-0.5 !px-1.5 text-white bg-[#94A3B8]">
                                2
                            </p>

                            <p
                                className={`font-semibold text-base ${
                                    currentTab === NewRiskTemplateTabs.riskMitigation
                                        ? 'text-[#395BA9]'
                                        : 'text-[#3D6BA3]'
                                }`}
                            >
                                Risk treatment
                            </p>
                        </button>
                    </section>

                    <section className="flex-1 !pr-24 !pb-24">
                        <RenderActiveTab />
                    </section>
                </main>
                <footer
                    className={`!border-t !border-[#F1F5F9] !py-6 !px-8 w-full fixed bottom-0 bg-white flex justify-between items-center`}
                >
                    <div className="flex items-center gap-5">
                        <button
                            className=""
                            onClick={() => {
                                if (multipleRisks?.length === 5) {
                                    toast.error('You can only create a maximum of 5 risks at a time');
                                    return;
                                } else {
                                    createNewMultipleRisk();
                                }
                            }}
                        >
                            <img src={AddBtn} alt="Add Button" className="cursor-pointer" />
                        </button>

                        <div className="flex items-center gap-3 relative">
                            {multipleRisks?.length === 0 ? (
                                <div className="relative group">
                                    <button
                                        className={` rounded-md font-semibold text-base hover:brightness-90 transition-all bg-[#202D66] text-white shadow-[2px,4px,8px,0px,#0000001A] py-1.5 px-3 flex items-center gap-2`}
                                    >
                                        Risk 1
                                    </button>
                                </div>
                            ) : (
                                multipleRisks?.map((_, index) => (
                                    <div key={index} className="relative group">
                                        <button
                                            className={` rounded-md font-semibold text-base ${
                                                currentRiskIndex === index
                                                    ? 'hover:brightness-90 transition-all bg-[#202D66] text-white'
                                                    : 'text-[#64748B] !border !border-[#202D66]'
                                            } shadow-[2px,4px,8px,0px,#0000001A] py-1.5 px-3 flex items-center gap-2`}
                                            onClick={() => switchBetweenMultipleRisk(currentRiskIndex, index)}
                                        >
                                            Risk {index + 1}
                                        </button>

                                        {currentRiskIndex === index ? null : (
                                            <div
                                                className="group-hover:grid rounded-full hidden absolute -top-[0.75rem] -right-[0.25rem] cursor-pointer bg-[#ffff] !border !border-[#202D66] place-items-center h-5 w-5"
                                                onClick={() => handleRemoveRisk(index)}
                                            >
                                                <ClearIcon sx={{ width: 14, height: 14, color: '#FF0000' }} />
                                            </div>
                                        )}
                                    </div>
                                ))
                            )}

                            {riskFirstPageStates?.name ? (
                                <p className="text-[13px] text-[#64748B] font-bold absolute px-1 left-0 -bottom-[1.4rem] whitespace-nowrap">
                                    Risk {currentRiskIndex + 1}:{' '}
                                    <span className="font-normal">{riskFirstPageStates?.name}</span>
                                </p>
                            ) : null}
                        </div>
                    </div>
                    {/* )} */}
                    <div className="!border-t !border-[#F1F5F9] flex items-center justify-end flex justify-end bg-white gap-5">
                        {!validate && vulnerabilities?.length > 0 && (
                            <button
                                className="hover:brightness-90 transition-all text-[#395BA9] font-semibold text-base bg-[#F1F5F9] shadow-[2px,4px,8px,0px,#0000001A] rounded !py-2.5 !px-4 flex items-center gap-2"
                                onClick={() => {
                                    if (currentTab === 'Risk treatment') {
                                        setCurrentTab('Risk identification');
                                    } else {
                                        setCurrentTab('Risk treatment');
                                    }
                                }}
                            >
                                {currentTab === 'Risk treatment'
                                    ? 'Back to risk identification'
                                    : 'Save & proceed to risk treatment'}
                            </button>
                        )}
                        <div className="relative">
                            <ButtonGroup variant="contained" aria-label="Basic button group">
                                <Button
                                    color="primary"
                                    variant="contained"
                                    disableElevation
                                    onClick={handleRunAssessment}
                                    sx={{
                                        display: 'flex-inline',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        fontWeight: '600',
                                        py: 1.2,
                                        px: multipleRisks?.length === 0 ? 6 : 2.4,
                                        textTransform: 'unset',
                                        '&.Mui-disabled': {
                                            color: theme.palette.white.main,
                                            backgroundColor: theme.palette.gray[600],
                                        },
                                    }}
                                >
                                    {(loading || multipleLoading) && <Loader2 className="mr-1 h-4 w-4 animate-spin" />}{' '}
                                    Save & close
                                </Button>

                                {multipleRisks?.length === 0 ? null : (
                                    <Button
                                        onClick={async (event) => {
                                            try {
                                                updateCurrentRiskInMultipleRisk(currentRiskIndex, 'save');
                                                setSaveAnchorEl(event.currentTarget);
                                            } catch (error) {
                                                toast.error(error?.message);
                                            }
                                        }}
                                        color="primary"
                                        variant="contained"
                                        // disabled={multipleRisks?.length === 0}
                                        sx={{ borderLeft: '1px solid #5474C4', py: 1.2 }}
                                    >
                                        <KeyboardArrowDownIcon />
                                    </Button>
                                )}
                            </ButtonGroup>

                            <p className="text-xs font-medium text-[#755B00] absolute -bottom-[1.25rem] left-0 px-1">
                                This saves and closes risk {currentRiskIndex + 1}
                            </p>
                        </div>
                        <Menu
                            open={Boolean(saveAnchorEl)}
                            anchorEl={saveAnchorEl}
                            onClose={() => setSaveAnchorEl(null)}
                        >
                            {multipleRisks?.length === 0 ? null : (
                                <MenuItem
                                    sx={{
                                        color: '#64748B',
                                        fontWeight: 500,
                                        fontSize: '14px',
                                        textTransform: 'none',
                                        borderBottom: '1px solid #F1F5F9',
                                        padding: '8px 16px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: 3,
                                    }}
                                    onClick={() => {
                                        handleMultipleRiskAssessment('close');
                                        setSaveAnchorEl(null);
                                    }}
                                >
                                    {multipleLoading && <Loader2 className="mr-1 h-4 w-4 animate-spin" />} Save all risk
                                    & close all
                                </MenuItem>
                            )}

                            {multipleRisks?.length === 0 ? null : (
                                <MenuItem
                                    sx={{
                                        color: '#64748B',
                                        fontWeight: 500,
                                        fontSize: '14px',
                                        textTransform: 'none',
                                        borderBottom: '1px solid #F1F5F9',
                                        padding: '8px 16px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: 3,
                                    }}
                                    onClick={() => {
                                        handleMultipleRiskAssessment('no_close');
                                        setSaveAnchorEl(null);
                                    }}
                                >
                                    {multipleLoading && <Loader2 className="mr-1 h-4 w-4 animate-spin" />} Save all risk
                                    only
                                </MenuItem>
                            )}
                        </Menu>
                    </div>
                </footer>
            </div>
        </Drawer>
    );
};
export default RunRiskDrawer;
