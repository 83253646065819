import * as types from '../constants';

const initialState = {
    one_article: null,
    one_article_loading: false,
    loading: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case types.ALL_ARTICLE_HEADER:
            return {
                ...state,
                all_article_header: action.payload,
            };
        case types.ALL_ARTICLES:
            return {
                ...state,
                all_articles: action.payload,
            };
        case types.ALL_MERCHANT_ARTICLES:
            return {
                ...state,
                all_merchant_articles: action.payload,
                loading: false,
            };
        case types.ONE_ARTICLE:
            return {
                ...state,
                one_article_loading: false,
                one_article: action.payload,
            };
        case types.ONE_ARTICLE_REQUEST:
            return {
                ...state,
                one_article_loading: true,
            };
        case types.ONE_ARTICLE_HELPFUL:
            return {
                ...state,
                one_article_helpful: action.payload,
            };
        case types.ARTICLES_REQUEST:
            return {
                ...state,
                loading: true,
            };
        default:
            return state;
    }
};
