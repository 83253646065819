import React from 'react';
import { useSelector } from 'react-redux';
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const SSLCReport = () => {
    const { pci_slc_ciso_summary } = useSelector((state) => state?.CISOReducers);
    return (
        <div className="border-t border-[#F1F5F9] px-3 p-2 flex justify-between items-center gap-5">
            <div className="flex items-center gap-1">
                {pci_slc_ciso_summary?.documents?.user === pci_slc_ciso_summary?.documents?.total &&
                pci_slc_ciso_summary?.forms?.user === pci_slc_ciso_summary?.forms?.total ? (
                    <CheckCircleIcon sx={{ color: '#37A372', width: 12, height: 13 }} />
                ) : (
                    <ErrorIcon sx={{ color: '#FF5449', width: 12, height: 13 }} />
                )}
                <p className="text-gray-600 text-xs font-normal">SSLC</p>
            </div>
            <div className="flex items-center gap-2 flex-wrap justify-end">
                <div className="border border-[#E2E8F0] p-1 text-gray-500 text-xs font-medium flex items-center gap-2">
                    <p>
                        {pci_slc_ciso_summary?.forms?.user}/{pci_slc_ciso_summary?.forms?.total} Questions answered
                    </p>
                </div>
                <div className="border border-[#E2E8F0] p-1 text-gray-500 text-xs font-medium flex items-center gap-2">
                    <p>
                        {pci_slc_ciso_summary?.documents?.user}/{pci_slc_ciso_summary?.documents?.total} Documents
                        uploaded
                    </p>
                </div>
                {pci_slc_ciso_summary?.evidences && (
                    <div className="border border-[#E2E8F0] p-1 text-gray-500 text-xs font-medium flex items-center gap-2">
                        <p>
                            {pci_slc_ciso_summary?.evidences?.user}/{pci_slc_ciso_summary?.evidences?.total} Evidences
                            uploaded
                        </p>
                    </div>
                )}
            </div>
        </div>
    );
};

export default SSLCReport;
