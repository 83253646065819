import React from 'react';

//core components
import { Box, Drawer, Slide } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

const ThreatViewModal = (props) => {
    //props
    const { open, handleClose, currentThreat } = props;

    const Transition = React.forwardRef(function Transition(props, ref) {
        return <Slide direction="right" ref={ref} {...props} />;
    });

    return open ? (
        <Drawer
            open={open}
            onClose={handleClose}
            anchor="right"
            TransitionComponent={Transition}
            transitionDuration={1000}
            keepMounted
            title={
                <div className="flex items-center gap-1">
                    <ArrowBackIosIcon sx={{ width: 20, height: 20, color: '#000000' }} />
                    <div>
                        <p>{currentThreat?.name}</p>
                        <span className="text-xs pt-[-1rem]">{currentThreat?.aka}</span>
                    </div>
                </div>
            }
            PaperProps={{
                sx: {
                    boxShadow: '0px 12px 24px rgba(0, 0, 0, 0.16)',
                    flex: 1,
                    width: '500px',
                    overflow: 'scroll',
                    zIndex: 9999,
                    backgroundColor: '#F8FAFC',
                },
            }}
            scroll="paper"
        >
            <Box>
                <div className="flex items-center gap-1 border-b border-[#E2E8F0] py-2">
                    <ArrowBackIosIcon
                        sx={{ width: 20, height: 20, color: '#000000', ml: 2, cursor: 'pointer' }}
                        onClick={handleClose}
                    />
                    <div>
                        <p className="text-[#395BA9] text-base font-semibold">{currentThreat?.name}</p>
                        <span className="text-xs pt-[-1rem]">{currentThreat?.aka}</span>
                    </div>
                </div>
                <Box className="p-[1rem]">
                    <div className="border-b border-[#E2E8F0] pb-2 mb-3">
                        <h4 className="text-[#395BA9] text-base font-semibold">Overview</h4>
                        <p className="text-[#64748B] text-sm font-normal my-1">{currentThreat?.overview}</p>
                    </div>
                    <div className="border-b border-[#E2E8F0] pb-2 mb-3">
                        <h4 className="text-[#395BA9] text-base font-semibold">Attack Vectors</h4>
                        <p className="text-[#64748B] text-sm font-normal my-1">{currentThreat?.attack_vectors}</p>
                    </div>
                    <div className="border-b border-[#E2E8F0] pb-2 mb-3">
                        <h4 className="text-[#395BA9] text-base font-semibold">Target Vectors</h4>
                        <p className="text-[#64748B] text-sm font-normal my-1">{currentThreat?.target_sectors}</p>
                    </div>
                    <div className="border-b border-[#E2E8F0] pb-2 mb-3">
                        <h4 className="text-[#395BA9] text-base font-semibold">Malware</h4>
                        <p className="text-[#64748B] text-sm font-normal my-1">
                            {currentThreat?.malware?.split(',')?.map((data, index) => (
                                <span>
                                    {data}
                                    {length < index + 1 ? ',' : ''}
                                </span>
                            ))}
                        </p>
                    </div>
                    <div className="border-b border-[#E2E8F0] pb-2 mb-3">
                        <h4 className="text-[#395BA9] text-base font-semibold">Country</h4>
                        <p className="text-[#64748B] text-sm font-normal my-1">{currentThreat?.country}</p>
                    </div>
                </Box>
            </Box>
        </Drawer>
    ) : null;
};

export default ThreatViewModal;
