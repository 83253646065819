import {
    ALL_AUDITORS,
    ALL_MERCHANTS,
    ALL_AUDITOR_MERCHANTS,
    ALL_FORM_FIELDS,
    ALL_GDPA_CATEGORIES,
    ALL_KDPA_CATEGORIES,
    ALL_ADMIN_DOCUMENTS_BY_TAGS,
    ALL_ANNEXES,
    ALL_ANNEXES_TWO,
    ALL_ANNEXES_NEW,
    ALL_ISO27001_DOCUMENTS,
    ALL_ISO27001_2_DOCUMENTS,
    ALL_ISONEW_DOCUMENTS,
    ALL_ISO22301_DOCUMENTS,
    ALL_ADMIN_DOCUMENTS_BY_ALTTAGS,
    ALL_ADMIN_ISO_FORMS,
    ALL_ADMIN_ISOTWO_FORMS,
    ALL_ADMIN_ISONEW_FORMS,
    ALL_ADMIN_CONTINUITY_ISO_FORMS,
    ALL_ONBOARDING_ITEMS,
    ALL_THREATS,
    ALL_THREATS_CATEGORIES,
    ALL_RISK_CATEGORIES,
    ALL_VULNERABILITIES,
    ALL_CONTROLS,
    ALL_ADMIN_DOCUMENTS_BY_CONTROL,
    ALL_ADMIN_DOCUMENTS_BY_MANAGEMENT,
    ALL_ADMIN_FORMS_BY_CONTROL,
    ALL_ADMIN_FORMS_BY_MANAGEMENT,
    ALL_SUPPORT_USERS,
    ALL_RELEASES,
    ALL_DOCUMENTS_BY_REQUIREMENT_ID,
    ALL_DOCUMENTS_BY_CONTROL_ID,
    ALL_INTEGRATIONS_GROUPS,
    ALL_INTEGRATIONS_BY_GROUP,
    INTEGRATION_BY_ID,
    ALL_ISOGROUPS,
    ALL_ISOGROUPSTWO,
    ALL_ISOGROUPS_NEW,
    ALL_FAQS,
    ALL_GLOSSARY,
    ALL_TESTIMONIALS,
    ALL_CRITERIA,
    DOCUMENTS_BY_SUBCRITERIA,
    ALL_ADMIN_BLOCKCHAIN_FORMS,
    ALL_MANAGEMENT_CLAUSES,
    ALL_MANAGEMENT_CLAUSES_TWO,
    ALL_MANAGEMENT_CLAUSES_NEW,
    ALL_CYBERSECURITIES,
    ALL_PROJECT_MANAGEMENT,
    ALL_CLOUD_SECURITIES,
    ALL_CONTINUITY_CLAUSES,
    ALL_SUBCRITERIA_DOCUMENT,
    ALL_SUBCRITERIA_FORMS,
    MERCHANT_BY_ID,
    CONDITIONAL_BY_ID,
    ALL_CONDITIONAL_ITEMS,
    ALL_TOOLS_ITEMS,
    ALL_BACKGROUND_CHECK_TOOLS,
    ALL_USER_TOOLS_BY_ID,
    CODEREVIEW_DOCUMENTS,
    ALL_PUSH_NOTIFICATIONS,
    ALL_USERS,
    ALL_SURVEY,
    ALL_SURVEY_RESPONSE,
    ALL_PENTESTS,
    ALL_EMPLOYEES,
    ADMIN_UPLOADED_DOCS,
    MERCHANT_EXTRA_DOCS,
    ALL_FORMS_BY_TAG,
    ALL_DOCUMENTS_BY_TAG,
    ALL_ANNEX_CATEGORIES,
    ALL_ANNEXTWO_CATEGORIES,
    ALL_ANNEXNEW_CATEGORIES,
    ALL_GDPA_DOCUMENTS,
    ALL_KDPA_DOCUMENTS,
    ALL_ADMIN_GDPA_FORMS,
    ALL_ADMIN_KDPA_FORMS,
    ALL_SECTOR,
    ALL_CII_TOOLS_ITEMS,
    ALL_SUBSECTOR_DOCUMENT,
    ALL_ADMIN_CII_FORMS,
    ALL_CII_CONDITIONAL_ITEMS,
    ALL_USER_CII_TOOLS,
    ALL_ADMIN_PCIDSS_FORMS,
    ALL_ADMINPCIDSS4POINT0_DOCUMENTS_BY_SUBREQ,
    ALL_ADMIN_PCIDSS4POINT0_FORMS,
    ALL_REQUIREMENTS,
    ALL_REQUIREMENTS_METRICS,
    ALL_PCIDSS4POINT0_REQUIREMENTS,
    ALL_ADMINPCIDSS4POINT0_DOCUMENTS_BY_TAGS,
    ALL_ADMINPCIDSS4POINT0_DOCUMENTS_BY_ALTTAGS,
    ALL_ADMIN_DOCUMENTS_BY_SUBREQ,
    ONE_PCIDSS4POINT0_REQUIREMENT,
    ALL_CATEGORIES,
    ALL_NDPR_DOCUMENTS,
    ALL_ADMIN_NDPR_FORMS,
    ALL_ADMIN_DOCUMENTS_BY_COMMON_CONTROL,
    ALL_ADMIN_EVIDENCE_BY_CONTROL,
    ALL_ADMIN_EVIDENCE_BY_MANAGEMENT,
    ALL_ADMIN_EVIDENCE_BY_COMMON_CONTROL,
    ALL_ADMIN_EVIDENCE_BY_SUB_REQUIREMENT,
} from '../constants';

export default (state = {}, action) => {
    switch (action.type) {
        case ALL_AUDITORS:
            return {
                ...state,
                all_auditors: action.payload,
            };
        case ALL_CATEGORIES:
            return {
                ...state,
                all_categories: action.payload,
            };
        case ALL_MERCHANTS:
            return {
                ...state,
                all_merchants: action.payload,
            };
        case ALL_REQUIREMENTS:
            return {
                ...state,
                all_requirements: action.payload,
            };
        case ALL_REQUIREMENTS_METRICS:
            return {
                ...state,
                all_requirements_metrics: action.payload,
            };
        case ALL_PCIDSS4POINT0_REQUIREMENTS:
            return {
                ...state,
                all_pcidss4point0_requirements: action.payload,
            };
        case ONE_PCIDSS4POINT0_REQUIREMENT:
            return {
                ...state,
                one_pcidss4point0_requirement: action.payload,
            };
        case ALL_ADMINPCIDSS4POINT0_DOCUMENTS_BY_TAGS:
            return {
                ...state,
                all_pcidss4point0_documents_by_tags: action.payload,
            };
        case ALL_ADMINPCIDSS4POINT0_DOCUMENTS_BY_ALTTAGS:
            return {
                ...state,
                all_pcidss4point0_documents_by_alttags: action.payload,
            };
        case ALL_AUDITOR_MERCHANTS:
            return {
                ...state,
                all_auditor_merchants: action.payload,
            };
        case ALL_ADMINPCIDSS4POINT0_DOCUMENTS_BY_SUBREQ:
            return {
                ...state,
                all_pcidss4point0_documents_by_subreq: action.payload,
            };
        case ALL_ADMIN_PCIDSS_FORMS:
            return {
                ...state,
                all_pcidss_forms: action.payload,
            };
        case ALL_FORMS_BY_TAG:
            return {
                ...state,
                all_forms_by_tag: action?.payload,
            };
        case ALL_DOCUMENTS_BY_TAG:
            return {
                all_documents_by_tag: action?.payload,
            };
        case ALL_ADMIN_PCIDSS4POINT0_FORMS:
            return {
                ...state,
                all_pcidss4point0_forms: action.payload,
            };
        case ALL_ADMIN_DOCUMENTS_BY_SUBREQ:
            return {
                ...state,
                all_documents_by_subreq: action.payload,
            };
        case ALL_ADMIN_BLOCKCHAIN_FORMS:
            return {
                ...state,
                all_blockchain_forms: action.payload,
            };
        case ALL_FORM_FIELDS:
            return {
                ...state,
                all_form_fields: action.payload,
            };
        case ALL_GDPA_CATEGORIES:
            return {
                ...state,
                all_gdpa_categories: action.payload,
            };
        case ALL_KDPA_CATEGORIES:
            return {
                ...state,
                all_kdpa_categories: action.payload,
            };
        case ALL_KDPA_DOCUMENTS:
            return {
                ...state,
                all_kdpa_documents: action.payload,
            };
        case ALL_GDPA_DOCUMENTS:
            return {
                ...state,
                all_gdpa_documents: action.payload,
            };
        case ALL_ADMIN_DOCUMENTS_BY_TAGS:
            return {
                ...state,
                all_documents_by_tags: action.payload,
            };
        case ALL_ADMIN_DOCUMENTS_BY_ALTTAGS:
            return {
                ...state,
                all_documents_by_alttags: action.payload,
            };
        case ALL_ADMIN_KDPA_FORMS:
            return {
                ...state,
                all_kdpa_forms: action.payload,
            };
        case ALL_ADMIN_GDPA_FORMS:
            return {
                ...state,
                all_gdpa_forms: action.payload,
            };
        case ALL_ANNEXES:
            return {
                ...state,
                all_annexes: action.payload,
            };
        case ALL_ANNEX_CATEGORIES:
            return {
                ...state,
                all_annex_categories: action.payload,
            };
        case ALL_MANAGEMENT_CLAUSES:
            return {
                ...state,
                all_management_clauses: action.payload,
            };
        case ALL_ANNEXES_TWO:
            return {
                ...state,
                all_annexes_two: action.payload,
            };
        case ALL_ANNEXTWO_CATEGORIES:
            return {
                ...state,
                all_annextwo_categories: action.payload,
            };
        case ALL_MANAGEMENT_CLAUSES_TWO:
            return {
                ...state,
                all_management_clauses_two: action.payload,
            };
        case ALL_ANNEXES_NEW:
            return {
                ...state,
                all_annexes_new: action.payload,
            };
        case ALL_ANNEXNEW_CATEGORIES:
            return {
                ...state,
                all_annexnew_categories: action.payload,
            };
        case ALL_MANAGEMENT_CLAUSES_NEW:
            return {
                ...state,
                all_management_clauses_new: action.payload,
            };
        case ALL_CYBERSECURITIES:
            return {
                ...state,
                all_cybersecurities: action.payload,
            };
        case ALL_PROJECT_MANAGEMENT:
            return {
                ...state,
                all_project_management: action.payload,
            };
        case ALL_CLOUD_SECURITIES:
            return {
                ...state,
                all_cloud_securities: action.payload,
            };
        case ALL_CONTINUITY_CLAUSES:
            return {
                ...state,
                all_continuity_clauses: action.payload,
            };
        case ALL_ISO27001_DOCUMENTS:
            return {
                ...state,
                all_iso_documents: action.payload,
            };
        case ALL_ISO27001_2_DOCUMENTS:
            return {
                ...state,
                all_iso_2_documents: action.payload,
            };
        case ALL_ISONEW_DOCUMENTS:
            return {
                ...state,
                all_isonew_documents: action.payload,
            };
        case ALL_ISO22301_DOCUMENTS:
            return {
                ...state,
                all_continuity_iso_documents: action.payload,
            };
        case ALL_ADMIN_ISO_FORMS:
            return {
                ...state,
                all_iso_forms: action.payload,
            };
        case ALL_ADMIN_ISOTWO_FORMS:
            return {
                ...state,
                all_isotwo_forms: action.payload,
            };
        case ALL_ADMIN_ISONEW_FORMS:
            return {
                ...state,
                all_isonew_forms: action.payload,
            };
        case ALL_ADMIN_CONTINUITY_ISO_FORMS:
            return {
                ...state,
                all_continuity_iso_forms: action.payload,
            };
        case ALL_ONBOARDING_ITEMS:
            return {
                ...state,
                all_onboarding_items: action.payload,
            };
        case ALL_THREATS:
            return {
                ...state,
                all_threats: action.payload,
            };
        case ALL_THREATS_CATEGORIES:
            return {
                ...state,
                all_threat_categories: action.payload,
            };
        case ALL_RISK_CATEGORIES:
            return {
                ...state,
                all_risk_categories: action.payload,
            };
        case ALL_VULNERABILITIES:
            return {
                ...state,
                all_vulnerabilities: action.payload,
            };
        case ALL_CONTROLS:
            return {
                ...state,
                all_controls: action.payload,
            };
        case ALL_ADMIN_DOCUMENTS_BY_CONTROL:
            return {
                ...state,
                all_admin_documents_by_control: action.payload,
            };
        case ALL_ADMIN_DOCUMENTS_BY_MANAGEMENT:
            return {
                ...state,
                all_admin_documents_by_management: action.payload,
            };
        case ALL_ADMIN_EVIDENCE_BY_CONTROL:
            return {
                ...state,
                all_admin_evidences_by_control: action.payload,
            };
        case ALL_ADMIN_EVIDENCE_BY_MANAGEMENT:
            return {
                ...state,
                all_admin_evidence_by_management: action.payload,
            };
        case ALL_ADMIN_DOCUMENTS_BY_COMMON_CONTROL:
            return {
                ...state,
                all_admin_docs_by_common_control: action.payload,
            };
        case ALL_ADMIN_EVIDENCE_BY_COMMON_CONTROL:
            return {
                ...state,
                all_admin_evidence_by_common_control: action.payload,
            };
        case ALL_ADMIN_FORMS_BY_CONTROL:
            return {
                ...state,
                all_admin_forms_by_control: action.payload,
            };
        case ALL_ADMIN_FORMS_BY_MANAGEMENT:
            return {
                ...state,
                all_admin_forms_by_mgt: action.payload,
            };
        case ALL_SUPPORT_USERS:
            return {
                ...state,
                all_support_users: action.payload,
            };
        case ALL_RELEASES:
            return {
                ...state,
                all_releases: action.payload,
            };
        case ALL_DOCUMENTS_BY_REQUIREMENT_ID:
            return {
                ...state,
                all_documents_by_requirement_id: action.payload,
            };
        case ALL_DOCUMENTS_BY_CONTROL_ID:
            return {
                ...state,
                all_documents_by_control_id: action.payload,
            };
        case ALL_INTEGRATIONS_GROUPS:
            return {
                ...state,
                all_integrations_groups: action.payload,
            };
        case ALL_INTEGRATIONS_BY_GROUP:
            return {
                ...state,
                all_integrations_by_group: action.payload,
            };
        case INTEGRATION_BY_ID:
            return {
                ...state,
                integration_by_id: action.payload,
            };
        case ALL_ISOGROUPS:
            return {
                ...state,
                all_isogroups: action.payload,
            };
        case ALL_ISOGROUPSTWO:
            return {
                ...state,
                all_isogroupstwo: action.payload,
            };
        case ALL_ISOGROUPS_NEW:
            return {
                ...state,
                all_isogroups_new: action.payload,
            };
        case ALL_FAQS:
            return {
                ...state,
                all_faqs: action.payload,
            };
        case ALL_GLOSSARY: {
            return { ...state, all_glossaries: action.payload };
        }
        case ALL_TESTIMONIALS: {
            return { ...state, all_testimonials: action.payload };
        }
        case ALL_CRITERIA: {
            return { ...state, all_criteria: action.payload };
        }
        case DOCUMENTS_BY_SUBCRITERIA: {
            return { ...state, documents_by_subcriteria: action.payload };
        }
        case CONDITIONAL_BY_ID: {
            return { ...state, conditional_by_id: action.payload };
        }
        case ALL_SUBCRITERIA_DOCUMENT: {
            return { ...state, all_subcriteria_document: action.payload };
        }
        case ALL_SUBCRITERIA_FORMS: {
            return { ...state, all_subcriteria_forms: action.payload };
        }
        case MERCHANT_BY_ID: {
            return { ...state, merchant_by_id: action.payload };
        }
        case ALL_CONDITIONAL_ITEMS: {
            return { ...state, all_conditional_items: action.payload };
        }
        case ALL_TOOLS_ITEMS: {
            return { ...state, all_tools_items: action.payload };
        }
        case ALL_BACKGROUND_CHECK_TOOLS: {
            return { ...state, all_background_check_items: action.payload };
        }
        case ALL_USER_TOOLS_BY_ID: {
            return { ...state, tools_by_id: action.payload };
        }
        case CODEREVIEW_DOCUMENTS: {
            return { ...state, codereview_uploaded_documents: action.payload };
        }

        case ALL_PUSH_NOTIFICATIONS: {
            return { ...state, all_push_notifications: action.payload };
        }
        case ALL_USERS: {
            return {
                ...state,
                all_users: action.payload,
            };
        }
        case ALL_SURVEY: {
            return {
                ...state,
                all_survey: action.payload,
            };
        }
        case ALL_SURVEY_RESPONSE: {
            return {
                ...state,
                all_survey_response: action.payload,
            };
        }
        case ALL_PENTESTS: {
            return {
                ...state,
                all_pentests: action.payload,
            };
        }
        case ALL_EMPLOYEES: {
            return {
                ...state,
                all_employees: action.payload,
            };
        }
        case ADMIN_UPLOADED_DOCS: {
            return {
                ...state,
                all_admin_uploaded_docs: action.payload,
            };
        }
        case MERCHANT_EXTRA_DOCS: {
            return {
                ...state,
                merchants_extra_docs: action.payload,
            };
        }
        case ALL_SECTOR: {
            return {
                ...state,
                all_sector: action.payload,
            };
        }
        case ALL_CII_TOOLS_ITEMS: {
            return { ...state, all_cii_tools_items: action.payload };
        }
        case ALL_SUBSECTOR_DOCUMENT: {
            return { ...state, subsector_documents: action.payload };
        }
        case ALL_ADMIN_CII_FORMS: {
            return { ...state, admin_cii_forms: action.payload };
        }
        case ALL_CII_CONDITIONAL_ITEMS: {
            return { ...state, admin_cii_conditional_items: action.payload };
        }
        case ALL_USER_CII_TOOLS: {
            return { ...state, user_cii_tools: action.payload };
        }
        case ALL_NDPR_DOCUMENTS:
            return {
                ...state,
                all_ndpr_documents: action.payload,
            };
        case ALL_ADMIN_NDPR_FORMS:
            return {
                ...state,
                all_ndpr_forms: action.payload,
            };
        case ALL_ADMIN_EVIDENCE_BY_SUB_REQUIREMENT:
            return {
                ...state,
                all_sub_requirement_evidence: action.payload,
            };
        default:
            return state;
    }
};
