export function formatNumber(num) {
    if (num >= 1000000) {
        return (num / 1000000).toFixed(1).replace(/\.0$/, '') + 'M'; // Format in millions
    } else if (num >= 1000) {
        return (num / 1000).toFixed(1).replace(/\.0$/, '') + 'K'; // Format in thousands
    } else {
        return num.toString(); // Return the number as is for less than 1000
    }
}

export const intelKeywords = {
    Compliance: [
        'violation',
        'breach',
        'vulnerability',
        'vulnerabilities',
        'failure',
        'hack',
        'risk',
        'threat',
        'misconfiguration',
        'unauthorized access',
        'leak',
        'theft',
        'attackers',
        'zero-day',
        'exploit',
        'victim',
        'malicious',
        'attacks',
        'targeted',
        'insecure',
        'phishing',
        'credentials',
        'cross-site scripting',
        'SQL injection',
        'man-in-the-middle',
        'privilege escalation',
    ],
    'Third-Parties': [
        'theft',
        'attackers',
        'attacks',
        'social engineering',
        'data theft',
        'credential stuffing',
        'backdoor',
        'vendor',
        'backdoors',
        'targeted',
    ],
    Infrastructure: [
        'exposure',
        'attack',
        'outage',
        'virus',
        'exfiltration',
        'malware',
        'ddos',
        'theft',
        'attacks',
        'attackers',
        'injection',
        'csrf',
        'ransomware',
        'trojan',
        'worm',
        'botnet',
        'spyware',
        'windows',
        'os',
        'linux',
        'android',
        'macos',
        'server',
        'software',
        'Command Injection',
        'Remote Code Execution',
        'server-side template injection',
        'buffer overflow',
        'broken authentication',
    ],
};
