import React from 'react';
import CybersecurityPosture from './CybersecurityPosture';
import Frameworks from './Frameworks';
import SecurityScans from './SecurityScans';
import RiskDistribution from '../SecondSection/RiskDistribution';

const FirstSection = (props) => {
    const { openSuggestionsModal, switch_on, handleOpen, compliance_health_percentage, compliance_health_status } =
        props;
    return (
        <div className="grid 2xl:grid-cols-3 xl:grid-cols-2 md:grid-cols-2 gap-5 mt-[1rem]">
            <CybersecurityPosture
                openSuggestionsModal={openSuggestionsModal}
                compliance_health_percentage={compliance_health_percentage}
                compliance_health_status={compliance_health_status}
            />
            <Frameworks switch_on={switch_on} handleOpen={handleOpen} />
            <SecurityScans switch_on={switch_on} handleOpen={handleOpen} />
            <div className="2xl:hidden xl:block">
                <RiskDistribution switch_on={switch_on} handleOpen={handleOpen} />
            </div>
        </div>
    );
};

export default FirstSection;
