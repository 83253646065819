import React, { useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import {
    Box,
    // Collapse,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Typography,
    // Badge,
    Tooltip,
    Menu,
    Stack,
} from '@mui/material';
import { KeyboardArrowRightRounded } from '@mui/icons-material';

// core component
import { removeSpaces } from 'utils';
import { getType } from 'utils';

const SidebarListItemGroup = (props) => {
    const theme = useTheme();
    // props
    const { active, icon, text, sub_text, subMenus, merchant_info, disabled, handleClose_ } = props;
    const role = getType('role');

    // state
    const [anchorEl, setAnchorEl] = useState(null);

    // memos
    const selectedPaidStandard = useMemo(() => {
        let paidStandards = removeSpaces(merchant_info?.paid_standards)?.toLowerCase();
        let standards = removeSpaces(merchant_info?.standards)?.toLowerCase();
        return standards?.split(',')?.filter((standard) => paidStandards?.split(',')?.includes(standard));
    }, [merchant_info]);

    // functions
    function handleClick(event) {
        if (anchorEl !== event.currentTarget) {
            setAnchorEl(event.currentTarget);
        }
    }
    // .replace(' ', '')
    function handleClose() {
        setAnchorEl(null);
    }

    const filtered_subMenus =
        text === 'Framework' || text === 'Cadres'
            ? merchant_info?.merchant_plan?.tag === 'free'
                ? subMenus
                : subMenus.filter((sub) => selectedPaidStandard?.includes(sub.name.toLowerCase()))
            : subMenus;

    let extraStyles = {};

    // constants
    if (active) {
        extraStyles = {
            backgroundColor: theme.palette.primary.new,
            color: theme.palette.white.main,
            '& .listItem__icon, & path': {
                color: theme.palette.primary[900],
                fill: theme.palette.primary[900],
            },
            '&:hover': {
                backgroundColor: theme.palette.primary.new,
                color: theme.palette.primary[900],
            },
        };
    } else {
        extraStyles = {
            '& .listItem__icon, & path': {
                color: theme.palette.neutral[500],
                fill: theme.palette.neutral[500],
            },
            '&:hover': {
                backgroundColor: '#F8FAFC',
                color: theme.palette.white.main,
            },
        };
    }
    const auditor_subMenu = filtered_subMenus.filter((menu) => menu.name !== 'Vendors');
    return (
        <Box>
            <ListItemButton
                onClick={handleClick}
                sx={{
                    pl: 1,
                    pr: 0,
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    '&, & *': {
                        transitionProperty: 'color, background-color, border-bottom, fill',
                        transitionDuration: '0.45s',
                    },
                    '&:hover': {
                        backgroundColor: 'inherit',
                    },
                    ...extraStyles,
                }}
                disabled={disabled}
            >
                <Stack direction="row" alignItems="center">
                    <ListItemIcon
                        className="listItem__icon"
                        sx={{
                            minWidth: 30,
                            backgroundColor: '#F1F5F9',
                            height: '30px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginRight: 2,
                        }}
                    >
                        {icon}
                    </ListItemIcon>
                    {/* <Badge variant="dot" invisible={!isNew} color="error"> */}
                    <ListItemText
                        primary={text}
                        sx={{ '& span': { fontSize: '12px', fontWeight: 600, color: theme.palette.gray[700] } }}
                    >
                        <Typography
                            sx={{
                                fontSize: 12,
                                fontWeight: 600,
                                lineHeight: '20px',
                                color: theme.palette.gray[700],
                            }}
                        >
                            {text}
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: 12,
                                fontWeight: 600,
                                lineHeight: '20px',
                                color: theme.palette.gray[700],
                            }}
                        >
                            {text}
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: 11,
                                fontWeight: 400,
                                lineHeight: '20px',
                                color: theme.palette.gray[700],
                            }}
                        >
                            {sub_text}
                        </Typography>
                    </ListItemText>
                    {/* </Badge> */}
                </Stack>
                <KeyboardArrowRightRounded sx={{ color: theme.palette.white.main }} />
            </ListItemButton>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                sx={{ '& .MuiMenu-paper': { maxWidth: '250px', width: '100%' } }}
                // MenuListProps={{ onMouseLeave: handleClose }}
            >
                <List sx={{ py: 0 }}>
                    {(role !== 'auditor' ? filtered_subMenus : auditor_subMenu)?.map((subMenu) => {
                        return (
                            <SidebarListGroupItem
                                active={subMenu.name === active?.name}
                                parentActive={active}
                                primaryTitle={subMenu.name}
                                link={subMenu.layout + subMenu.path}
                                key={subMenu.name}
                                subMenus={subMenus}
                                disabled={disabled}
                                isNew={subMenu.isNew}
                                handleClose={handleClose_}
                            />
                        );
                    })}
                </List>
            </Menu>
        </Box>
    );
};

const SidebarListGroupItem = (props) => {
    const theme = useTheme();
    const history = useHistory();
    const { active, parentActive, primaryTitle, link, disabled, handleClose, isNew } = props;
    let extraStyles = {};

    if (parentActive) {
        if (active) {
            extraStyles = {
                '&, &:hover': {
                    backgroundColor: '#F8FAFC',
                    color: theme.palette.primary[900],
                },
            };
        } else {
            extraStyles = {
                '&, &:hover': {
                    color: theme.palette.primary[900],
                },
            };
        }
    } else {
        extraStyles = {
            '&, &:hover': {
                color: theme.palette.white.main,
            },
        };
    }
    return (
        <Tooltip
            title={
                primaryTitle?.toLowerCase() === 'kdpa'
                    ? 'Kenya Data Protection Act'
                    : primaryTitle?.toLowerCase() === 'gdpa'
                    ? 'Ghana Data Protection Act & Regulations'
                    : primaryTitle?.toLowerCase() === 'cii'
                    ? 'Cii & Risk Management Directives'
                    : primaryTitle?.toLowerCase() === 'iso27017+iso27032'
                    ? 'Cloud Security, Cybersecurity, and Project Management'
                    : ''
            }
            placement="right-start"
            arrow
        >
            <span>
                <ListItemButton
                    sx={{ pl: 1, py: 1, position: 'relative', ...extraStyles }}
                    // component={Link}
                    // to={link}
                    disabled={disabled}
                    onClick={() => {
                        history.push(link);
                        handleClose();
                    }}
                >
                    <ListItemText
                        sx={{
                            '& span': { fontSize: '13.5px', fontWeight: 500 },
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: 12,
                                fontWeight: 600,
                                lineHeight: '20px',
                                color: theme.palette.neutral[600],
                                width: 'fit-content',
                                position: 'relative',
                            }}
                        >
                            {primaryTitle === 'CII'
                                ? 'CII DIRECTIVE'
                                : primaryTitle === 'PCISLC'
                                ? 'PCI Secure SLC'
                                : primaryTitle}
                            {isNew ? (
                                <Typography
                                    sx={{
                                        textTransform: 'uppercase',
                                        position: 'absolute',
                                        right: '-32px',
                                        top: '-8px',
                                        fontSize: 10,
                                        px: 0.4,
                                        fontWeight: 500,
                                        borderRadius: '4px',
                                        background: theme.palette.error[500],
                                        // color: theme.palette.primary.white,
                                        color: '#fff',
                                    }}
                                >
                                    New
                                </Typography>
                            ) : null}
                        </Typography>
                    </ListItemText>
                </ListItemButton>
            </span>
        </Tooltip>
    );
};

export default SidebarListItemGroup;
