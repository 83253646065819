import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

const PentestArrowDropdown = ({ anchorEl, handleClose, openUploadPentestReportModal }) => {
    return (
        <div className="mr-[100px]">
            <Menu
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleClose}
                sx={{ mr: '100px' }}
                PaperProps={{
                    style: {
                        width: '179px',
                        display: 'flex',
                        flexDirection: 'column',
                        backgroundColor: 'white',
                        borderRadius: '2px',
                        boxSizing: 'border-box',
                    },
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
            >
                <MenuItem
                    style={{
                        height: '44px',
                        marginTop: '-10px',
                        marginBottom: '-10px',
                        paddingLeft: '16px',
                        color: '#64748B',
                        fontSize: '14px',
                        fontWeight: 500,
                        borderTop: '1px solid #F1F5F9',
                        borderLeft: '1px solid  #F1F5F9',
                        borderRight: '1px solid #F1F5F9',
                        borderBottom: '0.5px solid #F1F5F9',
                        boxSizing: 'border-box',
                    }}
                    onClick={() => {
                        openUploadPentestReportModal();
                        handleClose();
                    }}
                >
                    Upload Report
                </MenuItem>
            </Menu>
        </div>
    );
};

export default PentestArrowDropdown;
