import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { getUserToken, getType } from 'utils';

export function MerchantRoute({ children, render: Component, ...rest }) {
    const type = getType('type') === 'implementer' ? 'merchant' : getType('type');
    const userTypes = ['merchant', 'employee', 'support'];
    const auth = getUserToken('token');
    return (
        <Route
            {...rest}
            render={({ location }) =>
                auth && userTypes?.includes(type) ? (
                    <>
                        <Component {...children} {...rest} />
                    </>
                ) : (
                    <Redirect
                        to={{
                            pathname: '/auth/login',
                            state: {
                                from: location.hash,
                                error: 'You have to login as a merchant to access this page.',
                            },
                        }}
                    />
                )
            }
        />
    );
}

export function AuditorRoute({ children, render: Component, ...rest }) {
    let auth = getUserToken('token');
    const type = getType('type');
    const role = getType('role');
    return (
        <Route
            {...rest}
            render={({ location }) =>
                auth && (type === 'auditor' || role === 'auditor') ? (
                    <Component {...rest} {...children} />
                ) : (
                    <Redirect
                        to={{
                            pathname: '/auth/login',
                            state: {
                                from: location,
                                error: 'You have to login as an auditor to access this page.',
                            },
                        }}
                    />
                )
            }
        />
    );
}

export function AdminRoute({ children, render: Component, ...rest }) {
    let auth = getUserToken('token');
    // const auth = getUserToken();
    const type = getType('type');
    return (
        <Route
            {...rest}
            render={({ location }) =>
                auth && (type === 'admin' || type === 'aud_admin') ? (
                    <Component {...rest} {...children} />
                ) : (
                    <Redirect
                        to={{
                            pathname: '/auth/login',
                            state: {
                                from: location,
                                error: 'You have to login as a admin to access this page.',
                            },
                        }}
                    />
                )
            }
        />
    );
}
export function SupportRoute({ children, render: Component, ...rest }) {
    let auth = getUserToken('token');
    // const auth = getUserToken();
    const type = getType('type');
    return (
        <Route
            {...rest}
            render={({ location }) =>
                auth && type === 'support' ? (
                    <Component {...rest} {...children} />
                ) : (
                    <Redirect
                        to={{
                            pathname: '/auth/login',
                            state: {
                                from: location,
                                error: 'You have to login as a support to access this page.',
                            },
                        }}
                    />
                )
            }
        />
    );
}

export function ImplementerRoute({ children, render: Component, ...rest }) {
    let auth = getUserToken('token');
    const type = getType('type');
    return (
        <Route
            {...rest}
            render={({ location }) =>
                auth && type === 'implementer' ? (
                    <Component {...rest} {...children} />
                ) : (
                    <Redirect
                        to={{
                            pathname: '/auth/login',
                            state: {
                                from: location,
                                error: 'You have to login as an implementer to access this page.',
                            },
                        }}
                    />
                )
            }
        />
    );
}
