import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
//core components
import { Stack, Box } from '@mui/material';
import AppReportModal from 'components/new_components/AppReportModal';
import closeIcon from '../../../assets/img/closeReportModal.svg';
import bulb from '../../../assets/img/lightBulb.svg';
import AppLoadingButton from 'components/new_components/AppLoadingButton';

//redux
import { connect } from 'react-redux';
import { GetMerchantPentestReportById } from 'store/actions/merchantActions';
import AppSelectInput from 'components/new_components/AppSelectInput';
import { Input } from 'antd';

const AssignPentestModal = (props) => {
    //props
    const { open, handleClose, GetMerchantPentestReportById, report } = props;

    let setGetReportLoading;
    const getPentestReports = async (id) => {
        setGetReportLoading(true);
        const res = await GetMerchantPentestReportById(id);
        setGetReportLoading(false);
        if (!res?.success) {
            toast.error(res?.message);
        }
    };
    useEffect(() => {
        if (report) {
            getPentestReports(report);
        }
    }, [report]);
    return (
        <AppReportModal open={open} handleClose={handleClose} width={511} sx={{ height: '600px' }}>
            <div className="flex items-end justify-between h-[52px] mb-[24px] ml-[24px] mr-[14px]">
                <h1 className="text-[18px] font-semibold text-[#202D66]">Assign Pentest</h1>
                <div onClick={handleClose} className=" flex items-center justify-center">
                    <img src={closeIcon} alt="close icon" className="cursor-pointer" />
                </div>
            </div>

            <div className="ml-[24px] mr-[12px]">
                <AppSelectInput
                    options={[]}
                    label={'User'}
                    name="target_env"
                    placeholder="Select User"
                    onChange={''}
                    defaultValue={'Select User'}
                    value={''}
                    required
                />
                <div className="mt-[16px] h-[76px]">
                    <Input.TextArea
                        type="text"
                        size="large"
                        name="description"
                        id="description"
                        value={''}
                        required
                        className="h-[76px]"
                        placeholder="Comment"
                        style={{
                            color: '#94aeb8',
                            fontSize: '14px',
                            fontWeight: 400,
                            outline: 'none',
                            border: '1px solid #e2e8f0',
                            fontFamily: 'poppins',
                        }}
                    />
                </div>
            </div>

            <Stack
                flexDirection="row"
                justifyContent="flex-start"
                sx={{
                    mt: '16px',
                    py: '8px',
                    px: '16px',
                    fontSize: 13,
                    fontWeight: 400,
                    color: '#64748B',
                    background: '#F8FAFC',
                    borderRadius: '4px',
                    ml: '24px',
                    mr: '14px',
                    display: 'flex',
                    gap: '10px',
                    alignItems: 'flex-start',
                    border: '1px solid #F0F0FA',
                }}
            >
                <img src={bulb} alt="bulb" />
                <span className="text-[#64748b] text-[13px]">
                    You can view tasks you assigned to users on the activity page of the smartcomply application.
                </span>
            </Stack>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    overflowX: 'hidden',
                    alignItems: 'center',
                    gap: '8px',
                    background: '#f8fafc',
                    height: '84px',
                    paddingRight: '30px',
                    position: 'relative',
                    width: '511px',
                    mt: '24px',
                    ml: '-10px',
                    pr: '35px',
                }}
            >
                <button
                    onClick={handleClose}
                    className="flex items-center text-[14px] font-medium bg-[#fff] justify-center border-[1px] border-solid border-[#e2e8f0] rounded-[2px] w-[83px] h-[36px]"
                >
                    Cancel
                </button>
                <AppLoadingButton
                    text={'Assign'}
                    variant="contained"
                    color="primary"
                    type="submit"
                    // loading={loadingState}
                    loadingPosition="center"
                    sx={{ width: 'fit-content', boxShadow: 'none', height: '36px' }}
                />
            </Box>
        </AppReportModal>
    );
};

const mapStateToProps = (state) => {
    return {
        pentest_report_by_id: state?.merchantReducers?.pentest_report_by_id,
    };
};
export default connect(mapStateToProps, { GetMerchantPentestReportById })(AssignPentestModal);
