import * as types from '../constants';

const initialState = {
    id: 0,
    isActive: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case types.GO_TO_PREVIOUS_GUIDE:
            return {
                ...state,
                id: state.id - 1,
            };
        case types.GO_TO_NEXT_GUIDE:
            return {
                ...state,
                id: state.id + 1,
            };
        case types.TOGGLE_USERGUIDE:
            console.log(action.payload, !action.payload ? 0 : state.id);
            return {
                ...state,
                isActive: action.payload,
                id: 0,
            };
        // for testing purposes
        case 'SET_USERGUIDE_ID':
            return {
                ...state,
                isActive: true,
                id: action.payload,
            };
        default:
            return state;
    }
};
