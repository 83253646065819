import { ButtonGroup, Button } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Add } from '@mui/icons-material';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { useTheme } from '@mui/styles';
import AppColorTag from 'components/new_components/AppColorTag';
import { formatDateObjectHandler } from 'utils';
import PCIScansTableActions from './PCIScansTableActions';

//core components
import AppTable from 'components/new_components/app-table2';
import AppUploadCSVModal from 'components/new_components/AppUploadCSVModal';
import useSearch from 'hooks/useSearch';
import PCIDeleteModal from './PCIDeleteModal';
import PCIReportModal from './PCIReportModal';
// import PCIScansTableActions from './PCIScansTableActions';
import RequestModal from './RequestModal';
import RequestScanAction from './RequestScanAction';
import UploadReportModal from './UploadReportModal';
import PCIDetailsDrawer from './PCIDetails';
import more from '../../../assets/img/more.svg';
import ChangeSchedulingModal from './ChangeSchedulingModal';

//redux
import { connect, useSelector } from 'react-redux';
import { GetMerchantASVscanRequests, UploadASVScanCSV } from 'store/actions/merchantActions';

//utils
import PageHeader from 'components/new_components/PageHeader';
// import { formatDateObjectHandler } from 'utils';
import SubscriptionAlert from '../../../components/Pricing/SubscriptionAlert';
import { GetRemaining } from '../../../components/Pricing/usePricingHook';
import RerunScanModal from './RerunScanModal';
import { requestInitialValues, uploadReportInitialValues } from './utils/constant';

//context
import { SelectedMerchantContext } from 'store/context/SelectedMerchantContextProvider';

//userguide
import AppArticle from 'components/new_components/AppArticle';
import AppUserGuide from 'components/new_components/AppUserGuide';
import { UserGuideContext } from 'store/context/UserGuideContextProvider';
import { steps } from './steps';

//translations
import { useTranslation } from 'react-i18next';

const NO_PER_PAGE = 8;

const PCIScans = ({
    GetMerchantASVscanRequests,
    UploadASVScanCSV,
    merchant_asvscans_requests,
    pricing_plan,
    userguide_info,
    all_article_header,
}) => {
    const [openRequestAction, setOpenRequestAction] = useState(false);
    const [modalPayload, setModalPayload] = useState({});
    const [modalType, setModalType] = useState('');
    const [openPCIRequestModal, setopenPCIRequestModal] = useState(false);
    const [openUploadCSVModal, setOpenUploadCSVModal] = useState(false);
    const [openUploadReportModal, setOpenUploadReportModal] = useState(false);
    const [openDowloadReportModal, setOpenDownloadReportModal] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [deletePayloadID, setDeletePayloadID] = useState(0);
    const [report, setReport] = useState(0);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [pageData, setPageData] = useState([]);

    const [openUserGuide, setOpenUserGuide] = useState(false);
    const [openArticle, setOpenArticle] = useState(false);
    const [rerunModalOpen, setrerunModalOpen] = useState(false);
    const [detailsOpen, setDetailsOpen] = useState(false);
    const [scanId, setScanId] = useState(0);
    const [uploadModalPayload, setUploadModalPayload] = useState(uploadReportInitialValues);
    const [changeScheduleModal, setChangeScheduleModal] = useState(false);
    const [tableTab, setTableTab] = useState('requested');

    const stepsTargetRef = useRef([]);
    const { updateOneUserGuide, toggleUserGuide, setToggleUserGuide } = useContext(UserGuideContext);

    const theme = useTheme();

    //translation
    const { t } = useTranslation('pciscans');
    const [setAnchorEl] = useState(null);
    const [setSelectedRow] = useState(null);

    const handleClick = (event, row) => {
        setAnchorEl(event.currentTarget);
        setSelectedRow(row);
    };

    // hooks
    const { data, handleSearch } = useSearch(merchant_asvscans_requests, [
        'name',
        'ip_address',
        'uploaded_by',
        'status',
        'quater',
    ]);

    //accessing values from the redux store
    const user_type = useSelector((state) => state?.authReducers?.user_type);
    const role = useSelector((state) => state?.generalReducers?.user_info?.role);

    //context api for auditor's selected merchant
    const { activeMerchant } = useContext(SelectedMerchantContext);

    //useMemo
    const oneArticle = useMemo(
        () => all_article_header?.find((article) => article?.name === 'PCI Scan'),
        [all_article_header]
    );

    //functions

    const openChangeScheduleModal = (type, data, id) => {
        setModalPayload(data);
        setChangeScheduleModal(true);
        setModalType(type);
        setScanId(id);
    };
    const closeChangeScheduleModal = () => {
        setChangeScheduleModal(false);
    };
    const openRequestActionModal = (event) => {
        setOpenRequestAction(event.currentTarget);
    };
    const closeRequestActionModal = () => {
        setOpenRequestAction(null);
    };
    const openPCIScanRequestModal = (type, data, id) => {
        setModalType(type);
        setopenPCIRequestModal(true);
        setModalPayload(data);
        setScanId(id);
        setOpenRequestAction(null);
    };

    const closePCIScanRequestModal = () => {
        setopenPCIRequestModal(false);
        setTimeout(() => {
            setModalPayload({});
            setModalType('');
            setScanId(0);
        }, 1000);
    };

    const openPCIScanUploadCSVModal = () => {
        setOpenUploadCSVModal(true);
        setOpenRequestAction(null);
    };
    const closePCIScanUploadCSVModal = () => {
        setOpenUploadCSVModal(false);
    };
    const openUploadPCIReportModal = () => {
        setOpenUploadReportModal(true);
    };
    const closeUploadPCIReportModal = () => {
        setOpenUploadReportModal(false);
        setUploadModalPayload({});
    };

    const openDownloadPCIReportModal = (payload) => {
        setOpenDownloadReportModal(true);
        setReport(payload);
    };
    const closeDownloadPCIReportModal = () => {
        setOpenDownloadReportModal(false);
        setTimeout(() => setReport(''), 1000);
    };

    const openRerunModal = (data) => {
        setrerunModalOpen(true);
        setModalPayload(data);
    };

    const openViewDetails = (data, id) => {
        setDetailsOpen(true);
        setModalPayload(data);
        setScanId(id);
    };
    const CloseViewDetails = (data) => {
        setDetailsOpen(false);
        setModalPayload(data);
    };
    const closeRerunModal = () => {
        setrerunModalOpen(false);
        setTimeout(() => {
            setModalPayload({});
        }, 1000);
    };

    //UserGuide
    const OpenUserGuide = () => {
        setOpenUserGuide(true);
    };
    const CloseUserGuide = () => {
        updateOneUserGuide({ pci_scans: true });
        setOpenUserGuide(false);
    };
    const OpenArticle = () => {
        setOpenArticle(true);
    };
    const CloseArticle = () => {
        setToggleUserGuide({ ...toggleUserGuide, pciscans: false });
        setOpenArticle(false);
    };

    // Delete Modal
    const openDeleteModal = (id) => {
        setDeleteModalOpen(true);
        setDeletePayloadID(id);
    };
    const closeDeleteModal = () => {
        setDeleteModalOpen(false);
        setTimeout(() => setDeletePayloadID(''), 1000);
    };
    const handlePageChange = (page) => {
        setPage(page);
    };

    const currentMonthForPciscans = (currentDate) => {
        const date = new Date(currentDate);
        const month = date.getMonth() + 1;

        if (month >= 1 && month <= 3) {
            return 'January-March';
        } else if (month > 3 && month <= 6) {
            return 'April-June';
        } else if (month > 6 && month <= 9) {
            return 'July-September';
        } else if (month > 9 && month <= 12) {
            return 'October-December';
        } else {
            return null;
        }
    };

    const requestedColumns = [
        {
            title: t('table.column1'),
            render: (row) => (
                <p className="custom-table-row-width break-words text-[#64748B] text-[14px]">{row.name}</p>
            ),
        },
        // {
        //     title: t('table.column2'),
        //     key: 'server',
        //     render: (row) => <p className="custom-table-row-width break-words">{row?.ip_address}</p>,
        // },

        {
            title: t('table.column4'),
            key: 'status',
            render: (row) =>
                (!row?.status && !row?.uploaded_by) || row?.progress === 'running' || row?.progress === 'rerunning' ? (
                    <div className="border-[1px] border-solid border-[#E2E8F0] rounded-[4px] w-[31px] flex items-center justify-center">
                        --
                    </div>
                ) : (
                    <AppColorTag
                        sx={{ width: '8px', height: '8px' }}
                        text={row?.status ? t('table.success') : t('table.failed')}
                        type={row?.status ? 'success' : 'error'}
                        margin="0"
                        fontWeight={500}
                    />
                ),
        },
        {
            title: t('table.column3'),
            key: 'progress',
            render: (row) => (
                <>
                    <p
                        type={row?.progress?.toLowerCase() === 'completed' ? 'success' : 'default'}
                        style={{
                            color: row?.progress === 'completed' ? '#37A372' : '#64748B',
                            fontStyle: row?.progress === 'completed' ? 'normal' : 'italic',
                        }}
                        className="custom-table-row-width break-words text-[13px]"
                    >
                        {row?.progress.charAt(0).toUpperCase() + row?.progress.slice(1)}
                    </p>
                </>
            ),
        },

        // {
        //     title: t('table.column5'),
        //     key: 'scanned_by',
        //     render: (row) => (
        //         <>
        //             {row?.uploaded_by}
        //             <Typography sx={{ color: theme.palette.primary.main, fontSize: 10, fontWeight: 500 }}>
        //                 {row?.uploaded_by?.toLowerCase() !== 'clone systems'
        //                     ? t('table.uploaded')
        //                     : t('table.requested')}
        //             </Typography>
        //         </>
        //     ),
        // },
        {
            title: t('table.column6'),
            key: 'date',
            render: (row) =>
                (!row?.status && !row?.uploaded_by) || row?.progress === 'running' || row?.progress === 'rerunning' ? (
                    <div>--</div>
                ) : (
                    <div className="custom-table-row-width break-words text-[#64748B] flex flex-col items-start">
                        <span className="text-[14px]">
                            {formatDateObjectHandler(row?.date_created, 'MMMM Do, YYYY') || 'Nil'}
                        </span>
                        <span className="text-[13px]">({currentMonthForPciscans(row?.date_created)})</span>
                    </div>
                ),
        },
        {
            title: (
                <>
                    {t('table.column7')}
                    {/* <FormTooltip placement="top-end" text={t('table.column7Tip')} /> */}
                </>
            ),
            key: 'frequency',
            render: (row) => {
                return row?.uploaded_by?.toLowerCase() === 'clone systems' ? (
                    <p className="custom-table-row-width break-words text-[#64748B] font-medium text-[12px] h-[26px] w-[21px] border-[1px] border-solid border-[#E2E8F0] rounded-[4px] flex items-center justify-center">
                        {row?.frequency}
                    </p>
                ) : (
                    // <Box sx={{ height: '6px', width: '80px', background: ' #D9D9D9', borderRadius: '8px' }}></Box>
                    <p className="custom-table-row-width break-words text-[#64748B] font-medium text-[12px] h-[26px] w-[21px] border-[1px] border-solid border-[#E2E8F0] rounded-[4px] flex items-center justify-center">
                        -
                    </p>
                );
            },
        },
        {
            title: (
                <img
                    onClick={handleClick}
                    src={more}
                    className="cursor-pointer text-[#64748B] text-[14px] font-semibold"
                    alt="More"
                />
            ),
            align: 'center',
            key: 'action',
            render: (row) => (
                <>
                    <PCIScansTableActions
                        row={row}
                        tableTab={tableTab}
                        openDeleteModal={openDeleteModal}
                        openDownloadPCIReportModal={openDownloadPCIReportModal}
                        openChangeScheduleModal={openPCIScanRequestModal}
                        openRerunModal={openRerunModal}
                        openViewDetails={openViewDetails}
                        openOnlySchedulingModal={openChangeScheduleModal}
                    />
                </>
            ),
        },
    ];
    const uploadedColumns = [
        {
            title: t('table.column1'),
            render: (row) => (
                <p className="custom-table-row-width break-words text-[#64748B] text-[14px]">{row.name}</p>
            ),
        },

        {
            title: t('table.column4'),
            key: 'status',
            render: (row) =>
                (!row?.status && !row?.uploaded_by) || row?.progress === 'running' || row?.progress === 'rerunning' ? (
                    <div className="border-[1px] border-solid border-[#E2E8F0] rounded-[4px] w-[31px] flex items-center justify-center">
                        --
                    </div>
                ) : (
                    <AppColorTag
                        sx={{ width: '8px', height: '8px' }}
                        text={row?.status ? t('table.success') : t('table.failed')}
                        type={row?.status ? 'success' : 'error'}
                        margin="0"
                        fontWeight={500}
                    />
                ),
        },
        {
            title: t('table.column3'),
            key: 'progress',
            render: (row) => (
                <>
                    <p
                        type={row?.progress?.toLowerCase() === 'completed' ? 'success' : 'default'}
                        style={{
                            color: row?.progress === 'completed' ? '#37A372' : '#64748B',
                            fontStyle: row?.progress === 'completed' ? 'normal' : 'italic',
                            background: row?.progress === 'completed' ? '#F4FFF5' : null,
                        }}
                        className={`flex items-center justify-center w-[84px] custom-table-row-width break-words text-[12px] h-[26px] rounded-[4px] ${
                            row?.progress === 'completed' ? 'border-[1px] border-solid border-[#37A372]' : null
                        }`}
                    >
                        {row?.progress.charAt(0).toUpperCase() + row?.progress.slice(1)}
                    </p>
                </>
            ),
        },

        {
            title: t('table.column6'),
            key: 'date',
            render: (row) =>
                (!row?.status && !row?.uploaded_by) || row?.progress === 'running' || row?.progress === 'rerunning' ? (
                    <div>--</div>
                ) : (
                    <div className="custom-table-row-width break-words text-[#64748B] flex flex-col items-start">
                        <span className="text-[14px]">
                            {formatDateObjectHandler(row?.date_created, 'MMMM Do, YYYY') || 'Nil'}
                        </span>
                    </div>
                ),
        },
        {
            title: (
                <>
                    {t('table.column7')}
                    {/* <FormTooltip placement="top-end" text={t('table.column7Tip')} /> */}
                </>
            ),
            key: 'frequency',
            render: (row) => {
                return row?.uploaded_by?.toLowerCase() === 'clone systems' ? (
                    <p className="custom-table-row-width break-words text-[#64748B] font-medium text-[12px] h-[26px] w-[21px] border-[1px] border-solid border-[#E2E8F0] rounded-[4px] flex items-center justify-center">
                        {row?.frequency}
                    </p>
                ) : (
                    // <Box sx={{ height: '6px', width: '80px', background: ' #D9D9D9', borderRadius: '8px' }}></Box>
                    <p className="custom-table-row-width break-words text-[#64748B] font-medium text-[12px] h-[26px] w-[21px] border-[1px] border-solid border-[#E2E8F0] rounded-[4px] flex items-center justify-center">
                        -
                    </p>
                );
            },
        },
        {
            title: (
                <img
                    onClick={handleClick}
                    src={more}
                    className="cursor-pointer text-[#64748B] text-[14px] font-semibold"
                    alt="More"
                />
            ),
            align: 'center',
            key: 'action',
            render: (row) => (
                <>
                    <PCIScansTableActions
                        row={row}
                        tableTab={tableTab}
                        openDeleteModal={openDeleteModal}
                        openDownloadPCIReportModal={openDownloadPCIReportModal}
                        openChangeScheduleModal={openPCIScanRequestModal}
                        openRerunModal={openRerunModal}
                        openViewDetails={openViewDetails}
                        openOnlySchedulingModal={openChangeScheduleModal}
                    />
                </>
            ),
        },
    ];

    // useEffects
    useEffect(() => {
        const getMerchantASVscanRequests = async () => {
            setLoading(true);
            const res = await GetMerchantASVscanRequests(activeMerchant);
            setLoading(false);
            if (!res?.success) {
                toast.error(res?.message);
            }
        };
        getMerchantASVscanRequests();
    }, [activeMerchant]);

    useEffect(() => {
        const start = (page - 1) * NO_PER_PAGE;
        const stop = start + NO_PER_PAGE;

        setPageData(data?.length > 0 ? data?.sort((a, b) => b?.id - a?.id)?.slice(start, stop) : []);
        return data;
    }, [page, data]);

    useEffect(() => {
        !userguide_info ? OpenUserGuide() : toggleUserGuide.pciscans ? OpenArticle() : null;
    }, [userguide_info, toggleUserGuide.pciscans]);

    const { reminderCount, showReminder } = GetRemaining(pricing_plan?.pci_scan, merchant_asvscans_requests?.length);

    return (
        <>
            <PageHeader browserTitle="PCI Scans | Smartcomply" />
            {showReminder && (
                <SubscriptionAlert isDashboardPage={false} limitedNumber={`${reminderCount} IP Servers`} />
            )}

            <AppTable
                title={t('title')}
                columns={tableTab === 'requested' ? requestedColumns : uploadedColumns}
                tabs={true}
                toggleTabs={setTableTab}
                tabState={tableTab}
                data={pageData}
                sorter={(a, b) => b?.id - a?.id}
                loading={loading}
                dataLength={data?.length}
                noPerPage={NO_PER_PAGE}
                page={page}
                onPageChange={handlePageChange}
                actions={
                    user_type === 'auditor' || role === 'auditor'
                        ? []
                        : [
                              <ButtonGroup
                                  style={{ marginTop: '45px', marginRight: '-7px' }}
                                  variant="contained"
                                  aria-label="Basic button group"
                              >
                                  <Button
                                      startIcon={<Add />}
                                      onClick={() => openPCIScanRequestModal('request', requestInitialValues)}
                                      color="primary"
                                      variant="contained"
                                      disableElevation
                                      key={1}
                                      ref={(el) => (stepsTargetRef.current[0] = el)}
                                      disabled={pricing_plan?.pci_scan === merchant_asvscans_requests?.length}
                                      sx={{
                                          display: 'flex-inline',
                                          alignItems: 'center',
                                          justifyContent: 'center',
                                          fontWeight: '600',
                                          // borderRadius: '4px',
                                          py: 1.2,
                                          px: 2.4,
                                          textTransform: 'unset',
                                          '&.Mui-disabled': {
                                              color: theme.palette.white.main,
                                              backgroundColor: theme.palette.gray[600],
                                          },
                                      }}
                                  >
                                      {t('requestScan')}
                                  </Button>
                                  <Button
                                      color="primary"
                                      variant="contained"
                                      sx={{ borderLeft: '1px solid #5474C4', py: 1.2 }}
                                      onClick={openRequestActionModal}
                                  >
                                      <KeyboardArrowDownIcon />
                                  </Button>
                              </ButtonGroup>,

                              //   <AppButton
                              //       icon={<Upload />}
                              //       name={t('uploadReport')}
                              //       color="primary"
                              //       variant="outlined"
                              //       disabled={pricing_plan?.pci_scan === merchant_asvscans_requests?.length}
                              //       disableElevation
                              //       onClick={openUploadPCIReportModal}
                              //       key={2}
                              //       ref={(el) => (stepsTargetRef.current[1] = el)}
                              //   />,
                          ]
                }
                search={true}
                onSearch={handleSearch}
            />

            <RequestScanAction
                open={openRequestAction}
                handleClose={closeRequestActionModal}
                handleOpenRequestModal={() => openPCIScanRequestModal('request', requestInitialValues)}
                handleOpenUploadCSVModal={openPCIScanUploadCSVModal}
                className="requestScan"
                openUploadPCIReportModal={openUploadPCIReportModal}
            />

            <RequestModal
                open={openPCIRequestModal}
                handleClose={closePCIScanRequestModal}
                payload={modalPayload}
                type={modalType}
                id={scanId}
            />
            <ChangeSchedulingModal
                open={changeScheduleModal}
                payload={modalPayload}
                handleClose={closeChangeScheduleModal}
                id={scanId}
            />
            <AppUploadCSVModal
                open={openUploadCSVModal}
                handleClose={closePCIScanUploadCSVModal}
                title={t('uploadCSVModal.title')}
                subheader={t('uploadCSVModal.subtitle')}
                templateLink="https://smartcomply-prod.s3.us-west-2.amazonaws.com/asv_upload_template.xlsx"
                uploadCSVAction={UploadASVScanCSV}
            />
            <UploadReportModal
                open={openUploadReportModal}
                handleClose={closeUploadPCIReportModal}
                title={t('uploadReport')}
                payload={uploadModalPayload}
            />

            <PCIReportModal
                open={openDowloadReportModal}
                handleClose={closeDownloadPCIReportModal}
                title={t('pciReportModal.title')}
                subtitle={
                    <span>
                        <strong>{t('pciReportModal.subtitle1')}</strong> {t('pciReportModal.subtitle2')}{' '}
                        <strong>{t('pciReportModal.subtitle3')}</strong> {t('pciReportModal.subtitle4')}
                    </span>
                }
                report={report}
                className="uploadReport"
            />

            <PCIDeleteModal open={deleteModalOpen} handleClose={closeDeleteModal} id={deletePayloadID} />

            <RerunScanModal
                open={rerunModalOpen}
                handleClose={closeRerunModal}
                title={t('rerunScanModal.title')}
                subtitle={t('rerunScanModal.subtitle')}
                payload={modalPayload}
            />
            <PCIDetailsDrawer
                handleOpenViewDetails={openViewDetails}
                open={detailsOpen}
                handleClose={CloseViewDetails}
                payload={modalPayload}
                detail={modalPayload}
            />

            <AppUserGuide
                open={openUserGuide}
                onClose={CloseUserGuide}
                steps={steps}
                targets={stepsTargetRef.current}
            />
            <AppArticle
                open={openArticle}
                handleClose={CloseArticle}
                title="PCI Scans"
                headerId={oneArticle?.id}
                icon={true}
            />
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        merchant_asvscans_requests: state?.merchantReducers?.merchant_asvscans_requests,
        pricing_plan: state?.authReducers.pricing_plan,
        userguide_info: state?.guideReducers?.userguide_info?.[0]?.pci_scans,
        // companyName: state?.generalReducers?.user_info?.organization?.name,
        all_article_header: state?.articlesReducers?.all_article_header,
    };
};

export default connect(mapStateToProps, {
    GetMerchantASVscanRequests,
    UploadASVScanCSV,
})(PCIScans);
