import React, { useState, useRef, useEffect } from 'react';
import TrapFocus from '@mui/material/Unstable_TrapFocus';
import { Box, Typography, Fade, Stack, Paper, Button, Skeleton } from '@mui/material';
// import { Close } from '@mui/icons-material';
import { useTheme } from '@mui/styles';
import { BsSend } from 'react-icons/bs';
// import { AVAILABLE_PROMPTS } from './util';
import { connect } from 'react-redux';
import { GetAIAnswersToPrompt } from 'store/actions/dashboardActions';
import Typewriter from 'typewriter-effect';
import Vifly from 'assets/img/vifly_.svg';
import CloseCircleIcon from 'assets/img/vifly/close-circle.svg';
import SmallVifly from 'assets/img/vifly/small_vifly.svg';
import moment from 'moment';
import { AVAILABLE_PROMPTS } from './util';

const SmartDashboardAI = (props) => {
    const typewriterRef = useRef(null);

    const { open, handleClose, GetAIAnswersToPrompt } = props;
    const [userInput, setUserInput] = useState('');
    const [prompt, setPrompt] = useState(null);
    const [conversation, setConversation] = useState([]);
    const [fetching, setFetching] = useState(false);
    const theme = useTheme();

    //handle Saving of Prompts
    const handlePromptSave = (message) => {
        setConversation([
            ...conversation,
            {
                id: conversation?.length + 1,
                text: message,
                type: 'prompt',
                time: moment(new Date()).format('hh:mm:a'),
            },
        ]);

        if (userInput) {
            setUserInput('');
        }
    };

    //handle input change
    const handleUserInput = (e) => {
        setUserInput(e.target.value);
    };
    //close and clear all prompts and inputs state
    const onCloseAll = () => {
        handleClose();
        setUserInput('');
        setPrompt('');
        setConversation([]);
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            // 👇 Get input value
            handlePromptSave(userInput);
        }
    };

    const scrollIntoView = () => {
        if (typewriterRef?.current) {
            typewriterRef.current?.scrollIntoView({ behavior: 'smooth', block: 'end' });
        }
    };

    //async action
    const getAIAnswersToPrompt = async (payload) => {
        setFetching(true);
        setConversation([...conversation, { id: conversation?.length + 1, text: 'loading', type: 'response' }]);
        const res = await GetAIAnswersToPrompt(payload);
        setFetching(false);
        if (res?.success) {
            setConversation([
                ...conversation,
                {
                    id: conversation?.length + 1,
                    text: res?.data?.[0],
                    type: 'response',
                    time: moment(new Date()).format('hh:mm:a'),
                },
            ])?.filter((item) => item?.text !== 'loading');
        }
    };

    useEffect(() => {
        if (conversation?.[conversation.length - 1]?.type === 'prompt') {
            // as long after prompt is saved, make request; don't watch for change in user prompt
            getAIAnswersToPrompt({ question: conversation?.[conversation.length - 1]?.text });
        }

        // scrolls into view if is response and is the last convo item
        if (
            conversation?.[conversation.length - 1]?.type === 'response' &&
            conversation?.[conversation.length - 1].id === conversation.length
        ) {
            scrollIntoView();
        }
    }, [conversation]);

    const replaceWithLinkeBreakers = (text) => text?.replaceAll(/\n/g, '<br>') ?? null;
    useEffect(() => {
        if (open) {
            setPrompt({
                text: 'Hi 👋 How may i be of help?',
                time: moment(new Date()).format('hh:mm:a'),
            });
        }
    }, [open]);

    return (
        <TrapFocus open disableAutoFocus disableEnforceFocus>
            <Fade appear={true} in={open}>
                <Paper
                    role="dialog"
                    aria-modal="false"
                    aria-label="Cookie banner"
                    square
                    variant="outlined"
                    tabIndex={-1}
                    sx={{
                        position: 'fixed',
                        bottom: '7%',
                        right: '3%',
                        m: 0,
                        width: '454px',
                        maxHeight: '75vh',
                        height: '100%',
                        minHeight: '300px',
                        borderRadius: '8px',
                    }}
                >
                    <div className="flex flex-column h-full">
                        <div>
                            <Box
                                sx={{
                                    pb: 3,
                                    p: 2,
                                    borderTopLeftRadius: '8px',
                                    borderTopRightRadius: '8px',
                                    height: '80px',
                                }}
                                className="bg-white shadow-sm"
                            >
                                <Stack direction="row" alignItems="center" justifyContent="space-between">
                                    <Stack direction="row" alignItems="center" gap={1}>
                                        <p className="text-[#202D66] text-lg font-semibold">Talk to</p>
                                        <div className="flex items-center">
                                            <img src={Vifly} alt="Vifly" />
                                            <p className="text-[#395BA9] text-base font-semibold">ifly</p>
                                        </div>
                                    </Stack>

                                    <Box>
                                        <div onClick={onCloseAll} className="cursor-pointer">
                                            <img src={CloseCircleIcon} alt="CloseCircleIcon" />
                                        </div>
                                    </Box>
                                </Stack>
                                <p className="text-[#94A3B8] text-xs font-normal">
                                    AI may produce inaccurate information
                                </p>
                            </Box>
                        </div>
                        <Box
                            sx={{
                                flexGrow: 1,
                                overflowY: 'auto',
                                px: 1.5,
                                pt: 3,
                                display: 'flex',
                                flexDirection: 'column',
                                '&::-webkit-scrollbar': {
                                    width: '8px',
                                },
                                '&::-webkit-scrollbar-track': {
                                    backgroundColor: 'transparent',
                                },
                                '&::-webkit-scrollbar-thumb': {
                                    backgroundColor: theme.palette.primary[900] + '30',
                                    borderRadius: '10px',
                                },
                            }}
                        >
                            <div className="pb-4">
                                <div className="flex items-center gap-1">
                                    <img src={SmallVifly} alt="Dashboard AI icon" />
                                    <p className="text-[#475569] text-[13px] font-medium">Vifly</p>
                                </div>
                                {open && prompt && (
                                    <div>
                                        <Box
                                            sx={{
                                                backgroundColor: '#F1F5F9',
                                                mt: 0.5,
                                                px: 2,
                                                borderRadius: '0px 24px 24px 24px',
                                                width: 'fit-content',
                                                height: '45px',
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: 2,
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    p: 1,
                                                    fontSize: 12,
                                                    fontWeight: 400,
                                                    color: '#334155',
                                                }}
                                            >
                                                <Typewriter
                                                    onInit={(typewriter) => {
                                                        typewriter
                                                            .typeString(replaceWithLinkeBreakers(prompt?.text))
                                                            .callFunction(() => {
                                                                console.log('String typed out!');
                                                            })
                                                            .changeDelay(10)
                                                            .start();
                                                    }}
                                                    options={{
                                                        loop: false,
                                                        delay: '10',
                                                        deleteSpeed: 0,
                                                    }}
                                                />
                                            </Typography>
                                        </Box>
                                        <Stack
                                            direction="row"
                                            flexWrap="wrap"
                                            columnGap={0.7}
                                            rowGap={0.7}
                                            sx={{ pt: 1.5 }}
                                        >
                                            {AVAILABLE_PROMPTS?.map((prompt, index) => (
                                                <Button
                                                    variant="outlined"
                                                    sx={{
                                                        minWidth: 'unset',
                                                        py: 0.4,
                                                        px: 0.8,
                                                        fontSize: 13,
                                                        fontWeight: 400,
                                                        textTransform: 'capitalize',
                                                        whiteSpace: 'nowrap',
                                                        color: '#5474C4',
                                                        border: `1px solid #5474C4`,
                                                        borderRadius: 30,
                                                        mb: 0.5,
                                                        '&:hover': {
                                                            border: `1px solid #5474C4`,
                                                        },
                                                    }}
                                                    disabled={fetching}
                                                    key={index}
                                                    onClick={() => handlePromptSave(prompt)}
                                                >
                                                    {prompt}
                                                </Button>
                                            ))}
                                        </Stack>
                                        <p className="text-[#94A3B8] text-xs font-normal uppercase mt-">
                                            {prompt?.time}
                                        </p>
                                    </div>
                                )}
                            </div>

                            <Box
                                sx={{
                                    display: 'grid',
                                    flexDirection: 'column',
                                    '&::-webkit-scrollbar': {
                                        width: '8px',
                                    },
                                    '&::-webkit-scrollbar-track': {
                                        backgroundColor: 'transparent',
                                    },
                                    '&::-webkit-scrollbar-thumb': {
                                        backgroundColor: theme.palette.primary[900] + '30',
                                        borderRadius: '10px',
                                    },
                                }}
                            >
                                {conversation?.map((convo, index) => {
                                    return (
                                        <div
                                            // direction="row"
                                            // alignItems="center"
                                            // sx={{
                                            //     justifySelf: convo.type === 'prompt' ? 'flex-end' : 'flex-start',
                                            //     flexDirection: 'column',
                                            //     position: 'relative',
                                            // }}
                                            className={`${
                                                convo.type === 'prompt' ? 'justify-self-end' : 'justify-self-start'
                                            }`}
                                            ref={
                                                convo.type === 'response' && index === conversation.length - 1
                                                    ? typewriterRef
                                                    : null
                                            }
                                        >
                                            <div className="mb-1">
                                                {convo.type === 'prompt' ? (
                                                    <p className="text-[#475569] text-[13px] font-medium text-right">
                                                        You
                                                    </p>
                                                ) : (
                                                    <div className="flex items-center gap-1">
                                                        <img src={SmallVifly} alt="Dashboard AI icon" />
                                                        <p className="text-[#475569] text-[13px] font-medium">Vifly</p>
                                                    </div>
                                                )}
                                            </div>
                                            {convo?.text === 'loading' ? (
                                                <Box
                                                    sx={{
                                                        backgroundColor: '#F1F5F9',
                                                        mb: 1.5,
                                                        px: 2,
                                                        borderRadius: '0px 24px 24px 24px',
                                                        width: 'fit-content',
                                                        height: '45px',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        gap: 2,
                                                    }}
                                                >
                                                    <Skeleton
                                                        animation="wave"
                                                        variant="circular"
                                                        width={10}
                                                        height={10}
                                                    />
                                                    <Skeleton
                                                        animation="wave"
                                                        variant="circular"
                                                        width={10}
                                                        height={10}
                                                    />
                                                    <Skeleton
                                                        animation="wave"
                                                        variant="circular"
                                                        width={10}
                                                        height={10}
                                                    />
                                                </Box>
                                            ) : (
                                                <Box
                                                    sx={{
                                                        backgroundColor:
                                                            convo.type === 'prompt'
                                                                ? theme.palette.primary[40]
                                                                : '#F1F5F9',
                                                        mb: 1,
                                                        borderRadius:
                                                            convo.type === 'prompt'
                                                                ? '24px 0px 24px 24px'
                                                                : '0px 24px 24px 24px',
                                                        width: 'fit-content',
                                                        minHeight: '33px',
                                                        height: 'fit-content',
                                                    }}
                                                >
                                                    <Typography
                                                        sx={{
                                                            p: 1,
                                                            fontSize: 12,
                                                            fontWeight: 400,
                                                            color:
                                                                convo.type === 'prompt'
                                                                    ? theme.palette.white.main
                                                                    : '#334155',
                                                        }}
                                                        key={index}
                                                    >
                                                        {convo.type !== 'prompt' ? (
                                                            <Typewriter
                                                                onInit={(typewriter) => {
                                                                    typewriter
                                                                        .typeString(
                                                                            replaceWithLinkeBreakers(convo?.text)
                                                                        )
                                                                        .callFunction(() => {
                                                                            console.log('String typed out!');
                                                                        })
                                                                        .changeDelay(10)
                                                                        .start();
                                                                }}
                                                                options={{
                                                                    loop: false,
                                                                    delay: '10',
                                                                    deleteSpeed: 0,
                                                                }}
                                                            />
                                                        ) : (
                                                            convo.text
                                                        )}
                                                    </Typography>
                                                </Box>
                                            )}
                                            <p className="text-[#94A3B8] text-xs font-normal uppercase">
                                                {convo?.time}
                                            </p>
                                        </div>
                                    );
                                })}
                            </Box>
                        </Box>
                        <Stack
                            direction="row"
                            sx={{
                                alignSelf: 'end',
                                p: 1.5,
                                border: `1px solid #F1F5F9`,
                                backgroundColor: '#F8FAFC',
                                width: '100%',
                            }}
                            gap={2}
                        >
                            <input
                                placeholder="Type your message..."
                                value={userInput}
                                className="basis-[90%] text-[13px] outline-none placeholder:text-[#64748B] placeholder:text-[14px] placeholder:font-normal bg-[#F8FAFC]"
                                onChange={handleUserInput}
                                onKeyDown={handleKeyDown}
                            />
                            <Button
                                variant="contained"
                                sx={{
                                    minWidth: 'unset',
                                    p: 1.5,
                                    basis: '10%',
                                    backgroundColor: '#395BA9',
                                    borderRadius: 30,
                                }}
                                onClick={() => handlePromptSave(userInput)}
                                disabled={fetching}
                            >
                                <BsSend size={20} />
                            </Button>
                        </Stack>
                    </div>
                    {/* </Box> */}
                </Paper>
            </Fade>
        </TrapFocus>
    );
};

const mapStateToProps = (state) => {
    return {
        ...state,
    };
};
export default connect(mapStateToProps, { GetAIAnswersToPrompt })(SmartDashboardAI);
