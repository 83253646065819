import React from 'react';
// import Assets from './Assets';
import Vendors from './Vendors';

const ThirdSection = (props) => {
    const { switch_on, handleOpen } = props;
    return (
        <div className="grid grid-cols-2 gap-10 mt-[1.5rem]">
            {/* <Assets switch_on={switch_on} handleOpen={handleOpen} /> */}
            <Vendors switch_on={switch_on} handleOpen={handleOpen} />
        </div>
    );
};

export default ThirdSection;
