import React from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';

const InventoryScansTable = ({ inventory_options }) => {
    const { inventory_scan_summary, vul_scan_summary } = useSelector((state) => state?.CISOReducers);
    return (
        <div className="mt-[5rem]">
            <div
                className=""
                hidden={!inventory_options?.includes('Vulnerability scans') || vul_scan_summary?.length === 0}
            >
                <div className="border-2 border-black bg-[#6E8EDF] flex justify-center items-center p-3">
                    <p className="text-white text-sm font-semibold">Vulnerability Scan</p>
                </div>
                <table className="w-full">
                    <thead className="w-full">
                        <tr>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border-2 border-black">
                                Request Name
                            </th>
                            <th className="text-[#64748B] text-[13px]  font-semibold p-2 text-center border-2 border-black">
                                IP address
                            </th>
                            <th className="text-[#64748B] text-[13px]  font-semibold p-2 text-center border-2 border-black">
                                Status
                            </th>
                        </tr>
                    </thead>
                    {vul_scan_summary?.map((option, index) => (
                        <tbody key={index}>
                            <tr>
                                <th className="text-gray-500 text-xs font-normal p-3 text-center border-2 border-black">
                                    {option?.request_name || '/N/A'}
                                </th>
                                <th className="text-gray-500 text-xs font-normal p-3 text-center border-2 border-black">
                                    {option?.public_ip}
                                </th>
                                <th
                                    scope="row"
                                    className={`${
                                        option?.status === 'open' ? 'text-[#55BE8B]' : 'text-[#FF5449]'
                                    } text-xs font-normal p-3 text-center border-2 border-black`}
                                >
                                    {option?.status || 'N/A'}
                                </th>
                            </tr>
                        </tbody>
                    ))}
                </table>
            </div>
            <div
                hidden={
                    !inventory_options?.includes('Penetration testing') ||
                    inventory_scan_summary?.pentest_info?.length === 0
                }
            >
                <div className="border-2 border-black bg-[#6E8EDF] flex justify-center items-center p-3">
                    <p className="text-white text-sm font-semibold">Penetration Testing</p>
                </div>
                <table className="w-full">
                    <thead className="w-full">
                        <tr>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border-2 border-black">
                                Test Name
                            </th>
                            <th className="text-[#64748B] text-[13px]  font-semibold p-2 text-center border-2 border-black">
                                Progress
                            </th>
                            <th className="text-[#64748B] text-[13px]  font-semibold p-2 text-center border-2 border-black">
                                Severity Level
                            </th>
                            <th className="text-[#64748B] text-[13px]  font-semibold p-2 text-center border-2 border-black">
                                Date
                            </th>
                        </tr>
                    </thead>
                    {inventory_scan_summary?.pentest_info?.map((option, index) => (
                        <tbody key={index}>
                            <tr>
                                <th className="text-gray-500 text-xs font-normal p-3 text-center border-2 border-black">
                                    {option?.name}
                                </th>

                                <th className="text-gray-500 text-xs font-normal p-3 text-center border-2 border-black capitalize">
                                    {option.status}
                                </th>
                                <th
                                    className={`${
                                        option?.severity_level
                                            ? option?.severity_level === 'low'
                                                ? 'text-[#55BE8B]'
                                                : option?.severity_level === 'medium'
                                                ? 'text-[#F2C021]'
                                                : 'text-[#FF5449]'
                                            : 'text-[#64748B]'
                                    } text-xs font-normal p-3 text-center border-2 border-black capitalize`}
                                >
                                    {option?.severity_level || 'N/A'}
                                </th>
                                <th className="text-gray-500 text-xs font-normal p-3 text-center border-2 border-black">
                                    {moment(option.date).format('Do MMMM, YYYY')}
                                    {/* <br />
                                    {option.date_range} */}
                                </th>
                            </tr>
                        </tbody>
                    ))}
                </table>
            </div>
            <div
                hidden={!inventory_options?.includes('PCI Scan') || inventory_scan_summary?.pci_scan_info?.length === 0}
            >
                <div className="border-2 border-black bg-[#6E8EDF] flex justify-center items-center p-3">
                    <p className="text-white text-sm font-semibold">PCI Scans</p>
                </div>
                <table className="w-full">
                    <thead className="w-full">
                        <tr>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border-2 border-black">
                                Scan Name
                            </th>
                            <th className="text-[#64748B] text-[13px]  font-semibold p-2 text-center border-2 border-black">
                                Scan Status
                            </th>
                            <th className="text-[#64748B] text-[13px]  font-semibold p-2 text-center border-2 border-black">
                                Progress
                            </th>
                            <th className="text-[#64748B] text-[13px]  font-semibold p-2 text-center border-2 border-black">
                                Date
                            </th>
                        </tr>
                    </thead>
                    {inventory_scan_summary?.pci_scan_info?.map((option, index) => (
                        <tbody key={index}>
                            <tr>
                                <th className="text-gray-500 text-xs font-normal p-3 text-center border-2 border-black">
                                    {option.scan_name}
                                </th>
                                <th
                                    className={`${
                                        option.scan_status === 'passed' || option.scan_status === 'success'
                                            ? 'text-[#55BE8B]'
                                            : 'text-[#FF5449]'
                                    } text-xs font-normal p-3 text-center border-2 border-black capitalize`}
                                >
                                    {option.scan_status}
                                </th>
                                <th className="text-gray-500 text-xs font-normal p-3 text-center border-2 border-black capitalize">
                                    {option.progress}
                                </th>
                                <th className="text-gray-500 text-xs font-normal p-3 text-center border-2 border-black">
                                    {moment(option.date).format('Do MMMM, YYYY')}
                                </th>
                            </tr>
                        </tbody>
                    ))}
                </table>
            </div>
            <div
                hidden={
                    !inventory_options?.includes('API compliance') ||
                    inventory_scan_summary?.api_compliance_info?.length === 0
                }
            >
                <div className="border-2 border-black bg-[#6E8EDF] flex justify-center items-center p-3">
                    <p className="text-white text-sm font-semibold">API Compliance Scan</p>
                </div>
                <table className="w-full">
                    <thead className="w-full">
                        <tr>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border-2 border-black">
                                Endpoint
                            </th>
                            <th className="text-[#64748B] text-[13px]  font-semibold p-2 text-center border-2 border-black">
                                Scan Severity Level
                            </th>
                            <th className="text-[#64748B] text-[13px]  font-semibold p-2 text-center border-2 border-black">
                                Status
                            </th>
                            <th className="text-[#64748B] text-[13px]  font-semibold p-2 text-center border-2 border-black">
                                Date Scanned
                            </th>
                        </tr>
                    </thead>
                    {inventory_scan_summary?.api_compliance_info?.map((option, index) => (
                        <tbody key={index}>
                            <tr>
                                <th className="text-gray-500 text-xs font-normal p-3 text-center border-2 border-black">
                                    {option.endpoint}
                                </th>
                                <th
                                    className={`${
                                        option.severity_level === 'looks okay' ? 'text-[#55BE8B]' : 'text-[#FF5449]'
                                    } text-xs font-normal p-3 text-center border-2 border-black capitalize`}
                                >
                                    {option.severity_level}
                                </th>
                                <th className="text-gray-500 text-xs font-normal p-3 text-center border-2 border-black capitalize">
                                    {option.status}
                                </th>
                                <th className="text-gray-500 text-xs font-normal p-3 text-center border-2 border-black">
                                    {moment(option.date_scanned).format('Do MMMM, YYYY')}
                                </th>
                            </tr>
                        </tbody>
                    ))}
                </table>
            </div>
            <div
                hidden={
                    !inventory_options?.includes('Source Code Review') ||
                    inventory_scan_summary?.codebase_security_info?.length === 0
                }
            >
                <div className="border-2 border-black bg-[#6E8EDF] flex justify-center items-center p-3">
                    <p className="text-white text-sm font-semibold">Codebase Security Scan</p>
                </div>
                <table className="w-full">
                    <thead className="w-full">
                        <tr>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border-2 border-black">
                                Repository Link
                            </th>
                            <th className="text-[#64748B] text-[13px]  font-semibold p-2 text-center border-2 border-black">
                                Status
                            </th>
                            <th className="text-[#64748B] text-[13px]  font-semibold p-2 text-center border-2 border-black">
                                Date Scanned
                            </th>
                        </tr>
                    </thead>
                    {inventory_scan_summary?.codebase_security_info?.map((option, index) => (
                        <tbody key={index}>
                            <tr>
                                <th className="text-gray-500 text-xs font-normal p-3 text-center border-2 border-black">
                                    {option?.repository_link}
                                </th>
                                <th
                                    className={`${
                                        option.status === 'completed' ? 'text-[#55BE8B]' : 'text-[#FF5449]'
                                    } text-xs font-normal p-3 text-center border-2 border-black capitalize`}
                                >
                                    {option.status}
                                </th>
                                <th className="text-gray-500 text-xs font-normal p-3 text-center border-2 border-black">
                                    {moment(option.date_scanned).format('Do MMMM, YYYY')}
                                </th>
                            </tr>
                        </tbody>
                    ))}
                </table>
            </div>
        </div>
    );
};

export default InventoryScansTable;
