import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { all_standards, tabs } from './utils';
// import { MoreVert } from '@mui/icons-material';
// import { Divider, Menu, MenuItem } from '@mui/material';
import AppLinearProgress from 'components/new_components/AppLinearProgress';
// import FileIcon from 'assets/img/file_icon.png';
import { getCompliancePercentage } from 'utils';
import { useHistory } from 'react-router-dom';
// import Carousel from 'components/new_components/carousel/Carousel';
// import SmartIntelLogo from 'assets/img/Smartcomply Intel fav 1.svg';
// import ArrowRight from 'assets/img/arrow-up-right.svg';
// import ViewThreatDrawer from '../../../components/Dashboard/components/firstSection/ViewThreatIntelligence';

const Framwworks = () => {
    const [currentTab, setCurrentTab] = useState('All');
    const [search, setSearch] = useState('');
    // const [anchor, setAnchor] = useState(null);
    // const [currentTag, setCurrentTag] = useState('');
    // const [type, setType] = useState('');
    // const [openDrawer, setOpenDrawer] = useState(false);
    // const [currentData, setCurrentData] = useState(null);

    const merchant_info = useSelector((state) => state?.merchantReducers?.merchant_info);
    const { dashboardResult } = useSelector((state) => state?.dashboardReducers);
    // const { all_smart_intel } = useSelector((state) => state?.generalReducers);

    // const openMenu = (e) => {
    //     e.stopPropagation();
    //     setAnchor(e.target);
    // };
    // const closeMenu = () => setAnchor(null);

    const router = useHistory();
    const gotoPage = (link) => {
        router.push(link);
    };

    const filtered_standards = merchant_info?.standards
        ?.split(',')
        ?.filter((standard) => standard !== 'soc2type2' && standard !== 'pcislc');
    const current_standard = [...new Set(filtered_standards)];
    // const compliance_intel = merchant_info?.smart_intel
    //     ? Object?.values(all_smart_intel?.Compliance)
    //           ?.map((option) => option)
    //           ?.flat()
    //     : [];

    // const handleOpen = (data, type, tag) => {
    //     setCurrentTag(tag);
    //     setType(type);
    //     setCurrentData(data);
    //     setOpenDrawer(true);
    // };
    function RenderTab() {
        switch (currentTab) {
            case 'All':
                return (
                    <div className="grid grid-cols-3 gap-5">
                        {all_standards
                            ?.filter((item) => item?.toLowerCase()?.includes(search?.toLowerCase()))
                            ?.map((standard, index) => (
                                <div
                                    key={index}
                                    className="bg-white border-1 border-[#F1F5F9] rounded-md p-3 cursor-pointer"
                                    onClick={() =>
                                        gotoPage(
                                            `/merchant/compliance/frameworks/${
                                                standard === 'cii'
                                                    ? 'cii_directive'
                                                    : standard === 'sslc'
                                                    ? 'pcislc'
                                                    : standard === 'iso27001-2022'
                                                    ? 'iso27001_2'
                                                    : standard
                                            }`
                                        )
                                    }
                                >
                                    <div className="flex justify-between items-center">
                                        <p className="text-[#475569] text-base font-semibold uppercase">
                                            {standard === 'cii'
                                                ? 'CII DIRECTIVE'
                                                : standard === 'sslc'
                                                ? 'PCI SLC'
                                                : standard === 'soc2'
                                                ? 'SOC 2'
                                                : standard === 'pcidss4.0'
                                                ? 'PCI DSS 4.0'
                                                : standard === 'iso27001'
                                                ? 'ISO 27001'
                                                : standard === 'iso22301'
                                                ? 'ISO 22301'
                                                : standard === 'iso9001'
                                                ? 'ISO 9001'
                                                : standard === 'iso27001-2022'
                                                ? 'ISO 27001 - 2022'
                                                : standard === 'iso27017+iso27032'
                                                ? 'ISO 27017 + ISO 27032'
                                                : standard}
                                        </p>
                                        {/* <>
                                            <div
                                                className="border border-[#E2E8F0] rounded cursor-pointer"
                                                onClick={openMenu}
                                            >
                                                <MoreVert />
                                            </div>
                                            <Menu
                                                anchorEl={anchor}
                                                open={Boolean(anchor)}
                                                onClose={closeMenu}
                                                anchorOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'right',
                                                }}
                                                transformOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'right',
                                                }}
                                            >
                                                <MenuItem sx={{ fontSize: 12 }}>Spool Report</MenuItem>
                                                <Divider />
                                                <MenuItem sx={{ fontSize: 12 }}>Download Certificate</MenuItem>
                                            </Menu>
                                        </> */}
                                    </div>
                                    <p className="text-[#64748B] text-[13px] font-medium mt-1">Undergoing Compliance</p>
                                    <div key={index} className="mb-2 mt-[4rem]">
                                        <AppLinearProgress
                                            value={getCompliancePercentage(dashboardResult, standard)}
                                            sx={{ my: 1, color: '#395BA9' }}
                                        />
                                        <div
                                            className="flex justify-end items-center cursor-pointer"
                                            onClick={() => gotoPage(`/merchant/scans/${standard}`)}
                                        >
                                            {/* <div className="flex items-center gap-1">
                                                <img src={FileIcon} alt="FileIcon" />
                                                <p className="text-[#475569] text-[11px] font-medium">
                                                    3/11{' '}
                                                    <span className="text-[#64748B] text-[11px] font-normal">
                                                        Control Objective
                                                    </span>
                                                </p>
                                            </div> */}
                                            <p className="text-[#64748B] text-[11px] font-semibold">
                                                {Math.ceil(getCompliancePercentage(dashboardResult, standard))}%{' '}
                                                <span className="text-[#64748B] text-[11px] font-normal">Done</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                    </div>
                );
            case 'Undergoing compliance':
                return (
                    <div className="grid grid-cols-3 gap-5">
                        {current_standard
                            ?.filter((item) => item?.toLowerCase()?.includes(search?.toLowerCase()))
                            ?.map((standard, index) => (
                                <div
                                    key={index}
                                    className="bg-white border-1 border-[#F1F5F9] rounded-md p-3 cursor-pointer"
                                    onClick={() =>
                                        gotoPage(
                                            `/merchant/compliance/frameworks/${
                                                standard === 'cii'
                                                    ? 'cii_directive'
                                                    : standard === 'sslc'
                                                    ? 'pcislc'
                                                    : standard === 'iso27001-2022'
                                                    ? 'iso27001_2'
                                                    : standard
                                            }`
                                        )
                                    }
                                >
                                    <div className="flex justify-between items-center">
                                        <p className="text-[#475569] text-base font-semibold uppercase">
                                            {standard === 'cii'
                                                ? 'CII DIRECTIVE'
                                                : standard === 'sslc'
                                                ? 'PCI SLC'
                                                : standard === 'soc2'
                                                ? 'SOC 2'
                                                : standard === 'pcidss4.0'
                                                ? 'PCI DSS 4.0'
                                                : standard === 'iso27001'
                                                ? 'ISO 27001'
                                                : standard === 'iso22301'
                                                ? 'ISO 22301'
                                                : standard === 'iso9001'
                                                ? 'ISO 9001'
                                                : standard === 'iso27001-2022'
                                                ? 'ISO 27001 - 2022'
                                                : standard === 'iso27017+iso27032'
                                                ? 'ISO 27017 + ISO 27032'
                                                : standard}
                                        </p>
                                        {/* <>
                                            <div
                                                className="border border-[#E2E8F0] rounded cursor-pointer"
                                                onClick={openMenu}
                                            >
                                                <MoreVert />
                                            </div>
                                            <Menu
                                                anchorEl={anchor}
                                                open={Boolean(anchor)}
                                                onClose={closeMenu}
                                                anchorOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'right',
                                                }}
                                                transformOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'right',
                                                }}
                                            >
                                                <MenuItem sx={{ fontSize: 12 }}>Spool Report</MenuItem>
                                                <Divider />
                                                <MenuItem sx={{ fontSize: 12 }}>Download Certificate</MenuItem>
                                            </Menu>
                                        </> */}
                                    </div>
                                    <p className="text-[#64748B] text-[13px] font-medium mt-1">Undergoing Compliance</p>
                                    <div key={index} className="mb-2 mt-[4rem]">
                                        <AppLinearProgress
                                            value={getCompliancePercentage(dashboardResult, standard)}
                                            sx={{ my: 1, color: '#395BA9' }}
                                        />
                                        <div className="flex justify-end items-center cursor-pointer">
                                            {/* <div className="flex items-center gap-1">
                                                <img src={FileIcon} alt="FileIcon" />
                                                <p className="text-[#475569] text-[11px] font-medium">
                                                    3/11{' '}
                                                    <span className="text-[#64748B] text-[11px] font-normal">
                                                        Control Objective
                                                    </span>
                                                </p>
                                            </div> */}
                                            <p className="text-[#64748B] text-[11px] font-semibold">
                                                {Math.ceil(getCompliancePercentage(dashboardResult, standard))}%{' '}
                                                <span className="text-[#64748B] text-[11px] font-normal">Done</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                    </div>
                );
            case 'Not subscribed':
                return (
                    <div className="w-full mt-5 flex justify-center items-center">
                        <div className=" mt-5 w-[60%]">
                            <div>hello</div>
                        </div>
                    </div>
                );
            default:
                return (
                    <div className="w-full mt-5 flex justify-center items-center">
                        <div className=" mt-5 w-[60%]">
                            <div>hello</div>
                        </div>
                    </div>
                );
        }
    }
    return (
        <div>
            <div className="flex justify-between items-center">
                <div className="flex items-center gap-2 w-full">
                    {tabs?.map((tab, index) => {
                        return (
                            <button
                                key={index}
                                className={`rounded-3xl font-medium text-sm !border !border-[#E2E8F0] transition-all hover:brightness-90 px-2 py-1.5 grid place-items-center capitalize ${
                                    currentTab === tab
                                        ? 'bg-[#395BA9] text-[#fff] font-medium text-[13px]'
                                        : 'text-[#64748B] font-normal text-[13px]'
                                }`}
                                onClick={() => setCurrentTab(tab)}
                            >
                                {tab}
                            </button>
                        );
                    })}
                </div>
                <input
                    className="border border-[#E2E8F0] bg-transparent p-1.5 placeholder:text-[#94A3B8] placeholder:text-sm placeholder:font-normal rounded outline:none"
                    placeholder="Search..."
                    value={search}
                    onChange={(event) => setSearch(event?.target?.value)}
                />
            </div>
            {/* {merchant_info?.smart_intel && (
                <div className=" my-[1.5rem] transition ease-in-out duration-500">
                    <div className="bg-[#E5F1FF] rounded-full px-3 py-2 flex justify-between items-center 2xl:h-[50px] xl:h-[70px] md:[100px]">
                        <div className="flex items-center gap-2 w-[85%]">
                            <img src={SmartIntelLogo} alt="SmartIntelLogo" className="xl:block md:hidden" />
                            <p className="bg-[#F43F5E] text-white text-[13px] rounded px-1 py-0.5 w-fit xl:block md:hidden">
                                {compliance_intel?.length}
                            </p>
                            <div className="flex justify-center items-center">
                                <Carousel
                                    slides={compliance_intel}
                                    handleOpen={handleOpen}
                                    type="Compliance"
                                    data={all_smart_intel?.Compliance}
                                    view="compliance"
                                />
                            </div>
                        </div>
                        <img src={ArrowRight} alt="ArrowRight" />
                    </div>
                </div>
            )} */}
            <div className="mt-4">
                <RenderTab />
            </div>

            {/* <ViewThreatDrawer
                open={openDrawer}
                handleClose={() => setOpenDrawer(false)}
                currentData={currentData}
                type={type}
                currentTag={currentTag}
            /> */}
        </div>
    );
};

export default Framwworks;
