import { Stack, Typography, Box, Select, MenuItem } from '@mui/material';
import { useMemo, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { ReactComponent as DateIcon } from 'assets/img/calendar.svg';
import { useSelector } from 'react-redux';
import moment from 'moment';

export default function RiskTrends() {
    const date_joined = useSelector((state) => state?.authReducers?.user_details?.user?.date_joined) ?? null;
    const yearUserSignedUp = date_joined ? moment(date_joined).year() : moment().year();

    const [filter, setFilter] = useState(moment().year());
    const handleChange = (e) => {
        setFilter(e.target.value);
    };

    const months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ];

    function getYearsSinceJoining() {
        const currentYear = new Date().getFullYear();
        const years = [];

        for (let year = yearUserSignedUp; year <= currentYear; year++) {
            years.push(year);
        }

        return years;
    }

    const overview = useSelector((state) => state?.riskAssessmentReducers?.overview);

    const values = useMemo(() => {
        const data = overview?.risk_trend ? overview?.risk_trend.filter((el) => el.year === filter) : [];
        return months.map((_arg, key) => {
            const existingData = data.find((el) => el?.month === key + 1);
            return existingData ? existingData.risk_percentage : 0;
        });
    }, [overview, filter]);

    const requestsData = useMemo(
        () => ({
            labels: months,
            datasets: [
                {
                    // label: 'API call requests',
                    data: values,
                    backgroundColor: 'transparent',
                    borderColor: '#395BA9',
                    pointBorderColor: 'transparent',
                    hoverOffset: 4,
                    pointBorderWidth: 4,
                    tension: 0.5,
                },
            ],
        }),
        [overview]
    );
    const requests0ptions = useMemo(
        () => ({
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
                legend: {
                    display: false,
                },
                tooltip: {
                    callbacks: {
                        // Customize the label shown in the tooltip
                        label: function (context) {
                            return context.label;
                        },
                        // Optionally customize the title or other parts
                        title: function (context) {
                            return `${context[0].raw}%`;
                        },
                    },
                    font: {
                        family: 'Poppins, sans-serif',
                    },
                },
            },
            scales: {
                x: {
                    grid: {
                        display: false,
                    },
                    border: {
                        display: false,
                    },
                    ticks: {
                        color: '#64748B',
                        padding: 4,
                        font: {
                            family: 'Poppins, sans-serif',
                            size: 12,
                        },
                        callback: (value) => months[value].slice(0, 3),
                    },
                },
                y: {
                    min: 0,
                    max: 100,
                    ticks: {
                        // stepsize should ensure there's max data of y is divided into 8
                        stepSize: 20,
                        color: '#64748B',
                        padding: 4,
                        font: {
                            family: 'Poppins, sans-serif',
                            size: 12,
                        },
                        callback: (value) => value + '%',
                    },
                    grid: {
                        // borderDash: [10]
                        display: false,
                    },
                    border: {
                        display: false,
                    },
                    title: {
                        display: true,
                        text: 'Risk percentage',
                        color: '#475569',
                        font: {
                            weight: 'bolder',
                            family: 'Poppins, sans-serif',
                        },
                    },
                },
            },
        }),
        [overview]
    );

    return (
        <div className="col-span-6 bg-white rounded-md border-[#f1f5f9] px-3 py-3">
            <Stack
                sx={{
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    display: 'flex',
                    width: '100%',
                    flexDirection: 'row',
                }}
            >
                <Typography
                    sx={{
                        fontSize: 16,
                        color: '#2b3674',
                        fontWeight: 600,
                    }}
                >
                    Risk trends
                </Typography>

                <Select
                    labelId="year-select-label"
                    id="year-select"
                    value={filter}
                    label="Years"
                    onChange={handleChange}
                    size="small"
                    sx={{
                        textTransform: 'capitalize',
                        backgroundColor: '#F8FAFC',
                        border: '1px solid #E2E8F0',

                        '& .MuiSelect-select': {
                            fontSize: 11,
                            paddingLeft: '10px',
                            paddingBlock: '3px',
                        },
                        '& .MuiOutlinedInput-notchedOutline': {
                            border: '1px solid #E2E8F0 !important',
                        },
                    }}
                    defaultValue={yearUserSignedUp}
                    renderValue={(value) => (
                        <span className="flex gap-1 items-center">
                            <DateIcon className="w-4 h-4" />
                            {value}
                        </span>
                    )}
                >
                    {getYearsSinceJoining().map((value, key) => (
                        <MenuItem sx={{ fontSize: 11, textTransform: 'capitalize' }} key={key} value={value}>
                            {value}
                        </MenuItem>
                    ))}
                </Select>
            </Stack>

            <div className="flex flex-col gap-8">
                <Box sx={{ mt: 3 }}>
                    {/* <div className="w-[200px]"> */}
                    <Line
                        data={requestsData}
                        options={requests0ptions}
                        width="auto"
                        height="-webkit-fill-available"
                    />{' '}
                    {/* </div> */}
                </Box>
            </div>
        </div>
    );
}
