import React, { useEffect, useMemo } from 'react';

import { useTheme } from '@mui/material/styles';
import { Box, Badge, Typography } from '@mui/material';
import Notification from 'assets/img/notification.svg';

//redux
import { connect } from 'react-redux';
import { ReadAllMerchantNotifications } from 'store/actions/notificationActions';

//translations
import { useTranslation } from 'react-i18next';

const NotificationDropdownTrigger = ({
    openNotificationModal,
    notificationModalOpen,
    all_merchant_notifications,
    ReadAllMerchantNotifications,
}) => {
    const theme = useTheme();
    // const [showText, setShowText] = useState(true);

    //translation
    const { t } = useTranslation('common');

    // const showNotiText = () => {
    //     setShowText(true);
    //     setTimeout(() => setShowText(false), 1500);
    // };

    // memo
    const newMerchantNotificationAvailable = useMemo(
        () =>
            all_merchant_notifications &&
            all_merchant_notifications?.length &&
            all_merchant_notifications?.some((notification) => !notification?.read_status),
        [all_merchant_notifications]
    );

    useEffect(() => {
        if (notificationModalOpen && newMerchantNotificationAvailable) {
            ReadAllMerchantNotifications();
        }
    }, [notificationModalOpen, newMerchantNotificationAvailable]);
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                mx: { xs: 0, md: 1.5 },
            }}
        >
            <Typography
                component="small"
                variant="body2"
                color="error"
                sx={{
                    position: 'relative',
                    zIndex: -1,
                    overflow: 'hidden',
                    boxShadow: '1px 4px 8px rgba(0, 0, 0, 0.12)',
                    borderRadius: '16px 0 0 16px',
                    fontWeight: 700,
                    fontSize: 12.1,
                    color: theme.palette.error[500],
                    display: { xs: 'none', md: 'block' },
                    right: newMerchantNotificationAvailable ? -16 : '-100%',
                    opacity: newMerchantNotificationAvailable ? 1 : 0,
                    width: newMerchantNotificationAvailable ? 133.14 : 0,
                    transition: newMerchantNotificationAvailable
                        ? 'right 0.3s, opacity 0.05s 0.2s, width 0.3s'
                        : 'right 0.3s, opacity 0.1s, width 0.3s',
                }}
            >
                <Box sx={{ py: 1, pr: 3, pl: 1.5, whiteSpace: 'nowrap' }}>{t('newNotification')}</Box>
            </Typography>

            <div onClick={openNotificationModal} className="cursor-pointer">
                <Badge
                    color="error"
                    badgeContent=""
                    variant="dot"
                    overlap="circular"
                    invisible={!newMerchantNotificationAvailable}
                >
                    <img src={Notification} alt="Notification" />
                </Badge>
            </div>
        </Box>
    );
};

const mapStateToProps = (state) => {
    return {
        all_merchant_notifications: state?.notificationReducers?.all_merchant_notifications,
    };
};
export default connect(mapStateToProps, { ReadAllMerchantNotifications })(NotificationDropdownTrigger);
