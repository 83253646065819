import React from 'react';
//components
import { Drawer, Stack, Button, Box, Typography } from '@mui/material';
import keyBoardArrowLeft from '../../../../assets/img/keyboardArrowLeft.svg';

const PentestDrawer = ({ open, handleClose, children, title }) => {
    return (
        <Drawer
            variant="temporary"
            anchor="right"
            open={open}
            onClose={handleClose}
            sx={{
                width: '100%',
                overflowX: 'hidden',
                [`& .MuiDrawer-paper`]: {
                    width: { lg: '525px', xs: '70%' },
                },
            }}
        >
            <Box sx={{ borderBottom: '1px solid #E2E8F0' }}>
                <Stack
                    sx={{ width: '50%', height: '60px' }}
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                >
                    <Button
                        sx={{
                            display: 'flex',
                            width: 'fit-content',
                            minWidth: 'unset',
                            // px: 0.5,
                            ml: 1,
                            justifyContent: 'center',
                            alignitems: 'center',
                        }}
                        onClick={handleClose}
                    >
                        <img src={keyBoardArrowLeft} alt="keyboard Arrow Left" />
                    </Button>
                    <Typography sx={{ fontSize: '18px', fontWeight: 600, color: '#202D66' }}>{title}</Typography>
                </Stack>
            </Box>

            {children}
        </Drawer>
    );
};

export default PentestDrawer;
