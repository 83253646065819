import React from 'react';
import DoubleRightCheck from 'assets/img/chevron-right-double.svg';
import { Progress } from 'antd';
import { ReactComponent as TrendDownIcon } from 'assets/img/trend-up.svg';
import { useSelector } from 'react-redux';

const CybersecurityPosture = (props) => {
    const { openSuggestionsModal, compliance_health_percentage, compliance_health_status } = props;
    const { smartComplianceReport } = useSelector((state) => state?.dashboardReducers);
    return (
        <div className="bg-white rounded-md h-[450px] p-3">
            <div className="flex justify-between items-center">
                <h4 className="text-[#2B3674] text-lg font-bold">Cybersecurity Posture</h4>
                <button className="bg-[#F1F5F9] py-1 px-2 rounded-md" onClick={openSuggestionsModal}>
                    <img src={DoubleRightCheck} alt="DoubleRightCheck" />
                </button>
            </div>
            <div className="flex justify-center items-center mt-[4rem] mb-[2rem]">
                <Progress
                    type="dashboard"
                    percent={compliance_health_percentage}
                    format={(percent) => (
                        <p>
                            <span className="text-[#000000] text-[31px] font-extrabold">{percent}% </span>
                            <br />
                            <span
                                className={`${
                                    compliance_health_percentage <= 40
                                        ? 'bg-[#DE3730]'
                                        : compliance_health_percentage <= 70
                                        ? 'bg-[#FDD865]'
                                        : 'bg-[#55BE8B]'
                                } text-[#FFFFFF] font-normal text-base px-2.5 py-1.5 rounded-2xl capitalize`}
                            >
                                {compliance_health_status} Risk
                            </span>
                        </p>
                    )}
                    trailColor="#CBD5E1"
                    size={220}
                    strokeWidth={12}
                    strokeColor={
                        compliance_health_percentage <= 40
                            ? '#DE3730'
                            : compliance_health_percentage <= 70
                            ? '#FDD865'
                            : '#55BE8B'
                    }
                    className="font-bolder"
                />
            </div>
            <div className="flex justify-center items-center gap-2">
                <TrendDownIcon className="-scale-y-100" />
                <p className="text-[#64748B] text-sm font-semibold">
                    {smartComplianceReport?.[2]?.percentage_change?.toFixed(2)}% decrease this month
                </p>
            </div>
        </div>
    );
};
export default CybersecurityPosture;
