/* eslint-disable no-unused-vars */
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Drawer,
    Menu,
    MenuItem,
    Slide,
    Typography,
} from '@mui/material';
import CircularLoader from 'components/Loaders/CircularLoader';
import AppCenteredModal from 'components/new_components/AppCenteredModal';
import { Loader2 } from 'lucide-react';
import { forwardRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { GetAllExceptions, GetGroupScanResultsDetails, GetScanAi, GetScans } from 'store/actions/automatedScansActions';
import { ALL_SCANS } from 'store/constants';
import { getType } from 'utils';
import AssignTaskModal from './Modals/AssignTaskModal';
import ExemptScanModal from './Modals/ExemptScanModal';
import ViewExemptedScanDrawer from './ViewExemptedScanDrawer';
import parse from 'html-react-parser';

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="right" ref={ref} {...props} />;
});

export default function ScansViewModified(props) {
    const { goBack, category, loading, isScan } = props;

    const [anchorEl, setAnchorEl] = useState(null);
    const [isScansExceptionLoading, setIsScansExceptionLoading] = useState(false);
    const [currentSub, setCurrentSub] = useState(null);
    const [isViewExemptedScanDrawerOpen, setIsViewExemptedScanDrawerOpen] = useState(false);

    const all_group_automated_scans = useSelector((state) => state?.automatedScansReducers?.all_group_automated_scans);

    const all_scan_exceptions = useSelector((state) => state?.automatedScansReducers?.all_scan_exceptions);

    const dispatch = useDispatch();

    const group_scans = all_group_automated_scans?.filter((subcat) => subcat?.category === category?.id);

    const filtered_scan_exception = all_scan_exceptions?.find((scan) => scan?.category === category?.name);

    const getAllScansExceptions = async () => {
        try {
            setIsScansExceptionLoading(true);
            const res = await dispatch(GetAllExceptions());
            if (!res.success) {
                toast.error(res?.message);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setIsScansExceptionLoading(false);
        }
    };

    useEffect(() => {
        getAllScansExceptions();
        return () => {
            setIsScansExceptionLoading(false);
        };
    }, [all_group_automated_scans]);

    return (
        <>
            <div className="m-[1.6rem] bg-white border-b px-[26px] !py-2 border-[#F1F5F9] flex items-center justify-between">
                <div className="flex items-center gap-3">
                    <img
                        src="/img/automated-scan/arrow-left-detailed-icon.svg"
                        alt="Arrow left Icon"
                        className="object-contain cursor-pointer"
                        onClick={() => goBack('groups')}
                    />

                    <h3 className="text-[#002C72] font-bold text-lg capitalize">{category?.name}</h3>
                </div>

                <div>
                    <button
                        aria-controls={anchorEl ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={anchorEl ? 'true' : undefined}
                        onClick={(event) => setAnchorEl(event.currentTarget)}
                        className="relative bg-[#F1F5F9] px-2 !py-1 rounded-sm text-[#202D66] font-medium text-[13px] hover:bg-[#E2E8F0] transition-all after:absolute after:-top-[2%] after:-right-[2%] after:w-2 after:h-2 after:bg-[#FF5449] after:rounded-full after:border-2 after:border-white disabled:after:hidden flex items-center disabled:opacity-50 disabled:pointer-events-none"
                        hidden={!filtered_scan_exception}
                        disabled={isScansExceptionLoading}
                    >
                        {isScansExceptionLoading && <Loader2 className="h-4 w-4 animate-spin mr-2" />} Exempted scans
                    </button>

                    <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={() => setAnchorEl(null)}
                        sx={{
                            '& .MuiPaper-root': {
                                borderRadius: '4px',
                                boxShadow:
                                    '4px 4px 8px rgba(175, 172, 172, 0.15), -4px -4px 8px rgba(175, 172, 172, 0.15)',
                            },
                        }}
                    >
                        {filtered_scan_exception?.subcategory?.map(
                            (sub, index) =>
                                Object.keys(sub?.account).length !== 0 && (
                                    <MenuItem
                                        key={index}
                                        onClick={(event) => {
                                            event.stopPropagation();
                                            setCurrentSub(sub);
                                            setIsViewExemptedScanDrawerOpen(true);
                                            setAnchorEl(null);
                                        }}
                                        sx={{
                                            color: '#64748B',
                                            fontWeight: 500,
                                            fontSize: '14px',
                                            textTransform: 'capitalize',
                                        }}
                                    >
                                        {sub?.name?.replace(/_/g, ' ')}
                                    </MenuItem>
                                )
                        )}
                    </Menu>
                </div>
            </div>

            {loading ? (
                <CircularLoader />
            ) : (
                <div className="mt-8 mx-auto w-full max-w-[954px] rounded flex flex-col !py-6 gap-6 bg-white">
                    <div className="mx-6 bg-[#F8FAFC] border border-[#F0F0FA] rounded flex gap-2 px-4 !py-2 items-start">
                        <img
                            src="/img/automated-scan/bulb-icon.svg"
                            alt="Bulb Icon"
                            className="object-contain mt-0.5"
                        />

                        <p className="text-[#64748B] font-normal text-[13px] leading-5">
                            These are the categories available for this scan constraint. Click any of them to view all
                            instances of the scans performed. You can assign a category to any of your user in the same
                            organization by clicking the more (...) button.
                        </p>
                    </div>

                    <div>
                        {group_scans?.map(
                            (sub, index) =>
                                Object.keys(sub?.accounts || sub?.account).length !== 0 && (
                                    <RenderScanCategory key={index} sub={sub} category={category} />
                                )
                        )}
                    </div>
                </div>
            )}

            <ViewExemptedScanDrawer
                open={isViewExemptedScanDrawerOpen}
                onClose={() => setIsViewExemptedScanDrawerOpen(false)}
                sub={currentSub}
            />
        </>
    );
}

function RenderScanCategory({ sub, category }) {
    const [assignTask, setAssignTask] = useState(false);
    const [isViewDrawerOpen, setIsViewDrawerOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    const role = getType('role');

    const organization = useSelector((state) => state?.generalReducers?.user_info?.organization);

    const find_failled_scan = Object?.keys(sub?.accounts || sub?.account)?.find((item) =>
        sub?.accounts ? sub?.accounts[item] === 'failed' : null
    );

    return (
        <>
            <div
                className={`border-b border-[#F1F5F9] cursor-pointer hover:bg-[#F8FAFC] transition-colors flex items-center justify-between px-6 !py-3 ${
                    find_failled_scan ? 'bg-[#FFFFFF]' : 'bg-[#F8FAFC]'
                }`}
                onClick={() => setIsViewDrawerOpen(true)}
            >
                <div className="flex flex-col gap-6">
                    <h5 className="text-[#395BA9] font-medium text-[15px] leading-5 capitalize">
                        {sub?.name?.replace(/_/g, ' ')}
                    </h5>

                    {Object?.keys(sub?.accounts)?.length !== 0 && (
                        <div className="flex items-center gap-2">
                            {Object?.keys(sub?.accounts)?.map((scan, index) => {
                                return scan === 'scan_time' ? null : (
                                    <div key={index} className="flex items-center gap-1">
                                        <img
                                            src={
                                                sub?.accounts[scan] === 'success'
                                                    ? '/img/automated-scan/instance-success-icon.svg'
                                                    : '/img/automated-scan/instance-error-icon.svg'
                                            }
                                            alt="Instance Icon"
                                            className="object-contain"
                                        />

                                        <p className="text-[#64748B] font-normal text-[13px] capitalize">{scan}</p>
                                    </div>
                                );
                            })}
                        </div>
                    )}
                </div>

                <div className="flex items-center gap-2">
                    <div className="flex gap-1 items-center !py-1">
                        <div
                            className={`w-1.5 h-1.5 rounded-full ${
                                find_failled_scan ? 'bg-[#FF897D]' : 'bg-[#72DAA5]'
                            }`}
                        />

                        <p className="text-[#64748B] font-semibold text-xs">
                            {find_failled_scan ? 'Needs attention' : 'OK'}
                        </p>
                    </div>

                    {sub?.accounts && sub.is_assigned_to_current_user && (
                        <p className="bg-[#D2A500] rounded-sm px-1 !py-0.5">Assigned</p>
                    )}

                    <div
                        hidden={
                            category?.is_assigned_to_current_user ||
                            sub?.is_assigned_to_current_user ||
                            role === 'auditor' ||
                            organization?.merchant_plan?.name === 'free_version' ||
                            organization?.merchant_plan?.name === '3_days_trial'
                        }
                    >
                        <button
                            aria-controls={anchorEl ? 'basic-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={anchorEl ? 'true' : undefined}
                            onClick={(event) => {
                                event.stopPropagation();
                                setAnchorEl(event.currentTarget);
                            }}
                            className="bg-[#F8FAFC] h-6 w-6 rounded hover:bg-[#E2E8F0] transition-colors grid place-items-center"
                        >
                            <img
                                src="/img/automated-scan/horizontal-dots-icon.svg"
                                alt="Horizontal Dots"
                                className="object-contain"
                            />
                        </button>

                        <Menu
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={() => setAnchorEl(null)}
                            sx={{
                                '& .MuiPaper-root': {
                                    borderRadius: '4px',
                                    boxShadow:
                                        '4px 4px 8px rgba(175, 172, 172, 0.15), -4px -4px 8px rgba(175, 172, 172, 0.15)',
                                },
                            }}
                        >
                            <MenuItem
                                onClick={(event) => {
                                    event.stopPropagation();
                                    setAssignTask(true);
                                    setAnchorEl(null);
                                }}
                                sx={{
                                    color: '#64748B',
                                    fontWeight: 500,
                                    fontSize: '14px',
                                }}
                            >
                                Assign
                            </MenuItem>

                            <MenuItem
                                onClick={(event) => {
                                    event.stopPropagation();
                                    setAnchorEl(null);
                                }}
                                sx={{
                                    color: '#64748B',
                                    fontWeight: 500,
                                    fontSize: '14px',
                                }}
                            >
                                Set SLA
                            </MenuItem>
                        </Menu>
                    </div>
                </div>
            </div>

            <AssignTaskModal
                open={assignTask}
                onClose={() => setAssignTask(false)}
                type="Scan Sub-constraint"
                modalTitle="scan category"
                sub_category={sub}
                category={category}
            />

            <ViewScanDrawer
                open={isViewDrawerOpen}
                onClose={() => setIsViewDrawerOpen(false)}
                sub={sub}
                category={category}
            />
        </>
    );
}

function ViewScanDrawer({ open, onClose, sub, category }) {
    const [isGetGroupScanResultsDetailsLoading, setIsGetGroupScanResultsDetailsLoading] = useState(false);
    const [isGetScansLoading, setIsGetScansLoading] = useState(false);
    const [searchIntance, setSearchInstance] = useState('');
    const [currentAccount, setCurrentAccount] = useState(Object.keys(sub?.accounts || {})[0]);
    const [scanInstanceStatus, setScanInstanceStatus] = useState('success');
    const [anchorEl, setAnchorEl] = useState(null);

    const all_scan_items = useSelector((state) => state?.automatedScansReducers?.all_scan_items);

    const dispatch = useDispatch();

    const failed_scan =
        sub?.accounts !== undefined &&
        sub?.accounts !== null &&
        Object?.keys(sub?.accounts)?.length &&
        Object?.keys(sub?.accounts)?.find((scan) => sub?.accounts[scan] === 'failed');

    const filtered_all_scan_items = all_scan_items
        ?.filter((scan) => scan?.result === scanInstanceStatus)
        .filter((scan) => scan?.scan_name?.toLowerCase().includes(searchIntance.toLowerCase()));

    const getGroupScanResultsDetails = async () => {
        setIsGetGroupScanResultsDetailsLoading(true);
        const res = await dispatch(GetGroupScanResultsDetails());
        setIsGetGroupScanResultsDetailsLoading(false);
        if (!res.success) {
            toast.error(res?.message);
        }
    };

    const getScans = async (subcategory, account_name) => {
        setIsGetScansLoading(true);
        const res = await dispatch(GetScans(subcategory, account_name));
        setIsGetScansLoading(false);
        if (!res.success) {
            toast.error(res?.message);
        }
    };

    useEffect(() => {
        if (open) {
            getGroupScanResultsDetails();
        }
    }, [open]);

    useEffect(() => {
        if (open) {
            if (currentAccount) {
                dispatch({
                    type: ALL_SCANS,
                    payload: [],
                });

                getScans(sub?.id, currentAccount);
            }
        }
    }, [currentAccount, open]);

    useEffect(() => {
        if (sub?.accounts) {
            setCurrentAccount(Object.keys(sub?.accounts)[0]);
        }
    }, [sub?.accounts]);

    return (
        <Drawer
            open={open}
            anchor="right"
            TransitionComponent={Transition}
            transitionDuration={1000}
            keepMounted
            onClose={onClose}
            PaperProps={{
                sx: {
                    boxShadow: '0px 12px 24px rgba(0, 0, 0, 0.16)',
                    minWidth: 370,
                    width: 535,
                    flex: 1,
                    overflow: 'auto',
                    zIndex: 9999,
                },
            }}
            scroll="paper"
        >
            <div className="flex flex-col gap-4">
                <div className="border-b border-[#F1F5F9] px-2 !py-4 flex items-center gap-2">
                    <img
                        src="/img/automated-scan/arrow-left-icon.svg"
                        alt="arrow-left-icon"
                        onClick={onClose}
                        className="cursor-pointer pl-2 object-contain"
                    />

                    <h3 className="pl-2 text-[#202D66] capitalize font-semibold text-base">
                        {sub?.name?.replace(/_/g, ' ')}
                    </h3>
                </div>

                {isGetGroupScanResultsDetailsLoading || isGetScansLoading ? (
                    <CircularLoader />
                ) : (
                    <>
                        <div className="flex items-center justify-between px-4">
                            <div className="relative">
                                <img
                                    src="/img/automated-scan/search-icon.svg"
                                    alt="Search Icon"
                                    className="absolute object-contain top-1/2 -translate-y-1/2 left-[10px]"
                                />

                                <input
                                    type="text"
                                    value={searchIntance}
                                    onChange={(event) => setSearchInstance(event.target.value)}
                                    placeholder="Search in scan instance"
                                    className="w-full max-w-[238px] py-2.5 pr-2.5 !pl-8 border border-[#E2E8F0] h-8 text-sm font-normal placeholder:text-[#94A3B8] text-[#64748B] rounded focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-[#2a447f] focus-visible:ring-opacity-75"
                                />
                            </div>

                            <div className="flex items-center gap-2">
                                {currentAccount && (
                                    <div>
                                        <button
                                            aria-controls={anchorEl ? 'basic-menu' : undefined}
                                            aria-haspopup="true"
                                            aria-expanded={anchorEl ? 'true' : undefined}
                                            onClick={(event) => {
                                                event.stopPropagation();
                                                setAnchorEl(event.currentTarget);
                                            }}
                                            className="border border-[#E2E8F0] px-2 py-1.5 flex items-center gap-1 rounded hover:bg-[#E2E8F0] transition-colors"
                                        >
                                            <p className="capitalize text-[#64748B] font-medium text-[13px]">
                                                {currentAccount.length > 7
                                                    ? currentAccount.slice(0, 8) + '...'
                                                    : currentAccount}
                                            </p>

                                            <img
                                                src="/img/automated-scan/arrow-down.svg"
                                                alt="Arrow Down Icon"
                                                className="object-contain"
                                            />
                                        </button>

                                        <Menu
                                            anchorEl={anchorEl}
                                            open={Boolean(anchorEl)}
                                            onClose={() => setAnchorEl(null)}
                                            sx={{
                                                '& .MuiPaper-root': {
                                                    borderRadius: '4px',
                                                    boxShadow:
                                                        '4px 4px 8px rgba(175, 172, 172, 0.15), -4px -4px 8px rgba(175, 172, 172, 0.15)',
                                                },
                                            }}
                                        >
                                            {Object?.keys(sub?.accounts)?.map(
                                                (scan, index) =>
                                                    scan !== 'scan_time' && (
                                                        <MenuItem
                                                            key={index}
                                                            onClick={(event) => {
                                                                event.stopPropagation();
                                                                setCurrentAccount(scan);
                                                                setAnchorEl(null);
                                                            }}
                                                            sx={{
                                                                color: '#64748B',
                                                                fontWeight: 500,
                                                                fontSize: '14px',
                                                                textTransform: 'capitalize',
                                                            }}
                                                        >
                                                            {scan}
                                                        </MenuItem>
                                                    )
                                            )}
                                        </Menu>
                                    </div>
                                )}

                                <div className="border border-[#E2E8F0] rounded flex items-center overflow-hidden">
                                    <button
                                        className={`grid place-items-center h-8 px-2 text-[#64748B] ${
                                            scanInstanceStatus !== 'success' && 'bg-[#395BA9] text-white'
                                        } transition-all hover:brightness-90 font-medium text-sm`}
                                        onClick={() => setScanInstanceStatus('failed')}
                                    >
                                        Failed
                                    </button>

                                    <button
                                        className={`grid place-items-center h-8 px-2 text-[#64748B] ${
                                            scanInstanceStatus === 'success' && 'bg-[#395BA9] text-white'
                                        } transition-all hover:brightness-90 font-medium text-sm`}
                                        onClick={() => setScanInstanceStatus('success')}
                                    >
                                        Passed
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div className="flex flex-col">
                            {filtered_all_scan_items?.length === 0 ? (
                                <div className="pt-8 justify-center items-center flex flex-col gap-4">
                                    <img
                                        src="/img/automated-scan/no-scan-instance-icon.svg"
                                        alt="No result Icon"
                                        className="object-contain"
                                    />

                                    <p className="text-[#475569] font-semibold text-lg">
                                        No {scanInstanceStatus === 'success' ? 'passed' : 'failed'} scan found
                                    </p>
                                </div>
                            ) : (
                                filtered_all_scan_items?.map((scan) => (
                                    <RenderScanItems
                                        key={scan?.id}
                                        scan={scan}
                                        sub={sub}
                                        category={category}
                                        currentAccount={currentAccount}
                                    />
                                ))
                            )}
                        </div>
                    </>
                )}
            </div>
        </Drawer>
    );
}

function RenderScanItems({ scan, sub, category, currentAccount }) {
    const [assignTask, setAssignTask] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [expandedAccordion, setExpandedAccordion] = useState(false);
    const [isReadMoreDrawerOpen, setIsReadMoreDrawerOpen] = useState(false);
    const [isFrameworkDialogOpen, setIsFrameworkDialogOpen] = useState(false);
    const [isExemptDialogOpen, setIsExemptDialogOpen] = useState(false);
    const [isHowToFixDrawerOpen, setIsHowToFixDrawerOpen] = useState(false);
    const [isRecommendationsDrawerOpen, setIsRecommendationsDrawerOpen] = useState(false);

    const scan_items = useSelector((state) => state?.automatedScansReducers?.scan_items);

    const all_assigned_task = useSelector((state) => state?.automatedScansReducers?.all_assigned_task);

    const role = getType('role');

    const organization = useSelector((state) => state?.generalReducers?.user_info?.organization);

    const filtered_scan_item = (scan_items || [])?.find((scan) => scan?.subcategory === sub?.id);

    const handleAccordionChange = (scanID) => (event, isAccordionExpanded) => {
        setExpandedAccordion(isAccordionExpanded ? scanID : false);
    };

    return (
        <>
            <Accordion
                expanded={expandedAccordion === scan?.id}
                onChange={handleAccordionChange(scan?.id)}
                className="!shadow-none !border-b !border-[#F1F5F9] [&.css-9mbo5h-MuiPaper-root-MuiAccordion-root.Mui-expanded]:!my-0 before:hidden"
            >
                <AccordionSummary
                    expandIcon={<></>}
                    aria-controls={`${scan?.id}-content`}
                    id={`${scan?.id}header`}
                    className={`!px-3 [&>.MuiAccordionSummary-content]:!my-1.5 !min-h-fit transition-colors ${
                        expandedAccordion === scan?.id && '!bg-[#F8FAFC]'
                    }`}
                >
                    <div className="flex items-center justify-between w-full">
                        <div className="flex items-center gap-2">
                            <div className="grid place-items-center w-[22px] h-[22px] rounded-full bg-[#F8FAFC] border border-[#F1F5F9] drop-shadow-[2px,2px,8px,0px,#09090914]">
                                <img
                                    src="/img/automated-scan/arrow-right-light-icon.svg"
                                    alt="Arrow Icon"
                                    className={`object-contain transition-transform ${
                                        expandedAccordion === scan?.id && 'transform rotate-90'
                                    }`}
                                />
                            </div>

                            <p className="text-[#475569] font-normal text-sm">{scan?.scan_name}</p>
                        </div>

                        <div className="flex items-center gap-2">
                            {scan?.is_assigned_to_current_user && (
                                <p className="bg-[#D2A500] px-1 rounded-sm text-white font-medium text-[11px] leading-5">
                                    Assigned
                                </p>
                            )}

                            <img
                                src={
                                    scan?.result === 'success'
                                        ? '/img/automated-scan/scan-success-icon.svg'
                                        : '/img/automated-scan/scan-failed-icon.svg'
                                }
                                alt="Scan Status Icon"
                                className="object-contain"
                            />
                        </div>
                    </div>
                </AccordionSummary>

                <AccordionDetails className="!px-6 !py-2">
                    <div className="flex flex-col gap-4">
                        {scan?.body?.length ? (
                            // <ul className="flex flex-col list-disc pr-6 pl-12">
                            //     {scan?.body?.slice(0, 2).map((bodyItem, index) => (
                            //         <li key={index} className="text-[#64748B] font-normal text-[13px] leading-5">
                            //             {bodyItem}{' '}
                            //             {index === 1 && scan?.body?.length > 2 && (
                            //                 <button
                            //                     className="underline hover:no-underline transition-all"
                            //                     onClick={() => setIsReadMoreDrawerOpen(true)}
                            //                 >
                            //                     Read more
                            //                 </button>
                            //             )}
                            //         </li>
                            //     ))}
                            // </ul>
                            <div className="flex flex-col">
                                <div className="scan-body-elements brief">
                                    {Array.isArray(parse(scan?.body))
                                        ? parse(scan?.body).slice(0, 2)
                                        : parse(scan?.body)}
                                </div>

                                {Array.isArray(parse(scan?.body)) ? (
                                    <button
                                        className="underline ml-auto hover:no-underline transition-all text-[#64748B] font-normal text-[13px] leading-5"
                                        onClick={() => setIsReadMoreDrawerOpen(true)}
                                    >
                                        Read more
                                    </button>
                                ) : null}
                            </div>
                        ) : (
                            <p className="text-[#64748B] font-normal text-[13px] leading-5">-</p>
                        )}

                        <div className="flex items-center justify-between">
                            {scan?.result === 'success' ? (
                                <button
                                    className="flex items-center gap-1 pr-3 py-1 transition-all hover:brightness-75"
                                    onClick={() => setIsRecommendationsDrawerOpen(true)}
                                >
                                    <img
                                        src="/img/automated-scan/recommendation-bulb-icon.svg"
                                        alt="Bulb Icon"
                                        className="object-contain"
                                    />

                                    <p className="text-[#395BA9] font-normal text-sm">Recommendations</p>
                                </button>
                            ) : (
                                <div className="flex items-center">
                                    <button
                                        className="border-r border-[#CBD5E1] py-1 pr-3 text-[#37A372] underline transition-all hover:no-underline hover:brightness-75 font-medium text-sm"
                                        onClick={() => setIsHowToFixDrawerOpen(true)}
                                    >
                                        See how to fix it
                                    </button>

                                    <button
                                        className="flex items-center gap-1 px-3 py-1 transition-all hover:brightness-75"
                                        onClick={() => setIsRecommendationsDrawerOpen(true)}
                                    >
                                        <img
                                            src="/img/automated-scan/recommendation-bulb-icon.svg"
                                            alt="Bulb Icon"
                                            className="object-contain"
                                        />

                                        <p className="text-[#395BA9] font-normal text-sm">Recommendations</p>
                                    </button>
                                </div>
                            )}

                            <div>
                                <button
                                    aria-controls={anchorEl ? 'basic-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={anchorEl ? 'true' : undefined}
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        setAnchorEl(event.currentTarget);
                                    }}
                                    className="bg-[#F1F5F9] h-7 w-7 rounded hover:bg-[#E2E8F0] transition-colors grid place-items-center"
                                >
                                    <img
                                        src="/img/automated-scan/horizontal-dots-icon.svg"
                                        alt="Horizontal Dots"
                                        className="object-contain"
                                    />
                                </button>

                                <Menu
                                    anchorEl={anchorEl}
                                    open={Boolean(anchorEl)}
                                    onClose={() => setAnchorEl(null)}
                                    sx={{
                                        '& .MuiPaper-root': {
                                            borderRadius: '4px',
                                            boxShadow:
                                                '4px 4px 8px rgba(175, 172, 172, 0.15), -4px -4px 8px rgba(175, 172, 172, 0.15)',
                                        },
                                    }}
                                >
                                    <MenuItem
                                        onClick={(event) => {
                                            event.stopPropagation();
                                            setAssignTask(true);
                                            setAnchorEl(null);
                                        }}
                                        sx={{
                                            color: '#64748B',
                                            fontWeight: 500,
                                            fontSize: '14px',
                                        }}
                                        hidden={
                                            category?.is_assigned_to_current_user ||
                                            sub?.is_assigned_to_current_user ||
                                            role === 'auditor' ||
                                            organization?.merchant_plan?.name === 'free_version' ||
                                            organization?.merchant_plan?.name === '3_days_trial'
                                        }
                                    >
                                        Assign
                                    </MenuItem>

                                    {scan?.framework?.length ? (
                                        <MenuItem
                                            onClick={(event) => {
                                                event.stopPropagation();
                                                setIsFrameworkDialogOpen(true);
                                                setAnchorEl(null);
                                            }}
                                            sx={{
                                                color: '#64748B',
                                                fontWeight: 500,
                                                fontSize: '14px',
                                            }}
                                        >
                                            Framework
                                        </MenuItem>
                                    ) : null}

                                    <MenuItem
                                        onClick={(event) => {
                                            event.stopPropagation();
                                            setIsExemptDialogOpen(true);
                                            setAnchorEl(null);
                                        }}
                                        sx={{
                                            color: '#D2A500',
                                            fontWeight: 500,
                                            fontSize: '14px',
                                        }}
                                    >
                                        Exempt
                                    </MenuItem>
                                </Menu>
                            </div>
                        </div>
                    </div>
                </AccordionDetails>
            </Accordion>

            <AssignTaskModal
                open={assignTask}
                onClose={() => setAssignTask(false)}
                type="Scan"
                modalTitle="scan instance"
                scans={scan}
                category={category}
                sub_category={sub}
            />

            <ExemptScanModal
                open={isExemptDialogOpen}
                onClose={() => setIsExemptDialogOpen(false)}
                sub={sub}
                accountName={currentAccount}
                scan={scan}
            />

            <FrameworkDialog open={isFrameworkDialogOpen} onClose={() => setIsFrameworkDialogOpen(false)} scan={scan} />

            <RecommendationsDrawer
                open={isRecommendationsDrawerOpen}
                onClose={() => setIsRecommendationsDrawerOpen(false)}
                scan={scan}
            />

            <ReadMoreDrawer open={isReadMoreDrawerOpen} onClose={() => setIsReadMoreDrawerOpen(false)} scan={scan} />

            <HowToFixDrawer open={isHowToFixDrawerOpen} onClose={() => setIsHowToFixDrawerOpen(false)} scan={scan} />
        </>
    );
}

function HowToFixDrawer({ open, onClose, scan }) {
    const [loading, setLoading] = useState(false);
    const [scan_ai, setScan_ai] = useState([]);
    const [text, setText] = useState('');
    const [fullText, setFullText] = useState({});
    const [index, setIndex] = useState(0);

    const dispatch = useDispatch();

    const getScanAi = async () => {
        const body = { scan_id: scan?.id };
        setLoading(true);
        const res = await dispatch(GetScanAi(body));
        setLoading(false);
        if (res?.success) {
            setScan_ai(res?.data);
            setFullText(res?.data[0]);
        } else {
            toast.error(res?.message);
        }
    };

    useEffect(() => {
        if (open && scan?.id) {
            setFullText({});
            setText('');
            setIndex(0);
            getScanAi();
        }
    }, [open && scan.id]);

    useEffect(() => {
        if (open && index < fullText?.length) {
            setTimeout(() => {
                setText(text + fullText[index]);
                setIndex(index + 1);
            }, 5);
        }
    }, [open, index, fullText]);

    return (
        <Drawer
            open={open}
            anchor="right"
            TransitionComponent={Transition}
            transitionDuration={1000}
            keepMounted
            onClose={onClose}
            PaperProps={{
                sx: {
                    boxShadow: '0px 12px 24px rgba(0, 0, 0, 0.16)',
                    minWidth: 370,
                    width: 535,
                    flex: 1,
                    overflow: 'auto',
                    zIndex: 9999,
                },
            }}
            scroll="paper"
        >
            <div className="flex flex-col gap-4">
                <div className="border-b border-[#F1F5F9] px-4 !py-4 flex items-center gap-2">
                    <img
                        src="/img/automated-scan/arrow-left-drawer.svg"
                        alt="arrow-left-icon"
                        onClick={onClose}
                        className="cursor-pointer object-contain"
                    />

                    <h3 className="pl-0.5 text-[#475569] capitalize font-semibold text-[15px]">{scan?.scan_name}</h3>

                    <p className="ml-auto bg-[#55BE8B] py-0.5 px-2 rounded font-normal text-sm text-[#FFFFFF] whitespace-nowrap">
                        How to fix
                    </p>
                </div>

                {loading || scan_ai?.length === 0 ? (
                    <div className="flex px-6 flex-col gap-4">
                        <div className="flex flex-col py-1 gap-3">
                            <div
                                className="h-4 w-[98%] rounded-full animate-pulse"
                                style={{
                                    background: 'linear-gradient(269.73deg, #E2E8F0 35.65%, #CEE3FD 110.36%)',
                                }}
                            />
                            <div
                                className="h-4 w-[75%] rounded-full animate-pulse"
                                style={{
                                    background: 'linear-gradient(92.92deg, #E2E8F0 14.41%, #CEE3FD 117.21%)',
                                }}
                            />
                            <div
                                className="h-4 w-[50%] rounded-full animate-pulse"
                                style={{
                                    background: 'linear-gradient(269.73deg, #E2E8F0 35.65%, #CEE3FD 110.36%)',
                                }}
                            />
                        </div>

                        <div className="flex flex-col py-1 gap-3">
                            <div
                                className="h-4 w-[75%] rounded-full animate-pulse"
                                style={{
                                    background: 'linear-gradient(269.73deg, #E2E8F0 35.65%, #CEE3FD 110.36%)',
                                }}
                            />
                            <div
                                className="h-4 w-[50%] rounded-full animate-pulse"
                                style={{
                                    background: 'linear-gradient(92.92deg, #E2E8F0 14.41%, #CEE3FD 117.21%)',
                                }}
                            />
                            <div
                                className="h-4 w-[35%] rounded-full animate-pulse"
                                style={{
                                    background: 'linear-gradient(269.73deg, #E2E8F0 35.65%, #CEE3FD 110.36%)',
                                }}
                            />
                        </div>

                        <div className="flex flex-col py-1 gap-3">
                            <div
                                className="h-4 w-[50%] rounded-full animate-pulse"
                                style={{
                                    background: 'linear-gradient(269.73deg, #E2E8F0 35.65%, #CEE3FD 110.36%)',
                                }}
                            />
                            <div
                                className="h-4 w-[35%] rounded-full animate-pulse"
                                style={{
                                    background: 'linear-gradient(92.92deg, #E2E8F0 14.41%, #CEE3FD 117.21%)',
                                }}
                            />
                            <div
                                className="h-4 w-[25%] rounded-full animate-pulse"
                                style={{
                                    background: 'linear-gradient(269.73deg, #E2E8F0 35.65%, #CEE3FD 110.36%)',
                                }}
                            />
                        </div>
                    </div>
                ) : (
                    <>
                        <div className="px-6 py-1">
                            {text?.split('\n')?.map((item, index) => (
                                <p
                                    key={index}
                                    className={`text-sm leading-[21px] text-[#64748B] mb-2.5 last:mb-0 ${
                                        item?.includes('#') ? 'font-bold' : 'font-normal'
                                    }`}
                                >
                                    {item?.replaceAll('#', '')}
                                </p>
                            ))}
                        </div>

                        <div className="flex justify-end items-center gap-2 px-6 pb-12">
                            <p className="text-[#64748B] text-xs font-normal">generated by</p>

                            <div>
                                <img
                                    src="/img/automated-scan/vifly-icon.svg"
                                    alt="Vifly Icon"
                                    className="object-contain"
                                />
                            </div>
                        </div>
                    </>
                )}
            </div>
        </Drawer>
    );
}

function ReadMoreDrawer({ open, onClose, scan }) {
    return (
        <Drawer
            open={open}
            anchor="right"
            TransitionComponent={Transition}
            transitionDuration={1000}
            keepMounted
            onClose={onClose}
            PaperProps={{
                sx: {
                    boxShadow: '0px 12px 24px rgba(0, 0, 0, 0.16)',
                    minWidth: 370,
                    width: 535,
                    flex: 1,
                    overflow: 'auto',
                    zIndex: 9999,
                },
            }}
            scroll="paper"
        >
            <div className="flex flex-col gap-4">
                <div className="border-b border-[#F1F5F9] px-4 !py-4 flex items-center gap-2">
                    <img
                        src="/img/automated-scan/arrow-left-drawer.svg"
                        alt="arrow-left-icon"
                        onClick={onClose}
                        className="cursor-pointer object-contain"
                    />

                    <h3 className="pl-0.5 text-[#475569] capitalize font-semibold text-[15px]">{scan?.scan_name}</h3>
                </div>

                {/* <ul className="flex flex-col list-disc py-1 pr-6 pl-12">
                    {scan?.body?.map((bodyItem, index) => (
                        <li key={index} className="text-[#64748B] font-normal text-sm leading-5">
                            {bodyItem}
                        </li>
                    ))}
                </ul> */}

                <div
                    className="scan-body-elements"
                    dangerouslySetInnerHTML={{
                        __html: scan?.body,
                    }}
                />
            </div>
        </Drawer>
    );
}
function RecommendationsDrawer({ open, onClose, scan }) {
    return (
        <Drawer
            open={open}
            anchor="right"
            TransitionComponent={Transition}
            transitionDuration={1000}
            keepMounted
            onClose={onClose}
            PaperProps={{
                sx: {
                    boxShadow: '0px 12px 24px rgba(0, 0, 0, 0.16)',
                    minWidth: 370,
                    width: 535,
                    flex: 1,
                    overflow: 'auto',
                    zIndex: 9999,
                },
            }}
            scroll="paper"
        >
            <div className="flex flex-col gap-4">
                <div className="border-b border-[#F1F5F9] px-2 !py-4 flex items-center gap-2">
                    <img
                        src="/img/automated-scan/arrow-left-icon.svg"
                        alt="arrow-left-icon"
                        onClick={onClose}
                        className="cursor-pointer pl-2 object-contain"
                    />

                    <h3 className="pl-2 text-[#202D66] capitalize font-semibold text-base">Recommendation</h3>
                </div>

                <div className="mx-4 px-4 py-2 rounded border border-[#F0F0FA] bg-[#F8FAFC] flex gap-2">
                    <img src="/img/automated-scan/bulb-icon.svg" alt="Bulb icon" className="object-contain" />

                    <Typography sx={{ fontSize: '13px', color: '#64748B', lineHeight: '19.5px' }}>
                        The following recommendation can help achieve a good scan result.
                    </Typography>
                </div>

                {scan?.recommendation?.length ? (
                    <div className="flex flex-col">
                        <h4 className="border-y-2 border-[#F1F5F9] px-3 py-1.5 text-sm font-semibold text-[#395BA9]">
                            {scan?.scan_name}
                        </h4>

                        <ul className="flex flex-col list-disc pr-6 pl-12 py-2">
                            {scan?.recommendation?.map((recommendation, index) => (
                                <li key={index} className="text-[#64748B] font-normal text-[13px] leading-5">
                                    {recommendation}
                                </li>
                            ))}
                        </ul>
                    </div>
                ) : (
                    <div className="pt-8 justify-center items-center flex flex-col gap-4">
                        <img
                            src="/img/automated-scan/no-scan-instance-icon.svg"
                            alt="No result Icon"
                            className="object-contain"
                        />

                        <p className="text-[#475569] font-semibold text-lg">No available recommendations</p>
                    </div>
                )}
            </div>
        </Drawer>
    );
}

function FrameworkDialog({ open, onClose, scan }) {
    return (
        <AppCenteredModal
            open={open}
            handleClose={onClose}
            title={<Typography sx={{ fontSize: '18px', fontWeight: 600, color: '#202D66' }}>Framework</Typography>}
            headerAction={
                <button
                    className="border-[#E2E8F0] border rounded-full p-1 h-6 w-6 grid place-items-center hover:bg-[#E2E8F0] transition-colors"
                    onClick={onClose}
                >
                    <img src="/img/automated-scan/close.svg" alt="close icon" className="object-contain" />
                </button>
            }
            titleStyle={{ marginTop: '6px' }}
            width={540}
            noBorder
        >
            <div className="px-4 py-2 rounded border border-[#F0F0FA] bg-[#F8FAFC] flex gap-2 my-4">
                <img src="/img/automated-scan/bulb-icon.svg" alt="Bulb icon" className="object-contain" />

                <Typography sx={{ fontSize: '13px', color: '#64748B', lineHeight: '19.5px' }}>
                    The following is a list of framework standards to which the scan is relevant.
                </Typography>
            </div>

            <div className="flex mt-4 mb-6 items-center gap-2 flex-wrap">
                {scan?.framework?.map((framework, index) => (
                    <div className="flex items-center border border-[#E2E8F0] rounded px-2 py-1 gap-2" key={index}>
                        <div>
                            <img
                                src="/img/automated-scan/framework-icon.svg"
                                alt="Framework Icon"
                                className="object-contain"
                            />
                        </div>

                        <p className="text-[#475569] text-sm font-medium uppercase">{framework}</p>
                    </div>
                ))}
            </div>

            <div className="bg-[#F8FAFC] flex items-center -mx-7 -mb-[1.6px] justify-end gap-2.5 p-6">
                <button
                    onClick={onClose}
                    className="text-sm px-3 font-semibold py-[10px] rounded border border-[#E2E8F0] bg-white hover:brightness-90 transition-all"
                >
                    Close
                </button>
            </div>
        </AppCenteredModal>
    );
}
