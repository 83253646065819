import React, { useState, useEffect, useRef, useContext, useMemo } from 'react';
import { ButtonGroup, Button } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Add } from '@mui/icons-material';
import { useTheme } from '@mui/material';
import { toast } from 'react-toastify';
import more from '../../../assets/img/more.svg';
import './loading.css';

//Core components
import AppTable from 'components/new_components/app-table2';
import PentestTableActions from './PentestTableActions';
import RequestModal from './RequestModal';
import UploadReportModal from './UploadReportModal';
import PentestDeleteModal from './PentestDeleteModal';
import PentestReportModal from './PentestReportModal';
import AppColorTag from 'components/new_components/AppColorTag';
import useSearch from 'hooks/useSearch';
import PentestArrowDropdown from './PentestArrowDropdown';
import AssignPentestModal from './AssignPentest';
// import TableTopbar from 'components/new_components/app-table2/TableTopbar';
import ChangeSchedulingModal from './ChangeSchedulingModal';
//redux
import { useDispatch, useSelector, connect } from 'react-redux';
import { GetMerchantPentestRequests } from 'store/actions/merchantActions';

//utils
import { uploadIntialValues } from './utils/constants';
import PageHeader from 'components/new_components/PageHeader';
// import SubscriptionAlert from '../../../components/Pricing/SubscriptionAlert';
// import { GetRemaining } from '../../../components/Pricing/usePricingHook';
import { formatDateObjectHandler } from 'utils';
// import { pentests } from 'data';
// import SubscriptionRoutingAlert from "../../../components/Pricing/SubscriptionRoutingAlert";

//userguide
import { Tour } from 'antd';
import AppArticle from 'components/new_components/AppArticle';
import { UserGuideContext } from 'store/context/UserGuideContextProvider';

//translations
import { useTranslation } from 'react-i18next';
import { SelectedMerchantContext } from 'store/context/SelectedMerchantContextProvider';
import { UserGuideHeader } from 'components/new_components/AppUserGuide';
import PentestViewDetails from './PentestViewDetails';

const NO_PER_PAGE = 8;

const PenetrationTest = ({ all_article_header }) => {
    const [openRequestModal, setOpenRequestModal] = useState(false);
    const [openViewDetailsDrawer, setOpenViewDetailsDrawer] = useState(false);
    const [modalPayload, setModalPayload] = useState({});
    const [mode, setMode] = useState('');
    const [uploadPayload, setUploadPayload] = useState(uploadIntialValues);
    const [openUploadReportModal, setOpenUploadReportModal] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [deletePayload, setDeletePayload] = useState({});
    const [modalPayloadId, setModalPayloadId] = useState(false);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [pageData, setPageData] = useState([]);
    const [openDowloadReportModal, setOpenDownloadReportModal] = useState(false);
    const [report, setReport] = useState('');
    const [openUserGuide, setOpenUserGuide] = useState(false);
    const [openArticle, setOpenArticle] = useState(false);
    const [openAssignPentestModal, SetOpenAssignPentestModal] = useState(false);
    const [changeScheduleModal, setChangeScheduleModal] = useState(false);

    const [anchorEl, setAnchorEl] = useState(null);
    const [tableTab, setTableTab] = useState('requested');

    const theme = useTheme();
    const dispatch = useDispatch();

    //userguide
    const ref1 = useRef(null);
    const ref2 = useRef(null);
    const ref3 = useRef(null);
    const ref4 = useRef(null);

    const { updateOneUserGuide, toggleUserGuide, setToggleUserGuide } = useContext(UserGuideContext);

    const STEPS_LENGTH = 4;

    const steps = [
        {
            title: <UserGuideHeader title="Requested" id={1} length={STEPS_LENGTH} />,
            description: 'This shows the list of all requested pentest',
            target: () => ref1.current,
            placement: 'leftTop',
        },
        {
            title: <UserGuideHeader title="Upload" id={2} length={STEPS_LENGTH} />,
            description: 'This shows the list of all uploaded pentest report',
            target: () => ref2.current,
            placement: 'leftTop',
        },
        {
            title: <UserGuideHeader title="Request Pentest" id={3} length={STEPS_LENGTH} />,
            description: 'Run penetration testing by making a request here.',
            target: () => ref3.current,
            placement: 'rightTop',
        },
        {
            title: <UserGuideHeader title="Upload Report" id={4} length={STEPS_LENGTH} />,
            description: 'You can Upload penetration report if you already performed the test elsewhere',
            target: () => ref4.current,
            placement: 'rightTop',
        },
    ];

    //accessing values from the redux store

    const role = useSelector((state) => state?.generalReducers?.user_info?.role);
    const merchant_pentests_requests = useSelector((state) => state?.merchantReducers?.merchant_pentests_requests);
    const pricing_plan = useSelector((state) => state?.authReducers.pricing_plan);
    const userguide_info = useSelector((state) => state?.guideReducers?.userguide_info?.[0]?.pentest);
    const user_type = useSelector((state) => state?.authReducers?.user_type);

    //context api for auditor's selected merchant
    const { activeMerchant } = useContext(SelectedMerchantContext);

    //translation
    const { t } = useTranslation('pentest');
    //useMemo
    const oneArticle = useMemo(
        () => all_article_header?.find((article) => article?.name === 'Penetration Testing'),
        [all_article_header]
    );

    const uploadedPentests = useMemo(() => {
        return merchant_pentests_requests?.filter(
            (pentest) => pentest?.uploaded_by !== 'wired assurance' && pentest?.uploaded_by !== '386konsult'
        );
    }, [merchant_pentests_requests]);

    const requestedPentests = useMemo(() => {
        return merchant_pentests_requests?.filter(
            (pentest) => pentest?.uploaded_by === 'wired assurance' || pentest?.uploaded_by === '386konsult'
        );
    }, [merchant_pentests_requests]);

    const { data, handleSearch } = useSearch(tableTab === 'requested' ? requestedPentests : uploadedPentests, [
        'name',
        'uploaded_by',
        'status',
    ]);

    const openChangeScheduleModal = (type, data, id) => {
        setModalPayload(data);
        setChangeScheduleModal(true);
        setModalPayloadId(id);
    };
    const closeChangeScheduleModal = () => {
        setChangeScheduleModal(false);
    };

    const OpenPentestDropdown = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const ClosePentestDropdown = () => {
        setAnchorEl(null);
    };

    const OpenAssignModal = () => {
        SetOpenAssignPentestModal(true);
    };

    const CloseAssignModal = () => {
        SetOpenAssignPentestModal(false);
    };

    //UserGuide
    const OpenUserGuide = () => {
        setOpenUserGuide(true);
    };
    const CloseUserGuide = () => {
        updateOneUserGuide({ pentest: true });
        setOpenUserGuide(false);
    };
    const OpenArticle = () => {
        setOpenArticle(true);
    };
    const CloseArticle = () => {
        setToggleUserGuide({ ...toggleUserGuide, pentest: false });
        setOpenArticle(false);
    };

    //functions
    const openRequestActionModal = (mode, payload) => {
        setOpenRequestModal(true);
        setMode(mode);
        if (mode === 'view' || mode === 'rerun') {
            setModalPayload(payload);
        }
    };
    const closeRequestActionModal = () => {
        setOpenRequestModal(false);
        setMode('');
        setTimeout(() => {
            setModalPayload({});
        }, 2000);
    };

    const OpenViewDetailsDrawer = (mode, payload) => {
        setOpenViewDetailsDrawer(true);
        setMode(mode);
        if (mode === 'view' || mode === 'rerun') {
            setModalPayload(payload);
        }
    };
    const CloseViewDetailsDrawer = () => {
        setOpenViewDetailsDrawer(false);
        setMode('');
        setTimeout(() => {
            setModalPayload({});
        }, 2000);
    };
    const openUploadPentestReportModal = (payload = uploadIntialValues) => {
        setOpenUploadReportModal(true);
        setUploadPayload(payload);
        console.log('Payload', payload);
    };
    const closeUploadPentestReportModal = () => {
        setOpenUploadReportModal(false);
        setTimeout(() => setUploadPayload({}), 1000);
    };
    // initialEmployeeValues TODO://to check
    const openDeleteModal = (payload = {}, id) => {
        setDeleteModalOpen(true);
        setDeletePayload(payload);
        setModalPayloadId(id);
    };
    const closeDeleteModal = () => {
        setDeleteModalOpen(false);
        setTimeout(() => setDeletePayload({}), 1000);
    };
    const handlePageChange = (page) => {
        setPage(page);
    };

    const openDownloadPentestReportModal = (id) => {
        setOpenDownloadReportModal(true);
        setReport(id);
    };
    const closeDownloadPentestReportModal = () => {
        setOpenDownloadReportModal(false);
        setReport('');
    };

    const currentMonthForPentest = (currentDate) => {
        const date = new Date(currentDate);
        const month = date.getMonth() + 1;

        if (month >= 1 && month <= 6) {
            return 'January-June';
        } else if (month > 6 && month <= 12) {
            return 'July - December';
        } else {
            return null;
        }
    };

    const requestedColumns = [
        {
            title: <span className="text-[14px] font-semibold">{t('table.column1')}</span>,
            render: (row) => (
                <span className="custom-table-row-width break-words text-[#64748B] text-[14px] capitalize">
                    {row?.name}
                </span>
            ),
        },
        {
            title: <span className="text-[14px] font-semibold">{t('table.column2')}</span>,
            key: 'severity_level',
            render: (row) => {
                return !row?.severity ? (
                    <div className="border-[1px] border-solid border-[#E2E8F0] rounded-[4px] w-[31px] flex items-center justify-center">
                        --
                    </div>
                ) : (
                    <AppColorTag
                        text={row?.severity}
                        type={
                            row?.severity?.toLowerCase() === 'high'
                                ? 'high'
                                : row?.severity?.toLowerCase() === 'medium'
                                ? 'warning'
                                : row?.severity?.toLowerCase() === 'critical'
                                ? 'error'
                                : 'success'
                        }
                    />
                );
            },
        },
        {
            title: <span className="text-[14px] font-semibold">{t('table.column3')}</span>,
            key: 'progress',
            render: (row) =>
                // <AppTag text={row?.progress} type={row?.progress === 'completed' ? 'success' : 'default'} />
                (!row?.status && !row?.uploaded_by) || row?.progress === 'running' || row?.progress === 'rerunning' ? (
                    <span style={{ fontStyle: 'italic' }} className="text-[#64748B]">
                        {row?.progress === 'running' ? (
                            <div className="flex items-center gap-[6px]">
                                <div class="loading-circle"></div>
                                <span>Running...</span>{' '}
                            </div>
                        ) : row?.progress === 'rerunning' ? (
                            <div className="flex items-center gap-[6px]">
                                <div class="loading-circle"></div>
                                <span>Rerunning...</span>{' '}
                            </div>
                        ) : null}
                    </span>
                ) : (
                    <AppColorTag
                        sx={{ width: '8px', height: '8px' }}
                        text={
                            row?.progress === 'completed' ? (
                                t('Completed')
                            ) : row?.progress === 'running' ? (
                                <div className="flex items-center gap-[6px]">
                                    <div class="loading-circle"></div>
                                    <span>Running...</span>{' '}
                                </div>
                            ) : null
                        }
                        type={row?.progress ? 'success' : 'error'}
                        margin="0"
                        fontWeight={500}
                    />
                ),
        },

        {
            title: <span className="text-[14px] font-semibold">{t('table.column5')}</span>,
            key: 'date',
            render: (row) =>
                (!row?.status && !row?.uploaded_by) || row?.progress === 'running' || row?.progress === 'rerunning' ? (
                    <div>--</div>
                ) : (
                    <div className="custom-table-row-width break-words text-[#64748B] flex flex-col items-start">
                        <span className="text-[14px]">
                            {formatDateObjectHandler(row?.date_created, 'MMMM Do, YYYY') || 'Nil'}
                        </span>
                        <span className="text-[13px]">({currentMonthForPentest(row?.date_created)})</span>
                    </div>
                ),
        },
        {
            title: <span className="text-[14px] font-semibold">{t('table.column6')}</span>,
            key: 'frequency',
            render: (row) => (
                <p className="custom-table-row-width break-words text-[#64748B] font-medium text-[12px] h-[26px] w-[21px] border-[1px] border-solid border-[#E2E8F0] rounded-[4px] flex items-center justify-center">
                    {row?.frequency ? row?.frequency : '-'}
                </p>
            ),
        },
        {
            title: <img src={more} className="cursor-pointer text-[#64748B] text-[14px] font-semibold" alt="More" />,
            align: 'center',
            key: 'action',
            render: (row) => (
                <PentestTableActions
                    row={row}
                    openDeleteModal={openDeleteModal}
                    tableTab={tableTab}
                    openDownloadPentestReportModal={openDownloadPentestReportModal}
                    openViewModal={OpenViewDetailsDrawer}
                    openAssignModal={OpenAssignModal}
                    openRequestActionModal={openRequestActionModal}
                    openChangeScheduleModal={openChangeScheduleModal}
                />
            ),
        },
    ];
    const uploadedColumns = [
        {
            title: <span className="text-[14px] font-semibold">{t('table.column1')}</span>,
            render: (row) => (
                <span className="custom-table-row-width break-words text-[#64748B] text-[14px] capitalize">
                    {row?.name}
                </span>
            ),
        },
        {
            title: <span className="text-[14px] font-semibold">{t('table.column2')}</span>,
            key: 'severity',
            render: (row) => (
                <>
                    <div
                        style={{
                            color: '#64748B',
                            fontSize: '12px',
                            fontWeight: 500,
                            textTransform: 'capitalize',
                            border: '1px solid #E2E8F0',
                            borderRadius: '4px',
                            height: '26px',
                            width: 'fit-content',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            gap: '4px',
                            paddingInline: '8px',
                        }}
                    >
                        <div
                            style={{
                                background:
                                    row?.severity?.toLowerCase() === 'high'
                                        ? 'rgb(255, 76, 76)'
                                        : row?.severity?.toLowerCase() === 'medium'
                                        ? 'rgb(226, 178, 8)'
                                        : row?.severity?.toLowerCase() === 'low'
                                        ? 'rgb(85, 190, 139)'
                                        : row?.severity?.toLowerCase() === 'critical'
                                        ? 'rgb(217, 27, 27)'
                                        : null,

                                width: '8px',
                                height: '8px',
                                borderRadius: '4px',
                            }}
                        ></div>

                        {row?.severity}
                    </div>
                </>
            ),
        },
        {
            title: <span className="text-[14px] font-semibold">{t('table.column4')}</span>,
            key: 'uploaded_by',
            render: (row) => (
                <span className="custom-table-row-width break-words text-[#64748B] text-[14px] capitalize">
                    {row?.uploaded_by || 'Nil'}
                </span>
            ),
        },
        {
            title: <span className="text-[14px] font-semibold">{t('table.column5')}</span>,
            key: 'date',
            render: (row) => (
                <>
                    <div className="custom-table-row-width break-words text-[#64748B] flex flex-col items-start">
                        <span className="text-[14px]">
                            {formatDateObjectHandler(row?.date_created, 'MMMM Do, YYYY') || 'Nil'}
                        </span>
                        <span className="text-[13px]">({currentMonthForPentest(row?.date_created)})</span>
                    </div>
                </>
            ),
        },

        {
            title: '',
            align: 'center',
            key: 'action',
            render: (row) => (
                <PentestTableActions
                    row={row}
                    openDeleteModal={openDeleteModal}
                    tableTab={tableTab}
                    openDownloadPentestReportModal={openDownloadPentestReportModal}
                />
            ),
        },
    ];

    useEffect(() => {
        const getMerchantPentestsRequests = async () => {
            setLoading(true);
            const res = await dispatch(GetMerchantPentestRequests(activeMerchant));
            setLoading(false);
            if (!res?.success) {
                toast.error(res?.message);
            }
        };
        getMerchantPentestsRequests();
    }, [activeMerchant]);

    useEffect(() => {
        const start = (page - 1) * NO_PER_PAGE;
        const stop = start + NO_PER_PAGE;

        setPageData(data?.length > 0 ? data?.sort((a, b) => b?.id - a?.id)?.slice(start, stop) : []);

        return data;
    }, [page, data]);

    useEffect(() => {
        !userguide_info ? OpenUserGuide() : toggleUserGuide.pentest ? OpenArticle() : null;
    }, [userguide_info, toggleUserGuide.pentest]);
    // console.log({ loading });

    return (
        <>
            <PageHeader browserTitle="Penetration Test | Smartcomply" />
            {/* <TableTopbar payload={modalPayload} numberOfPentest={data?.length} /> */}
            <AppTable
                columns={tableTab === 'requested' ? requestedColumns : uploadedColumns}
                data={pageData}
                sorter={(a, b) => b?.id - a?.id}
                loading={loading}
                title={t('title')}
                dataLength={data?.length}
                noPerPage={NO_PER_PAGE}
                page={page}
                tabs={true}
                toggleTabs={setTableTab}
                tabState={tableTab}
                onPageChange={handlePageChange}
                ref1={ref1}
                ref2={ref2}
                actions={[
                    user_type === 'auditor' ? (
                        []
                    ) : role === 'auditor' ? (
                        []
                    ) : (
                        <ButtonGroup
                            style={{ marginTop: '60px', marginRight: '-24px', marginBottom: '-15px' }}
                            variant="contained"
                            aria-label="Basic button group"
                        >
                            <Button
                                startIcon={<Add />}
                                onClick={() => openRequestActionModal('request')}
                                color="primary"
                                variant="contained"
                                disableElevation
                                key={2}
                                ref={ref3}
                                disabled={pricing_plan?.pentest_scan === pageData?.length || role === 'auditor'}
                                sx={{
                                    display: 'flex-inline',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    fontWeight: '600',
                                    // borderRadius: '4px',
                                    py: 1.2,
                                    px: 2.4,
                                    textTransform: 'unset',
                                    '&.Mui-disabled': {
                                        color: theme.palette.white.main,
                                        backgroundColor: theme.palette.gray[600],
                                    },
                                }}
                            >
                                {t('requestPentest')}
                            </Button>
                            <Button
                                color="primary"
                                variant="contained"
                                sx={{ borderLeft: '1px solid #5474C4', py: 1.2 }}
                                disabled={role === 'auditor'}
                                disableElevation
                                onClick={OpenPentestDropdown}
                                key={1}
                                ref={ref4}
                            >
                                <KeyboardArrowDownIcon />
                            </Button>
                        </ButtonGroup>
                    ),
                ]}
                search={true}
                onSearch={handleSearch}
            />

            <PentestArrowDropdown
                anchorEl={anchorEl}
                handleClose={ClosePentestDropdown}
                openUploadPentestReportModal={() => openUploadPentestReportModal()}
            />

            <AssignPentestModal open={openAssignPentestModal} handleClose={CloseAssignModal} />

            <RequestModal
                open={openRequestModal}
                handleClose={closeRequestActionModal}
                payload={modalPayload}
                mode={mode}
            />
            <PentestViewDetails
                open={openViewDetailsDrawer}
                handleClose={CloseViewDetailsDrawer}
                payload={modalPayload}
                mode={mode}
            />
            <UploadReportModal
                open={openUploadReportModal}
                handleClose={closeUploadPentestReportModal}
                payload={uploadPayload}
            />
            <PentestDeleteModal
                open={deleteModalOpen}
                handleClose={closeDeleteModal}
                payload={deletePayload}
                id={modalPayloadId}
            />
            <PentestReportModal
                open={openDowloadReportModal}
                handleClose={closeDownloadPentestReportModal}
                title={t('pentestReportModal.title')}
                subtitle={t('pentestReportModal.subtitle')}
                report={report}
            />
            <Tour open={openUserGuide} onClose={CloseUserGuide} steps={steps} targets={steps.target} />
            <AppArticle
                open={openArticle}
                handleClose={CloseArticle}
                title="Penetration Testing"
                headerId={oneArticle?.id}
                icon={true}
            />
            <ChangeSchedulingModal
                open={changeScheduleModal}
                payload={modalPayload}
                handleClose={closeChangeScheduleModal}
                id={modalPayloadId}
            />
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        merchant_pentests_requests: state?.merchantReducers?.merchant_pentests_requests,
        pricing_plan: state?.authReducers.pricing_plan,
        userguide_info: state?.guideReducers?.userguide_info?.[0]?.pentest,
        all_article_header: state?.articlesReducers?.all_article_header,
    };
};
export default connect(mapStateToProps, {})(PenetrationTest);
