export const riskOptions = ['Inherent risk', 'Residual risk'];
export const employeeOptions = ['Onboarding Policy', 'Computer scan', 'Employee Training'];
export const inventoryOptions = [
    'Vulnerability scans',
    // 'Infrastructures',
    'Source Code Review',
    'Penetration testing',
    'PCI Scan',
    'API compliance',
];
export const thirdpartyOptions = ['Anti-Money Laundering Check', 'Transaction Fraud Monitoring', 'Vendor Management'];
export const viewOptions = ['All', 'Report View', 'Graphical Presentation'];
export const resultOptions = ['All results', 'Okay results only', 'Needs attention result only'];

export const frameworksOptions = [
    { name: 'PCI DSS', status: 'successful', questions: '11/11', documents: '11/11' },
    { name: 'NDPR', status: 'failed', questions: '0/11', documents: '11/11' },
    { name: 'ISO 27001', status: 'successful', questions: '11/11', documents: '11/11' },
    { name: 'SOC 2', status: 'failed', value: '4/20', questions: '0/11', documents: '11/11' },
];

export const employeescansOptions = [
    { name: 'Employee with Background Check', status: 'successful', value: '10/10' },
    { name: 'Employee with Onboarding  Policy', status: 'failed', value: '4/20' },
    { name: 'Employee with Background Check', status: 'successful', value: '10/10' },
    { name: 'Employee Training', status: 'failed', value: '4/20' },
];

export const inventory_scans_Options = [
    { name: 'Anti-Money Laundering Check', status: 'successful', value: '10/10' },
    { name: 'Transaction Fraud Monitoring', status: 'failed', value: '4/20' },
    { name: 'Vendor Management', status: 'successful', value: '10/10' },
];

export const third_partyOptions = [
    {
        name: 'Anti-Money Laundering CheckVulnerability scan',
        is_port: true,
        status: 'successful',
        port_open: 4,
        port_closed: 2,
    },
    { name: 'Infrastructures scanned', status: 'failed', value: '2/4' },
    { name: 'API Compliance', attention: true, status: 'successful', needs_attention: '' },
];

export const risk_options = [
    {
        asset: 'Asset 11eeei eejeje',
        threat: 'Malicious Insider',
        risk_owner: 'Ononogbo Kolade',
        risk_level: {
            inherent: 'low',
            residual: 'high',
        },
        status: 'Closed',
    },
    {
        asset: 'Asset 11eeei eejeje',
        threat: 'Malicious Insider',
        risk_owner: 'Ononogbo Kolade',
        risk_level: {
            inherent: 'high',
            residual: 'low',
        },
        status: 'Active',
    },
    {
        asset: 'Asset 11eeei eejeje',
        threat: 'Malicious Insider',
        risk_owner: 'Ononogbo Kolade',
        risk_level: {
            inherent: 'low',
            residual: 'high',
        },
        status: 'Closed',
    },
    {
        asset: 'Asset 11eeei eejeje',
        threat: 'Malicious Insider',
        risk_owner: 'Ononogbo Kolade',
        risk_level: {
            inherent: 'high',
            residual: 'low',
        },
        status: 'Active',
    },
];

export const employees_options = [
    {
        name: 'Ononogbo Kolade',
        total_completed: 3,
        total_policy: 3,
        agent: 'installed',
        identity_check: 'completed',
    },
    {
        name: 'Ononogbo Kolade',
        total_completed: 2,
        total_policy: 3,
        agent: 'uninstalled',
        identity_check: 'pending',
    },
    {
        name: 'Ononogbo Kolade',
        total_completed: 3,
        total_policy: 3,
        agent: 'installed',
        identity_check: 'completed',
    },
    {
        name: 'Ononogbo Kolade',
        total_completed: 2,
        total_policy: 3,
        agent: 'uninstalled',
        identity_check: 'pending',
    },
];

export const computers_options = [
    {
        owner: 'Ononogbo Kolade',
        computer_name: 'P EliteBook 840 G3',
        date: '10/02/2021',
        password_manager: 'No',
        encryption: 'Yes',
        anit_virus: 'No',
        screenlock: 'Yes',
    },
    {
        owner: 'Ononogbo Kolade',
        computer_name: 'P EliteBook 840 G3',
        date: '10/02/2021',
        password_manager: 'Yes',
        encryption: 'No',
        anit_virus: 'Yes',
        screenlock: 'No',
    },
    {
        owner: 'Ononogbo Kolade',
        computer_name: 'P EliteBook 840 G3',
        date: '10/02/2021',
        password_manager: 'No',
        encryption: 'Yes',
        anit_virus: 'No',
        screenlock: 'Yes',
    },
    {
        owner: 'Ononogbo Kolade',
        computer_name: 'P EliteBook 840 G3',
        date: '10/02/2021',
        password_manager: 'Yes',
        encryption: 'No',
        anit_virus: 'Yes',
        screenlock: 'No',
    },
];

export const employee_training_options = [
    {
        name: 'Ononogbo Kolade',
        course: 'Secure Coding',
        status: 'completed',
    },
    {
        name: 'Ononogbo Kolade',
        course: 'Cyberecurity 101',
        status: 'uncompleted',
    },
    {
        name: 'Ononogbo Kolade',
        course: 'Secure Coding',
        status: 'completed',
    },
    {
        name: 'Ononogbo Kolade',
        course: 'Cyberecurity 101',
        status: 'uncompleted',
    },
];

export const automatedScanChecks = [
    'Identity & Access Management',
    'Network Operations',
    'Vulnerability Management',
    'System & security Monitoring',
    'Server Configuration',
    'Data Management',
    'Configuration Management',
    'Software development Lifecycle',
];

export const standardOptions = [
    {
        category: 'Requirement 1',
        sub_category: [
            {
                name: '1.01',
                questions: ['4/8', '4/8'],
                policies: [
                    {
                        name: 'Network Security Policy',
                        value: true,
                    },
                    {
                        name: 'Change Management Process',
                        value: false,
                    },
                ],
                evidences: [
                    {
                        name: 'Documentation of Notice Provision',
                        value: true,
                    },
                    {
                        name: 'Privacy Policy Availability',
                        value: false,
                    },
                ],
            },
            {
                name: '1.02',
                questions: ['4/8', '4/8'],
                policies: [
                    {
                        name: 'Network Security Policy',
                        value: true,
                    },
                    {
                        name: 'Change Management Process',
                        value: false,
                    },
                ],
                evidences: [
                    {
                        name: 'Documentation of Notice Provision',
                        value: true,
                    },
                    {
                        name: 'Privacy Policy Availability',
                        value: false,
                    },
                ],
            },
        ],
    },
    {
        category: 'Requirement 2',
        sub_category: [
            {
                name: '2.01',
                questions: ['4/8', '4/8'],
                policies: [
                    {
                        name: 'Network Security Policy',
                        value: true,
                    },
                    {
                        name: 'Change Management Process',
                        value: false,
                    },
                ],
                evidences: [
                    {
                        name: 'Documentation of Notice Provision',
                        value: true,
                    },
                    {
                        name: 'Privacy Policy Availability',
                        value: false,
                    },
                ],
            },
            {
                name: '2.02',
                questions: ['4/8', '4/8'],
                policies: [
                    {
                        name: 'Network Security Policy',
                        value: true,
                    },
                    {
                        name: 'Change Management Process',
                        value: false,
                    },
                ],
                evidences: [
                    {
                        name: 'Documentation of Notice Provision',
                        value: true,
                    },
                    {
                        name: 'Privacy Policy Availability',
                        value: false,
                    },
                ],
            },
        ],
    },
];

export const vul_scans_options = [
    {
        port: '8000',
        ip_address: '192.192.78.01',
        status: 'open',
    },
    {
        port: '8000',
        ip_address: '192.192.78.01',
        status: 'closed',
    },
    {
        port: '8000',
        ip_address: '192.192.78.01',
        status: 'open',
    },
    {
        port: '8000',
        ip_address: '192.192.78.01',
        status: 'closed',
    },
];

export const infracture_scans_options = [
    {
        port: '8000',
        type: 'Local',
        sensitivity: 'Low',
        status: 'active',
    },
    {
        port: '8000',
        type: 'Local',
        sensitivity: 'Low',
        status: 'inactive',
    },
    {
        port: '8000',
        type: 'Local',
        sensitivity: 'Low',
        status: 'active',
    },
    {
        port: '8000',
        type: 'Local',
        sensitivity: 'Low',
        status: 'inactive',
    },
];

export const pentesting_options = [
    {
        name: 'SmartComply Dev',
        date: '21st July, 2024',
        date_range: '(July - December)',
        sensitivity: 'High',
        status: 'completed',
    },
    {
        name: 'SmartComply Dev',
        date: '21st July, 2024',
        date_range: '(July - December)',
        sensitivity: 'Low',
        status: 'completed',
    },
    {
        name: 'SmartComply Dev',
        date: '21st July, 2024',
        date_range: '(July - December)',
        sensitivity: 'High',
        status: 'completed',
    },
    {
        name: 'SmartComply Dev',
        date: '21st July, 2024',
        date_range: '(July - December)',
        sensitivity: 'Low',
        status: 'completed',
    },
];

export const pci_scans_options = [
    {
        name: 'SmartComply Dev',
        date: '21st July, 2024',
        date_range: '(July - December)',
        progress: 'Completed',
        status: 'Success',
    },
    {
        name: 'SmartComply Dev',
        date: '21st July, 2024',
        date_range: '(July - December)',
        progress: 'Completed',
        status: 'Failed',
    },
    {
        name: 'SmartComply Dev',
        date: '21st July, 2024',
        date_range: '(July - December)',
        progress: 'Completed',
        status: 'Success',
    },
    {
        name: 'SmartComply Dev',
        date: '21st July, 2024',
        date_range: '(July - December)',
        progress: 'Completed',
        status: 'Failed',
    },
];

export const api_compliance_options = [
    {
        endpoint: 'https://iqsa.herokuapp.com',
        date: '21st July, 2024',
        date_range: '(July - December)',
        severity: 'Needs Attention',
        status: 'completed',
    },
    {
        endpoint: 'https://iqsa.herokuapp.com',
        date: '21st July, 2024',
        date_range: '(July - December)',
        severity: 'Looks Okay',
        status: 'completed',
    },
    {
        endpoint: 'https://iqsa.herokuapp.com',
        date: '21st July, 2024',
        date_range: '(July - December)',
        severity: 'Needs Attention',
        status: 'completed',
    },
    {
        endpoint: 'https://iqsa.herokuapp.com',
        date: '21st July, 2024',
        date_range: '(July - December)',
        severity: 'Looks Okay',
        status: 'completed',
    },
];

export const codebase_scanning_options = [
    {
        repository: 'https://github.com/386konsult/smartcompl..',
        date: '21st July, 2024',
        status: 'Completed',
    },
    {
        repository: 'https://github.com/386konsult/smartcompl..',
        date: '21st July, 2024',
        status: 'Pending',
    },
    {
        repository: 'https://github.com/386konsult/smartcompl..',
        date: '21st July, 2024',
        status: 'Completed',
    },
    {
        repository: 'https://github.com/386konsult/smartcompl..',
        date: '21st July, 2024',
        status: 'Pending',
    },
];
